import React, { useState, useEffect, useRef } from 'react';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const Units = () => {
  

  const tableRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);

  const accessToken = localStorage.getItem('accessToken');

  const [unitFormData, setUnitFormData] = useState({
    code: '',
    unitName: '',
  });
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    
    setUnitFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUnitSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('unit_code', unitFormData.code);
    formData.append('unit_name', unitFormData.unitName);
    const apiBrandUrl = `${config.API_URL}/add_product_unit`; 
    try {
      const response = await fetch(apiBrandUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to add unit');
      }

      const data = await response.json();
      const msg = data.message;
      if(data.success){

        Swal.fire({
          title: 'Success',
          text: 'Unit added successfully.',
          icon: 'success', 
          confirmButton: false, 
          timer: 1500,
          showConfirmButton: false, 
        });
      }else{

        Swal.fire({
          title: 'Failed',
          text: msg,
          icon: 'error', 
          confirmButton: false,
          timer: 1500,
          showConfirmButton: false,  
        });
      }


      setUnitFormData({
        code: '',
        unitName: '',
      });

      initializeDataTable();


      
    } catch (error) {
      console.error('Error adding unit:', error);
    }

  };

  const [unitEditData, setUnitEditData] = useState({
    code: '',
    unitName: '',
    uid : '',
  });
  const handleEditClick = (rowData) => {
    setUnitEditData({
      code: rowData.code,
      unitName: rowData.name,
      uid : rowData.id,
    });
  };
  const handleFormEditChange = (e) => {
    const { name, value } = e.target;
    setUnitEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUnitUpdate = async(event) =>{
    event.preventDefault();

    const formData = new FormData();
    formData.append('unit_code', unitEditData.code);
    formData.append('unit_name', unitEditData.unitName);
    formData.append('uid', unitEditData.uid);
    const apiBrandEditUrl = `${config.API_URL}/edit_product_unit`; 
    try {
      const response = await fetch(apiBrandEditUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update unit');
      }

      const data = await response.json();
    

      const msg = data.message;
      if(data.success){

        Swal.fire({
          title: 'Success',
          text: 'unit updated successfully.',
          icon: 'success', 
          confirmButton: false, 
          timer: 1500,
          showConfirmButton: false, 
        });
      }else{

        Swal.fire({
          title: 'Failed',
          text: msg,
          icon: 'error', 
          confirmButton: false,
          timer: 1500,
          showConfirmButton: false,  
        });
      }

      setUnitEditData({
        code: '',
        unitName: '',
        uid : '',
      });

      initializeDataTable();
      
    } catch (error) {
      console.error('Error updated unit:', error);
    }
  };

  
  const initializeDataTable = () => {
      const $table = $(tableRef.current);
      const searchValue = $('#searchValue').val();
      // Destroy existing DataTable instance if it exists
      if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
      }
  
      let i = 0;
      $table.DataTable({
          processing: true,
          serverSide: true,
          ajax: {
              url: `${config.API_URL}/get_product_unit`,
              type: 'POST',
              headers: {
                 authorization : `Bearer ${accessToken}`,
              },
              data: function (d) {
                  return $.extend({}, d, {
                      draw: d.draw,
                      start: d.start,
                      length: d.length,
                      searchValue : searchValue
                  });
              },
              dataSrc: function (json) {
                  return json.data;
                  console.log(json.data);
              }
          },
          language: {
              paginate: {
                  first: 'First',
                  last: 'Last',
                  next: 'Next',
                  previous: 'Previous',
              },
              search: 'Search:',
          },
          columns: [
              { data: null,
                width: "6%",
                render: function(data, type, row, meta) {
                    return meta.row + meta.settings._iDisplayStart + 1;
                }
               },
              { data: 'code',width: "7%"  },
              { data: null,
                render : function(data, type, row){
                  return `<span>${row.name}</span>`;
                }

              },
              {
                data: null,
                title: 'Action',
                width: "7%",
                render: (data, type, row) => `
                <a href="#" class="icon editbtn" title="Edit" data-id="${row.id}" data-bs-toggle="modal" data-bs-target="#edit-unit">
                  <i class="fa fa-pencil"></i>
                </a>`
              }
          ],
          columnDefs: [
            {
                orderable: false, 
                targets: '_all'
            },
            {
                targets: 2, 
                render: function(data) {
                    return data === '1' ? 'Active' : 'Inactive'; 
                }
            }
        ],
        
          pageLength: 10,
      });

      
  

      // Event delegation for edit and view actions
      $table.on('click', '.editbtn', function() {
        const rowId = $(this).data('id'); 
        const rowData = $table.DataTable().row($(this).closest('tr')).data(); // Access DataTable instance
        
        if (rowData && Object.keys(rowData).length > 0) {
            //console.log(rowData);
            handleEditClick(rowData); 
        } 
      });
  };

  useEffect(() => {

      if (accessToken === null) {
          window.location.href = `${config.BASE_URL}/login`;
          return;
      }

      initializeDataTable();  
      return () => {
          if (tableRef.current && $.fn.DataTable.isDataTable(tableRef.current)) {
              $(tableRef.current).DataTable().destroy();
          }
      };
  }, [searchValue]);

  return (
  <div>
  <div class="modal fade" id="add-unit" tabindex="-1">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Unit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="popup-area">
            <p>Please fill in the information below. The field labels marked with <span className='red'>*</span> are required input fields.</p>
            
            <form id="addNewUnit" onSubmit={handleUnitSubmit}>
              <div class="row mb-3 padd-l-13px">
                <label for="unitCode" class="col-sm-4 col-form-label">Unit Code <span className='red'>*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="code" id="unitCode" value={setUnitFormData.code} onChange={handleFormChange} required />
                </div>
              </div>

              <div class="row mb-3 padd-l-13px">
                <label for="unitName" class="col-sm-4 col-form-label">Unit Name <span className='red'>*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="unitName" id="unitName" value={setUnitFormData.unitName} onChange={handleFormChange}  required />
                </div>
              </div>

              

              <hr />
              <div class="row padd-l-13px">
                <div class="col-sm-12">
                  <button type="submit" data-bs-dismiss="modal" aria-label="Close" class="float-right-btn common-popup-btn">Add Unit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="edit-unit" tabindex="-1">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Unit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="popup-area">
            
            
            <form id="editUnit" onSubmit={handleUnitUpdate}>
              <div class="row mb-3 padd-l-13px">
                <label for="editUnitCode" class="col-sm-4 col-form-label">Unit Code <span className='red'>*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="code" id="editUnitCode" value={unitEditData.code}  onChange={handleFormEditChange} required />
                </div>
              </div>

              <div class="row mb-3 padd-l-13px">
                <label for="editUnitName" class="col-sm-4 col-form-label">Unit Name <span className='red'>*</span></label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="unitName" id="editUnitName" value={unitEditData.unitName}  onChange={handleFormEditChange} required />
                </div>
              </div>

             <input type="hidden" value={unitEditData.uid} />

              <hr />
              <div class="row padd-l-13px">
                <div class="col-sm-12">
                  <button type="submit" data-bs-dismiss="modal" aria-label="Close" class="float-right-btn common-popup-btn">Edit Unit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Header />
  <div class="breadcrumbs">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-12 col-sm-12">
          <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="Dashboard">Master</a> &gt; <span class="last-crumb">Units</span>
        </div>
      </div>
    </div>
  </div>

  <div className="main-container">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-12 col-sm-12">
          <div style={{ position: 'relative' }}>
            <div class="row">
              <div class="col-12 col-lg-4 col-sm-4">
                {/* Search functionality can be added here */}
              </div>
              <div class="col-12 col-lg-8 col-sm-8">
                <div style={{ float: "right" }}>
                <a class="create-catalogue-btn" href="#" data-bs-toggle="modal" data-bs-target="#add-unit" style={{ marginLeft: '10px'}}>Add Unit</a>
                <a class="create-catalogue-btn" href="#" style={{ marginLeft: '10px'}}>Export to Excel</a>
                

                  {/* <div class="dropdown">
                    <button class="action-btn-dd dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> Action </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#add-unit">Add Unit</a></li>
                      <li><a class="dropdown-item" href="#">Export to Excel</a></li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="show-order-area dashboard" >
              <div className="verical-scrl-wrapper common-table unit-table" id="style-3">
                <table className="table table-bordered dataTable resizable" id="neworderTable" ref={tableRef} >
                  <thead>
                    <tr>
                      <th style={{width: '50px'}}>#</th>
                      <th>Unit Code</th>
                      <th>Unit Name</th>
                      <th style={{width: '120px'}}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</div>

  );
};

export default Units; 