import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css'; // Import Select2 CSS
import 'select2';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import config from "../../common/Config";
import Swal from 'sweetalert2';
import SelectDropdown from './SelectDropdown';

const User_Profile = () => {
  const { userId } = useParams();
  const modalRef = useRef(null);
  const [userRoles, setUserRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [privacyErrors, setPrivacyErrors] = useState({});
  const navigateToUserList = useNavigate();

  const [formData, setFormData] = useState({
    id: '',
    avatar: '',
    username: '',
    email: '',
    group: [], // Initialize as an empty array
    first_name: '',
    last_name: '',
    address: '',
    postcode: '',
    city: '',
    state: '',
    mobile: '',
    image: '',
  });
  const [roleOptions, setRoleOptions] = useState([]);
  const selectRef = useRef(null);
  const accessToken = localStorage.getItem('accessToken'); // Ensure access token is valid

  const fetchUserRoles = async () => {
    const apiUserRolesUrl = `${config.API_URL}/get_users_role`;
    try {
      const response = await fetch(apiUserRolesUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      /*const formattedOptions = data.data.map(item => ({
        key: `${item.name}`,
        value: item.id,
      }));*/

      const formattedOptions = data.data.map(item => ({
        key: `${item.name}`,
        value: `${item.id}`
      }));
      setRoleOptions(formattedOptions);
    } catch (error) {
      console.error('Error fetching user roles:', error);
    }
  };

  const fetchUserInfo = async () => {
    const formData = new FormData();
    formData.append('id', userId); // Make sure userId is defined

    const apiUseInfoUrl = `${config.API_URL}/edit_users`;
    try {
      const response = await fetch(apiUseInfoUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });
      const data = await response.json();
      const rolesArray = data.data.id_roles
        ? data.data.id_roles.split(',').map(role => role.trim())
        : [];
      setFormData(prevFormData => ({
        ...prevFormData,
        id: data.data.id,
        avatar: data.data.avatar,
        username: data.data.username,
        email: data.data.email,
        group: rolesArray, // Set group from rolesArray
        first_name: data.data.first_name,
        last_name: data.data.last_name,
        address: data.data.address,
        postcode: data.data.postcode,
        city: data.data.city,
        state: data.data.state,
        mobile: data.data.phone,
        image: data.data.image,
      }));


      //handleChangeRole(data.data.id_roles);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    fetchUserRoles();
    fetchUserInfo();



  }, [accessToken]);

  useEffect(() => {
    if (selectRef.current) {
      const selectElement = $(selectRef.current);
      selectElement.select2({
        placeholder: 'Select Role',
        width: '100%',
        tags: true,
      }).val(formData.group).trigger('change'); // Set initial value

      selectElement.on('change', handleChangeRole);

      return () => {
        selectElement.select2('destroy');
      };
    }


  }, [roleOptions]);



  const handleChangeRole = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setFormData(prevFormData => ({
      ...prevFormData,
      group: selectedOptions, // Update group directly
    }));
  };

  const validateForm = () => {
    const newErrors = {};


    if (!formData.first_name) {
      newErrors.first_name = "Mention Name.";
    } else if (formData.first_name.length < 2) {
      newErrors.first_name = "name must consist of at least 2 characters.";
    } else if (formData.first_name.length > 255) {
      newErrors.first_name = "name must not exceed 255 characters.";
    }

    if (!formData.last_name) {
      newErrors.last_name = "Mention Name.";
    } else if (formData.last_name.length < 2) {
      newErrors.last_name = "name must consist of at least 2 characters.";
    } else if (formData.last_name.length > 255) {
      newErrors.last_name = "name must not exceed 255 characters.";
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Mention Email Id.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email.";
    }







    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let fieldErrors = {};
    switch (name) {
      case 'first_name':
        if (!value) {
          fieldErrors.first_name = "Mention Name.";
        } else if (value.length < 2) {
          fieldErrors.first_name = "name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.first_name = "name must not exceed 255 characters.";
        }
        if (!fieldErrors.first_name) {
          fieldErrors.first_name = '';
        }
        break;


      case 'last_name':
        if (!value) {
          fieldErrors.last_name = "Mention Name.";
        } else if (value.length < 2) {
          fieldErrors.last_name = "name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.last_name = "name must not exceed 255 characters.";
        }
        if (!fieldErrors.last_name) {
          fieldErrors.last_name = '';
        }
        break;



      case 'email':
        if (!value) {
          fieldErrors.email = "Mention Email Id.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.email = "Please enter a valid email.";
        }
        if (!fieldErrors.email) {
          fieldErrors.email = '';
        }
        break;











      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors
    }));
  };

  const submitUpdateUserForm = async (event) => {

    event.preventDefault();

    if (!validateForm()) return;
    const form = document.getElementById('updateuserform');
    const formData = new FormData(form);

    console.log("test");

    try {
      const response = await axios.post(`${config.API_URL}/update_users`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });
      const result = response.data;
      if (result.success == true) {
        Swal.fire({
          title: "Success!",
          text: "User updated successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {

          window.location.href = config.BASE_URL + '/users';


        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: "User updated Failed. Try Again",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error User update:", error);
    }
  };


  const [formPrivacyData, setFormPrivacyData] = useState({
    currentPassword: '',
    password: '',
    confpass: '',
  });

  const validatePrivacyForm = () => {
    const newPrivacyErrors = {};

    if (!formPrivacyData.currentPassword) {
      newPrivacyErrors.currentPassword = "Mention Password.";
    } else if (formPrivacyData.currentPassword.length < 6) {
      newPrivacyErrors.currentPassword = "Password must consist of at least 6 digits.";
    } else if (formPrivacyData.currentPassword.length > 255) {
      newPrivacyErrors.currentPassword = "Password must not exceed 6 digits.";
    }

    if (!formPrivacyData.password) {
      newPrivacyErrors.password = "Mention Password.";
    } else if (formPrivacyData.password.length < 6) {
      newPrivacyErrors.password = "Password must consist of at least 6 digits.";
    } else if (formPrivacyData.password.length > 6) {
      newPrivacyErrors.password = "Password must not exceed 6 digits.";
    }

    if (!formPrivacyData.confpass) {
      newPrivacyErrors.confpass = "Please confirm your password.";
    } else if (formPrivacyData.confpass !== formPrivacyData.password) {
      newPrivacyErrors.confpass = "Passwords do not match.";
    }
    setPrivacyErrors(newPrivacyErrors);
    return Object.keys(newPrivacyErrors).length === 0;
  };
  const handlePrivacyChange = (e) => {
    const { name, value } = e.target;
    setFormPrivacyData({ ...formPrivacyData, [name]: value });

    let fieldPrivacyErrors = {};
    switch (name) {

      case 'currentPassword':
        if (!value) {
          fieldPrivacyErrors.currentPassword = "Mention password.";
        } else if (value.length < 6) {
          fieldPrivacyErrors.currentPassword = "password must consist of at least 6 digits.";
        } else if (value.length > 6) {
          fieldPrivacyErrors.currentPassword = "password must not exceed 6 digits.";
        }
        if (!fieldPrivacyErrors.currentPassword) {
          fieldPrivacyErrors.currentPassword = '';
        }
        break;

      case 'password':
        if (!value) {
          fieldPrivacyErrors.password = "Mention password.";
        } else if (value.length < 6) {
          fieldPrivacyErrors.password = "password must consist of at least 6 digits.";
        } else if (value.length > 6) {
          fieldPrivacyErrors.password = "password must not exceed 6 digits.";
        }
        if (!fieldPrivacyErrors.password) {
          fieldPrivacyErrors.password = '';
        }
        break;

      case 'confpass':
        if (!value) {
          fieldPrivacyErrors.confpass = "Please confirm your password.";
        } else if (value.length < 6) {
            fieldPrivacyErrors.confpass = "confirm password must consist of at least 6 digits.";
        } else if (value.length > 6) {
            fieldPrivacyErrors.confpass = "confirm password must not exceed 6 digits.";
        }else  if (value !== formPrivacyData.password) {
          fieldPrivacyErrors.confpass = "Passwords do not match.";
        }
        if (!fieldPrivacyErrors.confpass) {
          fieldPrivacyErrors.confpass = '';
        }
        break;





      default:
        break;
    }
    setPrivacyErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldPrivacyErrors
    }));
  };
  const submitUpdatePasswordForm = async (event) => {
    event.preventDefault();

    if (!validatePrivacyForm()) return;
    const form = document.getElementById('privacyFormUpdate');
    const formData = new FormData(form);



    try {
      const response = await axios.post(`${config.API_URL}/update_password`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });
      const result = response.data;
      if (result.success == true) {
        Swal.fire({
          title: "Success!",
          text: "Password updated successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {




        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Password updated Failed. Try Again",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error User update:", error);
    }
  }



  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <a href="#"><i class="fa fa-home"></i></a> &gt;
              <a href="Dashboard">Master</a> &gt;
              <span class="last-crumb">User Profile</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div class="userprofile-detail-area">
                <div class="row">
                  {/* <div class="col-xl-4"> */}
                    {/* <div class="h-100 gradient-custom-2">
                      <div class="h-100">
                        <div class="row d-flex justify-content-center h-100">
                          <div class="col col-lg-12 col-xl-12">
                            <div class="card">
                              <div class="rounded-top text-white d-flex flex-row" style={{ backgroundColor: "#424852", height: "200px" }}>
                                <div class="ms-4 mt-5 d-flex flex-column" style={{ width: "150px" }}>
                                  <img
                                    src={formData.avatar ? formData.avatar : "../react/public/assets/img/profile-img.jpg"}
                                    className="img-fluid img-thumbnail mt-4 mb-2"
                                    style={{ width: "150px", zIndex: "1" }}
                                  />
                                </div>
                                <div class="ms-3" style={{ marginTop: "130px" }}>
                                  <h4>{formData.first_name}</h4>
                                  <p>{formData.state}</p>
                                </div>
                              </div>
                              <div class="p-4" style={{ backgroundColor: "#f8f9fa" }}>
                                <div class="d-flex justify-content-end text-center py-1">
                                  <div>
                                    <p class="mb-1 h5">Phone</p>
                                    <p class="small text-muted mb-0">{formData.mobile}</p>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}


                    {/* New */}
                    {/* <div class="card">
            <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">

             
              <img src={formData.avatar ? formData.avatar : "../react/public/assets/img/profile-img.jpg"}
                                    className="img-fluid img-thumbnail mt-4 mb-2"
                                    style={{ width: "150px", zIndex: "1" }} class="rounded-circle"
                                  />
              <h4>{formData.first_name}</h4>
              
              <div>
                                    <p class="mb-1 h5">Phone</p>
                                    <p class="small text-muted mb-0">{formData.mobile}</p>
                                  </div>
              
            </div>
          </div> */}

{/* End new */}

                  {/* </div> */}
                  <div class="col-sm-7 mx-auto">
                    <div class="card">
                      <div class="card-body pt-3">
                        <ul class="nav nav-tabs nav-tabs-bordered">
                          <li class="nav-item">
                            <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                          </li>
                          <li class="nav-item">
                            <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                          </li>
                        </ul>
                        <div class="tab-content pt-2">
                          <div class="tab-pane fade show active profile-edit pt-3" id="profile-edit">
                            <form id="updateuserform" >
                              <div class="mb-3">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="col-md-12 col-lg-12">
                                      <label>First Name <b className='red'>*</b></label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <div className="input-group">
                                        <div className="col-sm-12">
                                          <input type="text" class="userinput" id="first_name" value={formData.first_name} onChange={handleChange} name="first_name" />
                                        </div>
                                        <div className="col-sm-12">
                                          {errors.first_name && <span className="text-danger">{errors.first_name}</span>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="col-md-12 col-lg-12">
                                      <label>Last Name <b className='red'>*</b></label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <div className="input-group">
                                        <div className="col-sm-12">
                                          <input name="last_name" type="text" class="userinput" id="last_name" value={formData.last_name} onChange={handleChange} />
                                        </div>
                                        <div className="col-sm-12">
                                          {errors.last_name && <span className="text-danger">{errors.last_name}</span>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mb-3">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="col-md-4 col-lg-3">
                                      <label>Phone </label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <input name="mobile" type="text" class="userinput" id="mobile" value={formData.mobile} onChange={handleChange} />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="col-md-4 col-lg-3">
                                      <label>Email <b className='red'>*</b></label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <div className="input-group">
                                        <div className="col-sm-12">
                                          <input name="email" type="text" class="userinput" id="email" value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div className="col-sm-12">
                                          {errors.email && <span className="text-danger">{errors.email}</span>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mb-3">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="col-md-4 col-lg-3">
                                      <label>Address</label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <input name="address" type="text" class="userinput" id="address" value={formData.address} onChange={handleChange} />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="col-md-4 col-lg-3">
                                      <label>State</label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <input name="city" type="text" class="userinput" id="city" value={formData.city} onChange={handleChange} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mb-3">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="col-md-4 col-lg-3">
                                      <label>Country</label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <input name="state" type="text" class="userinput" id="state" value={formData.state} onChange={handleChange} />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="col-md-4 col-lg-3">
                                      <label>Postcode</label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <input type="text" class="userinput" id="fullName" value={formData.postcode} onChange={handleChange}
                                        name="postcode" /> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mb-3">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="col-md-4 col-lg-3">
                                      <label>Username <b className='red'>*</b></label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <input type="text" class="userinput" id="fullName" value={formData.username} name="username" disabled />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="col-md-12 col-lg-12">
                                      <label>Change Avatar</label>
                                    </div>
                                    <div class="col-md-12 col-lg-12">
                                      <input type="file" accept="image/png, image/jpg, image/jpeg" class="form-control" name="avatar" id="avatar" />
                                    </div>

                                  </div>
                                </div>
                                <input type="hidden" class="form-control" name="id" id="id" value={formData.id} />
                              </div>
                              <div class="mb-3">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="col-md-4 col-lg-3">
                                      <label>Role <b className='red'>*</b></label>
                                    </div>
                                    <div class="col-md-12 col-lg-12" style={{ postion: 'relative' }}>




                                      <SelectDropdown
                                        selectRef={selectRef}
                                        value={formData.group}
                                        handleChange={handleChange}
                                        roleOptions={roleOptions}
                                        name="group[]"
                                        id="group"
                                      />


                                      {/* <select
                                                                    ref={selectRef}
                                                                    multiple={true}
                                                                    value={formData.group}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    name="group[]"
                                                                    id="group"
                                                                  >
                                                                    {roleOptions.map(option => (
                                                                      <option key={option.value} value={option.value}>
                                                                        {option.key}
                                                                      </option>
                                                                    ))}
                                                                  </select> */}


                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              <div class="mb-3">
                                {/* <label class="col-md-4 col-lg-3 col-form-label">&nbsp;</label> */}
                                <div class="col-md-8 col-lg-9">
                                  <button type="button" onClick={submitUpdateUserForm} class="common-popup-btn">Save Changes</button>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div class="tab-pane fade pt-3" id="profile-change-password">
                            <form id="privacyFormUpdate">
                              <div class="row mb-3">
                                <div class="col-md-5 col-lg-4">
                                  <label>Current Password <b className='red'>*</b></label>
                                </div>
                                <input type="hidden" class="form-control" name="id" id="id" value={formData.id} />
                                <div class="col-md-7 col-lg-8">
                                  <div className="input-group">
                                    <div className="col-sm-12">
                                      <input name="currentPassword" type="password" class="userinput" id="currentPassword" value={formPrivacyData.currentPassword} onChange={handlePrivacyChange} />
                                    </div>
                                    <div className="col-sm-12">
                                      {privacyErrors.currentPassword && <span className="text-danger">{privacyErrors.currentPassword}</span>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-md-5 col-lg-4">
                                  <label>New Password <b className='red'>*</b></label>
                                </div>
                                <div class="col-md-7 col-lg-8">
                                  <div className="input-group">
                                    <div className="col-sm-12">
                                      <input name="password" type="password" class="userinput" id="password" value={formPrivacyData.password} onChange={handlePrivacyChange} />
                                    </div>
                                    <div className="col-sm-12">
                                      {privacyErrors.password && <span className="text-danger">{privacyErrors.password}</span>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-md-5 col-lg-4">
                                  <label>Re-enter New Password <b className='red'>*</b></label>
                                </div>
                                <div class="col-md-7 col-lg-8">
                                  <div className="input-group">
                                    <div className="col-sm-12">
                                      <input name="confpass" type="password" class="userinput" id="confpass" value={formPrivacyData.confpass} onChange={handlePrivacyChange} />
                                    </div>
                                    <div className="col-sm-12">
                                      {privacyErrors.confpass && <span className="text-danger">{privacyErrors.confpass}</span>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mb-3">
                                <div class="col-md-5 col-lg-4">
                                  <label>&nbsp;</label>
                                </div>
                                <div class="col-md-7 col-lg-8">
                                  <button type="button" onClick={submitUpdatePasswordForm} class="common-popup-btn">Change Password</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <Footer />
    </div>
  );
};

export default User_Profile; 