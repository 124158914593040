import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import config from "../common/Config";
import ActiveProductList from "../Master/Products/ActiveProductList";
import InactiveProductList from "../Master/Products/InactiveProductList";
import AllProductList from "../Master/Products/AllProductList";
import $ from "jquery";
import Swal from "sweetalert2";
import Header from "../Header";
import Footer from "../Footer";

const Inventory_Report = () => {
  const activePdtRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const [totalActiveProduct, setTotalActiveProduct] = useState("");

  const getAcivepdtList = () => {
    const $table = $(activePdtRef.current);
    const searchValue = $("#searchValue").val();
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }
    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/products/getactive_product`,
        type: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue,
          });
        },
        dataSrc: function (json) {
          if (json && json.data && Array.isArray(json.data)) {
            setTotalActiveProduct(json.recordsFiltered);
          }
          return Array.isArray(json.data) ? json.data : [];
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        { data: "code", width: "5%" },

        {
          data: null,

          render: function (data, type, row) {
            return `<span>${row.name}</span>`;
          },
        },
        {
          data: null,

          render: function (data, type, row) {
            return `<span>${row.categories}</span>`;
          },
        },
        {
          data: "brand",
          render: function (data, type, row) {
            if (data === null || data === undefined) {
              return "";
            }
            return data;
          },
        },
        {
          data: null,

          render: function (data, type, row) {
            return `<span>${row.size}</span>`;
          },
        },

        { data: "box_cost", width: "5%" },
        { data: "price", width: "5%" },
        { data: "box_diff", width: "5%", className: "text-center" },
        { data: "quantity" },
        { data: "piece_cost" },
        { data: "piece_price" },
        { data: "piece_diff", width: "5%" },
        { data: "split_quantity" },
      ],
      columnDefs: [
        {
          orderable: false,
          targets: "_all",
        },
        {
          targets: 2,
          render: function (data) {
            return data === "1" ? "Active" : "Inactive";
          },
        },
      ],

      pageLength: 10,
    });

    $table.on("change", ".switch-input", function () {
      const id = $(this).data("id");
      const status = this.checked ? 1 : 0;

      handleChangeStatus(id, status);
    });
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    getAcivepdtList();

    return () => {
      if (
        activePdtRef.current &&
        $.fn.DataTable.isDataTable(activePdtRef.current)
      ) {
        $(activePdtRef.current).DataTable().destroy();
      }
    };
  }, [searchValue]);

  const handleChangeStatus = async (id, status) => {
    const formData = new FormData();
    formData.append("productId", id);
    formData.append("status", status);
    const apiUrl = `${config.API_URL}/products/update_status`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to change status");
      }

      const data = await response.json();
      console.log(data);

      Swal.fire({
        title: "Success",
        text: "Status changed successfully.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });

      getAcivepdtList();
    } catch (error) {
      console.error("Error status change:", error);
    }
  };

  return (
    <>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i class="fa fa-home"></i>
              </a>{" "}
              &gt;
              <a href="Dashboard">Sales</a> &gt;
              <span class="last-crumb">Inventory Report</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: "relative" }}>
                <div className="show-order-area dashboard">
                  <div
                    className="verical-scrl-wrapper common-table inventory-report-area"
                    id="style-3"
                  >
                    <table
                      class="table table-bordered dataTable active-tbl resizable"
                      id="activePdtListTable"
                      ref={activePdtRef}
                    >
                      <thead>
                        <tr>
                          <th colspan="5" className="dark-head">
                            &nbsp;
                          </th>
                          <th
                            colspan="4"
                            className="dark-head"
                            style={{ textAlign: "center" }}
                          >
                            Box
                          </th>
                          <th
                            colspan="4"
                            className="dark-head"
                            style={{ textAlign: "center" }}
                          >
                            Piece
                          </th>
                        </tr>
                        <tr>
                          <th className="first-class">Code</th>
                          <th>Name</th>
                          <th>Category</th>
                          <th>Brand</th>
                          <th>Size</th>
                          <th className="minitable-one">Cost P</th>
                          <th className="minitable-one">Sale P</th>
                          <th className="minitable-one">Profit %</th>
                          <th className="minitable-one" width="50px">
                            Qty
                          </th>
                          <th className="minitable-two">Cost P</th>
                          <th className="minitable-two">Sale P</th>
                          <th className="minitable-two">Profit %</th>
                          <th className="minitable-two">Qty</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <Footer />
    </>
  );
};

export default Inventory_Report;
