import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Button } from 'bootstrap/dist/js/bootstrap.bundle.min';


const Quick_Order = () => {
 const accessToken = localStorage.getItem("accessToken");
const [customerOptions, setCustomerOptions] = useState([]);
const [customerInputValue, setCustomerInputValue] = useState("");
const [customerDeatils, setCustomerDeatils] = useState("");


 const fetchCustomerNameOptions = async (inputValue) => {
    if (inputValue.length < 3) {
      return;
    }

    const formData = new FormData();
    formData.append("term", inputValue);
    formData.append("limit", "10");

    const apiSalesRapUrl = `${config.API_URL}/suggestions`;
    try {
      const response = await fetch(apiSalesRapUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (Array.isArray(data.results)) {
        const formattedOptions = data.results.map((item) => ({
          label: item.text,
          value: item.id,
        }));
        setCustomerOptions(formattedOptions);

      }
    } catch (error) {
      console.error("Error fetching customer options:", error);
    }
  };


   const fetchCustomerDeatils = async (selectedOption) => {
    let customer_id = selectedOption.value ? selectedOption.value : "";
    const formData = new FormData();
    formData.append("customer_id",customer_id);
    formData.append("platform", 'web');
   formData.append("authorization", `${accessToken}`);

    const apiSalesRapUrl = `${config.API_URL}/ws-get-previous-dues`;
    try {
      const response = await fetch(apiSalesRapUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      console.log(data);

   setCustomerDeatils(data.details); 

    } catch (error) {
      console.error("Error fetching customer options:", error);
    }
  };
const fetchProductDeatils = async (selectedOption) => {
    let product_code = selectedOption ? selectedOption : "";
    // alert(customer_id);
    const formData = new FormData();
    formData.append("customerId",customerInputValue.value ? customerInputValue.value : "");
    formData.append("pattern", product_code);
  
    const apiSalesRapUrl = `${config.API_URL}/get_quick_order_products_list`;
    try {
      const response = await fetch(apiSalesRapUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      console.log(data);
       if (data.details && data.details.length) {
      setRows((prevRows) => [
        ...prevRows, 
        ...data.details.map((product) => ({
          productCode: product.product_code,
          productName: product.product_name,
          orderType: 'Box', // Default to 'Box' or change based on your requirement
          quantity: 1,      // Default value for quantity
          rate: product.price || 0, // Default price or fetched price
        })),
      ]);
    }
  // setCustomerDeatils(data.details); 

    } catch (error) {
      console.error("Error fetching customer options:", error);
    }
  };


    const [rows, setRows] = useState([
        { productCode: '', productName: '', orderType: '', quantity: '', rate: '', amount: '' },
      ]);
    
      // Function to add a new row
      const addRow = () => {
        const newRow = { productCode: '', productName: '', orderType: '', quantity: '', rate: '', amount: '' };
        setRows((prevRows) => [...prevRows, newRow]);
      };
    
      // Function to remove a row
      const removeRow = (index) => {
        if (rows.length === 1) {
          alert("You don't have permission to delete this!");
          return;
        }
        setRows((prevRows) => prevRows.filter((_, i) => i !== index));
      };
    
      // Function to handle input changes
      const handleInputChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
        //fetchProductDeatils(value);

      };
     const handleFocusOut =(index, field, value)=>{
         fetchProductDeatils(value);
     }
   const handleCustomerChange = (inputValue) => {
    fetchCustomerNameOptions(inputValue);
  };
  const handleChange = (selectedOption) => {
    setCustomerInputValue(selectedOption);
    fetchCustomerDeatils(selectedOption);
  };

    return ( 
        <div>
            <Header />
            <div className="breadcrumbs">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-sm-12">
                            <a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Sales</a> &gt; <span className="last-crumb">Quick Order</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container">
                <div className="container">
                    <div class="quick-code-area">
                        <div class="row">
                            <div class="col-12 col-lg-12 col-sm-12">
                                <ul>
                                    <li style={{ width: "50%" }}>
                                        <div class="row">
                                            <div class=" col-4">
                                                <label>Select Customer :</label>
                                            </div>
                                            <div class=" col-8">
                                        <Select isClearable onInputChange={handleCustomerChange} onChange={handleChange} options={customerOptions} placeholder="Search..." value={customerInputValue}/>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <button type="button" id="submit" class="acceptbtn"><i class="fa fa-shopping-cart" aria-hidden="true"></i> &nbsp;
                                        Cart</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='quick-order-area'>
                        <div className="row">
                            <div className="col-12 col-lg-12 col-sm-12">
                                <div className='verical-scrl-wrapper common-table cutomertbl'>
                                    <table class="table table-bordered resizable dataTable" >
                                        <thead>
                                            <tr>
                                                <th>Customer Name</th>
                                                <th>Post Code</th>
                                                <th>Account No</th>
                                                <th>Previous Due</th>
                                                <th>Previous Inv</th>
                                                <th>Order History</th> 
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>{customerDeatils.company}</td>
                                                <td>{customerDeatils.postal_code}	</td>
                                                <td>{customerDeatils.account_no}</td>
                                                <td>{customerDeatils.previous_deus}</td>
                                                <td>{customerDeatils.previous_invoices}</td>
                                                <td><a href="#" className='change-vehicl-btn mdlBtn'>View</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                <div className='verical-scrl-wrapper common-table quicktbl'>
                <table className="table table-bordered resizable dataTable" id="myTable">
                    <thead>
                      <tr>
                        <th>Product Code</th>
                        <th>Product Name</th>
                        <th>Order Type</th>
                        <th>Quantity</th>
                        <th>Rate</th>
                        <th>Amount</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <input type="text" className="form-control" value={row.productCode}
                                onChange={(e) => handleInputChange(index, 'productCode', e.target.value)}
                                    onBlur={(e) => handleFocusOut(index, 'productCode', e.target.value)}/>
                          </td>
                          <td>
                            <input type="text" className="form-control" value={row.productName}
                              onChange={(e) => handleInputChange(index, 'productName', e.target.value)}
                            />
                          </td>
                          <td>
                            <select className="form-select small-select" value={row.orderType} onChange={(e) => handleInputChange(index, 'orderType', e.target.value)}
                            >
                              <option value="Box">Box</option>
                              <option value="Piece">Piece</option>
                            </select>
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              value={row.quantity}
                              onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              value={row.rate}
                              onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                            />
                          </td>
                          <td>
                            {row.quantity * row.rate || 0}
                          </td>
                          <td>
                          <a class="tip" title="Delete" onClick={() => removeRow(index)}>
                          <i className='fa fa-trash'></i>
                          </a>
                            
                            
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="7" className="text-center">
                          <button className="create-catalogue-btn" onClick={addRow}>
                            <i className="fa fa-plus"></i> Add Row
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    );

}
export default Quick_Order;