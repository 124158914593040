import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import AccountService from '../../services/AccountServices';
import Swal from 'sweetalert2';

const CustomerAccounts = () =>{

	const [dates, setDates] = useState([null, null]);
	const [reportrange, setReportrange] = useState('');

	const [customerOptions, setCustomerOptions] = useState([]);
	const [customerInputValue, setCustomerInputValue] = useState('');
	const accessToken = localStorage.getItem('accessToken');
	const user_id = localStorage.getItem('userId');



	const [flashMessage, setFlashMessage] = useState(null);

	const [customerLedger, setCustomerLedger] = useState([]);
	

	const [customer, setCustomer] = useState({
	    name: '',
	    accound_no: '',
	    route_name: '',
	});
	const [nodataRow, setNodataRow] = useState('');

	const [debtProfile, setDebtProfile] = useState({
	    zeroto30Due: '0.00',
	    moreThan30DaysDue: 0,
	    moreThan60DaysDue: 0,
	    moreThan90DaysDue: 0,
	    current_outstanding: 0,
	    total_due1 : 0,
	    unallocated_amount : 0,
	});
	const [showModal, setShowModal] = useState(false);
	const modalRef = useRef(null);

	


	const handleCloseMessage = () => {
	    setFlashMessage(null);
	};

	const formatNumber = (num) => {
	    return parseFloat(num).toFixed(2);
	};

	const searchFormData = () => {
	    let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
	    let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
	    setReportrange(startDate ? startDate + ' - ' + endDate : '');
	    //setShouldLoadPayments(true); 
	    let customerId = customerInputValue ? customerInputValue.value : '';
	   // console.log(customerInputValue ? customerInputValue.value : '');

	   	if (!customerId) {
	        setFlashMessage({ message: 'Please select customer', type: 'error' });
	        return;
	    }
	    loadCustomerLadger(customerId, startDate, endDate);

	   
	    
	};

	const loadCustomerLadger = async (customerId, startDate, endDate) => {
		try{
			const data = await AccountService.fetchCustomerLadger(customerId, startDate, endDate, accessToken);
			//console.log(data);
			if (Array.isArray(data) && data.length === 0) {
	            console.log('No data available.');
	            setCustomer({ name: '', accound_no: '', route_name: '' });
	            setDebtProfile({
	                zeroto30Due: 0,
	                moreThan30DaysDue: 0,
	                moreThan60DaysDue: 0,
	                moreThan90DaysDue: 0,
	                current_outstanding: 0,
	                total_due1: 0,
	                unallocated_amount : 0,
	            });
	            setCustomerLedger([]); 
	            setNodataRow('showinfo');
	            return; 
	        }

	        if (data.customer) {
				setCustomer({
			        name : data.customer.name,
			        accound_no : data.customer.accound_no,
			        route_name: data.customer.route_name,   
			    });
			    setDebtProfile({
			    	zeroto30Due: data.debtProfile.zeroto30Due,
				    moreThan30DaysDue: data.debtProfile.moreThan30DaysDue,
				    moreThan60DaysDue: data.debtProfile.moreThan60DaysDue,
				    moreThan90DaysDue: data.debtProfile.moreThan90DaysDue,
				    current_outstanding: data.debtProfile.current_outstanding,
				    total_due1 : data.debtProfile.total_due1, 
				    unallocated_amount : data.allocation_amout[0].total,
			    });
	      		setCustomerLedger(data.customer_ledger); 
	      		 setNodataRow('');
      		}
		}catch(error){
			console.log('Failed to load customer ledger', error);
		}
	}

	const fetchCustomerNameOptions = async (inputValue) => {
	    if (inputValue.length < 3) {
	      return;
	    }

	    const formData = new FormData();
	    formData.append('pattern', inputValue);
	    formData.append('platform', 'web');
	    formData.append('authorization', accessToken);

    	const apiSalesRapUrl = `${config.API_URL}/ws-get-customer-list`;
	    try {
	      const response = await fetch(apiSalesRapUrl, {
	        method: 'POST',
	        headers: {
	          
	        },
	        body: formData,
	      });

	      const data = await response.json();
	      
	        

	        const formattedOptions = Array.isArray(data.customer_details) 
            ? data.customer_details.map(item => ({
                label: item.name,
                value: item.id,
            }))
            : [];
	        
	        setCustomerOptions(formattedOptions);
	      

	    } catch (error) {
	      console.error('Error fetching customer options:', error);
	    }
  	};
	const handleInputChange = (inputValue) => {
    	fetchCustomerNameOptions(inputValue);
  	};
  	const handleChange = (selectedOption) => {
    	setCustomerInputValue(selectedOption);
  	};

  	useEffect(() => {
	  	if (accessToken === null) {
	    	window.location.href = BASE_URL;
	    	return;
	  	}

	  	document.title = "Customer Account";
	    return () => {
	      document.title = "Ebusso";
	    };
  	}, []);

  	const handleShow = () => {
     	if (modalRef.current) 
     	{
      		const modal = new window.bootstrap.Modal(modalRef.current);
      		modal.show();
    	}
  	};

  	const [payDate, setPayDate] = useState(new Date().toISOString().split('T')[0]);
  	const [paymentType, setPaymentType] = useState('');
  	const [chequeNo, setChequeNo] = useState('');
  	const [payAmount, setPayAmount] = useState('');
  	const [reference, setReference] = useState('');
  	const [isChequeVisible, setChequeVisible] = useState(false);

	const handlePaymentTypeChange = (e) => {
	    const selectedType = e.target.value;
	    setPaymentType(selectedType);
	    setChequeVisible(selectedType === 'Cheque');
	};



	const handleCustomerPaymentSubmit = async (event) => 
	{
	    event.preventDefault();

	    let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
	    let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';

	    const formData = new FormData();
	    formData.append('user_id', user_id);
	    formData.append('customer_id', customerInputValue.value);
	    formData.append('date', payDate);
	    formData.append('amount', payAmount);
	    formData.append('paid_by', paymentType);
	    formData.append('reference_no', reference);
	    formData.append('cheque_no', chequeNo);

	    const apiBrandUrl = `${config.API_URL}/payments/add_payment`; 
	    try {
	        const response = await fetch(apiBrandUrl, {
	            method: 'POST',
	            headers: {
	                authorization : `Bearer ${accessToken}`,
	            },
	            body: formData,
	        });

	        if (!response.ok) {
	            throw new Error('Failed to add payment');
	        }

	        const data = await response.json();
	        console.log(data);

	        const msg = data.message;

	         

	        if (data.success) {

	        	
	            Swal.fire({
	                title: "Success!",
	                text: "Payment added successfully.",
	                icon: "success",
	                timer: 2500,
	                showConfirmButton: false,
	            }).then(() => {
	                


	                // Reset the form state
	                setPayDate('');
	                setPaymentType('');
	                setChequeNo('');
	                setPayAmount('');
	                setReference('');
	                setChequeVisible(false);
	                setCustomer({
	                    name: '',
	                    accound_no: '',
	                    route_name: '',
	                });
	                setCustomerLedger([]);

	                // Reload the customer ledger data
	                loadCustomerLadger(customerInputValue.value, startDate, endDate);
	            });


	        } else {
	            Swal.fire({
	                title: "Failed!",
	                text: msg,
	                icon: "error",
	                timer: 2500,
	                showConfirmButton: false,
	            });
	        }

	    } catch (error) {
	        console.error('Error adding payment:', error);
	    }
	};



	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/account/customer_accounts">Account</Link> 
		                  <Link to="/account/customer_accounts">Customer Ledger</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Customer Ledger </b>
						{customer.name && (
	                   	<div style={{float: 'right'}}>
		                    <button  onClick={handleShow} type="button" class="acceptbtn" >Add Payment</button>
		                    {/*<button type="button" class="acceptbtn" style={{position: 'absolute', right: '18px', top: '6px'}}>Print</button>*/}
	                    </div>
	                    )}
						</p>
	                   	
	                   	
	                   
	                    <div className="row" >
	                    	<div className="col-md-3 col-lg-3 col-sm-3" style={{float: 'left', paddingLeft: '20px'}}>    		
		            		
							    <Select
							      isClearable
							      onInputChange={handleInputChange}
							      onChange={handleChange}
							      options={customerOptions}
							      placeholder="Search..."
							      value={customerInputValue}
							    />
							</div>

	              			<div className="col-md-9 col-lg-9 col-sm-12">
	                			<CustomDateRangePicker dates={dates} onChange={setDates} onSubmit={searchFormData} />
	              			</div>
		            	</div>

		            	{customer.name && (
		            	<div className="row customerInfo" >
		            		<div className="col-md-9 col-lg-9 col-sm-12">
				            	<div className="verical-scrl-wrapper common-table resizable autoheight-table" id="style-3">
			                        <table className="table table-bordered dataTable resizable" id="pushSageList">
			                           
			                            <tbody>
			                             	<tr>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '800'}}>{customer.name}({customer.accound_no})</b></td>
			                                	<td></td>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '600'}}>0 to 30 Days</b></td>
			                                	<td className="text-end" >{debtProfile.zeroto30Due}</td>
			                                </tr>
			                                <tr>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '600'}}>Current O/S</b></td>
			                                	<td className="text-end" >{debtProfile.current_outstanding}</td>
			                                	<td style={{width: '35%'}}> <b style={{fontWeight : '600'}}>30 + Days</b></td>
			                                	<td className="text-end" >{debtProfile.moreThan30DaysDue}</td>
			                                </tr>
			                                <tr>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '600'}}>Total Amount</b></td>
			                                	<td className="text-end" >{debtProfile.total_due1}</td>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '600'}}>60 + Days</b></td>
			                                	<td className="text-end" >{debtProfile.moreThan60DaysDue}</td>
			                                </tr>
			                                <tr>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '600'}}>Unallocated Amount</b></td>
			                                	<td className="text-end" >
			                                		<Link to={`${config.BASE_URL}/payments/payment_allocation/${customerInputValue.value}`}>
			                                			{debtProfile.unallocated_amount}
			                                		</Link>
			                                	</td>
			                                	<td style={{width: '35%'}}><b style={{fontWeight : '600'}}>90 + Days</b></td>
			                                	<td className="text-end" >{debtProfile.moreThan90DaysDue}</td>
			                                </tr>
			                            </tbody>
			                          
			                        </table>
			                    </div>
		                    </div>
	                    </div>
	                    )}

		            	{customerLedger.length > 0 && (
	                    <div className="row customerInfo">
	                    	<div className="col-md-9 col-lg-9 col-sm-12">
	                    		<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
			                        <table className="table table-bordered dataTable" id="pushSageList">
			                            <thead>
			                                <tr>
			                                    <th>Date</th>
			                                    <th>Inv No</th>
			                                    <th>Status</th>
			                                    <th>Type</th>
			                                    <th>Amount</th>
			                                    <th>Balance</th>

			                                </tr>
			                            </thead>
			                            <tbody>
									    {customerLedger.map((item) => {
									        const {
									            id,
									            date,
									            payment_type,
									            amount,
									            balance,
									            sales_person_id,
									            driver_id,
									            payment_status,
									            particulars,
									            paid_by,
									            orderId
									        } = item;

									        let amountFormatted = parseFloat(amount).toFixed(2);
									        let balanceFormatted = parseFloat(balance).toFixed(2);
									        let particular1 = '';
									        let status = '';
									        let name1 = '';
									        let bgColor = '';

									        if (payment_type === 'c') {
									            // Credit logich
									            if(particulars == "driver collected" || particulars == "sales rep collected"){

									               	 	particular1 = 'Pay';
									            	}else{
									            		particular1 = (particulars === 'Credit Note') ? 'CrN' : particulars;
									            	}
									        } else {
									            // Debit logic
									            if (sales_person_id === '0' && driver_id === '0') {
									                particular1 = 'Inv';

									                status = payment_status === 'Paid' ? 'Paid' : payment_status === 'Due' ? 'UnPaid' : 'Partial';
									                if(orderId != null){
									                	name1 = `<a href="${config.BASE_URL}/trip_summary/print/${orderId}" target="_black" >${particulars}</a>`;
									                }else{
									                	name1 = `<span >${particulars}</span>`;
									                }
									                
									            } else {
									                particular1 = 'Pay';
									            }
									        }

									        // Handle undefined amounts
									        if (isNaN(amountFormatted) || amountFormatted === '') {
									            amountFormatted = '0.00';
									        }
									        if (isNaN(balanceFormatted) || balanceFormatted === '') {
									            balanceFormatted = '0.00';
									        }

									        // Adjust amount1 if necessary
									        let amount1Formatted = amountFormatted; // Initialize it directly

									        if (particular1 === 'sales rep collected') {
									            amount1Formatted = '0.00'; // Set to '0.00' if necessary
									        }

									        // Set the background color based on conditions
									        if (particular1 === 'Pay') {
									            bgColor = '#b1edb1';
									        } else if (particular1 === 'Inv') {
									            bgColor = '';
									        } else {
									            bgColor = '';
									        }

									        return (
									            <tr key={id} >
									                <td style={{ backgroundColor: bgColor }}>{date}</td>
									                <td style={{ backgroundColor: bgColor }} dangerouslySetInnerHTML={{ __html: name1 || '' }}></td>
									                <td style={{ backgroundColor: bgColor }} >{particular1 === 'Inv' ? status : ''}</td>
									                <td style={{ backgroundColor: bgColor }} >{particular1}</td>
									                <td style={{ backgroundColor: bgColor }} >{amount1Formatted}</td>
									                <td style={{ backgroundColor: bgColor }} >{balanceFormatted}</td>
									            </tr>
									        );
									    })}
									</tbody>

			                        </table>
			                    </div>
	                    	</div>
	                    </div>
	                    )}

						{nodataRow && (
						<div className="row text-center">
							<div className="col-md-12 col-lg-12 col-sm-12" style={{paddingTop: '15px'}}>
								<p className="text-danger" style={{ textAlign: 'left' }}>NO Transaction Available.</p>
							</div>
						</div>
						)}

	                    

	                    
	                </div>



			    </div>
			</div>

			<div ref={modalRef} className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog" role="document">
	          		<div className="modal-content">
	            		<div className="modal-header">
	              			<h5 className="modal-title">Add Payment</h5>
	              			<button type="button" className="btn-close" aria-label="Close"></button>
	            		</div>
						<div className='add-payment-popup'>
							<form id='addCustomerForm' onSubmit={handleCustomerPaymentSubmit}>
							<div className="modal-body">
								
									<div className="row">
									<div className="col-md-6">
										<div className="form-group">
										<label style={{float : 'left'}} htmlFor="date" className="col-form-label">Date: *</label>
										<input 
											type="date" 
											className="form-control" 
											id="date" 
											value={payDate}
											name="date"
											required 
											onChange={(e) => setPayDate(e.target.value)} 
										/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
										<label style={{float : 'left'}} className="col-form-label" htmlFor="paid_by_1">Paying by *</label>
										<select 
											name="paid_by" 
											id="paid_by_1" 
											className="form-select" 
											required 
											value={paymentType} 
											onChange={handlePaymentTypeChange}
										>
											<option value="">Select Payment Type</option>
											<option value="cash">Cash</option>
											<option value="CC">Credit Card</option>
											<option value="Cheque">Cheque</option>
										</select>
										</div>
									</div>
									{isChequeVisible && (
										<div className="col-md-6">
										<div className="form-group">
											<label style={{float : 'left'}} htmlFor="cheque_1" className="col-form-label">Cheque No: *</label>
											<input 
											type="text" 
											name="cheque" 
											className="form-control" 
											id="cheque_1" 
											required
											value={chequeNo} 
											onChange={(e) => setChequeNo(e.target.value)} 
											/>
										</div>
										</div>
									)}
									<div className="col-md-6">
										<div className="form-group">
										<label htmlFor="amount" style={{float : 'left'}} className="col-form-label">Amount: *</label>
										<input 
											type="number" 
											className="form-control no-arrows" 
											id="amount" 
											required
											value={payAmount} 
											onChange={(e) => setPayAmount(e.target.value)} 
										/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
										<label htmlFor="reference" style={{float : 'left'}} className="col-form-label">Reference:</label>
										<input 
											type="text" 
											className="form-control" 
											id="reference" 
											name="reference" 
											value={reference} 
											onChange={(e) => setReference(e.target.value)} 
										/>
										</div>
									</div>
									</div>
								

							</div>
							<div className="modal-footer">
								<button type="button" className="common-closebtn" data-bs-dismiss="modal" >Close</button>
								<button type="submit" className="float-right-btn common-popup-btn" data-bs-dismiss="modal" >Submit</button>
							</div>
							</form>
						</div>
	          		</div>
	        	</div>
	      	</div>

	      	<Footer />
			<FlashMessage
	          message={flashMessage?.message}
	          type={flashMessage?.type}
	          onClose={handleCloseMessage}
	        />

		</>
	);
}

export default CustomerAccounts;