import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";
import Swal from 'sweetalert2';

const EposSales = () => {

	const eposSaleTableRef = useRef(null);
	const [searchValue, setSearchValue] = useState([]);

	const accessToken = localStorage.getItem('accessToken');

	const fetchEposSalesTable = () => {
		const $table = $(eposSaleTableRef.current);
		const searchValue = $('#searchValue').val();
		if ($.fn.DataTable.isDataTable($table)) {
			$table.DataTable().destroy();
		}

		let i = 0;
		$table.DataTable({
			processing: true,
			serverSide: true,
			ajax: {
				url: `${config.API_URL}/sales/pos_list`,
				type: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				data: function (d) {
					return $.extend({}, d, {
						draw: d.draw,
						start: d.start,
						length: d.length,
						searchValue: searchValue,
						sale_status: 'Delivered'
					});
				},
				dataSrc: function (json) {

					return Array.isArray(json.data) ? json.data : [];
					console.log(json);
				}
			},
			language: {
				paginate: {
					first: 'First',
					last: 'Last',
					next: 'Next',
					previous: 'Previous',
				},
				search: 'Search:',
			},
			columns: [

				{
					data: null,
					width: '8%',
					render: function (data, type, row) {
						return `<span>${row.date}</span>`
					}
				},
				{
					data: null,
					width: '10%',
					render: function (data, type, row) {

						return '<a href="' + config.BASE_URL + '/order_summary/print/' + row.ids + '">' + row.reference_no + '</a>';
					}
				},
				{
					data: null,
					width: '12%',
					render: function (data, type, row) {
						return `<span>${row.customer}</span>`
					}
				},
				{
					data: null,
					width: '12%',
					render: function (data, type, row) {
						return `<span  class="text-right">${row.grand_total}</span>`
					}
					,
					createdCell: function (td) {
						$(td).css("text-align", "right");
					}
				},
				{
					data: null,
					width: '12%',
					render: function (data, type, row) {
						return `<span>${row.grand_total}</span>`

					}
					,
					createdCell: function (td) {
						$(td).css("text-align", "right");
					}
				},
				{
					data: null,
					width: '12%',
					render: function (data, type, row) {
						return `<span>00.0</span>`
					}
					,
					createdCell: function (td) {
						$(td).css("text-align", "right");
					}
				},
				{
  data: null,  // No direct data field for this column
  width: '12%',
  render: function (data, type, row) {
    // Check the value of 'sale_status' and render accordingly
    if (row.sale_status === 'Delivered') {
      return `<span class="status-delivered">Completed</span>`;  // Correct class name if needed
    } else {
      // Default case for handling other statuses
      return `<span class="status-other">${row.sale_status}</span>`;
    }
  }
},


				{
					data: null,
					width: '12%',
					render: function (data, type, row) {
						return `<span>Paid</span>`
					}
				},

			],
			columnDefs: [
				{
					orderable: false,
					targets: '_all'
				},
			],

			pageLength: 10,
		});


	};

	useEffect(() => {

		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}
		fetchEposSalesTable();
		return () => {
			if (eposSaleTableRef.current && $.fn.DataTable.isDataTable(eposSaleTableRef.current)) {
				$(eposSaleTableRef.current).DataTable().destroy();
			}
		};
	}, [searchValue]);

	return (
		<div>
			<Header />
			<div className="breadcrumbs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Epos</a> &gt; <span className="last-crumb">POS Sales</span>
						</div>
					</div>
				</div>
			</div>
			<div className="main-container">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<div className="show-order-area dashboard" style={{ position: 'relative' }}>
								<div className="row">
									<div className="col-12 col-lg-4 col-sm-4">

									</div>

								</div>
								<div>
									<div className="verical-scrl-wrapper common-table epos-sales-area" id="style-3">
										<table className="table table-bordered dataTable resizable" id="neworderTable" ref={eposSaleTableRef}>
											<thead>
												<tr>
													<th>Date</th>
													<th>Reference No</th>
													<th>Customer</th>
													<th>Grand Total</th>
													<th>Paid</th>
													<th>Balance</th>
													<th>Sale Status</th>
													<th>Payment Status</th>
												</tr>
											</thead>
											<tbody>

											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />

		</div>
	);

}
export default EposSales;