import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL, BASE_URL,PREFIX } from './Config';
import axios from "axios";
import Swal from 'sweetalert2';


function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [options, setOptions] = useState([]);
    const [selectedTill, setSelectedTill] = useState('0');
    const [selectedTillName, setSelectedTillName] = useState('');
    const [tillInfo, setTillInfo] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const modalRef = useRef(null);
    const accessToken = localStorage.getItem('accessToken');

    // console.log(BASE_URL);
    // console.log(API_URL);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        
        if (!username || !password) {
            setError('Please enter both username and password');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);

            const response = await fetch( `${API_URL}/ws-login-v2`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await response.json();        
            console.log('Login successful:', data);
            localStorage.setItem('accessToken', data.prefix); 
            localStorage.setItem('user_role', data.role); 
            localStorage.setItem('userId', data.profile_details.id);
            localStorage.setItem('site_name', data.site_name); 
            localStorage.setItem('first_name', data.profile_details.first_name); 
            localStorage.setItem('last_name', data.profile_details.last_name); 

            console.log(data.role);
            //console.log(data.role);
                    
           //navigate('/dashboard/');
           if(data.role==8)
           {
                navigate(`/dashboard/${data.prefix}/${data.profile_details.id}`);
                setError(null);
           }

        if(data.role === '18')
           {
            fetchTillOptions(data.prefix);
            setOpenModal(true);
            setError(null);
           }
        if(data.role==19)
           {
            navigate(`sales/IncompleteOrderList`);
            setError(null);
           }
        
        } catch (error) 
        {
            setError(error.message || 'Login failed');
            console.error('Login error:', error);
        }
    };

    async function fetchTillOptions(token) {
        try {
          const response = await axios.post(
            `${API_URL}/till_number`, 
            {}, 
            {
              headers: {
                authorization: `Bearer ${token}`, 
              },
            }
          );

          if (!response.data || !response.data.data || !Array.isArray(response.data.data)) {
            throw new Error('Invalid response format or empty vehicle array');
          }
    
          const formattedOptions = response.data.data.map(item => ({
            key: item.number,
            value: item.id,
          }));
    
          setOptions(formattedOptions);
        } catch (error) {
          console.error('Error fetching vehicle options:', error);
        }
      }

    return (
        <div className="login-body d-flex flex-column h-100">
            <div className="login-container">
                <div className="login-content">
                {openModal && (
                    <div
                        ref={modalRef}
                        className={`modal fade ${openModal ? "show d-block" : ""}`}
                        id="vehicleMdl verticalycentered"
                        tabIndex="-1"
                        role="dialog"
                        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                            <div className="modal-content loginpopup-bg">
                                <div className="modal-header lg-popup-bg">
                                    <h5 className="modal-title">Select Till Number </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setOpenModal(false)}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body lg-popup-body">
                                <select
                                    value={selectedTill}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const selectedOption = options.find((option) => option.value === selectedValue);
                                        setSelectedTill(selectedValue);
                                        setSelectedTillName(selectedOption ? selectedOption.key : '');
                                    }}
                                    className="datatable-selector form-select lg-select"
                                    name="driver_list"
                                    id="driver_list"
                                    style={{ marginRight: "15px" }}
                                >
                                    <option value="0">Select </option>
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.key}
                                        </option>
                                    ))}
                                </select>

                                </div>
                                <div className="modal-footer lg-popup-footer">
                                    <button
                                        type="button"
                                        className="lg-close-btn"
                                        onClick={() => setOpenModal(false)}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="float-right-btn lg-sbmit-btn"
                                        onClick={()=>{
                                        if(selectedTill === '0')
                                        {
                                              Swal.fire({
                                                    title: 'Error',
                                                    text: 'Please Select a till to login.',
                                                    icon: 'error',
                                                    showConfirmButton: false, 
                                                    timer: 1500,               
                                                    timerProgressBar: true,     
                                                  });
                                        }
                                        else
                                        {
                                            navigate(`sales/Epos`);
                                            localStorage.setItem('tillUserId', selectedTill);
                                            localStorage.setItem('tillUserName', selectedTillName);
                                        }
                                        }}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                    <div className="login-content_header">
                        <span className="logo">
                          <img src={`assets/img/${PREFIX}_logo.png`} alt="Ebusso Logo" />

                        </span>
                        <p>Please login to your account.</p>
                    </div>
                    <div style={{ float: 'left', width: '100%' }}>
                        <form className="login-form" onSubmit={handleSubmit}>
                            <input type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
                            <input type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                            {error && <p className="text-danger">{error}</p>}
                            <button type="submit" className="login-submit-btn">Login</button>
                        </form>
                    {/*      <div className="login-networks">
                            <p>Forgot your password?<br />Don't worry! <a href="#">Click here</a> to reset</p>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
