import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const PreviousProduct = () =>{
	const { productId } = useParams();
	const priceTableRef = useRef(null);
	const [searchValue, setSearchValue] = useState([]);
  	const accessToken = localStorage.getItem('accessToken');

  	const getPreviousPriceList = () => {


      	const $table = $(priceTableRef.current);

      	const formData = new FormData();
    	formData.append('id', productId);
      	const searchValue = $('#searchValue').val();
      	if ($.fn.DataTable.isDataTable($table)) {
          	$table.DataTable().destroy();
      	}
      	let i = 0;
      	$table.DataTable({
          	processing: true,
          	serverSide: true,
          	ajax: {
              	url: `${config.API_URL}/product_previous_pricing`,
              	type: 'POST',
              	headers: {
                 	authorization : `Bearer ${accessToken}`,
              	},
              	data: function (d) {
                  	return $.extend({}, d, {
                      	draw: d.draw,
                      	start: d.start,
                      	length: d.length,
                      	searchValue : searchValue,
                      	id : productId,
                  	});
              	},
              	dataSrc: function (json) {
                  	return json.data;
              	}
          	},
          	language: {
              	paginate: {
                  	first: 'First',
                  	last: 'Last',
                  	next: 'Next',
                  	previous: 'Previous',
              	},
              	search: 'Search:',
          	},
          	columns: [
             	{ data: 'addedOn_date' },
		        { data: 'name' },
		        { data: 'old_price' },
		        { data: 'new_price' },
		        { data: 'username' },
		        
          	],
          	columnDefs: [
	            {
	                orderable: false, 
	                targets: '_all'
	            }
        	],
        
          	pageLength: 10,
      	});

      	

      
  	};
  	useEffect(() => {
      	if (accessToken === null) {
          	window.location.href = `${config.BASE_URL}/login`;
          	return;
      	}
      	getPreviousPriceList();  
      	return () => {
          	if (priceTableRef.current && $.fn.DataTable.isDataTable(priceTableRef.current)) {
              	$(priceTableRef.current).DataTable().destroy();
          	}
      	};
  	}, [searchValue]);

	return(
		<>
			<Header />
		      <div class="breadcrumbs">
		        <div class="container-fluid">
		          <div class="row">
		            <div class="col-12 col-lg-12 col-sm-12">
		              <a href="#"><i class="fa fa-home"></i></a> &gt; 
		              <a href="Dashboard">Master</a> &gt; 
		              <span class="last-crumb">Product Previous Price</span>
		            </div>
		          </div>
		        </div>
      		</div>
      		
      		<div className="main-container">
		        <div className="container-fluid">
		          <div className="row">
		            <div className="col-12 col-lg-12 col-sm-12">
		              <div className="show-order-area dashboard" style={{ position: 'relative' }}>
		                
		                <div>
		                  <div className="verical-scrl-wrapper common-table product-previous-price" id="style-3">
		                    <table className="table table-bordered dataTable resizable" id="brandListTable" ref={priceTableRef}>
		                      <thead>
		                        <tr>
		                          <th>Changed Date</th>
		                          <th>Product Name</th>
		                          <th>Old Price</th>
		                          <th>New Price</th>
		                          <th>Changed By</th>
		                        </tr>
		                      </thead>
		                      <tbody>
		                       
		                      </tbody>
		                    </table>
		                  </div>
		                </div>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div>

      		<Footer />


		</>

	);
}
export default PreviousProduct;