import React, { useState, useEffect, useRef } from 'react';
import { API_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import Swal from 'sweetalert2';

const Day_Close = () => {
  const [data, setData] = useState(null);
  const [openingBal, setOpeningBal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cashHandover, setCashHandover] = useState(''); // Manage the cash handover value
  const user_id = localStorage.getItem('userId'); // Get user ID from local storage
  const modalRef = useRef(null); // Reference to the modal

  // Function to fetch data from API
  const fetchData = async () => {
    try {
      const formData = new FormData();
      formData.append('user_id', user_id); // Append user_id to formData

      const response = await fetch(`${API_URL}/get_total_pos_sales`, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Example for auth header
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setData(result.data);
        setOpeningBal(result.opening_bal);
      } else {
        Swal.fire('Error', 'Failed to fetch data', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while fetching data', 'error');
    }
  };

  // Function to open the modal and fetch data
  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  // Close the modal manually
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  // Use effect to call fetchData when the modal is opened
  useEffect(() => {
  
      fetchData(); // Fetch data when modal is opened
   
  }, [isModalOpen]); // Dependency on isModalOpen, triggers when modal opens

  // Calculate the total for each payment mode (cash, cheque, card)
  const calculateTotal = (paymentMode) => {
    if (!data) return 0;

    return data
      .filter(item => item.payment_mode.toLowerCase() === paymentMode.toLowerCase())
      .reduce((sum, item) => sum + parseFloat(item.amount), 0);
  };

  const totalCash = calculateTotal('cash');
  const totalCheque = calculateTotal('cheque');
  const totalCard = calculateTotal('card');

  // Function to handle submit and send cash handover data
  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('cash_handover', cashHandover);

      const response = await fetch(`${API_URL}/ws-submit-day-close`, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          Swal.fire('Success', 'Day Close Submitted Successfully', 'success');
          
          // Close the modal using Bootstrap's modal instance
          const bsModal = window.bootstrap.Modal.getInstance(modalRef.current); 
          bsModal.hide(); // Close the modal
          setIsModalOpen(false);
          window.location.reload(); // Update React state to close the modal
        } else {
          Swal.fire('Error', result.message || 'Failed to submit', 'error');
        }
      } else {
        Swal.fire('Error', 'Failed to submit Day Close', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred while submitting data', 'error');
    }
  };

  return (
    <div className="container">
      {/* Trigger the modal with this button */}
      {/* <button type="button" className="opendaybtn" onClick={openModal} data-bs-toggle="modal" data-bs-target="#Day-Close" ref={modalRef}>
        Open Day Close Modal
      </button> */}

      {/* Modal */}
      <div
        className={`modal fade ${isModalOpen ? 'show' : ''}`} 
        id="Day-Close" 
        tabIndex="-1" 
        style={{ display: isModalOpen ? 'block' : 'none' }}
        aria-hidden={!isModalOpen}
        ref={modalRef} // Make sure to reference the modal element
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Day Close</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
            </div>
            <div className="modal-body">
              <div className="customer-ledger-table">
                <h5><span>Opening Balance:</span> {openingBal ? `£${openingBal.total}` : '£0.00'}</h5>
                <h5><span>Total Sale:</span> £{(totalCash + totalCheque + totalCard).toFixed(2)}</h5>
                <h5><span>Net Balance:</span> £{((totalCash + totalCheque + totalCard) - (openingBal?.total || 0)).toFixed(2)}</h5>

                <h5><span>Cash:</span> £{totalCash.toFixed(2)}</h5>
                <h5><span>Cheque:</span> £{totalCheque.toFixed(2)}</h5>
                <h5><span>Card:</span> £{totalCard.toFixed(2)}</h5>
                <h5><span>Cash Handover:</span>
                  <input
                    type="text"
                    name="cash_handover"
                    value={cashHandover}
                    onChange={(e) => setCashHandover(e.target.value)} // Update cashHandover state
                  />
                </h5>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="closebtn" data-bs-dismiss="modal" onClick={closeModal}>
                Cancel
              </button>
              <button type="button" className="float-right-btn common-popup-btn" onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Day_Close;
