import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";

const UnitSuggestionBox = ({unit_id }) =>{

	const accessToken = localStorage.getItem('accessToken');

	const [unitOptions, setUnitOptions] = useState([]);
	const [unitFilteredOptions, setUnitFilteredOptions] = useState([]);
	const [unitValue, setUnitValue] = useState(null);
	const [unitInputValue, setUnitInputValue] = useState('');

	const unitHandleChange = (selectedOption) => {
	    setUnitValue(selectedOption);
	};

	const unitHandleInputChange = (inputValue) => {
	    setUnitInputValue(inputValue);
	    const newFilteredOptions = unitOptions.filter(option =>
	        option.label.toLowerCase().includes(inputValue.toLowerCase())
	    );
	    setUnitFilteredOptions(newFilteredOptions);
	};

	const fetchUnitList = async () => {
		const apiunitUrl = `${config.API_URL}/get_unit`;
		try {
			const response = await fetch(apiunitUrl, {
				method : 'POST',
				headers : {
					authorization : `Bearer ${accessToken}`,
				}
			});
			const data = await response.json();
			//console.log(data);
			const formattedOptions = data.data.map(item => ({
	        label: item.code,  
	  		value: item.id,    
	    }));
	    setUnitOptions(formattedOptions);
	    setUnitFilteredOptions(formattedOptions);

		}catch(error){
			console.error('api fail error fetching units');
		}
	};

	useEffect(() => {
		if (accessToken === null){
	   		window.location.href = `${config.BASE_URL}/login`; 
	    	return;
		}
		fetchUnitList();
	},[]);

	useEffect(() => {
        if (unit_id) {
            const selectedUnit = unitOptions.find(option => option.value === unit_id);
            setUnitValue(selectedUnit || null);
        }
    }, [unit_id, unitOptions]);

	

	return(
		<>
		
			<Select className='promoselect abovedd'
			    isClearable
			    onInputChange={unitHandleInputChange}
			    onChange={unitHandleChange}
			    options={unitFilteredOptions}
			    placeholder="Select"
			    value={unitValue}
			    name="unit" 
			/>


		</>
	);

}
export default UnitSuggestionBox;