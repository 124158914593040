import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { API_URL, BASE_URL } from "../Config";
import config from "../common/Config";
import Swal from "sweetalert2";
import ProductBatch from "./ProductBatch";

const PurchasesAddFrom = () => {
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [supplierFilteredOptions, setSupplierFilteredOptions] = useState([]);
  const [supplierValue, setSupplierValue] = useState("");
  const [supplierInputValue, setSupplierInputValue] = useState("");
  const [productBatch, setProductBatch] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productFilteredOptions, setProductFilteredOptions] = useState([]);
  const [productValue, setProductValue] = useState(null);
  const [productInputValue, setProductInputValue] = useState("");
  const accessToken = localStorage.getItem("accessToken");
  const [newProductList, setNewProductList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const tableRef = React.useRef(null);
  const [note, setNote] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const productBatchRef = useRef(null);
  const [modalData, setModalData] = useState(null);
  const [netStdGoods, setNetStdGoods] = useState(0);
  const [netVatAmt, setNetVatAmt] = useState(0);
  const [netZeroGoods, setNetZeroGoods] = useState(0);
  const [netGrandTotal, setNetGrandTotal] = useState(0);
  const [netWeight, setNetWeight] = useState(0);
  const [grossWeight, setGrossWeight] = useState(0);

  const supplierHandleChange = (selectedOption) => {
    setSupplierValue(selectedOption);
    setTableRows([]);
    if (selectedOption) {
      fetchProductList(selectedOption.value);
      fetchSupplierProductInfo(selectedOption.value);
    } else {
      setProductOptions([]);
      setProductFilteredOptions([]);
      setProductValue("");
      setTableRows([]);
    }
  };

  const supplierHandleInputChange = (inputValue) => {
    setSupplierInputValue(inputValue);
    const newFilteredOptions = supplierOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSupplierFilteredOptions(newFilteredOptions);
  };

  const productHandleChange = (selectedOption) => {
    if (selectedOption) {
      setProductValue(selectedOption);
      fetchAddProductInfo(selectedOption.value);
    } else {
      setProductValue(null);
    }
  };

  const productHandleInputChange = (inputValue) => {
    setProductInputValue(inputValue);
    const newFilteredOptions = productOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setProductFilteredOptions(newFilteredOptions);
  };

  const formatNumber = (num) => {
    return parseFloat(num).toFixed(2);
  };

  const getProductBatchData = async (id, batch_id) => {
    try {
      const formData = new FormData();
      formData.set("product_id", id);
      const response = await axios.post(
        `${config.API_URL}/ws-batch-info-by-product-id`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;

      setProductBatch(data.batch);
      handleOpenPastPurchaseModal(data.batch);
      //  handleOpenPastPurchaseModal();
    } catch (error) {
      console.error("Error fetching product batch data:", error);
    }
  };

  const handleOpenPastPurchaseModal = (data) => {
    setModalData(data);
    if (productBatchRef.current) {
      productBatchRef.current();
    }
  };

  const fetchSupplierProductInfo = async (supplierid) => {
    const formData = new FormData();
    formData.append("supplier_id", supplierid);
    formData.append("product_id", "");

    const apiUrl = `${config.API_URL}/purchases/get_order_datails`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch new product");
      }

      const data = await response.json();

      setNewProductList(data.data); 
      let stdGoods = 0;
      let totalVat = 0;
      let zeroGoods = 0;

      // Loop through the API response and create a new row for each product
      const newRows = data.data.map((productData) => {
        // salesAvgQuantity is a one month estimate quantity
        const averageProduct = data.averageData.find(item => item.id === productData.id);
        const salesAvgQuantity = averageProduct ? parseFloat(averageProduct.sales_avg_quantity).toFixed(2) : '0.00';

        const number = parseFloat(salesAvgQuantity);
        const adjustedValue = (number % 1 >= 0.5) ? Math.ceil(number) : Math.floor(number);
        const onOrderData = data.on_order_data.find(item => item.id === productData.id);
        const onOrderQuantity = onOrderData ? parseInt(onOrderData.on_order_data) : '0';  
        const stockCount = productData.split == "0" || productData.split == "2" ? parseInt(productData.quantity)
        : parseInt(productData.split_quantity);

        const deficit =  parseInt(stockCount) - parseInt(onOrderQuantity) - parseFloat(adjustedValue);

        // salesAvgQuantity is a one month estimate quantity
        const unitCost =
          productData.split === "0" || productData.split === "2"
            ? parseFloat(productData.cost)
            : parseFloat(productData.piece_cost);

        if(parseFloat(productData.rate) > 0){
            stdGoods += unitCost;
        }

        if(parseFloat(productData.rate) === 0.00){
            zeroGoods += unitCost;
        }

        let vat = productData.rate !== null ? ((unitCost * 1 * formatNumber(productData.rate)) / 100).toFixed(2) : '';
        totalVat += parseFloat(vat);

        const net_weight = productData.net_weight === null ? 0 : productData.net_weight;
        const gross_weight = productData.gross_weight === null ? 0 : productData.gross_weight;

        return {
          code: productData.code,
          id: productData.id,
          pack: productData.pack,
          price: productData.price,
          piece_cost: productData.piece_cost,
          split: productData.split,
          type: productData.split === "0" || productData.split === "2" ? "box" : "piece",
          split_quantity: productData.split_quantity,
          split_price: productData.split_price,
          name: productData.name,
          size: productData.size,
          cost: productData.cost,
          rate: productData.rate,
          net_weight: net_weight,
          gross_weight: gross_weight,
          quantity: productData.quantity,
          pdtcost: unitCost.toFixed(2),
          order_qty: 1,
          subtotal: (unitCost * 1).toFixed(2),
          vat: vat,
          sales_quantity: parseInt(productData.sales_quantity),
          salesAvgQuantity: adjustedValue,
          onOrderQuantity: onOrderQuantity,
          deficit: deficit,
          total: ( unitCost * 1 + (unitCost * 1 * formatNumber(productData.rate !== null ? productData.rate : 0 )) / 100 ).toFixed(2),
          stockCount: stockCount,
        };
      });

      setNetStdGoods(stdGoods);
      setNetVatAmt(totalVat);
      setNetZeroGoods(zeroGoods);

      let GrandTotal = stdGoods + totalVat + zeroGoods;
      setNetGrandTotal(GrandTotal);

      // Update tableRows state with all the new rows
      setTableRows((prevRows) => [...prevRows, ...newRows]);
    } catch (error) {
      console.error("API fetch error:", error);
    }
  };

  const fetchAddProductInfo = async (selectedOption) => {
    const formData = new FormData();
  
    if (selectedOption) {
      formData.append("product_id", selectedOption);
      const existingProduct = tableRows.find(
        (item) => item.id === selectedOption
      );
      if (existingProduct) {
        Swal.fire({
          title: "Error",
          text: "Product already exists in the list, Please Add Quantity.",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        return;
      }
    }
  
    formData.append("supplier", supplierValue.value);
    const apiUrl = `${config.API_URL}/purchases/get_order_datails`;
  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch new product");
      }
  
      const data = await response.json();
      const productDataList = data.data; 
      setNewProductList(productDataList);
  
      const newRows = productDataList.map((productData) => {
        const averageProduct = data.averageData.find(item => item.id === productData.id);
        const salesAvgQuantity = averageProduct ? parseFloat(averageProduct.sales_avg_quantity).toFixed(2) : '0.00';  
        const number = parseFloat(salesAvgQuantity);
        const adjustedValue = (number % 1 >= 0.5) ? Math.ceil(number) : Math.floor(number);
        const onOrderData = data.on_order_data.find(item => item.id === productData.id);
        const onOrderQuantity = onOrderData ? parseInt(onOrderData.on_order_data) : 0;  // Default to 0 if not found
        const stockCount = productData.split === "0" || productData.split === "2" ? parseInt(productData.quantity) : parseInt(productData.split_quantity);
        // Calculate the deficit
        const deficit = parseInt(stockCount) - onOrderQuantity - parseFloat(adjustedValue);

        const unitCost =
          productData.split === "0" || productData.split === "2"
            ? parseFloat(productData.cost)
            : parseFloat(productData.piece_cost);

          const net_weight = productData.net_weight === null ? 0 : productData.net_weight;
          const gross_weight = productData.gross_weight === null ? 0 : productData.gross_weight;
  
        const newRow = {
          code: productData.code,
          id: productData.id,
          pack: productData.pack,
          price: productData.price,
          piece_cost: productData.piece_cost,
          split: productData.split,
          type: productData.split === "0" || productData.split === "2" ? "box" : "piece",
          split_quantity: productData.split_quantity,
          split_price: productData.split_price,
          name: productData.name,
          size: productData.size,
          cost: productData.cost,
          rate: productData.rate,
          net_weight: net_weight,
          gross_weight: gross_weight,
          quantity: productData.quantity,
          pdtcost: unitCost.toFixed(2), // formatted unit cost
          order_qty: 1,
          subtotal: (unitCost * 1).toFixed(2), // subtotal calculation
          vat: ((unitCost * 1 * formatNumber(productData.rate)) / 100).toFixed(2), // VAT calculation
          sales_quantity: parseInt(productData.sales_quantity),
          salesAvgQuantity: adjustedValue,
          onOrderQuantity: onOrderQuantity,
          deficit: deficit,
          total: (unitCost * 1 + (unitCost * 1 * formatNumber(productData.rate)) / 100).toFixed(2), // total including VAT
          stockCount: stockCount,
        };
  
        return newRow; // Return the new row object
      });
  
      // Set the updated table rows in the state
      setTableRows((prevRows) => [...prevRows, ...newRows]);
  
    } catch (error) {
      console.error("API fetch error:", error);
    }
  };
  

  const fetchSupplierList = async () => {
    const apiSalesRouteUrl = `${config.API_URL}/purchases/getSupplier`;
    try {
      const response = await fetch(apiSalesRouteUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      const formattedOptions = data.supplier.map((item) => ({
        label: item.company,
        value: item.id,
      }));
      setSupplierOptions(formattedOptions);
      setSupplierFilteredOptions(formattedOptions);
    } catch (error) {
      console.error("api fail error fetching sales routes");
    }
  };

  const fetchProductList = async (supplierId) => {
    const formData = new FormData();
    formData.append("supplier_id", supplierId);
    const apiSalesRouteUrl = `${config.API_URL}/purchases/getProducts`;
    try {
      const response = await fetch(apiSalesRouteUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      const formattedOptions = data.products.map((item) => ({
        label: item.name + "(" + item.code + ")",
        value: item.id,
      }));
      setProductOptions(formattedOptions);
      setProductFilteredOptions(formattedOptions);
    } catch (error) {
      console.error("api fail error fetching products");
    }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    fetchSupplierList();
  }, []);


  useEffect(() => {
    let stdGoods = 0;
    let totalVat = 0;
    let zeroGoods = 0;
    let netWeightTotal = 0;
    let netGrossTotal = 0;
  
    const calculateTotals = () => {
      const newRows = tableRows.map((productData) => {

        if (parseFloat(productData.rate) > 0) {
          stdGoods +=   parseFloat(productData.subtotal);
        }
  
        if (parseFloat(productData.rate) === 0.00) {
          zeroGoods += parseFloat(productData.subtotal);
        }
  
        totalVat += parseFloat(productData.vat);
        netWeightTotal += (parseFloat(productData.net_weight || "0") * parseInt(productData.order_qty || "0"));
        netGrossTotal += (parseFloat(productData.gross_weight || "0") * parseInt(productData.order_qty || "0"));
      });
  
      setNetStdGoods(stdGoods);
      setNetVatAmt(totalVat);
      setNetZeroGoods(zeroGoods);

      const GrandTotal = stdGoods + totalVat + zeroGoods;
      setNetGrandTotal(GrandTotal);
      setNetWeight(netWeightTotal);
      setGrossWeight(netGrossTotal);
    };
  
      calculateTotals();
  }, [tableRows]); 
  

  const handleQuantityChange = (id, newQty) => {
    setTableRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          const order_qty = parseFloat(newQty) || 0;
          const unitCost = parseFloat(row.pdtcost) || 0;
          const rate = parseFloat(row.rate) || 0;
          const subtotal = (order_qty * unitCost).toFixed(2);
          const vat = ((order_qty * unitCost * rate) / 100).toFixed(2);
          const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
          return {
            ...row,
            order_qty,
            subtotal,
            vat,
            total,
          };
        }
        return row;
      });
    });
  };

  const handleCostChange = (id, newCost) => {
    setTableRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          const pdtcost = parseFloat(newCost) || 0;
          const order_qty = parseFloat(row.order_qty) || 0;
          const rate = parseFloat(row.rate) || 0;

          const subtotal = (order_qty * pdtcost).toFixed(2);
          const vat = ((order_qty * pdtcost * rate) / 100).toFixed(2);
          const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

          return {
            ...row,
            pdtcost,
            subtotal,
            vat,
            total,
          };
        }
        return row;
      });
    });
  };

  const handleOptionChange = (id, pdtType) => {
    console.log(id + "----" + pdtType);
    setTableRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id) {
          const pdtcost = pdtType === "Box" ? row.cost : row.piece_cost;
          const order_qty = parseFloat(row.order_qty) || 0;
          const rate = parseFloat(row.rate) || 0;
          const subtotal = (order_qty * pdtcost).toFixed(2);
          const vat = ((order_qty * pdtcost * rate) / 100).toFixed(2);
          const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
          const type = pdtType === "Piece" ? "box" : "piece";
          const stockCount =
            pdtType == "Box"
              ? parseInt(row.quantity || 0)
              : parseInt(row.split_quantity || 0);

          return {
            ...row,
            type,
            pdtcost,
            subtotal,
            vat,
            total,
            stockCount,
          };
        }
        return row;
      });
    });
  };
  const handleCancel = (id) => {
    setTableRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  const handleChange = (event) => {
    setNote(event.target.value);
  };

  const submitFormData = async () => {
    if (!supplierValue.value) {
      Swal.fire({
        title: "Error!",
        text: "Please select a supplier.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    if (tableRows.length === 0) {
      Swal.fire({
        title: "Error!",
        text: "Please add at least one product.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    const filteredTableRows = tableRows.map((row) => ({
      code: row.code,
      name: row.name,
      order_qty: row.order_qty,
      subtotal: row.subtotal,
      vat: row.vat,
      total: row.total,
      type: row.type,
    }));

    const form = document.getElementById("addproductform");
    const formData = new FormData(form);
    formData.append("supplier", supplierValue.value);
    //formData.append('tableRows', JSON.stringify(filteredTableRows));
    formData.append("note", note);
    formData.append("warehouse", "1");

    const apiSalesRapUrl = `${config.API_URL}/purchases/purchasesAdd`;
    try {
      const response = await fetch(apiSalesRapUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      console.log(data);
      if (data.success == true) {
        Swal.fire({
          title: "Success!",
          text: "Purchase Added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          window.location.href = `${config.BASE_URL}/purchases/purchases_order/`;
        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Purchase  Failed",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error fetching api:", error);
    }

    setTableRows([]);
    setSupplierValue("");
    setNote("");
  };

  return (
    <>
      <ProductBatch
        onViewPastPurchase={(showModal) => {
          productBatchRef.current = showModal;
        }}
        getProductBatchData={getProductBatchData}
        modalData={modalData}
      />

      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="/reports/mis_report">Purchases</Link>
              <Link to="/reports/mis_report">Add Purchase</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-lg-9 col-sm-12">
              <div
                className="col-md-4 cp-lg-4 col-sm-4"
                style={{ float: "left", padding: "17px 0px 0px 0px" }}
              >
                <Select
                  className="abovedd"
                  isClearable
                  onInputChange={supplierHandleInputChange}
                  onChange={supplierHandleChange}
                  options={supplierFilteredOptions}
                  placeholder="Select Supplier"
                  value={supplierValue}
                />
              </div>

              <div
                className="col-md-4 cp-lg-4 col-sm-4"
                style={{ float: "left", padding: "17px 0px 0px 15px" }}
              >
                <Select
                  className="abovedd"
                  isClearable
                  onInputChange={productHandleInputChange}
                  onChange={productHandleChange}
                  options={productFilteredOptions} // Pass the filtered options here
                  placeholder="Select Product"
                  value={productValue}
                />
              </div>
            </div>
          </div>
          <div  className="total-manifests-area dashboard"  style={{ position: "relative" }} >
            <p> <b>Order Items </b> </p>
            <form id="addproductform">
              <div className="verical-scrl-wrapper common-table autoheight-table add-purchase-form" id="style-3" >
                <table className="table table-bordered dataTable resizable" id="newProductTable">
                  <thead>
                    <tr>
                      <th width="300">Code-Name-Size</th>
                      <th>PK</th>
                      <th>Current Stock</th>
                      <th>On Order <a href="#" class="info" title="PO Qty with Draft or Partial status" data-toggle="popover" data-placement="top" data-content="Content"><i class="fa fa-info-circle"></i></a></th>
                      <th>Estimated <a href="#" class="info" title="Avg 2 month sell" data-toggle="popover" data-placement="top" data-content="Content"><i class="fa fa-info-circle"></i></a></th>
                      <th>Deficit <a href="#" class="info" title="Current stock - On order Stock - Estimate" data-toggle="popover" data-placement="top" data-content="Content"><i class="fa fa-info-circle"></i></a></th>
                      <th>Order QTY</th>
                      <th>Type</th>
                      <th>Cost Price</th>
                      <th>VAT </th>
                      <th>Sub Total</th>
                      <th>Net P</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableRows.length === 0 ? (
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan={10}>No product added</td>
                      </tr>
                    ) : (
                      tableRows.map((row) => (
                        <tr key={row.id}>
                          <input
                            type="hidden"
                            value={row.id}
                            name="product_id[]"
                          />
                          <input type="hidden" value={row.code} name="code[]" />
                          <input type="hidden" value={row.name} name="name[]" />
                          <td>
                            <Link
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                                getProductBatchData(row.id);
                              }}
                            >
                              {row.code} - {row.name} - {row.size}
                            </Link>
                          </td>
                          <td style={{width: '80px'}} className="text-center">{row.pack}</td>
                          <td style={{ width: "80px" }}>{row.stockCount}</td>
                          <td style={{ width: "80px" }}>
                            {/* On Order*/}
                            {row.onOrderQuantity}
                          </td>
                          <td style={{ width: "80px" }}>
                            {/* Next Month Estimated */}
                            {row.salesAvgQuantity}
                          </td>
                          <td style={{ width: "80px" }}>
                            {/* Deficit */}
                            {row.deficit}
                          </td>
                          <td style={{ width: "80px" }}>
                            <input
                              type="text"
                              name="order_qty[]"
                              value={row.order_qty}
                              min="0"
                              onChange={(e) =>
                                handleQuantityChange(row.id, e.target.value)
                              }
                              className="form-control order-qty text-center small-control"
                            />
                          </td>
                          <td style={{ width: "80px" }} className="text-center">
                            {row.split == 2 ? (
                              <select
                                onChange={(e) =>
                                  handleOptionChange(row.id, e.target.value)
                                }
                                className="form-select small-select text-center"
                                name="type[]"
                              >
                                <option value="Box">Box</option>
                                <option value="Piece">Piece</option>
                              </select>
                            ) : (
                              <>
                                {row.split == 0 ? "Box" : "Piece"}
                                <input
                                  type="hidden"
                                  value={row.split == 0 ? "Box" : "Piece"}
                                  name="type[]"
                                />
                              </>
                            )}
                          </td>
                          <td style={{ width: "80px" }}>
                            <input
                              type="number"
                              value={row.pdtcost}
                              min="0"
                              name="cost[]"
                              onChange={(e) =>
                                handleCostChange(row.id, e.target.value)
                              }
                              className="form-control order-costno-arrows small-control"
                              
                            />
                          </td>                         
                          <td style={{ width: "80px" }} className="text-end">
                            {row.vat}
                          </td>
                          <td style={{ width: "80px" }} className="text-end">
                            {row.subtotal}
                          </td>
                          <td style={{ width: "80px" }} className="text-end">
                            {row.total}
                          </td>
                          <input
                            type="hidden"
                            name="subtotal[]"
                            value={row.subtotal}
                          />
                          <input type="hidden" name="vat[]" value={row.vat} />
                          <input
                            type="hidden"
                            name="total[]"
                            value={row.total}
                          />
                          <td style={{ width: "3%" }} className="text-center">
                            {/* <button
                                                            className="btn btn-danger cancel-btn"
                                                           onClick={() => handleCancel(row.id)}

                                                           style={{padding: '0px',fontSize: '19px',color: '#dc3545',background: 'none',border: 'none'}}
                                                        >
                                                        <i class="fa fa-trash-o" ></i>
                                                            
                                                        </button> */}

                            <a
                              onClick={() => handleCancel(row.id)}
                              class="icon editbtn"
                            >
                              {" "}
                              <i class="fa fa-trash-o"></i>
                            </a>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </form>

            <div className="purchaseftr">
              <div className="row">
                <div className="col-6 col-lg-3 col-sm-3">
                  <p className="frtbx">
                    {" "}
                    Net Weight :{" "}
                    <span id="netWeight">{netWeight.toFixed(2)}</span>
                  </p>
                </div>
                <div className="col-6 col-lg-3 col-sm-3">
                  <p className="frtbx">
                    {" "}
                    Gross Weight :{" "}
                    <span id="grossWeight">{grossWeight.toFixed(2)}</span>
                  </p>
                </div>
                <div className="col-6 col-lg-3 col-sm-3">
                  <p className="frtbx">
                    {" "}
                    Std Goods :{" "}
                    <span id="std_goods">{netStdGoods.toFixed(2)}</span>
                  </p>
                </div>
                <div className="col-6 col-lg-3 col-sm-3">
                  <p className="frtbx">
                    {" "}
                    VAT Amount :{" "}
                    <span id="vat_amt">{netVatAmt.toFixed(2)}</span>
                  </p>
                </div>
                <div className="col-6 col-lg-3 col-sm-3">
                  <p className="frtbx">
                    {" "}
                    Zero Goods :{" "}
                    <span id="zero_goods">{netZeroGoods.toFixed(2)}</span>
                  </p>
                </div>
                <div className="col-6 col-lg-3 col-sm-3">
                  <p className="frtbx last">
                    {" "}
                    Gross Total :{" "}
                    <span id="grand_total">{netGrandTotal.toFixed(2)}</span>
                  </p>
                </div>
              </div>
            </div>

            
            <div className="row">
              <div className="col-12">
                <label>
                  <b>Note</b>
                </label>
                <textarea
                  name="note"
                  cols="40"
                  rows="10"
                  className="form-control"
                  id="ponote"
                  style={{ marginTop: "10px", height: "100px" }}
                  value={note}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12" style={{ paddingTop: "20px", marginBottom: '20px' }}>
                <button
                  onClick={submitFormData}
                  type="button"
                  class="acceptbtn"
                >
                  Add Order
                </button>
              </div>
            </div>

           
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default PurchasesAddFrom;
