import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";

// Generate a unique filename for the PDF
const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generatePickerListPDF = async (manifestId) => {
  const accessToken = localStorage.getItem('accessToken');
  const formData = new FormData();
  formData.set('manifest_id', manifestId);
  const apiUrl = `${config.API_URL}/get_manifestPrint`;
  const doc = new jsPDF('portrait'); // Set to portrait orientation
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
    const firstColumnWidth = pageWidth * 0.25;  // 30% of the page width
    const secondColumnWidth = pageWidth * 0.75; 
    //const H2firstColumnWidth = pageWidth * 0.45; 

    const H2firstColumnWidth = pageWidth * 0.45;  // 45% of the page width
    const H2secondColumnWidth = pageWidth * 0.45; // 27.5% of the page width
    const H2thirdColumnWidth = pageWidth * 0.25;  // 27.5% of the page width
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Picker List');
    }
  
    const data = await response.json();
    const { date, route_id, sale_rep, driver_name, inwards } = data.data;

    const doc = new jsPDF('p', 'mm', 'a4'); // Set to A4 paper size (portrait)
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10; 
    const leftMargin = margin;  
    const rightMargin = margin; 
    let y = margin;  
    
    const logoUrl = `${config.BASE_URL}/assets/img/${accessToken}_logo.png`; // Path to the logo image

    const logoWidth = 40; 
    const logoHeight = 15; 
    doc.addImage(logoUrl, 'PNG', margin, y, logoWidth, logoHeight); 
    y += logoHeight + 5; 

    const boldTxt = '<b>';
    const boldTxtClose = '</b>';

    const headerSections2 = [
      [
        // Add shop details (below logo)
        ],
      [
      { text: `${data.data.shop_details.shop_name}`, size: 13, bold: true },
      { text: `${data.data.shop_details.address}`, size: 8, bold: false }, // First line of address
    { text: ``, size: 8, bold: false }, // Second line of address
    { text: `Company No : ${data.data.shop_details.company_reg_no} | VAT No : ${data.data.shop_details.vat_no}`, size: 8, bold: false },
    { text: `Phone No : ${data.data.shop_details.phone} | Email : ${data.data.shop_details.email}`, size: 8, bold: false },
    { text: `Website : ${data.data.shop_details.website}`, size: 8, bold: false },

    ],
   
 
      ];
    const headerSections3 = [
      [
        { text: `Date : ${date}`, size: 8, bold: false },
        { text: `Manifest No : ${manifestId}`, size: 8, bold: false},
        { text: `Journey No : ${route_id}`, size: 8, bold: false },
        
      ],
       [
        
        { text: `SalesRep Name : ${sale_rep}`, size: 8, bold: false },
        { text: `Driver Name : ${driver_name}`, size: 8, bold: false },
      ]
      ];
    const manifestDetails = Array.isArray(inwards) ? inwards : [];
     const sectionWidth = (pageWidth - margin * 2) / 2; // Adjust for margins

    // Render header sections
    y = 7;
   const renderSections = (sections, marginTop = 7) => { // marginTop is an argument that defaults to 10
  let yPosition = y + marginTop; // Add initial marginTop space before rendering starts

  sections.forEach((section, index) => {
    // Calculate starting X position for the columns (for layout)
    const colStartX = margin + (index % 3) * firstColumnWidth;

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, yPosition + i * 4); // Adjust Y based on current position
    });

    // Increase the Y position after rendering each section
    if (index % 4 === 3) {
      yPosition += 12; // Adjust spacing between sections as needed
    }
  });

  // Add a little space after the last section
  yPosition += 25;

  // Update the global Y value if you need to keep track of the Y position globally
  y = yPosition;
};

renderSections(headerSections2);


  const h2renderSections = (sections) => {
  let rowHeight = 0; // To store the maximum height of columns in a row
  let rowStartY = y; // Track the starting Y position for each row of 2 columns
  let columnHeights = []; // To store individual column heights within a row

  sections.forEach((section, index) => {
    // Column X position based on section index (for a 2-column layout)
    let colStartX;
    if (index % 2 === 0) {
      colStartX = margin;
    } else {
      colStartX = margin + H2firstColumnWidth; // Adjust for the second column
    }

    let sectionHeight = 0; // To track the height of the current section

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

      // Wrap text if it exceeds column width (maxWidth)
      let maxWidth;
      if (index % 2 === 0) {
        maxWidth = H2firstColumnWidth - 5; // First column width
      } else {
        maxWidth = H2secondColumnWidth - 5; // Second column width
      }

      const textWidth = doc.getTextWidth(item.text);
      let lines = [];
      if (textWidth > maxWidth) {
        // Split text into multiple lines
        let words = item.text.split(' ');
        let line = '';

        words.forEach((word) => {
          const testLine = line + (line ? ' ' : '') + word;
          if (doc.getTextWidth(testLine) <= maxWidth) {
            line = testLine;
          } else {
            lines.push(line);
            line = word;
          }
        });
        lines.push(line); // Push remaining line
      } else {
        lines.push(item.text); // No wrapping needed, just one line
      }

      // Render each line of text
      lines.forEach((lineText, lineIndex) => {
        const lineHeight = 4; // Adjust vertical spacing for each line
        doc.text(lineText, colStartX + 5, y + 5 + i * lineHeight + lineIndex * lineHeight);
        sectionHeight = y + i + lineIndex - 5; // Update section height
      });
    });

    // Store the height of the current section (column height)
    columnHeights.push(sectionHeight);

    // After every 2 columns (2 sections), calculate the maximum height for this row
    if ((index + 1) % 2 === 0) {
      rowHeight = Math.max(...columnHeights); // Get the maximum height in this row
      columnHeights = []; // Reset for the next row

      // Draw borders for both columns in the row
      let colX = margin;
      for (let i = 0; i < 2; i++) {
        doc.setLineWidth(0.1); // Border line width
        let colWidth = (i === 0) ? H2firstColumnWidth : H2secondColumnWidth;
        doc.rect(colX, rowStartY - 2, colWidth, rowHeight-10); // Draw the rectangle for the column with a small padding
        colX += colWidth; // Update X position for the next column
      }

      // Move the Y position down for the next row of columns
      y += rowHeight - 12; // Add some space after each row of columns
    }
  });

  y += -3; // Add a little space after all header sections
};

// Call the function to render sections with equal height borders
h2renderSections(headerSections3);
    // Header Section
    
    doc.autoTable({
      startY: y,
      theme: 'striped',
      styles: {
        fontSize: 7,
        cellPadding: 3,
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: '#000000',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 30 }, 
        1: { cellWidth: 40 },
        2: { cellWidth: 50 },
        3: { cellWidth: 40 },
        4: { cellWidth: 30 },
      },
      margin: { top: 0, left: leftMargin, right: rightMargin },
    });

    // Adjust Y after header table
    y = doc.autoTable.previous.finalY + 10; 

    // Packing Details Table
    const packingColumns = [
      { title: 'Customer name (A/C No)', dataKey: 'customer', size: 8 },
      { title: 'Inv No', dataKey: 'Inv_no', size: 8 },
      { title: 'Inv Amt', dataKey: 'inv_amt', size: 8 },
      { title: 'Total Dues', dataKey: 'total_dues', size: 8 },
      { title: 'Rep Collect', dataKey: 'total_dues', size: 8 },
      { title: 'Driver to Collect', dataKey: 'total_dues', size: 8 },
      { title: 'Remark', dataKey: 'total_dues', size: 8 },
    ];

    const packingTableBody = []; // Initialize packingTableBody here

  let creditOrders = [];
let tcOrders = [];
let cashOrders = [];

// Loop through each order to categorize it based on credit_type
manifestDetails.forEach((order) => {
  let creditRendered = false;  // Flag to avoid multiple credit section renderings

  // Check for Credit (amount_wise or day_wise)
  if (order.credit_type && order.credit_type.some(type => type.name === 'amount_wise' || type.name === 'day_wise')) {
    creditOrders.push({
      customer: `${order.customer || 'N/A'} (${order.accound_no || 'N/A'})`,
      reference_no: order.reference_no || 'N/A',
      grand_total: `£ ${order.grand_total || 0}`,
      get_total: `£ ${order.get_total || 0}`,
      sale_person_collected: `£ ${order.sale_person_collected || 0}`,
      driver_to_collect: order.driver_to_collect || '',
      remark: order.remark || ''
    });
  }

  // Check for TC (invoice_wise)
  if (order.credit_type && order.credit_type.some(type => type.name === 'invoice_wise')) {
    tcOrders.push({
      customer: `${order.customer || 'N/A'} (${order.accound_no || 'N/A'})`,
      reference_no: order.reference_no || 'N/A',
      grand_total: `£ ${order.grand_total || 0}`,
      get_total: `£ ${order.get_total || 0}`,
      sale_person_collected: `£ ${order.sale_person_collected || 0}`,
      driver_to_collect: order.driver_to_collect || '',
      remark: order.remark || ''
    });
  }

  // If there are no credit types or all credit type names are null/empty, treat as Cash
  if (!order.credit_type || order.credit_type.every(type => !type.name)) {
    cashOrders.push({
      customer: `${order.customer || 'N/A'} (${order.accound_no || 'N/A'})`,
      reference_no: order.reference_no || 'N/A',
      grand_total: `£ ${order.grand_total || 0}`,
      get_total: `£ ${order.get_total || 0}`,
      sale_person_collected: `£ ${order.sale_person_collected || 0}`,
      driver_to_collect: order.driver_to_collect || '',
      remark: order.remark || ''
    });
  }
});

// Push Credit section to the table
if (creditOrders.length > 0) {
  packingTableBody.push([ 
    { content: 'Credit', colSpan: 7, styles: { halign: 'center', fontStyle: 'bold' } }
  ]);
  creditOrders.forEach(order => {
    packingTableBody.push([
      order.customer,
      order.reference_no,
      order.grand_total,
      order.get_total,
      order.sale_person_collected,
      order.driver_to_collect,
      order.remark
    ]);
  });
}

// Push TC section to the table
if (tcOrders.length > 0) {
  packingTableBody.push([ 
    { content: 'TC', colSpan: 7, styles: { halign: 'center', fontStyle: 'bold' } }
  ]);
  tcOrders.forEach(order => {
    packingTableBody.push([
      order.customer,
      order.reference_no,
      order.grand_total,
      order.get_total,
      order.sale_person_collected,
      order.driver_to_collect,
      order.remark
    ]);
  });
}

// Push Cash section to the table
if (cashOrders.length > 0) {
  packingTableBody.push([ 
    { content: 'Cash', colSpan: 7, styles: { halign: 'center', fontStyle: 'bold' } }
  ]);
  cashOrders.forEach(order => {
    packingTableBody.push([
      order.customer,
      order.reference_no,
      order.grand_total,
      order.get_total,
      order.sale_person_collected,
      order.driver_to_collect,
      order.remark
    ]);
  });
}


    // Packing Details Table with customized margins
    doc.autoTable({
      startY: y,
      head: [packingColumns.map(col => col.title)], // Table headers
      body: packingTableBody, // Table rows
      theme: 'striped',
      styles: {
        fontSize: 8,
        cellPadding: 3,
        lineColor: [0, 0, 0],
        lineWidth: 0.3,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: '#000000',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 20 },
        2: { cellWidth: 20 ,halign: 'right'},
        3: { cellWidth: 30 ,halign: 'right'},
        4: { cellWidth: 30 ,halign: 'right'},
        5: { cellWidth: 30 },
        6: { cellWidth: 20 },
      },
      margin: { top: 0, left: leftMargin, right: rightMargin },  // Set left and right margins
    });

    // Adjust Y after packing table
    y = doc.autoTable.previous.finalY + 12; 

    // Generate a unique filename for the PDF
    const uniqueFilename = generateUniqueFilename('PickerList', 'pdf');

    // Open the document in a new window for printing
    doc.autoPrint();
    window.open(doc.output('bloburl'));

  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default generatePickerListPDF;
