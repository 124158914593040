import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const About = ({ accessToken }) => 
{
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => 
  {
    let title;
    let accessToken='ayt';


    console.log(accessToken);
    
    if (accessToken === 'ayt') 
    {
      title = "Ayt Southall";
    } else if (accessToken === 'dds') 
    {
      title = "DDsnack";
    } else 
    {
      title = "Ebusso";
    }
    setPageTitle(title); 
  }, [accessToken]); 

  return (
  
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
     
  );
};

export default About;
