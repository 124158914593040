import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import { useParams } from 'react-router-dom';

const StockTakeHistoryList = (props) => {

	const { stockId } = useParams();

	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(50);
	const [stockTakeHistory, setStockTakeHistory] = useState('');

	const fetchStockTakeHistory = async () => {
	    // Create URL parameters
	    const params = new URLSearchParams();
	    params.append('draw', draw);
	    params.append('start', start);
	    params.append('length', length);

	    // Construct API URL with query parameters
	    //const apiUrl = `${API_URL}/customer_not_order_report?${params.toString()}`;
	    const apiUrl = `${API_URL}/show_history_of_stock_take/${stockId}?${params.toString()}`;

	    const accessToken = localStorage.getItem('accessToken');      
	    if (accessToken === null) {
	        window.location.href = `${config.BASE_URL}/login`; 
	        return;
	    }

	    try {
	        const response = await fetch(apiUrl, {
	            method: 'GET',
	            headers: {
	                authorization: `Bearer ${accessToken}`,
	            },
	        });

	        if (!response.ok) {
	            throw new Error('Failed to fetch Not Order Report');
	        }
	        const data = await response.json();
	        setStockTakeHistory(data.data);
	        //console.log(data);

	    } catch (error) {
	        console.error('api fail Error fetching Not Order Report:', error);
	    }
	};

	useEffect(() => {
    	fetchStockTakeHistory();
  	},[]);

  	useEffect(() => {
    	const table = $('#stockTakeReport').DataTable({
      		pageLength: 50,
      		paging: true,
      		lengthMenu: [10, 20, 50],
      		searching: true,
      		ordering: false,
      		info: true,
      		responsive: true,
      		autoWidth: false,
      		language: {
	        	paginate: {
	          		first: 'First',
	          		last: 'Last',
	          		next: 'Next',
	          		previous: 'Previous',
	        	},
	        	search: 'Search:',
	      	},
      		data: stockTakeHistory,
      		columns: [
        		
        		{ 
        			data: null,
        			render : function(data, type, row){
        				return '<span>'+ row.name +'</span>';
        			}
        		},
        		{ data: 'bay', className : 'text-center', width: '4%' },
        		{ data: 'rack', className : 'text-center', width: '4%' },
        		{ data: 'price', className : 'text-center', width: '4%' },
        		{ data: 'cost', className : 'text-center', width: '4%' },
        		{ data: 'quantity', className : 'text-center', width: '6%' },
        		{ data: 'physical_qty', className : 'text-center', width: '6%' },
        		{ data: 'diff', className : 'text-center', width: '5%' },
        		{ data: 'piece_qty', className : 'text-center', width: '7%' },
        		{ data: 'physical_piece_qty', className : 'text-center', width: '9%' },
        		{ data: 'piece_diff', className : 'text-center', width: '6%' },
        		{ 
        			data: null, className : 'text-start', width: '15%',
        			render : function(data, type, row){
        				return '<input disabled type="text" readonly class="form-control" value="'+row.remark+'">';
        			}
        		},
        		
        		
    		],
    		drawCallback: function (settings) {
        		var api = this.api();
        		
    		}
    	});
    	return () => {
      		table.destroy();
    	};
  	}, [stockTakeHistory]);

	return(
		<>
			<Header />
			<div className="breadcrumbs">
		        <div className="container-fluid">
		          <div className="row">
		            <div className="col-12 col-lg-12 col-sm-12"> 
		              <Link to="/Dashboard"><i className="fa fa-home"></i></Link>  
		              <Link to="/stock_take/history">Stock Take History </Link> 
		            </div>
		          </div>
		       	</div>
		    </div>
		    <div className="main-container">
			    <div className="container-fluid">

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}}>

	                    <p><b>Stock Take History </b></p>
	                    
	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="stockTakeReport">
	                            <thead>
	                                <tr>
	                                    <th>Product Code - Name</th>
	                                    <th>Bay</th>
	                                    <th>Rack</th>
	                                    <th>Sale P</th>
	                                    <th>Cost P</th>
	                                    <th>Sys Qty</th>
	                                    <th>Phy Qty</th>
	                                    <th>Diff</th>
	                                    <th>Piece Qty</th>
	                                    <th>Phy Picec Qty</th>
	                                    <th>Picec Diff</th>
	                                    <th>Remark</th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                
	                            </tbody>
	                        </table>
	                    </div>
	                </div>

			    </div>
			</div>

		    <Footer />


		</>
	);
}
export default StockTakeHistoryList;
