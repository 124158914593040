import React, { useState, useEffect } from 'react';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const SalesRepReport = () => {

	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(50);
	const [dates, setDates] = useState([null, null]);
	const [reportrange, setReportrange] = useState('');
	const [reportDate, setReportDate] = useState('');
	const [salesRapReport, setSalesRapReport] = useState([]);
	const accessToken = localStorage.getItem('accessToken');



	const handleDateChange = (value) => {
		setDates(value);
	};
	const searchFormData = () => {
		let startDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
		let endDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
		setReportrange(startDate ? startDate + ' - ' + endDate : '');
		// fetchReport();
	};

	const fetchReport = async () => {
		const formData = new FormData();
		formData.append('draw', draw);
		formData.append('start', start);
		formData.append('length', length);
		formData.append('reportrange', reportrange);

		const apiUrl = `${config.API_URL}/staff_report_show`;
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					// 'Content-Type': 'application/json',
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to fetch Not Order Report');
			}
			const data = await response.json();
			//console.log(data.data);
			setSalesRapReport(data);
			setReportDate(data.startdate + ' To Date : ' + data.enddate);
		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}

	};

	useEffect(() => {
		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}
		fetchReport();
	}, [reportrange]);

	useEffect(() => {
		const table = $('#salesRepReport').DataTable({
			pageLength: 50,
			paging: true,
			lengthMenu: [10, 20, 50],
			searching: true,
			ordering: true,
			info: true,
			responsive: true,
			autoWidth: false,
			language: {
				paginate: {
					first: 'First',
					last: 'Last',
					next: 'Next',
					previous: 'Previous',
				},
				search: 'Search:',
			},
			data: salesRapReport.data,
			columns: [
				{
					data: null,
					render: function (data, type, row) {
						return `<span style={{text-align: 'center'}}>${row.name}</span>`;
					}
				},
				{ data: 'order_amt' },
				{ data: 'accept_amt' },
				{ data: 'picking_amt' },
				{ data: 'deliver_amt' },
				{ data: 'invoice_amt' },
				{ data: 'return_amt' },
				{ data: 'total_tax' }

			],
			columnDefs: [
				{
					targets: [0, 1, 2, 3, 4],
					orderable: false
				}
			],
			drawCallback: function (settings) {
				var api = this.api();
				var startIndex = api.page.info().start;

				$('tfoot').empty();
				var footerRow = $('<tr>')
					.append("<td><b style='font-weight: 700 !important;' >Total</b></td>")
					.append("<td style='text-align: right !important;font-weight: 700 !important;'>" + salesRapReport.order_amt + "</td>")
					.append("<td style='text-align: right !important;font-weight: 700 !important;'>" + salesRapReport.accept_amt + "</td>")
					.append("<td style='text-align: right !important;font-weight: 700 !important;'>" + salesRapReport.picking_amt + "</td>")
					.append("<td style='text-align: right !important;font-weight: 700 !important;'>" + salesRapReport.deliver_amt + "</td>")
					.append("<td style='text-align: right !important;font-weight: 700 !important;'>" + salesRapReport.deliver_amt + "</td>")
					.append("<td style='text-align: right !important;font-weight: 700 !important;'>" + salesRapReport.return_amt + "</td>")
					.append("<td style='text-align: right !important;font-weight: 700 !important;'>" + salesRapReport.total_tax + "</td>")
				$('tfoot').append(footerRow);
			}
		});
		return () => {
			table.destroy();
		};
	}, [salesRapReport]);


	return (
		<div>
			<Header />
			<div className="breadcrumbs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<Link to="/Dashboard"><i className="fa fa-home"></i></Link>
							<Link to="/reports/sales_report">Reports</Link>
							<Link to="/reports/sales_report">Sales Report</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="main-container">
				<div className="container-fluid">
					<div className="row">
						<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
							<div class="row mb-3 padd-0">
								<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select Date</b></label>
								<div class="col-sm-8">
									<DateRangePicker
										value={dates}
										placeholder="DD/MM/YYYY - DD/MM/YYYY"
										onChange={handleDateChange}
										format="dd/MM/yyyy"
										character=" - "
										style={{ width: '100%' }}
									/>
								</div>
							</div>
						</div>


						<div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
							<button
								type="button"
								onClick={searchFormData}
								className="acceptbtn"
								style={{ float: 'left' }}
							>
								Submit
							</button>
						</div>

					</div>

					<div className="total-manifests-area dashboard">
						<p><b>Sales Report From Date : {reportDate} </b></p>
						<div className="verical-scrl-wrapper common-table autoheight-table salsrep_report-tbl" id="style-3">
							<table className="table table-bordered resizable" id="salesRepReport">
								<thead>
									<tr>
										<th>Sales Rep </th>
										<th>Order</th>
										<th>Accept</th>
										<th>Picking</th>
										<th>Deliverd</th>
										<th>Invoice</th>
										<th>Return</th>
										<th>VAT</th>
									</tr>
								</thead>
								<tbody>

								</tbody>
								<tfoot>

								</tfoot>
							</table>
						</div>
					</div>

				</div>
			</div>
			<Footer />

		</div>
	);
}


export default SalesRepReport;
