import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { API_URL, BASE_URL,PREFIX } from './Config';

const Invoice_Format = () => {


  return (
    <div>
      <Header />
      <div class="breadcrumbs">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="dashboard.html">Dashboard</a> &gt; <a href="new-sales.html">New Sales</a> &gt; <span class="last-crumb">Sales View</span></div>
    </div>
  </div>
</div>
      <div className="main-container">
      <div class="container invoice-container"> 

  <header>
    <div class="row align-items-center gy-3">
      <div class="col-sm-5 text-center text-sm-start"> <img src={`assets/img/${PREFIX}_logo.png`} alt="Dan" /> </div>
      <div class="col-sm-7">
		  
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td><h4 class="text-7 mb-0">DAN INTERNATIONAL LTD</h4></td>
    </tr>
    <tr>
      <td>
		<address>
			Unit F1, Larkfield Trading Estate, New
Hythe Lane, Aylesford ME20 6SW
		  </address>
		</td>
    </tr>
    <tr>
      <td><b>Phone No :</b> 01622 792470 | <b>Email :</b> sales@ddsnacks.co.uk</td>
    </tr>
	  <tr>
      <td><b>Website :</b> dan.rameegroup.co.in</td>
    </tr>
  </tbody>
</table>

		  
		  
      </div>
    </div>
    <hr/>
  </header>
  

  <main>
    <div class="row">
      <div class="col-sm-6"><strong>Company No :</strong> 03044003</div>
      <div class="col-sm-6 text-sm-end"> <strong>VAT No :</strong> 662656122</div>
    </div>
    <hr/>
    <div style={{ marginBottom:'20px' }} >
      <div class="col-12 col-lg-12 col-sm-12">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered">
          <tbody>
            <tr>
              <td style={{ paddingRight:'10px'}}>
				<div class="col-sm-12 order-sm-1">
				  	<table width="100%" border="0" cellspacing="0" cellpadding="0">
						<tr>
							<td><strong>Order Number :</strong> </td>
							<td>006576, 006584, 006594</td>
						</tr>
						
						<tr>
							<td><strong>Account Number :</strong> </td>
							<td> TP312, 143143, 143143</td>
						</tr>
						
						<tr>
							<td><strong>Route Number :</strong> </td>
							<td> N1, JR1, JR1</td>
						</tr>
					</table>
                </div>
				</td>
              <td style={{paddingRight: '10px', width:'25%'}}>
				  <div class="col-sm-12 order-sm-0"> 
					  <table width="100%" border="0" cellspacing="0" cellpadding="0">
						<tr>
							<td><strong>Picking List :</strong> </td>
							<td><strong>AAA1567</strong></td>
						</tr>
						
						<tr>
							<td><strong>Delivery Date :</strong> </td>
							<td>13-06-2024</td>
						</tr>
						
						<tr>
							<td><strong>Print By : </strong> </td>
							<td> K.C</td>
						</tr>
						  <tr>
							<td><strong>Print Date : </strong> </td>
							<td> 04-01-2025</td>
						</tr>
					</table>
                </div>
				</td>
				
				<td style={{width: '25%'}}>
				  <div class="col-sm-12 order-sm-0"> 
					  <table width="100%" border="0" cellspacing="0" cellpadding="0">
						<tr>
							<td><strong>Picking List :</strong> </td>
							<td><strong>AAA1567</strong></td>
						</tr>
						
						<tr>
							<td><strong>Delivery Date :</strong> </td>
							<td>13-06-2024</td>
						</tr>
						
						<tr>
							<td><strong>Print By : </strong> </td>
							<td> K.C</td>
						</tr>
						  <tr>
							<td><strong>Print Date : </strong> </td>
							<td> 04-01-2025</td>
						</tr>
					</table>
                </div>
				</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
	  
	  
	  
    <div class="table-responsive protbl">
      <table class="table table-bordered mb-0">
        <thead>
          <tr class="bg-light" style={{background: '#f8f9fa'}}>
            <td class="col-4"><strong>Product</strong></td>
            <td class="col-2 text-center"><strong>Type</strong></td>
            <td class="col-1 text-center"><strong>Qty</strong></td>
            <td class="col-1 text-end"><strong>Price </strong></td>
			<td class="col-1 text-center"><strong>Disc</strong></td>
			<td class="col-2 text-end"><strong>VAT</strong></td>
			<td class="col-1 text-end"><strong>Total</strong></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1006 - TOXIC WASTE PURPLE DRUM</td>
            <td class="col-1 text-center">box</td>
            <td class="col-1 text-center">3</td>
            <td class="col-1 text-end">£ 8.49</td>
            <td class="col-1 text-center">£ 0.00</td>
			  
			<td class="col-1 text-end">£ 5.09</td>
            <td class="col-1 text-end">£ 25.47</td>
          
			</tr>
			
			<tr>
            <td>1006 - TOXIC WASTE PURPLE DRUM</td>
            <td class="col-1 text-center">box</td>
            <td class="col-1 text-center">3</td>
            <td class="col-1 text-end">£ 8.49</td>
            <td class="col-1 text-center">£ 0.00</td>
			  
			<td class="col-1 text-end">£ 5.09</td>
            <td class="col-1 text-end">£ 25.47</td>
          
			</tr>
			
			<tr>
            <td>1006 - TOXIC WASTE PURPLE DRUM</td>
            <td class="col-1 text-center">box</td>
            <td class="col-1 text-center">3</td>
            <td class="col-1 text-end">£ 8.49</td>
            <td class="col-1 text-center">£ 0.00</td>
			  
			<td class="col-1 text-end">£ 5.09</td>
            <td class="col-1 text-end">£ 25.47</td>
          
			</tr>
			
			
			
			<tr>
            <td>1006 - TOXIC WASTE PURPLE DRUM</td>
            <td class="col-1 text-center">box</td>
            <td class="col-1 text-center">3</td>
            <td class="col-1 text-end">£ 8.49</td>
            <td class="col-1 text-center">£ 0.00</td>
			  
			<td class="col-1 text-end">£ 5.09</td>
            <td class="col-1 text-end">£ 25.47</td>
          
			</tr>
			<tr>
			  <td colspan="6" class="text-end"><strong>Item Total :</strong></td>
			  <td class="col-1 text-end">£ 42.45</td>
		  </tr>
			<tr>
			  <td colspan="5">NON VAT Goods - £ 0.00 | VAT Goods - £ 42.45</td>
			  <td class="col-2 text-end"><strong>Total Vat :</strong></td>
			  <td class="col-1 text-end">£ 8.49</td>
		  </tr>
			<tr>
			  <td colspan="6" class="text-end"><strong>Order Discount(0%) :</strong></td>
			  <td class="col-1 text-end">0.00</td>
		  </tr>
			<tr>
			  <td colspan="6" class="text-end"><strong>Total Amount :</strong></td>
			  <td class="col-1 text-end">£ 50.94</td>
		  </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive protbl">
      <table class="table table-bordered mb-0">
        <tr class="bg-light">
          <td class="col-sm-2"><strong>A/c No</strong></td>
          <td class="col-sm-2"><strong>Comment</strong></td>
          <td class="col-sm-2"><strong>Comment By</strong><strong>&nbsp; </strong></td>
        </tr>
        
      </table>
		
		
		 <div class="invoice-footer-text">
 		   <p><span>Good Received & Checked By :</span> </p>
			 <p><span>No of Boxes Received :</span></p>
			 
			 
			 <p><span>Amount Paid :</span> </p>
			 <p><span>Cash / Cheque Collected By : </span></p>
			 
			 
	  </div>
		
		<div class="term-area">
			<h5>Bank Details</h5>
			<p>A/C No : | Sort Code :</p>
			
			<h6>Terms -</h6>
			<ul>
				<li>1 - Once delivery is received D&D SNACK FOODS is not responsible for any damage or loss</li>
				<li>2 - Goods remain the property of D&D SNACK FOODS until invoice fully paid</li>
				<li>3 - No claims for damages or short delivery will be considered unless made at the time of delivery of goods.</li>
				<li>4 - There is No Refund Policy Of goods sold</li>
			</ul>
		</div>
		
    </div>
	  
	 
	  
	  
  </main>
</div>
      </div>
      <Footer />
    </div>
  );
};

export default Invoice_Format;
