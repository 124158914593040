import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL, BASE_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const InvoicePaymentAllocation = () => {

  const navigate = useNavigate();
  const { customer_id } = useParams();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [allocationAmount, setAllocationAmount] = useState(0);
  const [order, setOrder] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [invoiceAllocationAmount, setInvoiceAllocationAmount] = useState(0);
  const [checkedInvoices, setCheckedInvoices] = useState(new Set());

  const accessToken = localStorage.getItem('accessToken');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const invoiceIds = selectedInvoices.map(invoice => invoice.invoiceNumber);

    if (invoiceIds.length === 0) {
      alert('Please select at least one invoice');
      return;
    }

    const formData = new FormData();
    formData.append('customer_id', customer_id);
    formData.append('ids[]', invoiceIds);

    const apiUrl = `${API_URL}/SubmitInvoicePaymentAllocation`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to submit allocation');
      }

      const data = await response.json();

      if (data.success) {
        setSelectedInvoices([]);
        setInvoiceAllocationAmount(0);
        setCheckedInvoices(new Set());
        Swal.fire('Success', 'Invoice payment allocation successfully submitted', 'success');
    
        navigate('/account/customer_accounts');

      } else {
        alert('Failed to allocate payment');
      }
    } catch (error) {
      console.error('Error submitting allocation:', error);
    }
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('customer_id', customer_id);

    const apiUrl = `${API_URL}/InvoicePaymentAllocation`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();

      if (data.success && data.data) {
        setOrder(data.data.customer_order || []);
        setAllocationAmount(data.data.allocation_amout[0]?.total || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, customer_id]);

  useEffect(() => {
    if (order.length > 0) {
      const table = $('#pushtosagelist').DataTable({
        pageLength: 50,
        paging: true,
        lengthMenu: [10, 20, 50],
        searching: true,
        ordering: true,
        info: true,
        responsive: true,
        autoWidth: false,
        language: {
          paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous',
          },
          search: 'Search:',
        },
        data: order,
        columns: [
          { data: null, render: (data, type, row, meta) => meta.row + 1 },
          { data: 'invoice_date' },
          { data: 'reference_no' },
          { data: 'grand_total' },
          {
            data: null,
            render: (data, type, row, meta) => {
              return `<input type="checkbox" class="select-checkbox" data-id="${row.id}" data-grandtotal="${row.grand_total}" />`;
            }
          },
        ]
      });

      $('#pushtosagelist').on('change', '.select-checkbox', function () {
        const invoiceNumber = $(this).data('id');
        const grandTotal = parseFloat($(this).data('grandtotal'));

        if (this.checked) {
          setCheckedInvoices(prevState => new Set(prevState.add(invoiceNumber)));
          setSelectedInvoices(prevState => [...prevState, { invoiceNumber, grandTotal }]);
        } else {
          setCheckedInvoices(prevState => {
            const newState = new Set(prevState);
            newState.delete(invoiceNumber);
            return newState;
          });
          setSelectedInvoices(prevState => prevState.filter(invoice => invoice.invoiceNumber !== invoiceNumber));
        }
      });

      return () => {
        table.clear().destroy();
      };
    }
  }, [order]);

useEffect(() => {
  const totalAmount = selectedInvoices.reduce((total, invoice) => total + invoice.grandTotal, 0);
  setInvoiceAllocationAmount(totalAmount.toFixed(2));

  let newTotal = totalAmount;

  if (newTotal > allocationAmount) 
  {
    Swal.fire('Error', 'Amt Available is not sufficient to allocate to selected invoices', 'error');

    // Remove invoices from the selected list until allocationAmount is met
    setSelectedInvoices(prevState => {
      let newInvoices = [...prevState];
      let remainingAmount = allocationAmount;

      // Track removed invoices by their invoiceNumber (or id)
      let removedInvoices = [];

      // Keep removing invoices while newTotal exceeds the allocation amount
      while (newTotal > remainingAmount && newInvoices.length > 0)
       {
        const removedInvoice = newInvoices.pop();
        newTotal -= removedInvoice.grandTotal;
        removedInvoices.push(removedInvoice);  // Track removed invoice
      }




      // Only uncheck the removed invoices
      setCheckedInvoices(prevChecked => {
        const updatedChecked = new Set(prevChecked);
        removedInvoices.forEach(invoice => {

          $(`input[type="checkbox"][data-id="${invoice.invoiceNumber}"]`).prop('checked', false);

          updatedChecked.delete(invoice.invoiceNumber); 
        });
        return updatedChecked;
      });

      return newInvoices;
    });

    setInvoiceAllocationAmount(0); // Reset the invoice allocation amount
  }
}, [selectedInvoices, allocationAmount]);


  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a>
              <span className="last-crumb">Dashboard</span>
             {/*  <span className="last-crumb">Account</span>
              <span className="last-crumb">Customer Ledger</span>
              <span className="last-crumb">Payment Allocation</span>*/}
            </div>
          </div>
        </div>
      </nav>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div className="row">
                    <div className="date-range-picker col-md-12 col-lg-4 col-sm-12" style={{ float: 'left' }}>
                      <div className="row mb-3 padd-0">
                        <label className="col-12 col-lg-5 col-sm-5" style={{ marginBottom: '5px' }}><b>Amt Available</b></label>
                        <div className="col-12 col-lg-7 col-sm-7">
                          <input type="text" name="amount_available" value={allocationAmount} readOnly />
                        </div>
                      </div>
                    </div>

                    <div className="date-range-picker col-md-12 col-lg-4 col-sm-12" style={{ float: 'left' }}>
                      <div className="row mb-3 padd-0">
                        <label className="col-12 col-lg-5 col-sm-5" style={{ marginBottom: '5px' }}><b>Amt Allocated</b></label>
                        <div className="col-12 col-lg-7 col-sm-7">
                          <input type="text" name="amount_allocated" value={invoiceAllocationAmount} readOnly />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="verical-scrl-wrapper newsales-table" id="style-3">
                  <table className="table table-bordered dataTable" id="pushtosagelist">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Invoice Number</th>
                        <th>Amount</th>
                        <th>Allocate</th>
                      </tr>
                    </thead>
                    <tbody>
                    </tbody>
                  </table>
                </div>

                <div className="text-center mt-4">
                
                  <button className="acceptbtn" onClick={handleSubmit} style={{ float: 'left', margin: '26px 0 0 0'}}>Submit Allocation</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default InvoicePaymentAllocation;
