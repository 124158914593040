import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import config from "../../common/Config";
import Swal from "sweetalert2";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const Supplier = () => {
  const supplierTableRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);
  const navigateToSupplierList = useNavigate();
  const [errors, setErrors] = useState({});
  const accessToken = localStorage.getItem("accessToken");
  const addSupplierModal = useRef(null);
  const [isDisable, setIsDisable] = useState(false);

  const initializeSupplierTable = () => {
    const $table = $(supplierTableRef.current);
    const searchValue = $("#searchValue").val();
    // Destroy existing DataTable instance if it exists
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/get_supplier_list`,
        type: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue,
          });
        },
        dataSrc: function (json) {
          // return json.data;
          return Array.isArray(json.data) ? json.data : [];
          console.log(json);
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        {
          data: null,
          width: "6%",
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        { data: "company" },
        {
          data: null,
          render: function (data, type, row) {
            return `<span>${row.name}</span>`;
          },
        },
        {
          data: null,
          render: function (data, type, row) {
            return `<span class="text-lowercase mightOverflow col-xs-1">${row.email.toLowerCase()}</span>`;
          },
        },
        { data: "phone" },
        { data: "city" },
        { data: "state" },
        { data: "vat_no" },
        {
          data: null,
          render: function (data, type, row) {
            const checked = row.status == "1" ? "checked" : "";
            return `<label class="switch" style="margin: 0px auto;">
                      <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
                      <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span class="switch-handle"></span>
                    </label>`;
          },
        },
        {
          data: null,
          title: "Action",
          width: "7%",
          render: (data, type, row) => `
                <a href="${config.BASE_URL}/supplier/edit_supplier/${row.id}" className="icon editbtn" title="Edit" >
                  <i class="fa fa-pencil"></i>
                </a>`,
        },
      ],
      columnDefs: [
        {
          orderable: false,
          targets: "_all",
        },
        {
          targets: 2,
          render: function (data) {
            return data === "1" ? "Active" : "Inactive";
          },
        },
      ],

      pageLength: 10,
    });

    $("#neworderTable")
      .off("change", ".switch-input")
      .on("change", ".switch-input", function (event) {
        const id = $(this).data("id");
        const status = this.checked ? 1 : 0;

        handleChangeStatus(id, status);
      });

    const handleChangeStatus = async (id, status) => {
      const formData = new FormData();
      formData.append("status", status);
      formData.append("id", id);

      const apiUrl = `${config.API_URL}/update_supplier_status`;
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Failed to change status");
        }

        const data = await response.json();
        console.log(data);

        Swal.fire({
          title: "Success",
          text: "Status changed successfully.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.error("Error status change:", error);
      }
    };
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    initializeSupplierTable();
    return () => {
      if (
        supplierTableRef.current &&
        $.fn.DataTable.isDataTable(supplierTableRef.current)
      ) {
        $(supplierTableRef.current).DataTable().destroy();
      }
    };
  }, [searchValue]);

  const [formData, setFormData] = useState({
    company: "",
    name: "",
    email: "",
    phone: "",
    contact_person: "",
    contact_person_mob: "",
    contact_person_email: "",
    finance_person: "",
    finance_person_mob: "",
  });

  const validateForm = () => {
    const newErrors = {};

    // Company validation
    if (!formData.company) {
      newErrors.company = "Mention company Name.";
    } else if (formData.company.length < 2) {
      newErrors.company = "Company name must consist of at least 2 characters.";
    } else if (formData.company.length > 255) {
      newErrors.company = "Company name must not exceed 255 characters.";
    }

    // Name validation
    if (!formData.name) {
      newErrors.name = "Mention Name.";
    } else if (formData.name.length < 2) {
      newErrors.name = "Name must consist of at least 2 characters.";
    } else if (formData.name.length > 255) {
      newErrors.name = "Name must not exceed 255 characters.";
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is Required.";
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be between 10 and 12 digits.";
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Mention Email Id.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email.";
    }

    // Contact person validation
    // if (!formData.contact_person) {
    //   newErrors.contact_person = "Mention person Name.";
    // } else if (formData.contact_person.length < 2) {
    //   newErrors.contact_person =
    //     "Contact person name must consist of at least 2 characters.";
    // } else if (formData.contact_person.length > 255) {
    //   newErrors.contact_person =
    //     "Contact person name must not exceed 255 characters.";
    // }

    // Contact person mobile validation
    // alert(formData.contact_person_mob);
    // if (!formData.contact_person_mob) {
    //   newErrors.contact_person_mob = "Contact person mobile is Required.";
    // } else if (!/^\d{10,12}$/.test(formData.contact_person_mob)) {
    //   newErrors.contact_person_mob =
    //     "Contact person mobile must be between 10 and 12 digits.";
    // }

    // Contact person email validation
    if (!formData.contact_person_email) {
      newErrors.contact_person_email = "Mention Contact Person Email.";
    } else if (!/\S+@\S+\.\S+/.test(formData.contact_person_email)) {
      newErrors.contact_person_email =
        "Please enter a valid contact person email.";
    }

    // Finance person validation
    if (!formData.finance_person) {
      newErrors.finance_person = "Mention Finance Person Name.";
    } else if (formData.finance_person.length < 2) {
      newErrors.finance_person =
        "Finance person name must consist of at least 2 characters.";
    } else if (formData.finance_person.length > 255) {
      newErrors.finance_person =
        "Finance person name must not exceed 255 characters.";
    }

    if (!formData.finance_person_mob) {
      newErrors.finance_person_mob = "finance mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.finance_person_mob)) {
      newErrors.finance_person_mob =
        "finance person mobile must be between 10 and 12 digits.";
    }

    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let fieldErrors = {};

    // Validate the input field immediately as the user types
    switch (name) {
      case "company":
        if (!value) {
          fieldErrors.company = "Mention company Name.";
        } else if (value.length < 2) {
          fieldErrors.company =
            "Company name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.company = "Company name must not exceed 255 characters.";
        }

        // Clear error if all conditions are met
        if (!fieldErrors.company) {
          //delete fieldErrors.company;
          fieldErrors.company = "";
        }
        break;

      case "name":
        if (!value) {
          fieldErrors.name = "Mention Name.";
        } else if (value.length < 2) {
          fieldErrors.name = "Name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.name = "Name must not exceed 25 characters.";
        }
        if (!fieldErrors.name) {
          fieldErrors.name = "";
        }
        break;

      case "phone":
        if (!value) {
          fieldErrors.phone = "Phone is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.phone = "Phone number must be between 10 and 12 digits.";
        }
        if (!fieldErrors.phone) {
          fieldErrors.phone = "";
        }
        break;

      case "email":
        if (!value) {
          fieldErrors.email = "Mention Email Id.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.email = "Please enter a valid email.";
        }
        if (!fieldErrors.email) {
          fieldErrors.email = "";
        }
        break;

      case "contact_person":
        if (!value) {
          fieldErrors.contact_person = "Mention person Name.";
        } else if (value.length < 2) {
          fieldErrors.contact_person =
            "Contact person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.contact_person =
            "Contact person name must not exceed 255 characters.";
        }
        if (!fieldErrors.contact_person) {
          fieldErrors.contact_person = "";
        }
        break;

      case "contact_person_mob":
        if (!value) {
          fieldErrors.contact_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.contact_person_mob =
            "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.contact_person_mob) {
          fieldErrors.contact_person_mob = "";
        }
        break;

      case "contact_person_email":
        if (!value) {
          fieldErrors.contact_person_email = "Mention Contact Person Email.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.contact_person_email =
            "Please enter a valid contact person email.";
        }
        if (!fieldErrors.contact_person_email) {
          fieldErrors.contact_person_email = "";
        }
        break;

      case "finance_person":
        if (!value) {
          fieldErrors.finance_person = "Mention Finance Person Name.";
        } else if (value.length < 2) {
          fieldErrors.finance_person =
            "Finance person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.finance_person =
            "Finance person name must not exceed 255 characters.";
        }
        if (!fieldErrors.finance_person) {
          fieldErrors.finance_person = "";
        }
        break;

      case "finance_person_mob":
        if (!value) {
          fieldErrors.finance_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.finance_person_mob =
            "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.finance_person_mob) {
          fieldErrors.finance_person_mob = "";
        }
        break;

      default:
        break;
    }

    // Update errors state for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked ? "1" : "0", // Set '1' if checked, null if unchecked
    }));
  };

  const submitSupplierForm = async () => {
    setIsDisable(true);
    // alert('aaa');
    if (!validateForm()) return; // Validate before submission
    const form = document.getElementById("addsupplierform");

    const formData = new FormData(form);
    try {
      const response = await axios.post(
        `${config.API_URL}/add_supplier`,
        formData,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const result = response.data;
      if (result.success === true) {
        Swal.fire({
          title: "Success!",
          text: "Supplier added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          setIsDisable(false);                 
          if (addSupplierModal.current) {
            const modalElement = addSupplierModal.current;
          
            // Get the Bootstrap modal instance
            const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
          
            // Hide the modal using the Bootstrap modal instance
            modalInstance.hide();
          
            // Manually remove the backdrop if it's showing
            const backdropElement = document.querySelector('.modal-backdrop');
            if (backdropElement) {
              backdropElement.classList.remove('show'); // Remove the 'show' class from the backdrop
              document.body.classList.remove('modal-open'); // Remove 'modal-open' class from the body
            }
          }
          
          window.location.reload();
          initializeSupplierTable();
        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Supplier add Failed. Try Again",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };

  return (
    <div>
      {/* Add Supplier */}
      <div className="modal fade" id="largeModal" tabIndex="-1" ref={addSupplierModal}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Supplier</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div style={{ position: "relative" }}>
                <div className="add-supplier-popup">
                  <form id="addsupplierform">
                    <h5>Company Detail</h5>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="company" className="col-form-label">
                              Company <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="company"
                              id="company"
                              value={formData.company}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.company && (
                              <span className="text-danger">
                                {errors.company}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="name" className="col-form-label">
                              Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.name && (
                              <span className="text-danger">{errors.name}</span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="email" className="col-form-label">
                              Email Address{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="phone" className="col-form-label">
                              Phone <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control no-arrows"
                              name="phone"
                              id="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              style={{
                                appearance: "none",
                                MozAppearance: "textfield",
                              }}
                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.phone && (
                              <span className="text-danger">
                                {errors.phone}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-4">
                        <div className="row">
                          <div className="col-sm-12">
                            <label htmlFor="address" className="col-form-label">
                              Address
                            </label>
                            <textarea
                              name="address"
                              className="form-control"
                              id="address"
                              style={{ height: "70px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-8">
                        <div className="row">
                          <div className="col-sm-3">
                            <label htmlFor="city" className="col-form-label">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="city"
                              id="city"
                            />
                          </div>
                          <div className="col-sm-3">
                            <label htmlFor="state" className="col-form-label">
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="state"
                              id="state"
                            />
                          </div>
                          <div className="col-sm-3">
                            <label htmlFor="country" className="col-form-label">
                              Country
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="country"
                              id="country"
                            />
                          </div>

                          <div className="col-sm-3">
                            <label
                              htmlFor="postal_code"
                              className="col-form-label"
                            >
                              Postal Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="postal_code"
                              id="postal_code"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5>Contact Person</h5>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label htmlFor="city" className="col-form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="contact_person"
                          id="contact_person"
                        />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="phone" className="col-form-label">
                          Phone <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control no-arrows"
                          name="contact_person_mob"
                          id="contact_person_mob"
                          value={formData.contact_person_mob}
                          onChange={handleChange}
                          style={{
                            appearance: "none",
                            MozAppearance: "textfield",
                          }}
                        />
                        <div className="col-sm-12">
                          {errors.contact_person_mob && (
                            <span className="text-danger">{errors.contact_person_mob}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label
                              htmlFor="contact_person_email"
                              className="col-form-label"
                            >
                              Email <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="contact_person_email"
                              id="contact_person_email"
                              value={formData.contact_person_email}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.contact_person_email && (
                              <span className="text-danger">
                                {errors.contact_person_email}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5>Banking & Finance Details</h5>
                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label
                              htmlFor="finance_person"
                              className="col-form-label"
                            >
                              Financial Person Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="finance_person"
                              id="finance_person"
                              value={formData.finance_person}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.finance_person && (
                              <div className="text-danger">
                                {errors.finance_person}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label
                              htmlFor="finance_person_mob"
                              className="col-form-label"
                            >
                              Financial Person Mobile{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control no-arrows"
                              name="finance_person_mob"
                              id="finance_person_mob"
                              value={formData.finance_person_mob}
                              onChange={handleChange}
                              style={{
                                appearance: "none",
                                MozAppearance: "textfield",
                              }}
                            />
                          </div>
                          <div className="col-sm-12">
                            {errors.finance_person_mob && (
                              <div className="text-danger">
                                {errors.finance_person_mob}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <label
                          htmlFor="finance_person_email"
                          className="col-form-label"
                        >
                          Financial Person Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="finance_person_email"
                          id="finance_person_email"
                        />
                      </div>

                      <div className="col-sm-3">
                        <label
                          htmlFor="vat_no"
                          className="col-form-label"
                        >
                          Vat Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="vat_no"
                          id="vat_no"
                        />
                      </div>

                      {/* <div className="col-sm-3">
                        <label htmlFor="po_delivery_days" className="col-form-label">Standard PO Delivery (in days)</label>
                        <input type="text" className="form-control" name="po_delivery_days" id="po_delivery_days" />
                      </div> */}
                      {/* <div className="col-sm-3">
                        <label htmlFor="payment_clearance_days" className="col-form-label">Invoice Payment Clearance after (in days)</label>
                        <input type="text" className="form-control" name="payment_clearance_days" id="payment_clearance_days" />
                      </div> */}
                      {/* <div className="col-sm-3">
                        <label htmlFor="tobacco" className="col-form-label">Allowed for Tobacco Product Supply</label>
                        <select name="tobacco" className="form-select orgselect">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div> */}
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label
                          htmlFor="supplier_bank_name"
                          className="col-form-label"
                        >
                          Bank Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="supplier_bank_name"
                          id="supplier_bank_name"
                        />
                      </div>
                      <div className="col-sm-3">
                        <label
                          htmlFor="supplier_branch_name"
                          className="col-form-label"
                        >
                          Branch Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="supplier_branch_name"
                          id="supplier_branch_name"
                        />
                      </div>

                      <div className="col-sm-3">
                        <label
                          htmlFor="supplier_account_number"
                          className="col-form-label"
                        >
                          Bank Account Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="supplier_account_number"
                          id="supplier_account_number"
                        />
                      </div>
                      <div className="col-sm-3">
                        <label
                          htmlFor="supplier_branch_code"
                          className="col-form-label"
                        >
                          Branch Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="supplier_branch_code"
                          id="supplier_branch_code"
                        />
                      </div>
                    </div>
                    <h5>Licence Details</h5>
                    <div className="row mb-3 mt-3">
                      <div className="col-6">
                        <div className="col-12">
                          <div className="form-check">
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                name="tobacco"
                                checked={formData.tobacco === "1"}
                                onChange={handleCheckboxChange}
                                value="1"
                              />
                              <span className="default"></span>
                            </label>
                            <label className="form-check-label" htmlFor="gridCheck1">
                              {" "}
                              Tobacco Product{" "}
                            </label>
                          </div>

                          {formData.tobacco === "1" && (
                            <div className="arrivebox">
                              <div className="row">
                                <div className="col-6">
                                  <div className="col-12">
                                    <label
                                      htmlFor="tpd_license_no"
                                      className="col-form-label"
                                    >
                                      TPD License Number
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="tpd_license_no"
                                      id="tpd_license_no"
                                    />
                                  </div>
                                </div>

                                <div className="col-6">
                                  <div className="col-12">
                                    <label
                                      htmlFor="tpd_license_validity"
                                      className="col-form-label"
                                    >
                                      TPD License Validity Till Date
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="tpd_license_validity"
                                      id="tpd_license_validity"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="col-12">
                          <div className="form-check">
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                name="alcohol"
                                checked={formData.alcohol === "1"}
                                onChange={handleCheckboxChange}
                                value="1"
                              />
                              <span className="default"></span>
                            </label>
                            <label className="form-check-label" htmlFor="gridCheck2">
                              {" "}
                              Alcohol Licence{" "}
                            </label>
                          </div>
                          {formData.alcohol === "1" && (
                            <div className="arrivebox">
                              <div className="row">
                                <div className="col-6">
                                  <div className="col-12">
                                    <label
                                      htmlFor="awrs_license_no"
                                      className="col-form-label"
                                    >
                                      AWRS License Number
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="awrs_license_no"
                                      id="awrs_license_no"
                                    />
                                  </div>
                                </div>

                                <div className="col-6">
                                  <div className="col-12">
                                    <label
                                      htmlFor="awrs_license_validity"
                                      className="col-form-label"
                                    >
                                      AWRS License Validity Till Date
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="awrs_license_validity"
                                      id="awrs_license_validity"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-12 col-sm-12">
                      <hr />
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div
                        className="col-12 col-lg-12 col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          onClick={submitSupplierForm}
                          type="button"
                          className="common-popup-btn"
                          disabled={isDisable}
                        >
                          Add Supplier
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i className="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="Dashboard">Master</a> &gt;{" "}
              <span className="last-crumb">Supplier</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: "relative" }}>
                <div className="row">
                  <div className="col-12 col-lg-4 col-sm-4"></div>
                  <div className="col-12 col-lg-8 col-sm-8">
                    <div style={{ float: "right" }}>
                      {/* <Link to={`${config.BASE_URL}/supplier/add_supplier`} className="create-catalogue-btn">Add Supplier</Link> */}
                      <Link
                        data-bs-toggle="modal"
                        data-bs-target="#largeModal"
                        className="create-catalogue-btn"
                      >
                        Add Supplier
                      </Link>

<Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

                    </div>
                  </div>
                </div>
                <div className="show-order-area dashboard">
                  <div
                    className="verical-scrl-wrapper common-table supplier-table "
                    id="style-3"
                  >
                    <table
                      className="table table-bordered dataTable resizable"
                      id="neworderTable"
                      ref={supplierTableRef}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "50px" }}>Sr.No</th>
                          <th>Company</th>
                          <th>Name</th>
                          <th>Email Address</th>
                          <th style={{ width: "150px" }}>Phone</th>
                          <th style={{ width: "120px" }}>City</th>
                          <th style={{ width: "150px" }}>Country</th>
                          <th style={{ width: "150px" }}>Vat Number</th>
                          <th style={{ width: "120px" }}>Status</th>
                          <th style={{ width: "120px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
    
  );
};

export default Supplier;
