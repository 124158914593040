import jsPDF from 'jspdf';

const printOrderInvoice = (selectedCustomer, tillUserName, salesRefNo, firstName, lastName) => {
  // alert(salesRefNo);
  const doc = new jsPDF('portrait');
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 10; // Margin for the border
  const contentWidth = pageWidth - margin * 2;

  // Border for the entire data
  const borderTop = 10; // Top margin for the border
  const borderHeight = 50; // Height of the bordered content
  doc.setLineWidth(0.5);
  doc.rect(margin, borderTop, contentWidth, borderHeight); // Draw rectangle border

  // Function for Center Alignment
  const centerAlign = (text, y, fontSize = 12, bold = false, hwidth = 0) => {
    const textWidth = doc.getTextWidth(text);
    let x = 0;
    if( hwidth > 0 ){
      x = (pageWidth / 2) - hwidth;
    }
    else{
      x = (pageWidth - textWidth) / 2;
    }
    doc.setFontSize(fontSize);
    doc.setFont('helvetica', bold ? 'bold' : 'normal');
    doc.text(text, x, y);
  };

  // Function for Left Alignment
  const leftAlign = (text, x, y, fontSize = 12, bold = false) => {
    doc.setFontSize(fontSize);
    doc.setFont('helvetica', bold ? 'bold' : 'normal');
    doc.text(text, x, y);
  };

  // Content inside the border
  let y = borderTop + 5;
  centerAlign('AYTAC FOODS DISTRIBUTION LTD', y, 14, true);
  y += 6;
  centerAlign('SOUTHALL BRANCH', y, 10, true);

  // Horizontal Line below the title
  y += 4;
  doc.setLineWidth(0.5);
  doc.line(margin, y, pageWidth - margin, y);

  // Customer and Till User Information
  y += 6;
  leftAlign(`Customer Name : ${selectedCustomer?.label}`, margin + 5, y, 10);
  leftAlign(`Till Number : ${tillUserName}`, pageWidth / 2+30, y, 10);
  y += 6;
  leftAlign(`Till User :${firstName} ${lastName ? lastName : ""}`, margin + 5, y, 10);

  // Order Number Section
  y += 12;
  centerAlign('Order Number', y, 12);
  y += 8;
  centerAlign(`${salesRefNo}`, y, 20, true, 14);

  // Save or Print the Document
  const filename = 'Order_Invoice.pdf';
  doc.save(filename);
  doc.autoPrint();
  window.open(doc.output('bloburl'));
};

export default printOrderInvoice;
