import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { useParams } from "react-router-dom";
import { API_URL, BASE_URL } from "../Config";
import config from "../common/Config";
import jsPDF from "jspdf"; // Library to generate PDF
import "jspdf-autotable"; // Plugin for auto table
import PrintPurchaseOrderInvoice from "./PrintPurchaseOrderInvoice";

const PurchaseOrderInvoice = (props) => {
  const { orderId } = useParams();
  const [shopDetails, setShopDetails] = useState([]);
  const [commonData, setCommonData] = useState([]);
  const [productData, setProductDetails] = useState([]);
  const [totalVat, setTotalVat] = useState(0);
  const [totalVal, setTotalVal] = useState(0);

  const accessToken = localStorage.getItem("accessToken");
  const fetchInvoice = async () => {
    const formData = new FormData();
    formData.append("id", orderId);
    const apiUrl = `${API_URL}/purchases/getpurches_items`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      console.log(data.purchase);
      setShopDetails(data.shop_details);
      setCommonData(data.commonData[0]);
      setProductDetails(data.purchase);

      const grandTotal = data.purchase.reduce((acc, item) => {
        const pdtVat = (parseFloat(item.unit_cost) * parseFloat(item.quantity)) * parseFloat(item.rate) / 100;
        const stotal = parseFloat(item.unit_cost) * parseFloat(item.quantity);
        const pdtTotal = pdtVat + stotal;
        return acc + pdtTotal;
      }, 0);

      // Update state with grand total
      setTotalVal(formatNumber(grandTotal));


      const TotalVatCalculate = data.purchase.reduce((acc, item) => {
        const vat = item.tax;
        return acc + parseFloat(vat);
      }, 0);

      setTotalVat(TotalVatCalculate);

    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    fetchInvoice();
  }, []);

  useEffect(() => {
    const table = $("#productDetails").DataTable({
      pageLength: 50,
      paging: false,
      lengthMenu: [10, 20, 50],
      searching: false,
      ordering: false,
      info: false,
      responsive: true,
      autoWidth: false,
      data: productData,
      columns: [
        {
          data: null,
          render: function (data, type, row) {
            return `<span>${row.product_code} - ${row.product_name}</span> `;
          },
        },
        {
          data: null,
          className: "text-center",
          render: function (data, type, row) {
            return `<span>${parseInt(row.quantity)}</span>`;
          },
        },
        {
          data: null,
          className: "text-center",
          render: function (data, type, row) {
            return row.unit_cost
              ? `<span>£ ${formatNumber(row.unit_cost)}</span>`
              : `<span>£ 0.00</span>`;
          },
        },
        {
          data: "rate",
          className: "text-center",
          render: function (data, type, row) {
            const pdtVat = (row.unit_cost * row.quantity * row.rate) / 100;
            return pdtVat
              ? `<span>£ ${formatNumber(pdtVat)}</span>`
              : `<span>0.00</span>`;
          },
        },
        {
          data: null,
          className: "text-center",
          render: function (data, type, row) {
            const pdtVat = (row.unit_cost * row.quantity * row.rate) / 100;
            const stotal = row.unit_cost * row.quantity;
            const pdtTotal = pdtVat + stotal;

            return `<span>£ ${formatNumber(pdtTotal)}</span>`;
          },
        },
      ],
      columnDefs: [
        {
          targets: [0],
          orderable: false,
        },
      ],
      drawCallback: function (settings) {
        var api = this.api();
      },
    });
    return () => {
      table.destroy();
    };
  }, [productData]);

  const formatNumber = (num) => {
    return parseFloat(num).toFixed(2);
  };

  const handleGenerateDeliveryNotePDF = (e, orderId) => {
    PrintPurchaseOrderInvoice(orderId);
  };
  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <span className="last-crumb">Print Invoice </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="show-order-area dashboard">
            <div className="sales-view-area">
              <div className="row">
                <div className="col-7">
                  <button
                    onClick={(e) => handleGenerateDeliveryNotePDF(e, orderId)}
                  >
                    Print Invoice
                  </button>
                </div>
              </div>
              <div className="row page-header" style={{ paddingTop: "10px" }}>
                <div className="col-4">
                  <h6>
                    <span>{commonData.name}</span>
                  </h6>
                  <p>
                    <span>{commonData.note}</span>
                  </p>
                  <p>
                    <span>Tel : {commonData.phone}</span>
                  </p>
                  <p>
                    <span>Email : {commonData.email}</span>
                  </p>
                </div>
                <div className="col-4">
                  <h6>
                    <span>{shopDetails.shop_name}</span>
                  </h6>
                  <p>
                    <span>{shopDetails.address}</span>
                  </p>
                </div>
                <div className="col-4">
                  <h6>
                    <span>Reference : {shopDetails.shop_name}</span>
                  </h6>
                  <p>
                    <span>
                      Date:{" "}
                      {new Date(commonData.date).toLocaleDateString("en-GB")}
                    </span>
                  </p>
                  <p>
                    <span>Status : {commonData.status}</span>
                  </p>
                </div>
              </div>

              <div className="sales-view-table">
                <table
                  className="table table-bordered resizable"
                  id="productDetails"
                >
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Quanitiy</th>
                      <th>Unit Cost</th>
                      <th>Tax</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="4">
                        <span className="right">VAT Amount</span>
                      </td>
                      <td>
                        <span> ${totalVat}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <span className="right">Total Amount</span>
                      </td>
                      <td>
                        <span>${totalVal}</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <p style={{ textAlign: "center" }}>
                  No claims for damages or short delivery will be considered
                  unless made at the time of delivery of goods.These goods are
                  not sold on a sale or return basis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PurchaseOrderInvoice;
