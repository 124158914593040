import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import jsPDF from 'jspdf';  // Library to generate PDF
import 'jspdf-autotable';  // Plugin for auto table
import printNewSalesInvoice from './printNewSalesInvoice';

const Common_Invoice = (props) => {

	const { orderId } = useParams();
	const [shopDetails, setShopDetails] = useState([]);
	const [orderDetails, setOrderDetails] = useState([]);
	const [productDetails, setProductDetails] = useState([]);
	const [totalVal, setTotalVal] = useState(
  {
   totalstd: 0,
   totalzeroGoods: 0,
   totalTax: 0,
   orderDiscount: 0,
   grandTotal: 0,
 }
 );

	const accessToken = localStorage.getItem('accessToken');

	const fetchInvoice = async () => {
   const formData = new FormData();
   formData.append('orderId', orderId);
   const apiUrl = `${API_URL}/ws-new-sales-details`;
   try {
     const response = await fetch(apiUrl, {
       method: 'POST',
       headers: {
         authorization: `Bearer ${accessToken}`,
       },
       body: formData,
     });

     if (!response.ok) {
       throw new Error('Failed to fetch Not Order Report');
     }
     const data = await response.json();
	      //console.log(data.details.shop_details);
     setShopDetails(data.details.shop_details);
     setOrderDetails(data.details.orderDetails[0]);
     setProductDetails(data.details.product_details);


      
const totalstd = data.details.product_details
  .filter(item => item.item_tax != '0.00' && item.item_tax != null)
  .reduce((sum, item) => {
    return sum + parseFloat(item.subtotal);
  }, 0);

const totalzeroGoods = data.details.product_details
  .filter(item => item.item_tax == '0.00' || item.item_tax === null)
  .reduce((sum, item) => {
    return sum + parseFloat(item.subtotal);
  }, 0);  


      
     const totalTax = data.details.product_details
     .reduce((sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0), 0);

        
     const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
     const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount1) || 0;

       
     const grandTotal = totalstd + totalzeroGoods + totalTax - orderDiscount - (totalstd * orderDiscount1 / 100);



     setTotalVal({
      totalstd,
      totalzeroGoods,
      totalTax,
      orderDiscount,
      orderDiscount1,
      grandTotal
    });


   } catch (error) {
     console.error('api fail Error fetching Not Order Report:', error);
   }
 };

 useEffect(() => {
   if (accessToken === null) {
     window.location.href = `${config.BASE_URL}/login`;
     return;
   }
   fetchInvoice();
 },[]);

 useEffect(() => {
  const table = $('#invoicePrint').DataTable({
    pageLength: 50,
    paging: false,
    lengthMenu: [10, 20, 50],
    searching: false,
    ordering: false,
    info: false,
    responsive: true,
    autoWidth: false,
    data: productDetails,
    columns: [
    { 
      data: null,
      render: function (data, type, row) { 
        return `<span>${row.product_code} - ${row.product_name}</span> `;
      }
    },
    { 
      data: null, 
      className : 'text-center',
      render : function(data, type, row){
        return `<span>${parseInt(row.accept_qty)}</span>`;
      } 
    },
    { 
      data: null, 
      className : 'text-center',
      render : function(data, type, row){
        return `<span>${row.order_type}</span>`;
      } 
    },
    { 
      data: 'unit_price',
      className : 'text-end',
      width : '10%',
      render: function (data, type, row) { 
       return row.unit_price ? `<span>£ ${row.unit_price}</span>` : `<span>£ 0.00</span>`;
     }
   },
   { 
    data: 'item_discount',
    className : 'text-end',
    width : '10%',
    render: function (data, type, row) 
    { 
     return row.item_discount ? `<span>£ ${row.item_discount}</span>` : `<span>£ 0.00</span>`;
   }
 },
 { 
  data: 'item_tax',
  className : 'text-end',
  width : '10%',
  render: function (data, type, row) { 
    return row.item_tax ? `<span>£ ${row.item_tax}</span>` : `<span>0.00</span>`;
  }
},
{ 
  data: 'subtotal',
  className : 'text-end',
  width : '10%',
  render: function (data, type, row) { 
    return row.subtotal ? `<span>£ ${parseFloat(row.subtotal).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
  }
},

],
    columnDefs: [
    {
      targets: [0], 
      orderable: false
    }
    ],
    drawCallback: function (settings) {
      var api = this.api();	
      $('tfoot').empty();

	      // Create footer rows with totals
      const footerRows = [
      {
        colSpan: 6,
        label: 'Total Std Goods',
        value: `£ ${totalVal.totalstd.toFixed(2)}`
      },
      {
        colSpan: 6,
        label: 'VAT Amount',
        value: `£ ${totalVal.totalTax.toFixed(2)}`
      },
      {
        colSpan: 6,
        label: 'Total Zero Goods',
        value: `£ ${totalVal.totalzeroGoods.toFixed(2)}`
      },
      {
        colSpan: 6,
        label: 'Promotion Discount',
        value: `£ ${totalVal.orderDiscount.toFixed(2)}`
      },
      {
        colSpan: 6,
        label: 'Order Discount(%)',
  value: `£ ${totalVal && totalVal.orderDiscount1 ? totalVal.orderDiscount1.toFixed(2) : '0.00'}` // Check for totalVal and orderDiscount1
},
{
  colSpan: 6,
  label: 'Invoice Total',
  value: `£ ${(totalVal && totalVal.grandTotal && totalVal.orderDiscount1 !== undefined)
  ? (totalVal.grandTotal - (totalVal.grandTotal * (totalVal.orderDiscount1 / 100))).toFixed(2)
    : '0.00'}` 
  }
  ];

       
      footerRows.forEach(row => {
        const footerRow = $('<tr>')
        .append(`<td colSpan="${row.colSpan}"><span class="right">${row.label}</span></td>`)
        .append(`<td style="text-align : right;" ><span>${row.value}</span></td>`);
        $('tfoot').append(footerRow);
      });
    }
  });
  return () => {
    table.destroy();
  };
}, [productDetails, totalVal]);


 const handleGenerateDeliveryNotePDF = (e, orderId) => {
  printNewSalesInvoice(orderId);
}
return(
  <>
  <Header />
  <div className="breadcrumbs">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12"> 
  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
  
  <span className="last-crumb">Common Invoice </span> 
  </div>
  </div>
  </div>
  </div>

  <div className='main-container'>
<div className='container'>
  <div className="row">
  <div className="col-12">
  <div className="show-order-area dashboard">


  <div class="invoice-view-area"> 
  
			  
			  <div class="row">
              <div class="col-4 col-lg-4 col-sm-4">
                <img src="assets/img/aytacfood_logo.png" alt=""/> </div>
              <div class="col-4 col-lg-4 col-sm-4">
                <ul>
                  <li>
					  <p>Aytac Foods Distribution Limited<br/>
						  Unit 3 Forest Business Park, Argall Avenue, Leyton, London E10 7FB <br/>
						  <strong>Mobile :</strong> 832329145  <strong>Email :</strong> INFO@AYTAC.CO.UK<br/>
						  <strong>Website :</strong> www.aytac.co.uk <strong> Vat No :</strong> 04896297
						  
					  </p>
                  </li>
                </ul>
              </div>
				  
				  <div class="col-4 col-lg-4 col-sm-4">
                <ul>
                  <li>
					  <p>Aytac Foods Distribution Limited<br/>
						  Unit 3 Forest Business Park, Argall Avenue, Leyton, London E10 7FB <br/>
						  <strong>Mobile :</strong> 832329145  <strong>Email :</strong> INFO@AYTAC.CO.UK<br/>
						  <strong>Website :</strong> www.aytac.co.uk <strong> Vat No :</strong> 04896297
						  
					  </p>
                  </li>
                </ul>
              </div>
				
				 
				
            </div>
			  <hr />
            <div class="row">
              <div class="col-5 col-lg-5 col-sm-5">
                <p><span>Order : </span> 11A08947<br/>
				  <span>Order Date : </span>19-12-2024 22:14<br/>
				 <span> Sale Status : </span>New<br/>

                  <span>Payment Method : </span> 
					<span>Payment Status : </span> Due
				</p>
              </div>
              <div class="col-7 col-lg-7 col-sm-7">
                <ul>
                  <li>
					  <span>COSTCUTTER CROSSLAND PARADE (TAL FOOD & WINES LTD)</span><br/>
                    <span>Tel :</span> <br/>
						<span>Email :</span> <br/>
						<span>Address : </span>  1 CROSSLAND PARADE CROSSLAND AVENUE , UB2 5RB UK 
					</li>
                </ul>
              </div>
				
				 
				
            </div>
            
            <div class="invoice-view-table" style={{marginTop: '25px'}}>
              <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered">
                <tbody>
                  <tr>
					<th>Qty</th>
					<th>Order Type</th>
                    <th>Discription</th>
                    <th>Unit Price</th>
					<th>Discount</th>
                    <th>VAT</th>
                    <th>Net Amount</th>
                  </tr>
                  <tr>
                    <td>12.00</td>
                    <td>piece </td>
                    <td>0031 - AA GRATED MOZZARELLA 170G PM 1.99</td>
					<td>£1.51</td>
                    <td>--</td>
                    <td>0.00</td>
                    <td>£18.12</td>
                  </tr>
					
					
				 <tr>
                    <td>6.00 </td>
                    <td>piece</td>
                    <td>0032 - AA GRATED VEGETERIAN 170G PM 1.99</td>
					<td>£1.51</td>
                    <td>--</td>
                    <td>0.00</td>
                    <td></td>
                  </tr>	
					
				<tr>
                    <td>5.00</td>
                    <td>piece</td>
                    <td>0005 - AA GARSTANG GRATED MILD CHEDDAR 200G PM ? 2.59</td>
					<td>£1.90</td>
                    <td>--</td>
                    <td>0.00</td>
                    <td>£9.50</td>
                 </tr>
					
					<tr>
                    <td>5.00</td>
                    <td>piece</td>
                    <td>0008 - AA BJ MATURE SLICES 120G PM 1.99 (15)</td>
					<td>£1.51</td>
                    <td>(100.0%)</td>
                    <td>0.00</td>
                    <td>0.00</td>
                 </tr>
					
					<tr>
                    <td>5.00</td>
                    <td>piece</td>
                    <td>0189 - BM ISTANBUL SLICED CHICKEN BREAST WITH HERBS 130G PM 2.99 (10)</td>
					<td>£2.26</td>
                    <td>--</td>
                    <td>0.00</td>
                    <td>£6.78</td>
                 </tr>
					
					
					

					<tr>
					  <td colspan="6" class="alightright"><strong>Item Total </strong></td>
					  <td>£207.88</td>
				  </tr>
					
					<tr>
					  <td colspan="6" class="alightright"><strong>Vatable Total </strong></td>
					  <td>0.00</td>
				  </tr>
					
					<tr>
					  <td colspan="6" class="alightright"><strong>Total Vat</strong></td>
					  <td>0.00</td>
				  </tr>
					<tr>
					  <td colspan="6" class="alightright">Order Discount(0%)</td>
					  <td>0.00</td>
				  </tr>
					<tr>
					  <td colspan="6" class="alightright">Total Amount</td>
					  <td>£207.88</td>
				  </tr>
                  
                </tbody>
              </table>
            </div>
			  
			 <div class="inv-footer-tbl">
			  	<div class="row">
					<div class="col-6 col-lg-6 col-sm-6"><p><span>Goods Received & Checked By :</span></p></div> 
					<div class="col-6 col-lg-6 col-sm-6"><p><span>No of Boxes Received :</span></p></div> 
				</div>
				 
				 <div class="row">
					<div class="col-6 col-lg-6 col-sm-6"><p><span>Amount due to collect :</span></p></div> 
					
				</div>
				 
				 <div class="row">
					<div class="col-6 col-lg-6 col-sm-6"><p><span>Amount Paid :</span></p></div> 
					<div class="col-6 col-lg-6 col-sm-6"><p><span>Cash / Cheque Collected By :</span></p></div> 
				</div>
				 
				 <div class="row">
					<div class="col-6 col-lg-6 col-sm-6"><p><span>Amount Outstanding :</span></p></div> 
					<div class="col-6 col-lg-6 col-sm-6"><p><span>Driver Name :</span></p></div> 
				</div>
		
			  </div>
   </div>



  </div>
  </div>
  </div>
  </div>
  </div>


  <Footer />
  </>
  );
}

export default Common_Invoice;