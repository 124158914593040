import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "./common/Config";
import NewFile from './nsales/NewFile';
const accessToken = localStorage.getItem('accessToken');

//   const printInvoice = async () =>{
//     const brandForm = new FormData();
//     brandForm.append("draw", 1);
//     brandForm.append("start", 2);
//     brandForm.append("length", 3);

//     const apiBrandUrl = `${config.API_URL}/get_product_brand`;
//     try{
//       const response = await fetch(apiBrandUrl, {
//         method : 'POST',
//         headers : {
//           authorization : `Bearer ${accessToken}`,
//         },
//         body : brandForm,
//       });

//       if(!response.ok){
//         throw new Error("Failed to fetch brand list");
//       }
//       const data = await response.json();
//       console.log(data);

//       printNewSalesInvoice(14690);


//     }catch (error){
//       console.log("Error fetch brand list", error);
//     }

//   };
  

//   const handleGenerateDeliveryNotePDF = (e, orderId) => {
//     printNewSalesInvoice(orderId);
//   }




const Temp = () => {
  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <a href="#"><i class="fa fa-home"></i></a> &gt; 
              <a href="Dashboard">Master</a> &gt; 
              <span class="last-crumb">Temp</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <button
            onClick={async () => {
                await   NewFile(14690); 
            }}
            style={{ width: '250px', height: '250px' }}
            >
            PDF File
        </button>

      </div>
      <Footer />
    </div>

  );
};

export default Temp; 