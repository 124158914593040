import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../../Header";
import Footer from "../../Footer";
import FlashMessage from "../../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../../common/Config";

const ContraList = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [newSales, setNewSales] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const allPdtRef = useRef(null);

  let dateRange = '';
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/react/login";
      return;
    }

    fetchPageData();
  }, []);

  // const fetchPageData = async () => {
  //   let StartDate = localStorage.getItem('formattedStartDate');
  //   let EndDate = localStorage.getItem('formattedEndDate');
  //   // alert(StartDate);
  //   // alert(EndDate);
  //   const formData = new FormData();
  //   formData.append("draw", draw);
  //   formData.append("start", start);
  //   formData.append("length", length);
  //   formData.append("searchByFromdate", startDate ? startDate : "");
  //   formData.append("searchByTodate", endDate ? endDate : "");

  //   const apiUrl = `${config.API_URL}/contra_price_list`;
  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: "POST",
  //       headers: {
  //         // 'Content-Type': 'application/json',
  //         authorization: `Bearer ${accessToken}`,
  //       },
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch Not Order Report");
  //     }
  //     const data = await response.json();
  //     //	console.log(data.data);
  //     setNewSales(data.data);
  //   } catch (error) {
  //     console.error("api fail Error fetching Not Order Report:", error);
  //   }
  // };

  // useEffect(() => {
  //   const table = $("#neworderTable").DataTable({
  //     pageLength: 50,
  //     paging: true,
  //     lengthMenu: [10, 20, 50],
  //     searching: true,
  //     ordering: false,
  //     info: true,
  //     responsive: true,
  //     autoWidth: false,
  //     language: {
  //       paginate: {
  //         first: "First",
  //         last: "Last",
  //         next: "Next",
  //         previous: "Previous",
  //       },
  //       search: "Search:",
  //     },
  //     data: newSales,
  //     columns: [
  //        {
  //         data: null,
  //         render: function (data, type, row, meta) 
  //         {
  //           return meta.row+1;
  //         },
  //         createdCell: function (td) 
  //         {
  //           $(td).css("text-align", "center");
  //         }
  //       },        
  //       {
  //           data: "company",
  //           render: function(data, type, row) {
  //             return `<a href="${config.BASE_URL}/customers/contra_price_edit/${row.id}"  >${data}</a>`;
  //           }
  //       },          
  //       { data: "email" },
  //       { data: "phone" },
  //       { data: "accound_no" },
  //       { data: "postal_code" },
  //     ],
  //     columnDefs: [
  //       {
  //         targets: [1, 2, 3, 4, 5], // Allow sorting on other columns
  //         orderable: true,
  //       },
  //     ],
  //   });
  //   return () => {
  //     table.destroy();
  //   };
  // }, [newSales]);

  const fetchPageData = () => {
    const $table = $(allPdtRef.current);
    const searchValue = $('#searchValue').val();
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
    let i = 0;
    $table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            url: `${config.API_URL}/contra_price_list`,
            type: 'POST',
            headers: {
               authorization : `Bearer ${accessToken}`,
            },
            data: function (d) {
                return $.extend({}, d, {
                    draw: d.draw,
                    start: d.start,
                    length: d.length,
                    searchValue : searchValue
                });
            },
            dataSrc: function (json) {
                //return json.data;
                if (json && json.data && Array.isArray(json.data)) {
                  setNewSales(json.data);
                }
                return Array.isArray(json.data) ? json.data : [];
                console.log(json.data);
            }
        },
        language: {
            paginate: {
                first: 'First',
                last: 'Last',
                next: 'Next',
                previous: 'Previous',
            },
            search: 'Search:',
        },
        columns: [
         {
          data: null,
          render: function (data, type, row, meta) 
          {
            return meta.row+1;
          },
          createdCell: function (td) 
          {
            $(td).css("text-align", "center");
          }
        },        
        {
            data: "company",
            render: function(data, type, row) {
              return `<a href="${config.BASE_URL}/customers/contra_price_edit/${row.id}"  >${data}</a>`;
            }
        },          
        { data: "email" },
        { data: "phone" },
        { data: "accound_no" },
        { data: "postal_code" },
      ],
        columnDefs: [
          {
              orderable: false, 
              targets: '_all'
          }
      ],
    
        pageLength: 10,
    });

};





  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Customer</Link>
              <Link to="#">Contra Price List</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div
            className="total-manifests-area dashboard"
            style={{ position: "relative" }}
          >
            <div
              className="verical-scrl-wrapper common-table autoheight-table contra-list-table"
              id="style-3"
            >
              <table className="table table-bordered resizable" id="neworderTable" ref={allPdtRef}>
                <thead>
                  <tr>
                    <th style={{textAlign:'center', width: '70px'}}>Sr. No</th>
                    <th style={{textAlign:'center'}}>Customer Name</th>
                    <th style={{width: '300px'}}>Email</th>
                    <th style={{width: '150px'}}>Phone</th>
                    <th style={{textAlign:'center', width: '150px' }}>Account No</th>
                    <th style={{textAlign:'center'}}>Postal Code</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContraList;
