import React, { useEffect, useRef } from 'react';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import printOrderInvoice from './printOrderInvoice';
import jsPDF from 'jspdf';

const Order_Receipt_sticker = ({ tableRows, totalVal, customerDetails, companyAddress, salesRefNo, customeId, selectedCustomer, tillUserName, handleClose }) => {
  const today = new Date();
  const modalRef = useRef(null);
  const formattedDate = today.toLocaleDateString();
  const firstName = localStorage.getItem("first_name");
  const lastName = localStorage.getItem("last_name");
  const testPDF = useRef(null);

  const handleGenerateOrderReceipt = (e) => {
    printOrderInvoice(selectedCustomer, tillUserName, salesRefNo, firstName, lastName);
  };

  const handlePrint = (e) => {
    e.preventDefault();

    // Get the content from the specific area
    const printContents = document.querySelector('.order-receipt-sticker-area').innerHTML;

    if (!printContents) {
      console.error('Print content not found!');
      return;
    }

    // Open a new window for printing
    const printWindow = window.open('', '', 'height=800,width=1200');

    // Add the HTML and CSS to the new window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              font-size: 12px;
            }
            .order-receipt-sticker-area {
              padding: 20px;
              border: 1px solid #ddd;
              margin: 10px;
              text-align: left;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            td {
              padding: 8px;
              border: 1px solid #ddd;
              vertical-align: top;
            }
            h4 {
              margin: 0;
              font-size: 16px;
            }
            p {
              margin: 0;
              font-weight: bold;
            }
            .btn-close,
            #print-button {
              display: none !important;
            }
            @media print {
              body {
                font-size: 10px;
              }
              table {
                font-size: 10px;
              }
              .order-receipt-sticker-area {
                padding: 10px;
              }
            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);

    // Close the document and trigger printing
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();

    // Optional: Close the print window after printing
    // setTimeout(() => printWindow.close(), 1000);
  };

  const test = () => {
    const doc = new jsPDF({
      orientation: "portrait", // Set orientation to portrait
      unit: "mm", // Use millimeters
      format: "a4", // A4 page format
    });
  
    const element = document.getElementById("content-to-print");
  
    doc.html(element, {
      callback: (doc) => {
        doc.save("output.pdf"); // Save the generated PDF
      },
      x: 10, // Horizontal margin
      y: 10, // Vertical margin
      width: 190, // Content width for A4 (210mm - margins)
      windowWidth: 800, // Ensure the content width matches this
    });
  };
  


  return (
    <div className="container">
      <div className="modal fade" id="Order_Receipt" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Order Receipt</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={handleClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" id="modal-body-content">
              <div className="performa-invoice-popup">
                {/* <a href="#" id="print-button" onClick={handlePrint} style={{ textDecoration: 'none', color: 'blue' }}>Print</a> */}
                <button onClick={(e) => handleGenerateOrderReceipt(e)} className='btn btn-primary' style={{float:'right'}} >Print</button>
                {/* <button onClick={() => test()} className='btn btn-primary' style={{float:'right', marginBottom:'20px'}} >Print</button> */}
                <div
                  className="order-receipt-sticker-area"
                  ref={testPDF}
                  id="content-to-print"
                  style={{
                    width: '190mm', // A4 content width
                    margin: '0 auto', // Center the content
                    padding: '10mm', // Add padding for spacing within the content
                    boxSizing: 'border-box', // Include padding in width calculation
                    fontSize: '12px', // Adjust font size for better scaling
                  }}
                >
                  <h4>
                    Aytac Foods Distribution Ltd<br />
                    Southall Branch
                  </h4>

                  <table>
                    <tbody>
                      <tr>
                        <td>Customer Name: {selectedCustomer?.label}</td>
                        <td>Till Number: {tillUserName}</td>
                      </tr>
                      <tr>
                        <td>Till User: {firstName} {lastName || ''}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <p>Order Number</p>
                          <span>{salesRefNo}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>    
        </div>
      </div>
    </div>
  );
};

export default Order_Receipt_sticker;
