import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import CustomDateRangePicker from '../common/CustomDateRangePicker';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import AccountService from '../../services/AccountServices';

const ProductLedgerView = () => {
	const accessToken = localStorage.getItem('accessToken');
	const user_id = localStorage.getItem('userId');
	const { productId, startDate, endDate, orderType } = useParams();

	const [dates, setDates] = useState([null, null]);
	const [resStartDate, setResStartDate] = useState('');
	const [resEndDate, setResEndDate] = useState('');
	const [reportrange, setReportrange] = useState('');
	const [productOptions, setProductOptions] = useState([]);
	const [productFilteredOptions, setProductFilteredOptions] = useState([]);
	const [productValue, setProductValue] = useState(null);  
	const [searchValue, setSearchValue] = useState([]);
	const productLadgerRef = useRef(null);

	const searchFormData = () => {
		const startDateFormatted = dates[0] ? format(dates[0], 'dd/MM/yyyy') : '';
		const endDateFormatted = dates[1] ? format(dates[1], 'dd/MM/yyyy') : '';
		setReportrange(startDateFormatted ? `${startDateFormatted} - ${endDateFormatted}` : '');
		getProductLedgerList();
	};

	

    // Fetch product ledger list
	const getProductLedgerList = () => {
		const $table = $(productLadgerRef.current);

		const startDateFormatted = dates[0] ? format(dates[0], 'dd/MM/yyyy') : '';
		const endDateFormatted = dates[1] ? format(dates[1], 'dd/MM/yyyy') : '';
		const searchValue = $('#searchValue').val();

		if ($.fn.DataTable.isDataTable($table)) {
			$table.DataTable().destroy();
		}

		$table.DataTable({
			processing: true,
			serverSide: true,
			ajax: {
				url: `${config.API_URL}/view_product_ledger_details`,
				type: 'POST',
				headers: { authorization: `Bearer ${accessToken}` },
				data: function (d) {
					return $.extend({}, d, {
						start: d.start,
						length: d.length,
						searchValue: searchValue,
						product_id: productId || '',
						start_date: startDate || '',
						end_date: endDate || '',
						order_type: orderType || '',
					});
				},
				dataSrc: function (json) {
					if (json) {
						let openingBalanceRow = {
							date: '',
							code: '',
							name: '',
							order_type: '',
							status: 'O/B',
							qty: '',
							bal: json.data[0].order_type === 'box' 
							? json.data[0].box_system_qty || 0
							: json.data[0].piece_system_qty || 0
						};
						json.data.unshift(openingBalanceRow); 

						setResStartDate(json.start_date);
						setResEndDate(json.end_date);
					}
					return Array.isArray(json.data) ? json.data : [];
				},
			},
			language: {
				paginate: { first: 'First', last: 'Last', next: 'Next', previous: 'Previous' },
				search: 'Search:',
			},

			columns: [
				{ data: 'date' },
				{ data: 'code' },
				{ data: 'name' },
				{ data: 'order_type' },
				{ data: 'status' },
				{ data: 'qty' },
				{
					data: 'bal',
					render: function (data, type, row) {
						if (row.order_type === 'box') {
							return `<span style="text-align: right;">${row.actual_box_balance || ''}</span>`;
						} else if (row.order_type === 'piece') {
							return `<span style="text-align: right;">${row.actual_piece_balance || ''}</span>`;
						} else {
							return `<span style="text-align: right;">${data || ''}</span>`;
						}
					}
				},
				],
			columnDefs: [
				{ orderable: false, targets: '_all' },
				],
			pageLength: 10,
		});
	};

	useEffect(() => {
		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}

		getProductLedgerList();

		return () => {
			if (productLadgerRef.current && $.fn.DataTable.isDataTable(productLadgerRef.current)) {
				$(productLadgerRef.current).DataTable().destroy();
			}
		};
	}, [accessToken, searchValue, resStartDate, resEndDate]);

	return (
		<>
		<Header />
		<div className="breadcrumbs">
		<div className="container-fluid">
		<div className="row">
		<div className="col-12 col-lg-12 col-sm-12">
		<Link to="/Dashboard"><i className="fa fa-home"></i></Link>
		<Link to="/account/my_ledger">Account</Link>
		<Link to="/account/product_ledger">Product Ledger View</Link>
		</div>
		</div>
		</div>
		</div>

		<div className="main-container">
		<div className="container-fluid">
		<div className="total-manifests-area dashboard" style={{ position: 'relative' }}>
		<p><b>Product Ledger</b></p>



		<div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
		<table className="table table-bordered dataTable resizable" id="productLadgerList" ref={productLadgerRef}>
		<thead>
		<tr>
		<th>Date</th>
		<th>Code</th>
		<th>Product</th>
		<th>Type</th>
		<th>Transaction</th>
		<th>Qty</th>
		<th>Balance</th>
		</tr>
		</thead>
		<tbody>
		<tr>
		<td>1</td>
		<td>2</td>
		<td>3</td>
		<td>4</td>
		<td>5</td>
		<td>6</td>
		<td>7</td>
		</tr>
		</tbody>
		</table>
		</div>
		</div>
		</div>
		</div>

		<Footer />
		</>
		);
	};

	export default ProductLedgerView;
