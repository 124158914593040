import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL, BASE_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import config from "../common/Config";

const ViewShortQty = () => {

  const { picklistNo } = useParams();
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);
    formData.append('id', picklistNo);

    const apiUrl = `${API_URL}/get_short_list`;
    const accessToken = localStorage.getItem('accessToken');


    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      console.log(data);
      /* if (!data || data.recordsTotal === 0 || data.data === null) 
       {

         window.location.href = `${BASE_URL}/picking/short_qty`;

       }*/

      setNewSales(data.data);
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole, draw, start, length, picklistNo]);

  useEffect(() => {
    const initializeDataTable = () => {
      $('#neworderTable').DataTable({
        paging: true,
        lengthMenu: [10, 20, 50],
        searching: true,
        ordering: false,
        info: true,
        responsive: true,
        autoWidth: false,
        language: {
          paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous',
          },
          search: 'Search:',
        },
        data: newSales,
        columns: [
          { data: 'code', width: '12%', className: 'text-center' },
          { data: 'name', width: '20%', className: 'text-center' },
          { data: 'order_qty' },
          { data: 'pick_qty', width: '20%', className: 'text-center' },
          {
            data: null,
            width: '10%',
            render: function (data, type, row) {
              return `<a
            className="deductionbtn"
            href="${BASE_URL}/picking/confirm_short_qty/${row.picklist_number}/${row.product_id}/${row.order_type}" title="View"
            >
            <i class="fa fa-eye"></i> 
            </a>`;
            },
          },
        ],
      });
    };

    initializeDataTable();

    return () => {
      $('#neworderTable').DataTable().destroy();
    };
  }, [newSales]);

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i> </a>
              <span className="last-crumb">Short Qty</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard">
                <div className="verical-scrl-wrapper viewshort-qty" id="style-3">
                  <table className="table table-bordered dataTable resizable" id="neworderTable">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Product</th>
                        <th>Order Qty</th>
                        <th>Pick Qty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewShortQty;
