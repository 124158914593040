import jsPDF from 'jspdf';
import 'jspdf-autotable';
import config from "../common/Config";
import axios from 'axios';

const generateUniqueFilename = (baseName, extension) => {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  return `${baseName}_${timestamp}.${extension}`;
};

const generateSummaryPDF = async (picklistNo) => {
  const accessToken = localStorage.getItem('accessToken');
  const firstName = localStorage.getItem('first_name');
  const lastName = localStorage.getItem('last_name');
   const todayDate = new Date();
  const formData = new FormData();
    formData.set('picklist_number', picklistNo);
  const apiUrl = `${config.API_URL}/get_view_picklist`;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);  // Convert timestamp string to Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;  // Return formatted date as "YYYY-MM-DD"
  };
  function formatNewDate(dateString) {
    if (dateString=='0000-00-00') {
     return ``; 
    }else{
    const date = new Date(dateString); // Parse the date string
    const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based) and pad
    const year = date.getFullYear(); // Get full year

    return `${day}-${month}-${year}`; // Return formatted date
  }
  }
  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const data = response.data;
    console.log(data);

   const packingDetails = Array.isArray(data.data.packing_details) ? data.data.packing_details : [];
    const totalQty = packingDetails.reduce((acc, item) => acc + parseInt(item.quantity, 10), 0);

    

    const doc = new jsPDF('portrait'); // Set to portrait orientation
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;  // 5px padding
    let y = margin;
    let customername = '';
    if (data.data.reference_no.length == 1) {
      customername = `Customer Name : ${data.data.reference_no[0]?.customer || 'N/A'}`;
    }

   let referenceNos = '';
const items = data.data.reference_no.map(item => item.reference_no);

for (let i = 0; i < items.length; i++) {
  referenceNos += items[i].trim();
  // Add a newline after every 8 items, except after the last item
  // if ((i + 1) % 8 === 0 && (i + 1) !== items.length) {
  //   referenceNos += '\n'; // Insert newline after every 8th item
  // } else {
    referenceNos += ', '; // Add a comma between items
  // }
}

// alert(referenceNos);
// return;





// Process account numbers with newline after every 8 items
let acNos = '';
const accountNumbers = data.data.reference_no.map(item => item.accound_no);
for (let i = 0; i < accountNumbers.length; i++) {
  acNos += accountNumbers[i];
  //if ((i + 1) % 8 === 0 && i + 1 !== accountNumbers.length) {
   //  acNos += '\n'; // Insert newline after every 8th item
   //} else {
    acNos += ', '; // Add comma separator between items
   //}
}

// Process route numbers with newline after every 8 items
let rnNos = '';
const routeNumbers = data.data.reference_no.map(item => item.route_number);
for (let i = 0; i < routeNumbers.length; i++) {
  rnNos += routeNumbers[i];
 
    rnNos += ', '; 
  
}

// Display the result
//alert(`Account Numbers:\n${acNos}\n\nRoute Numbers:\n${rnNos}`);
const orderDetails = [
  `Order Number: ${referenceNos}`,
  `Account Number: ${acNos}`,
  `Route Number: ${rnNos}`,
];


    const firstColumnWidth = pageWidth * 0.25;  // 30% of the page width
    const secondColumnWidth = pageWidth * 0.75; 
    //const H2firstColumnWidth = pageWidth * 0.45; 

    const H2firstColumnWidth = pageWidth * 0.70;  // 45% of the page width
    const H2secondColumnWidth = pageWidth * 0.20; // 27.5% of the page width
    const H2thirdColumnWidth = pageWidth * 0.25;  // 27.5% of the page width


    // Add the logo image to the header (make sure the image path is correct)
   const logoUrl = `${config.BASE_URL}/assets/img/${accessToken}_logo.png`; // Path to the logo image

    const logoWidth = 40; 
    const logoHeight = 15; 
    doc.addImage(logoUrl, 'PNG', margin, y, logoWidth, logoHeight); 
    y += logoHeight + 5; 

    const boldTxt = '<b>';
    const boldTxtClose = '</b>';

    const headerSections2 = [
      [
        // Add shop details (below logo)
        ],
      [
        { text: `${data.data.shop_details.shop_name}`, size: 13, bold: true },
         { text: `${data.data.shop_details.address}`, size: 8, bold: false },
         {text: ``, size: 8, bold: false}, // First line of address
    { text: `Company No : ${data.data.shop_details.company_reg_no} | VAT No : ${data.data.shop_details.vat_no}`, size: 8, bold: false },
    { text: `Phone No : ${data.data.shop_details.phone} | Email : ${data.data.shop_details.email}`, size: 8, bold: false },
    { text: `Website : ${data.data.shop_details.website}`, size: 8, bold: false },

    ],
   

      ];





    const headerSections3 = [
      [
       
        { text: customername, size: 8, bold: true },
        { text: `Order Number: ${referenceNos}`, size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: `Account Number : ${acNos}`, size: 8, bold: false },
        { text: '', size: 8, bold: false },
        { text: `Route Number: ${rnNos}`, size: 8, bold: false },
      ],
      [
         { text: `Picking List : ${data.data.picklist_number}`, size: 8, bold: false },
        { text: `Delivery Date : ${formatNewDate(data.data.reference_no[0]?.deliverydate)}`, size: 8, bold: false },
        { text: `Print By: ${firstName} ${lastName}`, size: 8, bold: false },
        { text: `Print Date : ${formatDate(todayDate)}`, size: 8, bold: false },
      ],
      ];




    const sectionWidth = (pageWidth - margin * 2) / 2; // Adjust for margins

    // Render header sections
    y = 7;
   const renderSections = (sections, marginTop = 7) => { // marginTop is an argument that defaults to 10
  let yPosition = y + marginTop; // Add initial marginTop space before rendering starts

  sections.forEach((section, index) => {
    // Calculate starting X position for the columns (for layout)
    const colStartX = margin + (index % 3) * firstColumnWidth;

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, yPosition + i * 4); // Adjust Y based on current position
    });

    // Increase the Y position after rendering each section
    if (index % 4 === 3) {
      yPosition += 12; // Adjust spacing between sections as needed
    }
  });

  // Add a little space after the last section
  yPosition += 25;

  // Update the global Y value if you need to keep track of the Y position globally
  y = yPosition;
};

renderSections(headerSections2);


  const h2renderSections = (sections) => {
  let rowHeight = 0; // To store the maximum height of columns in a row
  let rowStartY = y; // Track the starting Y position for each row of 2 columns
  let columnHeights = []; // To store individual column heights within a row

  sections.forEach((section, index) => {
    // Column X position based on section index (for a 2-column layout)
    let colStartX;
    if (index % 2 === 0) {
      colStartX = margin;
    } else {
      colStartX = margin + H2firstColumnWidth; // Adjust for the second column
    }

    let sectionHeight = 0; // To track height of the current section

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');

      // Wrap text if it exceeds column width (maxWidth)
      let maxWidth;
      if (index % 2 === 0) {
        maxWidth = H2firstColumnWidth - 5; // First column width
      } else {
        maxWidth = H2secondColumnWidth - 5; // Second column width
      }

      const textWidth = doc.getTextWidth(item.text);
      let lines = [];
      if (textWidth > maxWidth) {
        // Split text into multiple lines
        let words = item.text.split(' ');
        let line = '';

        words.forEach((word) => {
          const testLine = line + (line ? ' ' : '') + word;
          if (doc.getTextWidth(testLine) <= maxWidth) {
            line = testLine;
          } else {
            lines.push(line);
            line = word;
          }
        });
        lines.push(line); // Push remaining line
      } else {
        lines.push(item.text); // No wrapping needed, just one line
      }

      // Render each line of text
      lines.forEach((lineText, lineIndex) => {
        const lineHeight = 5; // Adjust vertical spacing for each line
        doc.text(lineText, colStartX + 4, y + 4 + i * lineHeight + lineIndex * lineHeight);
        sectionHeight = y + i + lineIndex - 4; // Update section height
      });
    });

    // Store the height of the current section (column height)
    columnHeights.push(sectionHeight);

    // After every 2 columns (2 sections), calculate the maximum height for this row
    if ((index + 1) % 2 === 0) {
      rowHeight = Math.max(...columnHeights); // Get the maximum height in this row
      columnHeights = []; // Reset for the next row

      // Draw borders for both columns in the row
      let colX = margin;
      for (let i = 0; i < 2; i++) {
        doc.setLineWidth(0.1); // Border line width
        let colWidth = (i === 0) ? H2firstColumnWidth : H2secondColumnWidth;
        doc.rect(colX, rowStartY - 2, colWidth, rowHeight); // Draw the rectangle for the column with a small padding
        colX += colWidth; // Update X position for the next column
      }

      // Move the Y position down for the next row of columns
      y += rowHeight + 12; // Add some space after each row of columns
    }
  });

  y += -3; // Add a little space after all header sections
};


// Call the function to render sections with equal height borders
h2renderSections(headerSections3);




  const packingColumns = [
      { title: 'Code', dataKey: 'code', size: 8, bold: false },
      { title: 'Product Name', dataKey: 'name', size: 8, bold: false },
      { title: 'Type', dataKey: 'order_type', size: 8, bold: false },
      { title: 'Quantity', dataKey: 'quantity', size: 8, bold: false },
    ];
    // Calculate the table widths
    const codeWidth = pageWidth - 2 * margin;
   
    

    // Construct table body
    const packingTableBody = packingDetails.map((item) => [
      item.code,
      item.name,
      item.order_type,
      parseInt(item.quantity),
    ]);

    // Add an empty row with totals
    packingTableBody.push(['', '', 'Total : ', totalQty.toString()]);



// Initial Y-position


// Create the first table
doc.autoTable({
  startY: y,
  head: [packingColumns.map(col => col.title)], // Column headers
  body: packingTableBody, // Table data
  theme: 'grid',
  headStyles: {
    fillColor: '#f8f9fa',
    textColor: '#404040',
    fontSize: 8,
    valign: 'middle',
    lineWidth: 0.3,
    halign: 'center',
    lineColor: [0, 0, 0]
  },
  bodyStyles: {
    fontSize: 8,
    valign: 'middle',
    lineWidth: 0.3,
    lineColor: [0, 0, 0]
  },
  margin: { top: 0, left: margin, right: margin }, // Adjust margin
  columnStyles: {
    0: { fontSize: 8, fontStyle: 'bold' },
    1: { fontSize: 8, fontStyle: 'bold' },
    2: { fontSize: 8, fontStyle: 'bold' },
    3: { fontSize: 8, fontStyle: 'bold' },
    2: { halign: 'center' },
    3: { halign: 'center' }
  }
});

// Check if the table content exceeds the page height
if (doc.lastAutoTable.finalY > doc.internal.pageSize.height - 20) {
  // Table has overflowed, manually add a new page
  doc.addPage();
  
  // Add the table again with a startY to account for padding at the top of the new page
  doc.autoTable({
    startY: 10, // 10px padding on the second page
    head: [packingColumns.map(col => col.title)], // Column headers
    body: packingTableBody, // Table data
    theme: 'grid',
    headStyles: {
      fillColor: '#f8f9fa',
      textColor: '#404040',
      fontSize: 8,
      valign: 'middle',
      lineWidth: 0.3,
      halign: 'center',
      lineColor: [0, 0, 0]
    },
    bodyStyles: {
      fontSize: 8,
      valign: 'middle',
      lineWidth: 0.3,
      lineColor: [0, 0, 0]
    },
    margin: { top: 0, left: margin, right: margin }, // Adjust margin
    columnStyles: {
      0: { fontSize: 8, fontStyle: 'bold' },
      1: { fontSize: 8, fontStyle: 'bold' },
      2: { fontSize: 8, fontStyle: 'bold' },
      3: { fontSize: 8, fontStyle: 'bold' },
      2: { halign: 'center' },
      3: { halign: 'center' }
    }
  });
} else {
  // If the table fits on one page, adjust the y position
  y = doc.lastAutoTable.finalY + 10;
}




    y = doc.autoTable.previous.finalY + 12;

     const accountComments = data.data.accound_no || [];
    const commentsColumns = [
      { title: 'A/c No', dataKey: 'accound_no', size: 8, bold: false },
      { title: 'Comment', dataKey: 'comment', size: 8, bold: false },
      { title: 'Comment By', dataKey: 'name', size: 8, bold: false },
    ];

    const commentsTableBody = accountComments.map(item => {
      const comments = [];
      if (item.staff_note) {
        comments.push({ accound_no: item.accound_no, comment: item.staff_note, name: item.name });
      }
      if (item.note) {
        comments.push({ accound_no: item.accound_no, comment: item.note, name: item.name });
      }
      return comments;
    }).flat(); // Flatten the array of comments

    // Render the account comments table with no padding and border
    doc.autoTable({
      startY: y,
      head: [commentsColumns.map(col => col.title)],
      body: commentsTableBody.map(item => [item.accound_no, item.comment, item.name]),
      theme: 'plain', // Use plain theme for no grid
      headStyles: {
        fillColor: null, // No background color
        textColor: '#000000', // Text color
        fontSize: 8, // Adjust as necessary
      },
      styles: {
        cellPadding: 1, // Remove padding
        lineWidth: 0, // Remove borders
        fontSize: 8, // Set body font size to 6
      },
    });



const remainingSpace = pageHeight - y - 10; // Calculate available space

// Add footer content with direct positioning
if (remainingSpace >= 20) {
 // doc.text("No claims for damages or short delivery will be considered unless made at the time of delivery of goods. These goods are not sold on a sale or return basis.", 10, pageHeight - 20);  // Directly specify position
} else {
  doc.addPage(); 
 // doc.text("No claims for damages or short delivery will be considered unless made at the time of delivery of goods. These goods are not sold on a sale or return basis.", 10, pageHeight - 20);  // Directly specify position on new page
}

const filename = generateUniqueFilename('Invoice', 'pdf');
//doc.save(filename);

doc.autoPrint();
window.open(doc.output('bloburl')); 



} catch (error) {
  console.error('Error generating PDF:', error);
}
};

export default generateSummaryPDF;
