import React, { useState } from 'react';
import BarcodeReader from 'react-barcode-reader';  
import { handleEposProductAdd } from "../../services/EposServices"; 
import axios from 'axios';

const BarcodeScannerComponent = ({ 
  tableRows, 
  setTableRow, 
  customeId, 
  handleQuantityChange, 
  accessToken 
}) => {
  const [barcode, setBarcode] = useState(null);  
  const [scanning, setScanning] = useState(true);

 
  const handleBarcodeScan = (scannedBarcode) => 
  {
    console.log('Scanned Barcode:', scannedBarcode); 
    setBarcode(scannedBarcode); 
    const barcode="1";
   
    handleEposProductAdd(scannedBarcode,barcode, tableRows, setTableRow, customeId, handleQuantityChange, accessToken);
    
     setScanning(true); 
      
  };

  
  const handleError = (err) => 
  {
    console.error('Error while scanning:', err); 
  };

  return (
    <>
   
    {scanning ? (
    <BarcodeReader
    onScan={handleBarcodeScan}  // Handle barcode scan success
    onError={handleError}  // Handle scanning error
    />
    ) : (
    ''  // Hide BarcodeReader when scanning is false
    )}

   
    </>
    );
};

export default BarcodeScannerComponent;
