import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import Select from 'react-select';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Swal from 'sweetalert2';


const Edit_delivered_invoice = () => {
	const { orderId, tripId } = useParams();
	
	const tableRef = useRef(null);
	const [shopDetails, setShopDetails] = useState([]);
	const [orderDetails, setOrderDetails] = useState([]);
	const [productDetails, setProductDetails] = useState([]);
	const [totalVal, setTotalVal] = useState({
		totalstd: 0,
		totalzeroGoods: 0,
		totalTax: 0,
		orderDiscount: 0,
		grandTotal: 0,
		invDiscount: 0 , 
		over_all_discount:0,
		orderDiscountPercentage:0,
		orderDiscountType:''
	}
	);

	const [flashMessage, setFlashMessage] = useState(null);
	const [productOptions, setProductOptions] = useState([]);
	const [productInputValue, setProductInputValue] = useState('');
	const [orderOptions, setOrderOptions] = useState([]);
	const [orderType, setOrderType] = useState('');
	const [orderPrice, setOrderPrice] = useState('');
	const [addOrderQty, setAddOrderQty] = useState('');

	const [newProducts, setNewProducts] = useState([]);
	const accessToken = localStorage.getItem('accessToken');


	const navigateToGRNList = useNavigate();




	const handleQtyChange = (event) => {
		setAddOrderQty(event.target.value);
	};

	const handlePriceChange = (event) => {
		setOrderPrice(event.target.value);
	};


	const handleAddressChange = (event) => {
		//setOrderPrice(event.target.value);

		setOrderDetails(prevState => ({
			...prevState, // Spread the previous state to keep other values unchanged
			delivery_address: event.target.value // Update only the `orderDiscount` field
		}));

	};


	const handleInputChange = (inputValue) => {
		fetchProductNameOptions(inputValue);
	};


	const handleChangeOrderType = (event) => {
		const value = event.target.value;
		setOrderType(value);
	};


	const handleChange = (selectedOption) => {
		setProductInputValue(selectedOption);

		setOrderType('')
		if (selectedOption) {
			const selectedProduct = productOptions.find(option => option.value === selectedOption.value);

			fetchOrderOptions(selectedProduct ? selectedProduct.split : 3);

		} else {
			fetchOrderOptions(3);
		}
	};



	const fetchOrderOptions = async (splitValue) => {
		//console.log(splitValue);
		let data = [];
		if (splitValue == 2) {
			data = [
				{ box: 'Box', picec: 'Box' },
				{ box: 'Piece', picec: 'Piece' },
			];
		} else if (splitValue == 0) {
			data = [
				{ box: 'Box', picec: 'Box' },
			];
		} else if (splitValue == 1) {
			data = [
				{ box: 'Piece', picec: 'Piece' },
			];
		} else {
			data = [];
		}

		const formattedOptions = data.map((item) => ({
			label: item.box,
			value: item.picec,
		}));
		setOrderOptions(formattedOptions);
	};







	const fetchProductNameOptions = async () => {


		const formData = new FormData();
		const apiSalesRapUrl = `${config.API_URL}/ws_get_product`;

		try {
			const response = await fetch(apiSalesRapUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			const data = await response.json();

			console.log(data);

			if (Array.isArray(data.products_details)) {
				const formattedOptions = data.products_details.map(item => ({
					label: item.code + ' ' + item.name,
					value: item.id,
					split: item.split
				}));
				setProductOptions(formattedOptions);
			}

		} catch (error) {
			console.error('Error fetching customer options:', error);
		}
	};


	const fetchInvoice = async () => {
		const formData = new FormData();
		formData.append('orderId', orderId);
		const apiUrl = `${config.API_URL}/ws-proforma-invoice`;
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to fetch Not Order Report');
			}
			const data = await response.json();
			//console.log(data);
			setShopDetails(data.details.shop_details);
			setOrderDetails(data.details.orderDetails[0]);
			setProductDetails(data.details.product_details);

			const totalstd = data.details.product_details
				.filter(item => item.item_tax !== '0.00' && item.item_tax != null)
				.reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

			const totalzeroGoods = data.details.product_details
				.filter(item => item.item_tax === '0.00' || item.item_tax === null)
				.reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

			// Set the total values
			setTotalVal({
				totalstd: formatNumber(totalstd),
				totalzeroGoods: formatNumber(totalzeroGoods),
				totalTax: parseFloat(data.details.orderDetails[0].total_tax),
				orderDiscount: parseFloat(data.details.orderDetails[0].order_discount),
				invDiscount: parseFloat(data.details.orderDetails[0].over_all_amount),
				over_all_discount: parseFloat(data.details.orderDetails[0].over_all_discount),
				grandTotal: parseFloat(data.details.orderDetails[0].grand_total),
				orderDiscountType:data.details.orderDetails[0].orderDiscountType,
				orderDiscountPercentage:data.details.orderDetails[0].orderDiscountPercentage,
			});


		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}
	};



	useEffect(() => {
		fetchInvoice();
		fetchProductNameOptions();
	}, []);


	const formatNumber = (num) => {
		return parseFloat(num).toFixed(2);
	};


	useEffect(() => {
		const table = $('#salesInvoice').DataTable({
			pageLength: 50,
			paging: false,
			lengthMenu: [10, 20, 50],
			searching: false,
			ordering: false,
			info: false,
			responsive: true,
			autoWidth: false,
			data: productDetails,
			columns: [
				{ data: null, width: '3%' },
				{
					data: null,
					width: '6%',
					className: 'text-center',
					render: function (data, type, row) {

						if (row.is_promoted === '1') 
						{
						 if (row.discount == '100.00' || row.discount == 100.00) {
							return `<span style="color: #f14240;">${row.product_code}${'(P)'}</span>`;
						} else {
							return `<span>${row.product_code}${'(P)'}</span>`;
						}

						} else {

						if (row.discount == '100.00' || row.discount == 100.00){

							return `<span style="color: #f14240;">${row.product_code}</span>`;

						} else {

							return `<div>${row.product_code}</div>`;
						}
						}
						
					},
				},
				{ data: 'product_name',
				 render: function (data, type, row) {
				 	if (row.discount == '100.00' || row.discount == 100.00) {
							return `<span style="color: #f14240;">${row.product_name} - ${row.size}</span>`;
						} else {
						return `<span>${row.product_name} - ${row.size} </span>`;
						}
					},
				},
				{
					data: null,
					width: '6%',
					className: 'text-center',
					render: function (data, type, row) {
						const orderCount = row.quantity ? parseInt(row.quantity) : 0;
						if (row.is_promoted === '1') {
							if (row.discount == '100.00' || row.discount == 100.00) {
								return `<span style="color: #f14240;">${orderCount}</span>`;
							} else {
								return `<span>${orderCount}</span>`;
							}
					} else {
						if (row.discount == '100.00' || row.discount == 100.00) {
							return `<div><input type="text" style="color: #f14240;" data-id="${row.id}" class="form-control order-qty" value="${orderCount}" min="0" /></div>`;
						} else {
							return `<div><input type="text" data-id="${row.id}" class="form-control order-qty" value="${orderCount}" min="0" /></div>`;
						}
					}
					},
				},		
				{
					data: 'order_type',
					className: 'text-center',
					width: '8%',
					render: function (data, type, row) {
						if (row.discount == '100.00' || row.discount == 100.00) {
							return `<span style="color: #f14240;">${row.order_type}</span>`;
						} else {
							return `<span>${row.order_type}</span>`;
						}
					},
				},
				{
					data: 'unit_price',
					width: '6%',
					className: 'text-center',
					render: function (data, type, row) {
						const unitPriceVal = row.unit_price ? row.unit_price : 0;
						if (row.is_promoted === '1') {
							 if (row.discount == '100.00' || row.discount == 100.00) {
							return `<span style="color: #f14240;">${unitPriceVal}</span>`;
						}else{
							return `<span>${unitPriceVal}</span>`;
						}
						} else {
							 if (row.discount == '100.00' || row.discount == 100.00) {
							return `<input type="text" style="color: #f14240;" data-id="${row.id}" class="form-control unit-price" value="${unitPriceVal}" min="0.01" />`;
						}else{
						return `<input type="text" data-id="${row.id}" class="form-control unit-price" value="${unitPriceVal}" min="0.01" />`;
						
						}
						}

					},
				},

				{
					data: 'discount',
					width: '6%',
					className: 'text-center',
					render: function (data, type, row) {
					
						const item_discountVal = row.item_discount ? row.item_discount : 0;
						const discountpercentage = row.discount ? parseInt(row.discount, 10) : 0;
						if (row.is_promoted === '1') {
							 if (row.discount == '100.00' || row.discount == 100.00) {
							return `<span style="color: #f14240;">${item_discountVal}</span>`;
						}else{
							return `<span>${item_discountVal}</span>`;
						}
						} else {
							 if (row.discount == '100.00' || row.discount == 100.00) {
							return `<input type="text" style="color: #f14240;" data-id="${row.id}" class="form-control discountpercentage" value="${discountpercentage}" min="0.01" />`;
						}else{
						return `<input type="text" data-id="${row.id}" class="form-control discountpercentage" value="${discountpercentage}" min="0.01" />`;
						
						}
						}
					},
				},

				{
					data: 'subtotal',
					className: 'text-center',
					width: '8%',
					render: function (data, type, row) 
					{
						const subtotal = parseFloat(row.subtotal) || 0; 
						 if (row.discount == '100.00' || row.discount == 100.00) 
						 {
						return `<span style="color: #f14240;">${subtotal.toFixed(2)}</span>`;
					}else{
						return `<span>${subtotal.toFixed(2)}</span>`;
					}
					},
				},
				{
					data: 'item_tax',
					className: 'text-center', 
					width: '8%',
					render:function(data, type, row){
						const subtotal = parseFloat(row.item_tax) || 0; 
						 if (row.discount == '100.00' || row.discount == 100.00) {
						return `<span style="color: #f14240;">${subtotal.toFixed(2)}</span>`;
					}else{
						return `<span>${subtotal.toFixed(2)}</span>`;
					}
					}
				},
			],
			columnDefs: [
				{
					targets: [0],
					orderable: false
				}
			],
			drawCallback: function (settings) {
				const api = this.api();
				const startIndex = api.page.info().start;

				api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
					cell.innerHTML = startIndex + i + 1;
				});

				$('#salesInvoice').on('draw.dt', function () {
					$('#salesInvoice').off('focusout', '.order-qty').on('focusout', '.order-qty', function () {

						const id = $(this).data('id');
						const newValue = parseFloat($(this).val()) || 0;

						const rowIdx = api
							.rows()
							.indexes()
							.toArray()
							.find(idx => api.row(idx).data().id == id);

						if (rowIdx !== undefined) {
							const rowData = api.row(rowIdx).data();
							rowData.quantity = newValue;

							const unitPrice = parseFloat(rowData.unit_price) || 0;
							rowData.net_amount = newValue * unitPrice;

							const itemVAT = parseFloat(rowData.tax_rate_percent) || 0;
							rowData.item_tax = (itemVAT != 0.00)
								? (((newValue * unitPrice) * itemVAT) / 100).toFixed(2)
								: '0.00';

							api.row(rowIdx).invalidate().draw();

							//console.log(api.row(rowIdx).data());

							const orderId = orderDetails.id; // Replace with actual orderId
							const productId = rowData.product_id; // Replace with actual productId

							const output = (rowData.order_type === "box") ? "0" : (rowData.order_type === "piece") ? "1" : "";
							const order_type = output; // Replace with actual order_type

							const qty = newValue; // Replace with actual qty



							newInvoice(orderId, productId, order_type, qty);

							// Recalculate totals
							if (orderDetails.group_id == 3) {
								getOrderDiscount(api, orderDetails.group_id);
							}



							calculateTotals(api);




						} else {
							console.warn(`No row found with ID: ${id}`);
						}
					});



					$('#salesInvoice').off('focusout', '.unit-price').on('focusout', '.unit-price', function () {
						let newValue = parseFloat($(this).val()) || 0;

						if (newValue < 0) {
							newValue = 0;
							$(this).val(newValue);
						}

						const id = $(this).data('id');
						const rowIdx = api
							.rows()
							.indexes()
							.toArray()
							.find(idx => api.row(idx).data().id == id);

						if (rowIdx !== undefined) {
							const rowData = api.row(rowIdx).data();
							rowData.unit_price = formatNumber(newValue);

							const pdtQty = parseFloat(rowData.quantity) || 0;
							rowData.net_amount = newValue * pdtQty;

							const itemVAT = parseFloat(rowData.tax_rate_percent) || 0;
							rowData.item_tax = (itemVAT != 0.00)
								? (((newValue * pdtQty) * itemVAT) / 100).toFixed(2)
								: 0;

							api.row(rowIdx).invalidate().draw();

							if (orderDetails.group_id == 3) {
								getOrderDiscount(api, orderDetails.group_id);
							}

							calculateTotals(api);
						} else {
							console.warn(`No row found with ID: ${id}`);
						}
					});

					$('#salesInvoice').off('focusout', '.discountpercentage').on('focusout', '.discountpercentage', function () {
					    let discountpercentage = parseFloat($(this).val()) || 0;

					    if (discountpercentage < 0) {
					        discountpercentage = 0;
					        $(this).val(discountpercentage);
					    }

					    const id = $(this).data('id');
					    const rowIdx = api
					        .rows()
					        .indexes()
					        .toArray()
					        .find(idx => api.row(idx).data().id == id);

					    if (rowIdx !== undefined) {
					        // Initialize rowData before using it
					        const rowData = api.row(rowIdx).data();
					        
					        // Now you can safely access rowData properties
					        const unit_Price = parseFloat(rowData.unit_price) || 0;
					        
					        const discountedValue = unit_Price - (unit_Price * (discountpercentage / 100));
					        
					        rowData.unit_price = formatNumber(unit_Price);
					        const pdtQty = parseFloat(rowData.quantity) || 0;
					        rowData.discount = formatNumber(discountpercentage);
					        rowData.item_discount = formatNumber(discountedValue);
					        rowData.subtotal = formatNumber(discountedValue * pdtQty);

					        const itemVAT = parseFloat(rowData.tax_rate_percent) || 0;

					        rowData.item_tax = (itemVAT != 0.00)
					            ? (((discountedValue * pdtQty) * itemVAT) / 100).toFixed(2)
					            : 0;

					        api.row(rowIdx).invalidate().draw();

					        if (orderDetails.group_id == 3) {
					            getOrderDiscount(api, orderDetails.group_id);
					        }

					        calculateTotals(api);
					    } else {
					        console.warn(`No row found with ID: ${id}`);
					    }
					});
				});

				updateFooter(api);
			}
		});

		return () => {
			table.destroy();
		};
	}, [productDetails, totalVal]);





	const getOrderDiscount = async (api, group_id) => {

	const allData = api.rows().data().toArray();
	const totalzeroGoods = allData
	.filter(item => item.item_tax === '0.00' || item.item_tax === null || item.item_tax === 0)
	.reduce((sum, item) => sum + (parseFloat(item.subtotal)), 0);
	const totalstd = allData
	.filter(item => item.item_tax !== '0.00' && item.item_tax != null && item.item_tax !== 0)
	.reduce((sum, item) => sum + (parseFloat(item.subtotal)), 0);

		const totalamt = totalzeroGoods + totalstd;

		const formData = new FormData();
		formData.append('group_id', group_id);
		formData.append('totalamt', totalamt);

		const apiUrl = `${config.API_URL}/ws_check_Order_Discount`;

		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to fetch Not Order Report');
			}
			const data = await response.json();

			/*setTotalVal.({
	  
	  orderDiscount: parseFloat(data.discount),
	});*/

			setTotalVal(prevState => ({
				...prevState, // Spread the previous state to keep other values unchanged
				orderDiscount: parseFloat(data.discount), // Update only the `orderDiscount` field
				orderDiscountPercentage: data.percentage,
				orderDiscountType: data.discount_type
			}));

			if (data.success) {
				processNewData(data);
			}


		} catch (error) {
			console.error('API Error:', error);
		}

	};

	const newInvoice = async (orderId, productId, order_type, qty) => {

		const formData = new FormData();
		formData.append('orderId', orderId);
		formData.append('productId', productId);
		formData.append('order_type', order_type);
		formData.append('qty', qty);

		const apiUrl = `${config.API_URL}/ws_check_Product_Promation`;

		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to fetch Not Order Report');
			}
			const data = await response.json();

			//return data;
			//console.log(data);
			if (data.success) {
				processNewData(data);
			}


		} catch (error) {
			console.error('API Error:', error);
		}
	};




	const processNewData = (rowData) => {
		const oldData = productDetails;
		//const newData = await newInvoice(); 

		//console.log("function called");

		const newProduct = rowData;
		if (newProduct.item != null) {

			const match = oldData.find(oldProduct =>
				oldProduct.product_id === newProduct.item.product_id && oldProduct.order_type === newProduct.item.order_type
			);
			if (match) {
				updateRecord(newProduct);
				console.log(newProduct);
			} else {

				//console.log("Insert record");
				//insertRecord(newProduct);
			}
		}

	};

	/*const updateRecord = (product) => {

	  const table = $('#salesInvoice').DataTable();
	  const rowIdx = table.rows().indexes().toArray().find(idx => table.row(idx).data().id === product.item.id);
	  console.log(rowIdx);

	  if (rowIdx !== undefined) {
			console.log(product.item);
			//alert("hello");
		table.row(rowIdx).data(product.item).draw();
	  } else {
		console.warn(`No row found with ID: ${product.id}`);
	  }

	};*/

	const updateRecord = (product) => {
		const table = $('#salesInvoice').DataTable();
		const rowIdx = table.rows().indexes().toArray().find(idx => table.row(idx).data().id === product.item.id && table.row(idx).data().order_type === product.item.order_type);
		//console.log(rowIdx);

		if (rowIdx !== undefined) {
			const rowData = table.row(rowIdx).data();
			Object.assign(rowData, product.item);

			if (parseFloat(product.item.quantity) === 0.00) {
				// Remove the row if quantity is 0.00
				table.row(rowIdx).remove().draw();
			} else {
				// Update the row if quantity is not 0.00
				//table.row(rowIdx).data(product.item).draw();


				table.row(rowIdx).data(rowData).invalidate().draw();
			}


		} else {
			console.warn(`No row found with Product ID: ${product.item.product_id} and Order Type: ${product.item.order_type}`);
		}
	};

	const insertRecord = (product) => {
		$('#salesInvoice').DataTable().row.add(product).draw();
	};


	/*useEffect(() => {
	  fetchInvoice().then(processProductDetails);
	}, []);*/

	//	const newInvoice = async (orderId, productId, order_type, qty) => {}

	const calculateTotals = (api) => {
		const allData = api.rows().data().toArray();
		const totalzeroGoods = allData
			.filter(item => item.item_tax === '0.00' || item.item_tax === null || item.item_tax === 0)
			.reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

		const totalstd = allData
			.filter(item => item.item_tax !== '0.00' && item.item_tax != null && item.item_tax !== 0)
			.reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

		const totalTax = allData
			.filter(item => item.item_tax !== '0.00' && item.item_tax != null && item.item_tax !== 0)
			.reduce((sum, item) => sum + (parseFloat(item.item_tax)), 0);

		//const orderDiscount = parseFloat($('#orderDiscount').val()) || 0; 
		const orderDiscount = totalVal.orderDiscount ? totalVal.orderDiscount : 0.00;
		console.log(orderDiscount);


		
		const invDiscount = parseFloat(totalVal.invDiscount) || 0.00;
		const disc =  invDiscount;
		const over_all_discount = parseFloat(totalVal.over_all_discount) || 0.00;
		const orderDiscountPercentage = parseFloat(totalVal.orderDiscountPercentage) || 0.00;
		const orderDiscountType = totalVal.orderDiscountType ||'';

		let adjustedTax;  

		if (orderDiscountType == 'percentage') {
		 
		  adjustedTax = (totalTax - ((totalTax * orderDiscountPercentage) / 100)).toFixed(2);
		} else {

		  adjustedTax = totalTax;
		}

		const grandTotal = totalstd + totalzeroGoods + adjustedTax - disc-orderDiscount;
		setTotalVal({
			totalstd: totalstd.toFixed(2),
			totalzeroGoods: totalzeroGoods.toFixed(2),
			totalTax: totalTax.toFixed(2),
			orderDiscount: formatNumber(orderDiscount),
			invDiscount: invDiscount.toFixed(2),
			over_all_discount: over_all_discount.toFixed(2),
			grandTotal: grandTotal.toFixed(2),
			orderDiscountType:orderDiscountType,
			orderDiscountPercentage:orderDiscountPercentage,
		});

	};

	// Update the footer with totals
	const updateFooter = (api) => {
		const footer = $('#salesInvoice tfoot');
		footer.empty();

		footer.append(`<td colspan="9" class="footer-col">
		<div style="background:#eee; padding:5px; border:1px solid #eee; border-top:none; border-bottom:none;">
		<table class="table table-bordered table-condensed totals" style="margin-bottom:0;">
		<tbody>
		<tr class="warning">
		<td>Std Goods : ${totalVal.totalstd}</td>
		<td>Zero Goods : ${totalVal.totalzeroGoods}</td>
		<td>VAT Amount : ${totalVal.totalTax}</td>
		<td>Promo Discount : ${totalVal.orderDiscount ? totalVal.orderDiscount : '0.00'}</td>
		<td>Invoice Discount : ${totalVal.invDiscount ? totalVal.invDiscount : '0.00'}</td>
		<td>Grand Total : ${totalVal.grandTotal}</td>
		</tr>
		</tbody>
		</table>
		</div>
		</td>`);
	};


	useEffect(() => {
		if (orderType !== '') {
			const fetchProductPrice = async () => {
				const formData = new FormData();
				formData.append('productId', productInputValue.value);
				formData.append('customerId', orderDetails.customer_id);
				formData.append('order_type', orderType.toLowerCase());

				const apiUrl = `${config.API_URL}/ws_get_product_byid`;
				try {
					const response = await fetch(apiUrl, {
						method: 'POST',
						headers: {
							authorization: `Bearer ${accessToken}`,
						},
						body: formData,
					});

					if (!response.ok) {
						throw new Error('Failed to fetch product price');
					}
					const data = await response.json();
					//console.log('Product Price Data:', data);
					setOrderPrice(data.price);

					// Handle the data as needed
				} catch (error) {
					console.error('Error fetching product price:', error);
				}
			};

			fetchProductPrice();
		}

	}, [orderType, productInputValue, orderDetails.customer_id]);



	const addProductSubmit = async () => {
		const formData = new FormData();
		formData.append('product_id', productInputValue.value);
		formData.append('orderId', orderDetails.id);
		formData.append('qty_of_invoice', addOrderQty);
		formData.append('price_of_invoice', orderPrice);
		formData.append('order_type', orderType.toLowerCase());
       
		const apiUrl = `${config.API_URL}/ws_add_new_product`;
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to submit add product form');
			}
			const data = await response.json();
			console.log(data);
			Swal.fire({
				title: 'success',
				text: 'Product added successfully.',
				icon: 'success',
				confirmButtonText: 'Close'
			});

			//fetchInvoice();
			//fetchProductNameOptions();

			window.location.href = config.BASE_URL + '/trip/Edit_delivered_invoice/' +tripId +'/' + orderId;

		} catch (error) {
			console.error('Error submit product:', error);
		}
	};


	const submitOrderDetails = async () => {
		const formData = new FormData();
		formData.append('orderId', orderDetails.id);
		formData.append('status', 'Deliver');
		formData.append('group_id', orderDetails.group_id);
		formData.append('delivery_address', orderDetails.delivery_address ? orderDetails.delivery_address : '');
		const productDetailsJson = JSON.stringify(productDetails);
		const productDetailsUrlEncoded = encodeURIComponent(JSON.stringify(productDetails));

		formData.append('orderList', productDetailsJson);
		formData.append('over_all_amount', totalVal.invDiscount);
		formData.append('over_all_discount', totalVal.over_all_discount);
		console.log(formData);


		const apiUrl = `${config.API_URL}/ws_update_invoice`;
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to submit add product form');
			}
			const data = await response.json();
			console.log(data);

			if (data.success) {


				Swal.fire({
					title: "Success!",
					text: "Invoice successfully Updated.",
					icon: "success",
					timer: 1500,
					showConfirmButton: false,
				});
				navigateToGRNList("/open_trips/trip_summary/"+tripId);

			} else {
				Swal.fire({
					title: 'error',
					text: 'Something wrong try again.',
					icon: 'error',
					confirmButtonText: 'Close'
				});
			}
		} catch (error) {
			console.error('Error submit product:', error);
		}
	}

	return (
		<>
			<Header />
			<div className="breadcrumbs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<Link to="/Dashboard"><i className="fa fa-home"></i></Link>
							<Link to="/open_trips">Trip</Link>
							<Link to="#">Edit Delivered Invoice</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="main-container">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<div className="show-addprod-area dashboard">
								<div className="addnew-product-area">
									<h3 style={{ lineHeight: '20px', margin: '0px 0px 5px 0px' }} >
										<span>Edit Invoice</span>
										<button type="button" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add-product">Add New Product</button>
									</h3>
								</div>

								<div className="row">
									<div className="col-12 col-lg-6 col-sm-6">
										<p>Customer Name - {orderDetails.name}</p>
										<p>Account No - {orderDetails.account_no}	</p>
									</div>
									<div className="col-12 col-lg-6 col-sm-6">
										<div className="row mb-3">
											<div className="col-sm-3">
												<label htmlFor="inputText" className="col-form-label">Delivery Address:</label>
											</div>
											<div className="col-sm-9">
												<input type="text" onChange={handleAddressChange} value={orderDetails.delivery_address} className="form-control" />
											</div>
										</div>
									</div>
								</div>
								<div className="verical-scrl-wrapper common-table sales-edit-invoice" style={{ margin: '0px 0px 32px 0px' }} id="style-3">
									<table className="table table-bordered resizable" id="salesInvoice" >
										<thead>
											<tr>
												<th>No.</th>
												<th>Code</th>
												<th>Description</th>
												<th>Qty</th>
												<th>Order Type</th>
												<th>Unit price</th>
												<th>Discount (%)</th>
												<th>Net Amount</th>
												<th>VAT Amount</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>01</td>
												<td>1107</td>
												<td><p class="cut-text">WARRIOR BIRTHDAY CAKE-12x64g</p></td>
												<td>2</td>
												<td><input type="text" class="form-control" /></td>
												<td>Box</td>
												<td><input type="text" class="form-control" /></td>
												<td>25.98</td>
												<td>5.20</td>
											</tr>

										</tbody>
										<tfoot>

										</tfoot>
									</table>
								</div>
								<div className="row">
									<div className="col-3">
										<button type="button" onClick={submitOrderDetails} class="selectdate-btn" style={{ margin: '0px 0px 25px 0px', maxWidth: '150px' }} >Confirm & Submit</button>
									</div>
									<div className="col-8">
										<p>Sale Note: {orderDetails.staff_note ? orderDetails.staff_note : ''} </p>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="add-product" tabIndex="-1">
				<div className="modal-dialog modal-md">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add Product</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className="add-product-popup">
								<form>
									<div className="row mb-3 padd-l-13px">
										<label htmlFor="desc" className="col-sm-4 col-form-label-left">Select Product</label>
										<div className="col-sm-8">

											<Select
												isClearable
												onInputChange={handleInputChange}
												onChange={handleChange}
												options={productOptions}
												placeholder="Select Product *"
												value={productInputValue}
											/>
										</div>
									</div>
									<div className="row mb-3 padd-l-13px">
										<label htmlFor="desc" className="col-sm-4 col-form-label-left">Order Type</label>
										<div className="col-sm-8">


											<select value={orderType} onChange={handleChangeOrderType} className="form-select">
												<option value="">Select Order Type*</option>
												{orderOptions.map(option => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>


										</div>
									</div>
									<div className="row mb-3 padd-l-13px">
										<label htmlFor="desc" className="col-sm-4 col-form-label-left" >Price</label>
										<div className="col-sm-8">
											<input type="text" onChange={handlePriceChange} className="form-control" value={orderPrice} />
										</div>
									</div>
									<div className="row mb-3 padd-l-13px">
										<label htmlFor="desc" className="col-sm-4 col-form-label-left">QTY</label>
										<div className="col-sm-8">
											<input type="text" onChange={handleQtyChange} className="form-control" value={addOrderQty} />
										</div>
									</div>


									<hr />
									<div className="form-group">
										<input type="button" className="btn btn-secondary common-closebtn" data-bs-dismiss="modal" value="Close" style={{ float: 'right', width: 'auto' }} />
										<input type="button" onClick={addProductSubmit} className="common-popup-btn" data-bs-dismiss="modal" value="Save Changes" style={{ float: 'right', width: 'auto', marginRight: '15px' }} />
									</div>
								</form>
							</div>
						</div>

					</div>
				</div>
			</div>


			<Footer />
		</>


	);

}
export default Edit_delivered_invoice