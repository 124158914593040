import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { API_URL, BASE_URL } from "../Config";
import Select from "react-select";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Swal from "sweetalert2";
import config from "../common/Config";

const Cashier_Report = () => {
  const today = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState(today); 
  const [toDate, setToDate] = useState(today);
  const [selectedRole, setSelectedRole] = useState("");
  const [rejectedHistory, setRejectedHistory] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(1);
  const [length, setLength] = useState(50);
  const salesOrderRef = useRef(null);
  const accessToken = localStorage.getItem("accessToken");
  const [reportrange, setReportrange] = useState(fromDate+' - '+toDate);
  const [inventoryReport, setInventoryReport] = useState([]);

  const fetchReport = (startDate, endDate) => {
  const $table = $(salesOrderRef.current);
  const searchValue = $("#searchValue").val();

    if(startDate !== '' || endDate !== '')
    {
      setReportrange(fromDate+' - '+toDate);
    }

    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/cashier_report_data`,
        type: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue,
            reportrange: reportrange,
            authorization: `Bearer ${accessToken}`,
          });
        },
        dataSrc: function (json) {
          if (json && json.data && Array.isArray(json.data)) {
            setInventoryReport(json.data);
          }
          return Array.isArray(json.data) ? json.data : [];
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        { data: "code" },
        { data: "name" },
        { data: "cost" },
        { data: "price" },
        { data: "total_box_qty" },
      ],
      columnDefs: [
        {
          targets: [0, 1, 2, 3, 4],
          orderable: false,
        },
      ],
      pageLength: 10,
    });
  };

  useEffect(() => {
    fetchReport();
  }, [reportrange]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fromDate && toDate) {
      setReportrange(`${fromDate} - ${toDate}`);
    }
  };
  
  return (
    <>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              {" "}
              <a href="#">
                <i class="fa fa-home"></i>
              </a>{" "}
              &gt; <a href="dashboard.html">Dashboard</a> &gt;{" "}
              <span class="last-crumb">Cashier Report</span>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="col-md-12 col-lg-12 col-xs-12">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div
                      className="date-range-picker col-md-2 col-lg-2 col-sm-12"
                      style={{ float: "left" }}
                    >
                      <b>Cashier Report</b>
                    </div>

                    <div
                      className="date-range-picker col-md-3 col-lg-3 col-sm-12"
                      style={{ float: "left" }}
                    >
                      <div class="row padd-0">
                        <label class="col-sm-3" style={{ marginBottom: "5px" }}>
                          <b>Start</b>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="date"
                            name="fromDate"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="date-range-picker col-md-3 col-lg-3 col-sm-12"
                      style={{ float: "left" }}
                    >
                      <div class="row padd-0">
                        <label class="col-sm-3" style={{ marginBottom: "5px" }}>
                          <b>End</b>
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="date"
                            name="toDate"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2 col-lg-2 col-sm-12">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="acceptbtn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="total-manifests-area dashboard">
                <div className="col-md-12 col-lg-12 col-xs-12">
                  <div
                    className="verical-scrl-wrapper cashier-report"
                    id="style-3"
                  >
                    <table
                      className="table table-bordered dataTable resizable"
                      ref={salesOrderRef}
                      id="rejectedSalesTable"
                    >
                      <thead>
                        <tr>
                          <th width="300px">Name</th>
                          <th>Opening Balance</th>
                          <th>Payment Collected</th>
                          <th>Closing Balance</th>
                          <th width="300px">Handover to</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Raj </td>
                          <td>£210.00 </td>
                          <td>£15,000.00</td>
                          <td>£15,210.00</td>
                          <td>KC (Manager)</td>
                        </tr>
                        <tr>
                          <td>Raj </td>
                          <td>£210.00 </td>
                          <td>£15,000.00</td>
                          <td>£15,210.00</td>
                          <td>KC (Manager)</td>
                        </tr>
                        <tr>
                          <td>Raj </td>
                          <td>£210.00 </td>
                          <td>£15,000.00</td>
                          <td>£15,210.00</td>
                          <td>KC (Manager)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Cashier_Report;
