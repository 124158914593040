import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
//import Config from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import config from "../common/Config";
import generatePickerListHistoryPDF from './PickerListHistoryPDF';

const firstName = localStorage.getItem('first_name');
const lastName = localStorage.getItem('last_name');

const ViewPicking = () => {
  const { picklistNo } = useParams();
  const [newOrders, setNewOrders] = useState([]);
  const [pickingDetails, setPickingDetails] = useState({});
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [picker, setSelectedPicker] = useState('');
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const accessToken = localStorage.getItem('accessToken');
  useEffect(() => {
    async function fetchData() {
      const formData = new FormData();
      formData.append('searchByFromdate', fromDate);
      formData.append('searchByTodate', toDate);
      formData.append('draw', draw);
      formData.append('start', start);
      formData.append('length', length);
      formData.append('pickerId', picker);
      formData.append('picklist_number', picklistNo);

    
      if (accessToken === null) {
        window.location.href = `${config.BASE_URL}/login`;
        return;
      }

      try {
        // Fetch picking history
        const responseHistory = await fetch(`${config.API_URL}/get_view_picking_history`, {
          method: 'POST',
          headers: {
            'authorization': `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!responseHistory.ok) {
          throw new Error('Failed to fetch picking history');
        }

        const historyData = await responseHistory.json();
        console.log('Picking History Data:', historyData); // Debugging
        setNewOrders(historyData.data || []);

        // Fetch picking details
        const responseDetails = await fetch(`${config.API_URL}/get_view_picklist`, {
          method: 'POST',
          headers: {
            'authorization': `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!responseDetails.ok) {
          throw new Error('Failed to fetch picking details');
        }

        const detailsData = await responseDetails.json();
        console.log('Picking Details Data:', detailsData); // Debugging
        setPickingDetails(detailsData.data || {});
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [fromDate, toDate, picker, picklistNo, draw, start, length]);

  useEffect(() => {
    // Initialize DataTable for newOrders
    if ($.fn.DataTable.isDataTable('#neworderTable')) {
      $('#neworderTable').DataTable().clear().rows.add(newOrders).draw();
    } else {
     $('#neworderTable').DataTable({
          paging: false,
          lengthChange: false,
          searching: false,
          ordering: false,
          info: false,
          responsive: true,
          autoWidth: false,
          language: {
            paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
            },
            search: 'Search:',
          },
          data: newOrders,  // Assuming newOrders is your data
          columns: [
            { data: null,
             render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.code}</span>`;
            } else {
            return `<span>${row.code}</span>`;
            }
            }, width: '12%' },

            { data: null,
            render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.name}</span>`;
            } else {
            return `<span>${row.name}</span>`;
            }
            }, },

            { data: null,
            render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.order_type}</span>`;
            } else {
            return `<span>${row.order_type}</span>`;
            }
            }, width: '12%' },

            { data: null,
            render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.quantity}</span>`;
            } else {
            return `<span>${row.quantity}</span>`;
            }
            },  width: '12%' },

            { data: null,
            render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.picked_qty}</span>`;
            } else {
            return `<span>${row.picked_qty}</span>`;
            }
            }, width: '12%' },

            { data: '',
             render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.confirmed_total}</span>`;
            } else {
            return `<span>${row.confirmed_total}</span>`;
            }
            }, width: '12%', className: 'text-right' },

            { data: '',
             render: function (data, type, row) {
            if (row.discount == '100.00' || row.discount == 100.00) {
              return `<span style="color: #f14240;">${row.sale_person}</span>`;
            } else {
            return `<span>${row.sale_person}</span>`;
            }
            } ,width: '12%' },
          ],
          
        });

    }

    return () => {
      if ($.fn.DataTable.isDataTable('#neworderTable')) {
        $('#neworderTable').DataTable().destroy();
      }
    };
  }, [newOrders]);

  useEffect(() => {
    // Filter and Initialize DataTable for account details
    const filteredAccounts = (pickingDetails.accound_no || []).filter(row => row.note && row.note.trim() !== '');

    if ($.fn.DataTable.isDataTable('#accountDetailsTable')) {
      $('#accountDetailsTable').DataTable().clear().rows.add(filteredAccounts).draw();
    } else {
      $('#accountDetailsTable').DataTable({
        paging: false,
        lengthChange: false,
        searching: false,
        ordering: false,
        info: false,
        responsive: false,
        autoWidth: false,
        language: {
          paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous',
          },
          search: 'Search:',
        },
        data: filteredAccounts,
        columns: [
          { data: 'accound_no', title: 'A/c No' },
          { data: 'note', title: 'Comment' },
          { data: 'created_by', title: 'Comment By' },
        ],
      });
    }

    return () => {
      if ($.fn.DataTable.isDataTable('#accountDetailsTable')) {
        $('#accountDetailsTable').DataTable().destroy();
      }
    };
  }, [pickingDetails.accound_no]);

  const handleGeneratePickerListHistoryPDF = () => {
  generatePickerListHistoryPDF(picklistNo);
}


  // Helper function to join multiple reference numbers
  const getFormattedReferenceNos = () => {
    const refs = pickingDetails.reference_no || [];
    return refs.length > 0 ? refs.map(ref => ref.reference_no).join(', ') : 'N/A';
  };

  // Helper function to join multiple account numbers
  const getFormattedAccountNos = () => {
    const accs = pickingDetails.accound_no || [];
    return accs.length > 0 ? accs.map(acc => acc.accound_no).join(', ') : 'N/A';
  };

  // Print function
  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    const printContent = `
    <html>
    <head>
      <title>Print Picking Details</title>
      <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/bs5/1.13.4/css/dataTables.bootstrap5.min.css">
      <style>
        body { font-family: Arial, sans-serif; margin: 20px; }
        .container { max-width: 800px; margin: auto; }
        h1 { text-align: center; }
        .details-section { margin-bottom: 20px; }
        .details-section div { margin-bottom: 10px; }
        .details-section b { font-weight: 600; }
        table { width: 100%; border-collapse: collapse; margin-top: 20px; }
        table, th, td { border: 1px solid #ddd; }
        th, td { padding: 10px; text-align: left; }
        th { background-color: #f4f4f4; }
        td.text-right { text-align: right; }
        
      </style>
    </head>
    <body>
      <div class="container">
         <header>
                <div class="row align-items-center gy-3">
                <div class="col-sm-4 text-center text-sm-start"> <img src={${config.BASE_URL}/assets/img/{accessToken}_logo.png}  alt="Logo" />
                </div>
                <div class="col-sm-8">

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                <tr>
                <td><h4 class="text-7 mb-0">${pickingDetails?.shop_details?.shop_name || ''}</h4></td>
                </tr>
                <tr>
                <td>
                <address>
                ${pickingDetails?.shop_details?.address || ''}

                </address>
                </td>
                </tr>
                <tr>
                <td><b>Phone No :</b> ${pickingDetails?.shop_details?.phone} | <b>Email :</b> ${pickingDetails?.shop_details?.email}</td>
                </tr>
                <tr>
                <td><b>Website :</b> ${pickingDetails?.shop_details?.website}</td>
                </tr>
                </tbody>
                </table>

                </div>
                </div>
                <hr/>
                </header>
        <div class="details-section">
         <table style="border-collapse: collapse; border: none;">
          <tr style="border: none;">
            <td style="border: none; width:50%; padding-right:15px;" > 
             <div style="word-wrap: break-word;"><b>Order Number:</b> ${getFormattedReferenceNos()}</div>
             <div style="word-wrap: break-word;"><b>Account Number:</b> ${getFormattedAccountNos()}</div>
             <div style="word-wrap: break-word;"><b>Route Number:</b> ${pickingDetails.route_number ? pickingDetails.route_number.map(route => route.route_number).join(', ') : 'N/A'}</div>
            </td>

            <td style="border: none; width:50%;padding-right:15px;">
              <div style="word-wrap: break-word;"><b>Delivery Date:</b> ${pickingDetails.deliverydate || 'N/A'}</div>
              <div style="word-wrap: break-word;"><b>Print by:</b> kc</div>
              <div style="word-wrap: break-word;"><b>Print Date:</b> ${new Date().toLocaleDateString()}</div>
            </td>
          </tr>
          </table>
        </div>
        
     
        <table style="border-collapse: collapse; border: none;">
          <thead>
            <tr style="border: none;">
              <th style="border: none;">Code</th>
              <th style="border: none;">Name</th>
              <th style="border: none;">Type</th>
              <th style="border: none;">Order Qty</th>
              <th style="border: none;">Pick Qty</th>
              <th style="border: none;">Accept Qty</th>
              <th style="border: none;">Sales Person</th>
            </tr>
          </thead>
          <tbody>
            ${newOrders.map(order => `
              <tr style="border: none;">
                <td style="border: none; font-size:11px; padding:3px;">${order.code}</td>
                <td style="border: none; font-size:13px; padding:3px;">${order.name}</td>
                <td style="border: none; font-size:12px; padding:3px;">${order.order_type}</td>
                <td style="border: none; font-size:12px; padding:3px;">${order.quantity}</td>
                <td style="border: none; font-size:12px; padding:3px;">${order.picked_qty}</td>
                <td style="border: none; font-size:12px; padding:3px;">${order.confirmed_total}</td>
                <td style="border: none; font-size:12px; padding:3px;">${order.sale_person}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>

        <h6>Account Details</h6>
        <table className="print_special" border="0">
          <thead>
            <tr style="border: none;">
              <th style="border: none;">A/c No</th>
              <th style="border: none;">Comment</th>
              <th style="border: none;">Comment By</th>
            </tr>
          </thead>
          <tbody>
            ${pickingDetails.accound_no ? pickingDetails.accound_no.filter(row => row.note && row.note.trim() !== '').map(account => `
              <tr style="border: none;">
                <td style="border: none;">${account.accound_no}</td>
                <td style="border: none;">${account.note}</td>
                <td style="border: none;">${account.created_by}</td>
              </tr>
            `).join('') : ''}
          </tbody>
        </table>
      </div>
    </body>
    </html>
  `;

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <a href="#"><i className="fa fa-home"></i></a>
              <span className="last-crumb">View Picking</span>
            </div>
          </div>
        </div>
      </nav>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-lg-12 col-sm-12">
              <div className="row picking-details">
               
                <header>
                <div class="row align-items-center gy-3">
                <div class="col-sm-4 text-center text-sm-start"> <img src={`${config.BASE_URL}/assets/img/${accessToken}_logo.png`} width={accessToken === 'knm' ? '30%' : ''} alt="Logo" />
                </div>
                <div class="col-sm-8">

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                <tr>
                <td><h4 class="text-7 mb-0">{pickingDetails?.shop_details?.shop_name || ''}</h4></td>
                </tr>
                <tr>
                <td>
                <address>
                {pickingDetails?.shop_details?.address || ''}

                </address>
                </td>
                </tr>
                <tr>
                <td><b>Phone No :</b> {pickingDetails?.shop_details?.phone} | <b>Email :</b> {pickingDetails?.shop_details?.email}</td>
                </tr>
                <tr>
                <td><b>Website :</b> {pickingDetails?.shop_details?.website}</td>
                </tr>
                </tbody>
                </table>

                </div>
                </div>
                <hr/>
                </header>
               
                <div style={{ marginBottom:'0px' }} >
                <div class="col-12 col-lg-12 col-sm-12">
                <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered">
                <tbody>
                <tr>
                <td style={{ paddingRight:'10px', width: '33%'}}>
                <div class="col-12 col-lg-12 col-sm-12 order-sm-1" style={{ verticalAlign: 'top'}}>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">

                <tr>
                <td style={{ verticalAlign: 'top', width: '22%' }}><strong>Order Number :</strong> </td>
                <td style={{ whiteSpace: 'normal' }}> {getFormattedReferenceNos()}</td>
                </tr>
                <tr>
                <td style={{ verticalAlign: 'top', width: '22%' }}><strong>Account Number :</strong> </td>
                <td style={{ whiteSpace: 'normal' }}> {getFormattedAccountNos()}</td>
                </tr>

                <tr>
                <td style={{ verticalAlign: 'top', width: '22%' }}><strong>Route Number :</strong> </td>
                <td style={{ whiteSpace: 'normal' }}> {pickingDetails.route_number ? pickingDetails.route_number.map(route => route.route_number).join(', ') : 'N/A'}</td>
                </tr>
                </table>
                </div>
                </td>
                <td style={{paddingRight: '10px', width:'33%'}}>
                <div class="col-12 col-lg-12 col-sm-12 order-sm-0"> 
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                <td style={{width:'20%'}}><strong>Picking List :</strong> </td>
                <td><strong>{picklistNo}</strong></td>
                </tr>

                <tr>
                <td><strong>Delivery Date :</strong> </td>
                <td>{pickingDetails.reference_no && pickingDetails.reference_no[0] && pickingDetails.reference_no[0].deliverydate
                ? pickingDetails.reference_no[0].deliverydate
                : 'N/A'}</td>
                </tr>

                <tr>
                <td><strong>Print By : </strong> </td>
                <td> {firstName} {lastName}</td>
                </tr>
                <tr>
                <td><strong>Print Date : </strong> </td>
                <td> {new Date().toLocaleDateString()}</td>
                </tr>

                </table>
                </div>
                </td>
                </tr>
                </tbody>
                </table>
                </div>
                </div>
                </div>
                </div>
                </div>

          <div className="row">
            <div className="col-12">
              <div className="show-order-area dashboard">
                <form id="picking_create">
                  <div>
                    <div style={{ float: 'right' }}>
                      
                      {/*<button type="button" className="acceptbtn" onClick={handlePrint}>
                       Print Picking Details
                       </button>*/}

                    <button type="button" className="acceptbtn" onClick={(e) => handleGeneratePickerListHistoryPDF()}>
  Print
  </button>
                    </div>
                  </div>
                  <div>
                    <div className="verical-scrl-wrapper view-picking" id="style-3">
                      <table className="table table-bordered dataTable resizable" id="neworderTable">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Product Name</th>
                            <th>Type</th>
                            <th>Order Qty</th>
                            <th>Pick Qty</th>
                            <th>Accept Qty</th>
                            <th>Sales Person</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DataTables will handle rendering of rows */}
                        </tbody>
                      </table>
                    </div>
                    <div className="verical-scrl-wrapper newsales-table" id="style-3">
                      <table className="table table-bordered dataTable resizable" id="accountDetailsTable">
                        <thead>
                          <tr>
                            <th>A/c No</th>
                            <th>Comment</th>
                            <th>Comment By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* DataTables will handle rendering of rows */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewPicking;
