import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { API_URL, BASE_URL } from "../Config";
import config from "../common/Config";

const ProfitLossReport = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [dates, setDates] = useState([null, null]);
  const [reportrange, setReportrange] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerInputValue, setCustomerInputValue] = useState("");
  const [plRouteOptions, setPlRouteOptions] = useState([]);
  const [plRoute, setPlRoute] = useState("");
  const [salesRapOptions, setSalesRapOptions] = useState([]);
  const [salesRap, setSalesRap] = useState("");
  const [reportDate, setReportDate] = useState("");
  const [amtReport, setAmtReport] = useState("");
  const [profitLossReport, setProfitLossReport] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const salesOrderRef = useRef(null);

  /*useEffect(() => {
    if (customerInputValue && customerInputValue.value) {
    }
  }, [customerInputValue]); */

  const fetchCustomerNameOptions = async (inputValue) => {
    if (inputValue.length < 3) {
      return;
    }

    const formData = new FormData();
    formData.append("term", inputValue);
    formData.append("limit", "10");

    const apiSalesRapUrl = `${config.API_URL}/suggestions`;
    try {
      const response = await fetch(apiSalesRapUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (Array.isArray(data.results)) {
        const formattedOptions = data.results.map((item) => ({
          label: item.text,
          value: item.id,
        }));
        setCustomerOptions(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching customer options:", error);
    }
  };

  const handleInputChange = (inputValue) => {
    fetchCustomerNameOptions(inputValue);
  };

  const handleChange = (selectedOption) => {
    setCustomerInputValue(selectedOption);
  };

  const handleDateChange = (value) => {
    setDates(value);
  };

  const handleChangePlRoute = (event) => {
    const value = event.target.value;
    setPlRoute(value);
  };

  const handleChangeSalesRap = (event) => {
    const value = event.target.value;
    setSalesRap(value);
  };

  const searchFormData = () => {
    let startDate = dates?.[0] ? format(dates[0], "dd/MM/yyyy") : "";
    let endDate = dates?.[1] ? format(dates[1], "dd/MM/yyyy") : "";

    let searchDate = startDate ? `${startDate} - ${endDate}` : "";
    setReportrange(searchDate);
	if((customerInputValue || salesRap || plRoute) && searchDate === '' ){
		fetchReport();
	}
};


  const fetchSalesRapOptions = async () => {
    const apiSalesRapUrl = `${config.API_URL}/getsalesrep_for_profit_and_loss`;
    try {
      const response = await fetch(apiSalesRapUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      const formattedOptions = data.sales_rep.map((item) => ({
        key: `${item.name}`,
        value: item.id,
      }));
      setSalesRapOptions(formattedOptions);
    } catch (error) {
      console.error("api fail Error fetching Not Sales Rap:", error);
    }
  };

  const fetchRouteOptions = async () => {
    const apiRouteUrl = `${config.API_URL}/getRoute`;
    try {
      const response = await fetch(apiRouteUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      const formattedOptions = data.data.map((item) => ({
        key: `${item.route_number}`,
        value: item.id,
      }));
      setPlRouteOptions(formattedOptions);
    } catch (error) {
      console.error("api fail Error fetching Not Sales Rap:", error);
    }
  };

  const fetchReport = (startDate, endDate) => {
    const $table = $(salesOrderRef.current);
    const searchValue = $("#searchValue").val();

    // Destroy any existing DataTable instance to reinitialize with new data
    if ($.fn.DataTable.isDataTable($table)) {
      $table.DataTable().destroy();
    }

    // Initialize the DataTable with the new date range
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: `${config.API_URL}/get_profit_loss_report`,
        type: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        data: function (d) {
          return $.extend({}, d, {
            draw: d.draw,
            start: d.start,
            length: d.length,
            searchValue: searchValue,
            reportrange: reportrange,
            customer_id: customerInputValue?.value
              ? customerInputValue.value
              : "",
            sales_rep_id: salesRap,
            route_id: plRoute,
            authorization: `Bearer ${accessToken}`,
          });
        },
        dataSrc: function (json) {
          if (json && json.data && Array.isArray(json.data)) {
            setProfitLossReport(json.data);
            setReportDate(json.form_date + " To Date : " + json.to_date);
            setAmtReport(
              json.netamount +
                "  | Cost Total : " +
                json.cost_total +
                " | Profit Total : " +
                json.profit_total
            );
          }

          return Array.isArray(json.data) ? json.data : [];
        },
      },
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + 1;
          },
        },
        { data: "reference_no" },
        { data: "date" },
        { data: "customer" },
        { data: "netamount" },
        { data: "cost" },
        { data: "profit" },
        { data: "status" },
      ],
      columnDefs: [
        {
          targets: [0, 1, 2, 3, 4],
          orderable: false,
        },
      ],
      pageLength: 10,
    });
  };

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    fetchReport();
    fetchSalesRapOptions();
    fetchRouteOptions();
  }, [reportrange]);

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="/reports/profit_loss_report">Reports</Link>
              <Link to="/reports/profit_loss_report">Profit and Loss</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
           
           
              

{/* ------ */}

<div className="date-range-picker col-md-12 col-lg-3 col-sm-3" style={{ float: 'left' }}>
              <div class="row mb-3 padd-0">
                <label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select</b></label>
                <div class="col-sm-8">
                <DateRangePicker
                  value={dates}
                  placeholder="DD/MM/YYYY - DD/MM/YYYY"
                  onChange={handleDateChange}
                  format="dd/MM/yyyy"
                  character=" - "
                  style={{ width: "100%" }}
                />
                </div>
              </div>
            </div>

            <div className="date-range-picker col-md-12 col-lg-3 col-sm-3" style={{ float: 'left' }}>
              <div class="row mb-3 padd-0">
                <label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Customer</b></label>
                <div class="col-sm-8">
                <Select
                  isClearable
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  options={customerOptions}
                  placeholder="Search..."
                  value={customerInputValue}
                />
                </div>
              </div>
            </div>

            <div className="date-range-picker col-md-12 col-lg-2 col-sm-2" style={{ float: 'left' }}>
              <div class="row mb-3 padd-0">
                <label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Route</b></label>
                <div class="col-sm-8">
                <select
                  value={plRoute}
                  onChange={handleChangePlRoute}
                  className="form-control selectinput"
                >
                  <option value="">Select Route</option>
                  {plRouteOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </select>
                </div>
              </div>
            </div>

            <div className="date-range-picker col-md-12 col-lg-2 col-sm-12" style={{ float: 'left' }}>
              <div class="row mb-3 padd-0">
                <label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Sales Rep</b></label>
                <div class="col-sm-8">
                <select
                  value={salesRap}
                  onChange={handleChangeSalesRap}
                  className="form-control  selectinput"
                  name=""
                  id=""
                >
                  <option value="">Sales Rep</option>
                  {salesRapOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </select>
                </div>
              </div>
            </div>

            <div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
								<button type="button" onClick={searchFormData} className="acceptbtn" style={{ float: 'left' }} >Submit</button>
							</div>



            
          </div>

          <div className="total-manifests-area dashboard">
            <p>
              <b>Profit and Loss From : {reportDate} </b>
            </p>
            <p
              className="text-center"
              style={{ border: "none", margin: "0px", padding: "0px;" }}
            >
              <b>Net Amount : {amtReport} </b>
            </p>
            <div
              className="verical-scrl-wrapper common-table autoheight-table profit-loss-report"
              id="style-3"
            >
              <table
                className="table table-bordered resizable"
                id="profileLossReport"
                ref={salesOrderRef}
              >
                <thead>
                  <tr>
                    <th>Sr.no</th>
                    <th>Order Id</th>
                    <th>Order Date</th>
                    <th>Customer (A/C No)</th>
                    <th>Net Amount</th>
                    <th>Cost</th>
                    <th>Profit</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfitLossReport;
