import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";
import Swal from 'sweetalert2';


const Product_Category = () => {
  
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [searchValue, setSearchValue] = useState('');


  const[productCategoryList, setProductCategoryList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  const accessToken = localStorage.getItem('accessToken');

  
  const [catFormData, setCatFormData] = useState({
    category_code: '',
    category_name: '',
    sort_number: '',
    slug: '',
    description: '',
  });

  const [catEditData, setCatEditData] = useState({
    category_code: '',
    category_name: '',
    sort_number: '',
    slug: '',
    description: '',
  });

 

  const handleCatChange = (e) => {
    const { name, value } = e.target;
    if (name === "category_code" && value < 0) {
      return; 
    }
    if (name === "sort_number" && value < 0) {
      return; 
    }
    setCatFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCatEditChange = (e) => {
    const { name, value } = e.target;
    if (name === "category_code" && value < 0) {

      return; 
    }
   
    if (name === "sort_number" && value < 0) {
      return; 
    }

    setCatEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  

  
    const modalRef = useRef(null);
  const handleCategorySubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('category_code', catFormData.category_code);
    formData.append('category_name', catFormData.category_name);
    formData.append('sort_number', catFormData.sort_number);
    formData.append('slug', catFormData.slug);
    formData.append('description', catFormData.description);



  const apiUrl = `${config.API_URL}/add_product_categories`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to add category');
      }

      const data = await response.json();
    

      const msg = data.message;
      if (data.success == true)
      {
        Swal.fire({
          title: "Success!",
          text: "Category added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => 
        {
          
          
          const bsModal = window.bootstrap.Modal.getInstance(modalRef.current);
          bsModal.hide();

          setCatFormData({
            category_code: '',
            category_name: '',
            sort_number: '',
            slug: '',
            description: ''
          });

          fetchProductCategoryList();
      
        });
    }else{
      Swal.fire({
          title: "Failed!",
          text: msg,
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
      });
    }
      
    } catch (error) {
      console.error('Error adding category:', error);
    }

  };

  const handleCategoryUpdate = async(event) =>{
    event.preventDefault();

    const formData = new FormData();
    formData.append('category_code', catEditData.category_code);
    formData.append('category_name', catEditData.category_name);
    formData.append('sort_number', catEditData.sort_number);
    formData.append('slug', catEditData.slug);
    formData.append('description', catEditData.description);
    formData.append('catid', catEditData.catid);



    const apiUrl = `${config.API_URL}/edit_product_categories`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update category');
      }

      const data = await response.json();
      console.log(data);
      const msg = data.message;
      if(data.success){
        Swal.fire({
          title: 'Success',
          text: 'Category updated successfully.',
          icon: 'success', 
          showConfirmButton: false, 
          timer: 1500,  
        });
      }else{
        Swal.fire({
          title: 'Failed!',
          text: msg,
          icon: 'error', 
          showConfirmButton: false, 
          timer: 2500,  
        });
      }
    

      setCatEditData({
        category_code: '',
        category_name: '',
        sort_number: '',
        slug: '',
        description: '',
        catid : '',
      });

      fetchProductCategoryList();
      
    } catch (error) {
      console.error('Error adding category:', error);
      // Handle error (e.g., show an error message)
    }



  };

  const handleChangeStatus = async(id, status)=>{
    const formData = new FormData();
    
    formData.append('status', status);
    formData.append('catid', id);



    const apiUrl = `${config.API_URL}/update_categories_status`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update category');
      }

      const data = await response.json();
      console.log(data);
      
      Swal.fire({
        title: 'Success',
        text: 'Status changed successfully.',
        icon: 'success', 
        confirmButton: false,  
      });
      
    } catch (error) {
      console.error('Error adding category:', error);
    }
  }

  const fetchProductCategoryList = async () =>{
    const pdtCatForm = new FormData();

    pdtCatForm.append("draw", draw);
    pdtCatForm.append("start", start);
    pdtCatForm.append("length", length);

    const apiPdtCatUrl = `${config.API_URL}/get_product_categories`;
    try{
      const response = await fetch(apiPdtCatUrl, {
        method : 'POST',
        headers : {
          authorization : `Bearer ${accessToken}`,
        },
        body : pdtCatForm,
      });

      if(!response.ok){
        throw new Error("Failed to fetch product category");
      }

      const data = await response.json();

      console.log(data);
      setProductCategoryList(data.data);

    }catch (error){
      console.log("Error fetch product category", error);
    }

  };



  

  useEffect(() => {
    if (accessToken === null) {
        window.location.href = `${config.BASE_URL}/login`;
        return;
    }

    fetchProductCategoryList();
}, [accessToken]);


  const handleEditClick = (rowData) => {
    setCatEditData({
      category_code: rowData.code,
      category_name: rowData.name,
      sort_number: rowData.sort_number,
      slug: rowData.slug,
      description: rowData.description,
      catid : rowData.id,
    });
  };


  useEffect(() => {
      const table = $('#pdtCategoryTable').DataTable({
          pageLength: 10,
          paging: true,
          lengthMenu: [10, 20, 50],
          searching: true,
          ordering: false,
          info: true,
          responsive: true,
          autoWidth: false,
          language: {
            paginate: {
                first: 'First',
                last: 'Last',
                next: 'Next',
                previous: 'Previous',
            },
            search: 'Search:',
          },
          data: productCategoryList,
          columns: [
            {
                data: null,
                className : 'text-center',
                width : '4%',
                render: function(data, type, row, meta) {
                    return meta.row + meta.settings._iDisplayStart + 1; // Serial number calculation
                }
            },
            { data: 'code', className : 'text-start', width : '10%' },
            { data: 'name', className : 'text-start'},
            
            { 
              data: 'code', 
              className : 'text-center',
              width : '10%',
              render : function(data, type, row){
                const checked = row.status == "1" ? "checked" : "";
                return `
                    <label class="switch" style="margin: 0px auto;">
                      <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
                      <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span class="switch-handle"></span>
                    </label>`;
              } 
            },
            { 
              data: 'code', 
              className : 'text-center',
              width : '10%',
              render : function (data, type, row){
                
                return `
                   <a href="#" class="icon edit-icon" data-id="${row.id}" title="Edit" data-bs-toggle="modal" data-bs-target="#edit-productcategory">
                    <i class="fa fa-pencil"></i>
                  </a>`;
              } 
            }

            
          ],
          columnDefs: [
            
          ]
      });

      $('#pdtCategoryTable').off('change', '.switch-input').on('change', '.switch-input', function(event) {
        const id = $(this).data('id');
        const status = this.checked ? 1 : 0;

        handleChangeStatus(id, status);
      });

      $('#pdtCategoryTable').on('click', '.edit-icon', function () {
        const rowId = $(this).data('id'); // Get the ID from the clicked icon
        const rowData = table.row($(this).closest('tr')).data(); // Get the full row data

        // Log the rowData to see what it contains
        

        if (rowData && Object.keys(rowData).length > 0) {
          handleEditClick(rowData);
        } 
      });

      return () => {
        table.destroy();
      };
  }, [productCategoryList]);





  return (
   <div>

    <div class="modal fade" id="add-productcategory" tabindex="-1" ref={modalRef}>
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Product Category</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="popup-area">
              <p>Please fill in the information below. The field labels marked with <span className='red'>*</span> are required input fields.</p>
              <form id="addNewPdtCategory" onSubmit={handleCategorySubmit}>
                <div class="row mb-3 padd-l-13px">
                  <label for="code" class="col-sm-4 col-form-label">Category Code <span className='red'>*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="category_code" id="code" 
                    
                     value={catFormData.category_code}
                      onChange={handleCatChange}
                      required />
                  </div>
                </div>

                <div class="row mb-3 padd-l-13px">
                  <label for="name" class="col-sm-4 col-form-label">Category Name <span className='red'>*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="category_name" id="name" 
                    value={catFormData.category_name}
                      onChange={handleCatChange} 
                    required />
                  </div>
                </div>

                <div class="row mb-3 padd-l-13px">
                  <label for="sortNumber" class="col-sm-4 col-form-label">Sort Number</label>
                  <div class="col-sm-8">
                    <input type="number" class="form-control no-arrows" name="sort_number" id="sortNumber" 
                     value={catFormData.sort_number}
                      onChange={handleCatChange} 
                        />
                  </div>
                </div>
                <input type="hidden" class="form-control" name="slug" id="slug" 
                     value={catFormData.slug}
                      onChange={handleCatChange} 
                        />
               

                <div class="row mb-3 padd-l-13px">
                  <label for="description" class="col-sm-4 col-form-label">Description</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="description" id="description" 
                     value={catFormData.description}
                      onChange={handleCatChange} 
                        />
                  </div>
                </div>

               

                

                <hr />
                <div class="row padd-l-13px">
                  <div class="col-sm-12">
                    <button  type="submit" id="submit" class="float-right-btn common-popup-btn">Add Category</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="edit-productcategory" tabindex="-1">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Category</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="popup-area">
              <p>Please fill in the information below. The field labels marked with <span className='red'>*</span> are required input fields.</p>
              <form id="editBrand" onSubmit={handleCategoryUpdate}>
                <div class="row mb-3 padd-l-13px">
                  <label for="codeEdit" class="col-sm-4 col-form-label">Category Code <span className='red'>*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="category_code" id="codeEdit" value={catEditData.category_code}  onChange={handleCatEditChange} required  />
                  </div>
                </div>

                <div class="row mb-3 padd-l-13px">
                  <label for="nameEdit" class="col-sm-4 col-form-label">Category Name <span className='red'>*</span></label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="category_name" id="nameEdit" value={catEditData.category_name}  onChange={handleCatEditChange} required />
                  </div>
                </div>

                <div class="row mb-3 padd-l-13px">
                  <label for="sortNumberEdit" class="col-sm-4 col-form-label">Sort Number</label>
                  <div class="col-sm-8">
                    <input type="number" class="form-control no-arrows" min="0" name="sort_number" value={catEditData.sort_number}  onChange={handleCatEditChange} id="sortNumberEdit" />
                  </div>
                </div>
                <input type="hidden" class="form-control" name="slug" value={catEditData.slug}  onChange={handleCatEditChange} id="slugEdit" />
                

                <div class="row mb-3 padd-l-13px">
                  <label for="descriptionEdit" class="col-sm-4 col-form-label">Description</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control"  value={catEditData.description}  onChange={handleCatEditChange} name="description" id="descriptionEdit" />
                  </div>
                </div>
                <input type="hidden" name="catid" value={catEditData.catid} />

               

                <hr />
                <div class="row padd-l-13px">
                  <div class="col-sm-12">
                    <button data-bs-dismiss="modal" aria-label="Close" type="submit" id="submitEdit" class="float-right-btn common-popup-btn">Edit Category</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Header />
    <div class="breadcrumbs">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-12 col-sm-12">
            <a href="#"><i class="fa fa-home"></i></a> &gt; 
            <a href="Dashboard">Master</a> &gt; 
            <span class="last-crumb">Product Category</span>
          </div>
        </div>
      </div>
    </div>

    <div className="main-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-12 col-sm-12">
            <div style={{ position: 'relative' }}>
              <div class="row">
                <div class="col-12 col-lg-4 col-sm-4"></div>
                <div class="col-12 col-lg-8 col-sm-8">
                  <div style={{ float: "right" }}>

                  <a class="create-catalogue-btn" href="#" data-bs-toggle="modal" data-bs-target="#add-productcategory">Add Product Category</a>

                    {/* <div class="dropdown">
                      <button class="action-btn-dd dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> 
                        Action 
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#add-productcategory">Add Product Category</a></li>
                        
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="show-order-area dashboard">
                <div className="verical-scrl-wrapper common-table product-category-table" id="style-3">
                  <table className="table table-bordered dataTable resizable" id="pdtCategoryTable">
                    <thead>
                      <tr>
                        <th style={{width: '50px'}}>#</th>
                        <th>Category Code</th>
                        <th>Category Name</th>
                        <th style={{width: '100px'}}>Status</th>
                        <th style={{width: '120px'}}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>

  );
};

export default Product_Category; 