import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from './Config';
import config from "./common/Config";
import FlashMessage from './FlashMessage';
import Swal from 'sweetalert2';

const OpenTripSummary = (props) => {
	const { tripId } = useParams();
	const [tripOrder, setTripOrder] = useState([]);
	const [deliveredOrders, setDeliveredOrders] = useState([]);
	const [tripNumber, setTripNumber] = useState([]);
	const [driverName, setDriverName] = useState([]);
	const [vehicle, setVehicle] = useState([]);
	const [date, setDate] = useState([]);
	const [undeliveredOrders, setUndeliveredOrders] = useState([]);
	const [cashCollection, setCashCollection] = useState([]);
	const [totalCashCollection, setTotalCashCollection] = useState(0);
	const [chequeCollection, setChequeCollection] = useState([]);
	const [totalChequeCollection, setTotalChequeCollection] = useState(0);
	const [partialNotDeliveredBox, setPartialNotDeliveredBox] = useState([]);
	const [partialNotDeliveredPiece, setPartialNotDeliveredPiece] = useState([]);
	const [undeliveredProductsList, setUndeliveredProductsList] = useState([]);
	const [totalUndeliveredBox, setTotalUndeliveredBox] = useState(0);
	const [totalUndeliveredPcs, setTotalUndeliveredPcs] = useState(0);
	const [returnProductsList, setReturnProductsList] = useState([]);
	const [totalReturnBox, setTotalReturnBox] = useState(0);
	const [totalReturnPcs, setTotalReturnPcs] = useState(0);
	const [totalProductsBox, setTotalProductsBox] = useState([]);
	const [totalProductsPiece, setTotalProductsPiece] = useState([]);
	const [deliveredProductsBox, setDeliveredProductsBox] = useState([]);
	const [deliveredProductsPices, setDeliveredProductsPices] = useState([]);
	const [undeliveredProductsBox, setUndeliveredProductsBox] = useState([]);
	const [undeliveredProductsPices, setUndeliveredProductsPices] = useState([]);
	const [deliveredAmt, setDeliveredAmt] = useState(0);
	const [undeliveredAmt, setUndeliveredAmt] = useState(0);
	const deliveredAmtValue = parseFloat(deliveredAmt);
	const undeliveredAmtValue = parseFloat(undeliveredAmt);
	const totalAmt = parseFloat(deliveredAmtValue + undeliveredAmtValue).toFixed(2);
	const modalRef = useRef(null);
	const [deliveredOrderDetails, setDeliveredOrderDetails] = useState([]);
	const [undeliveredOrderDetails, setUndeliveredOrderDetails] = useState([]);
	const [partialUndelivered, setPartialUndelivered] = useState([]);
	const [returnDetails, setReturnDetails] = useState([]);
	const [totalManifest, setTotalManifest] = useState([]);
	const [flashMessage, setFlashMessage] = useState(null);
	const accessToken = localStorage.getItem('accessToken'); 
	const [isButtonDisabled, setButtonDisabled] = useState(true);
	const [reassignTripData, setReassignTripData] = useState([]);
	 const modalReassignRef = useRef(null);
	 const modalUndeliveredRef = useRef(null);
	 const [reassignComment, setReassignComment] = useState('');
	 const [undeliveredTripData, setUndeliveredTripData] = useState('');
	 const [totalProductsList, setTotalProductsList] = useState([]);
	 const [filteredProducts, setFilteredProducts] = useState([]);
	 const handleReassignCommentChange = (event) => {
	    setReassignComment(event.target.value);
	  };

	const fetchData = async () => {
	    const formData = new FormData();
	    formData.append('tripId', tripId);
	    const apiUrl = `${API_URL}/get-trip-summary`;
	    try {
	      const response = await fetch(apiUrl, {
	        method: 'POST',
	        headers: {
	          // 'Content-Type': 'application/json',
	          authorization: `Bearer ${accessToken}`,
	        },
	        body: formData,
	      });

	      if (!response.ok) {
	        throw new Error('Failed to fetch new sales data');
	      }

	      const data = await response.json();
	      const tripData = data.data;

	     //console.log(tripData.trip_order_details);

	      setTripOrder(tripData.trip_order_details);
	      checkIfButtonShouldBeDisabled(tripData.trip_order_details);
	      setTotalProductsList(data.data.total_products_list);
	      setDeliveredOrders(tripData.deliverySummary.delivered_orders);
	      setTripNumber(tripData.trip_number);
	      setDriverName(tripData.driver);
	      setVehicle(tripData.vehicle);
	      setDate(tripData.date);
	      setUndeliveredOrders(tripData.deliverySummary.undelivered_orders);
	      setCashCollection(tripData.payment_collection.cash_collection_details);
	      setChequeCollection(tripData.payment_collection.cheque_collection_details);
	      setPartialNotDeliveredBox(tripData.partial_not_delivered_box);
	      setPartialNotDeliveredPiece(tripData.partial_not_delivered_piece);
	      setUndeliveredProductsList(tripData.undelivered_products_list);
	      setReturnProductsList(tripData.return_products_list);
	      setTotalProductsBox(tripData.total_products_box);
	      setTotalProductsPiece(tripData.total_products_piece);
	      setDeliveredProductsBox(tripData.delivered_products_box);
	      setDeliveredProductsPices(tripData.delivered_products_piece);
	      setUndeliveredProductsBox(tripData.undelivered_products_box);
	      setUndeliveredProductsPices(tripData.undelivered_products_piece);
	      setDeliveredAmt(parseFloat(tripData.deliverySummary.delivered_amt).toFixed(2));
	      setUndeliveredAmt(parseFloat(tripData.deliverySummary.undelivered_amt).toFixed(2));
	      setDeliveredOrderDetails(tripData.deliverySummary.delivered_order_details);
	      setUndeliveredOrderDetails(tripData.deliverySummary.undelivered_order_details);
	      setPartialUndelivered(tripData.partial_undelivered);
	      //console.log(tripData.partial_undelivered);
	      setReturnDetails(tripData.return_details);
	      setTotalManifest(tripData.total_manifest);
	      
	      //console.log(data.data.total_products_list);
	    } catch (error) {
	      console.error('Error fetching new sales data:', error);
	    }
	};

	const checkIfButtonShouldBeDisabled = (tripOrderDetails) => {
        const allDelivered = tripOrderDetails.every(order => order.sale_status === "Delivered" || order.sale_status === "Undelivered");
        setButtonDisabled(!allDelivered);
    };

	useEffect(() => {

	    const calculateTotalPrice = () => {
	      const total = cashCollection.reduce((acc, product) => {
	        const price = parseFloat(product.cashCollected);
	        if (!isNaN(price)) {
	          return acc + price;
	        }
	        return acc;
	      }, 0);
	      setTotalCashCollection(total.toFixed(2)); // Format to 2 decimal places
	    };
	    calculateTotalPrice();

	    const calculateTotalCheque = () => {
	      const total = chequeCollection.reduce((acc, product) => {
	        const price = parseFloat(product.chequeAmount);
	        if (!isNaN(price)) {
	          return acc + price;
	        }
	        return acc;
	      }, 0);
	      setTotalChequeCollection(total.toFixed(2)); // Format to 2 decimal places
	    };
	    calculateTotalCheque();

	    let pieces = 0;
    	let boxes = 0;
	    const calculateUndeliveredProducts = () => {
	      const total = undeliveredProductsList.reduce((acc, product) => {
	        const pdtQty = parseFloat(product.qty);
	        if (!isNaN(pdtQty)) {
	          	if (product.qtyType === 'piece') {
		          pieces += pdtQty;
		        } else if (product.qtyType === 'box') {
		          boxes += pdtQty;
		      	}
	        }
	      }, 0);
	      setTotalUndeliveredBox(boxes);
	      setTotalUndeliveredPcs(pieces);
	    };
	    calculateUndeliveredProducts();

	    let returnPcs = 0;
    	let returnBox = 0;
	    const calculateReturnProducts = () => {
	      const total = returnProductsList.reduce((acc, product) => {
	        const pdtQty = parseFloat(product.qty);
	        if (!isNaN(pdtQty)) {
	          	if (product.qtyType === 'piece') {
		          returnPcs += pdtQty;
		        } else if (product.qtyType === 'box') {
		          returnBox += pdtQty;
		      	}
	        }
	      }, 0);
	      setTotalReturnBox(returnBox);
	      setTotalReturnPcs(returnPcs);
	    };
	    calculateReturnProducts();

	}, [cashCollection, chequeCollection, undeliveredProductsList, returnProductsList]);

	useEffect(() => {

		   
	    if (!accessToken) {
	      window.location.href = `${config.BASE_URL}/login`; 
	      return;
	    }
    
    	fetchData();
  	}, []);

  	useEffect(() => {
	    const table = $('#tripOrderDetails').DataTable({
	      pageLength: 50,
	      paging: true,
	      lengthMenu: [10, 20, 50],
	      searching: true,
	      ordering: true,
	      info: true,
	      responsive: true,
	      autoWidth: false,
	      language: {
	        paginate: {
	          first: 'First',
	          last: 'Last',
	          next: 'Next',
	          previous: 'Previous',
	        },
	        search: 'Search:',
	      },
	      data: tripOrder,
	      columns: [
	        { data: null },
	        { data: 'customerName' },
	        { data: 'manifestId' },
	        //{ data: 'referenceNo', className: "text-center" },
	        { 
           		data: 'referenceNo',
           		className: "text-center",
	            render: function (data, type, row) {
	                return '<a href="'+BASE_URL+'/trip_summary/print/' + row.orderId + '">' + row.referenceNo + '</a>';
	            }
	          
	        },
	        { data: 'postCode', className: "text-center" },
	        { data: 'route', className: "text-center" },
	        { data: 'sale_status', className: "text-center" },
	        {
    data: null,
    className: 'text-center',
    render: function (data, type, row) {
        if (row.sale_status === "Intransit") {
            return `<div>
                        <a class="deductionbtn reassignmdlBtn" href="#" data-action="reject" title="Order Reassign" data-trip='${encodeURIComponent(JSON.stringify(data))}'><i class="bi bi-arrow-clockwise"></i></a>
                        <a class="applybtn undeliveredMdlBtn" href="#" data-action="reject" title="Order Undelivered" data-trip='${encodeURIComponent(JSON.stringify(data))}'><i class="bi bi-truck-flatbed"></i></a>
                    <a class="deductionbt" href="${config.BASE_URL}/trip/Edit_delivered_invoice/${tripId}/${row.orderId}" title="Edit"><i class="bi bi-pencil-square"></i></a>
                    </div>`;
        }
        // Optionally, you can add conditions like 'Delivered' or other statuses
        else if (row.sale_status === "Delivered") {
            return `<div>
                        <a class="deductionbt" href="${config.BASE_URL}/trip/Edit_delivered_invoice/${tripId}/${row.orderId}" title="Edit"><i class="bi bi-pencil-square"></i></a>
                    </div>`;
        } else {
            return '';
        }
    }
}

	        
	    ],
	    columnDefs: [
	        { 
	            targets: [0, 1, 2, 3, 4, 5,6], 
	            orderable: false 
	        }
	    ],
	    drawCallback: function (settings) {
	        var api = this.api();
	        var startIndex = api.page.info().start;
	        api.column(0, { page: 'current' }).nodes().each(function (cell, i) {
	            cell.innerHTML = startIndex + i + 1;
	        });
	    }
	    });
	    return () => {
	      table.destroy();
	    };
	}, [tripOrder]);

	$(document).off('click', '#tripOrderDetails .reassignmdlBtn').on('click', '#tripOrderDetails .reassignmdlBtn', function() {
	    const tripData = $(this).attr('data-trip');
	    const tripOrderInfo = JSON.parse(decodeURIComponent(tripData));
	    console.log("hello");
	    setReassignTripData([]);
	    setTimeout(() => {
	      setReassignTripData(tripOrderInfo);
	    }, 0);
	    viewVechMdl(reassignTripData);
	});

	$(document).off('click', '#tripOrderDetails .undeliveredMdlBtn').on('click', '#tripOrderDetails .undeliveredMdlBtn', function() {
	    const tripData = $(this).attr('data-trip');
	    const tripOrderInfo = JSON.parse(decodeURIComponent(tripData));
	    console.log(tripOrderInfo);
	    setUndeliveredTripData([]);
	    setFilteredProducts([]);
	    setTimeout(() => {
	      setUndeliveredTripData(tripOrderInfo);
	    }, 0);
	    viewUndeliveredMdl(undeliveredTripData);
	});

	useEffect(() => {
		setFilteredProducts([]);
        if (Array.isArray(totalProductsList)) {
            const filtered = totalProductsList.filter(product => product.sale_id == undeliveredTripData.orderId);

	    	setFilteredProducts(filtered);
        }
    }, [totalProductsList, undeliveredTripData.orderId]); 

	

  const viewVechMdl = (rejectData) => {
    if (modalReassignRef.current) {
      const modal = new window.bootstrap.Modal(modalReassignRef.current);
      modal.show();
    }
  };

  const viewUndeliveredMdl = (rejectData) => {
    if (modalUndeliveredRef.current) {
      const modal = new window.bootstrap.Modal(modalUndeliveredRef.current);
      modal.show();
    }
  };

  	const ReassignFormData  = async () => {
	  const reason = reassignComment;
	  
	  if(!reason){
	  	
	  	Swal.fire({
          title: "Failed!",
          text: "Please enter resion for Reassign",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
      	});

	  }else{

	  	const formData = new FormData();
    	formData.append('reason_reassign', reason);
    	formData.append('id', reassignTripData.orderId);
    	formData.append('manifest_id', reassignTripData.manifestId);

    	const apistatusUrl = `${config.API_URL}/reassign_order_by_manager`;
	  	try {
	      const response = await fetch(apistatusUrl, {
	      		method: 'POST',
	      		headers: {
	        		authorization: `Bearer ${accessToken}`,
	      		},
	      		body: formData,
	    		});

	      if (!response.ok) {

	        Swal.fire({
		          title: "Failed!",
		          text: "Trip Reassign Failed",
		          icon: "error",
		          timer: 1500,
		          showConfirmButton: false,
		      });
	      }

	      const data = await response.json();
	      
	      if (data.success == true){
		        Swal.fire({
		          title: "Success!",
		          text: "Trip Reassign successfully.",
		          icon: "success",
		          timer: 1500,
		          showConfirmButton: false,
		        }).then(() => 
		        {
		          
		        	window.location.href = `${config.BASE_URL}/open_trips/trip_summary/${tripId}`;
		        });
		    }else{
		      Swal.fire({
		          title: "Failed!",
		          text: "Trip Reassign Failed",
		          icon: "error",
		          timer: 1500,
		          showConfirmButton: false,
		      });
		    }

	    } catch (error) {
	      console.error('Error rejecting order:', error);	      
	      setFlashMessage({ message: 'Error rejecting order. Please check the console for details', type: 'error' });
	    }
	  }
	};


  	// Define the async click handler function
	const handleAddStockClick = async (event) => {
	 
	  	event.preventDefault();
	  	const button = event.target;
	  	const productId = button.getAttribute('product-id');
	  	const orderId = button.getAttribute('order-id');
	  	const orderType = button.getAttribute('order-type');

	  	const formData = new FormData();
	  	formData.append('productId', productId);
	  	formData.append('orderId', orderId);
	  	formData.append('order_type', orderType);
      	const apiAddStockUrl = `${API_URL}/addstock_partial_undelivered`;
      	try {
	      	const response = await fetch(apiAddStockUrl, {
	      		method: 'POST',
	      		headers: {
	        		// 'Content-Type': 'application/json',
	        		authorization: `Bearer ${accessToken}`,
	      		},
	      		body: formData,
	    	});

	      	if (!response.ok) {
	        	setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
	      	}
	      	const data = await response.json();
	      	if(data.success ){

	      		setFlashMessage({ message: 'stock added successfully', type: 'success' });
	      	}
	      	
	       	fetchData();
	    } catch (error) {
	      	console.error('Error rejecting order:', error);	      
	      	setFlashMessage({ message: 'Error. Please check the console for details', type: 'error' });
	    }
	  
	};

	const handleCloseMessage = () => {
	    setFlashMessage(null);
	  };


	  const handleCompleteTrip = async () => {
        try {

        	const userId = localStorage.getItem('userId'); 

        	const formData = new FormData();
    		formData.append('user_id', userId);
    		formData.append('trip_id', tripId);

    		const apiUrl = `${config.API_URL}/complete_trip`; 
            const response = await fetch(apiUrl, {
		        method: 'POST',
		        headers: {
		           authorization : `Bearer ${accessToken}`,
		        },
		        body: formData,
		    });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.success == true){
		        Swal.fire({
		          title: "Success!",
		          text: "Trip Completed successfully.",
		          icon: "success",
		          timer: 1500,
		          showConfirmButton: false,
		        }).then(() => 
		        {
		          
		          
		        	window.location.href = `${config.BASE_URL}/complete_trips`;
		          
		      
		        });
		    }else{
		      Swal.fire({
		          title: "Failed!",
		          text: "Trip Completed Failed",
		          icon: "error",
		          timer: 1500,
		          showConfirmButton: false,
		      });
		    }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const UndeliveredFormData = async () =>{
    	const reason = reassignComment;
	  
	  if(!reason){
	  	
	  	Swal.fire({
          title: "Failed!",
          text: "Please enter resion for Reassign",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
      	});

	  }else{

	  	const formData = new FormData();
    	formData.append('reason_reassign', reason);
    	formData.append('id', undeliveredTripData.orderId);

    	const apistatusUrl = `${config.API_URL}/order_undelivered`;
	  	try {
	      const response = await fetch(apistatusUrl, {
	      		method: 'POST',
	      		headers: {
	        		authorization: `Bearer ${accessToken}`,
	      		},
	      		body: formData,
	    		});

	      if (!response.ok) {

	        Swal.fire({
		          title: "Failed!",
		          text: "Trip Undelivered Failed",
		          icon: "error",
		          timer: 1500,
		          showConfirmButton: false,
		      });
	      }

	      const data = await response.json();
	      
	      if (data.success == true){
		        Swal.fire({
		          title: "Success!",
		          text: "Trip Undelivered successfully.",
		          icon: "success",
		          timer: 1500,
		          showConfirmButton: false,
		        }).then(() => 
		        {
		          
		        	window.location.href = `${config.BASE_URL}/open_trips/trip_summary/${tripId}`;
		        });
		    }else{
		      Swal.fire({
		          title: "Failed!",
		          text: "Trip Undelivered Failed",
		          icon: "error",
		          timer: 1500,
		          showConfirmButton: false,
		      });
		    }

	    } catch (error) {
	      console.error('Error rejecting order:', error);	      
	      setFlashMessage({ message: 'Error rejecting order. Please check the console for details', type: 'error' });
	    }
	  }
    }


    const handelAcceptReturnList = async (event) => {
		  const id = event.target.getAttribute('data-id');
		  const isAcceptReturn = event.target.classList.contains('deductionbtn');
		  
		  const urlRejectLink = `${config.API_URL}/add_reject_return`;
		  const urlAcceptLink = `${config.API_URL}/add_accept_return`;
		  if (isAcceptReturn) {
		    const result = await Swal.fire({
		      title: 'Are you sure?',
		      text: 'Are you sure you want to create the Credit Note?',
		      icon: 'warning',
		      showCancelButton: true,
		      confirmButtonText: 'Yes',
		      cancelButtonText: 'No',
		    });

		    if (result.isConfirmed) {
		      await sendApiRequest(urlAcceptLink, id, 'yes');
		    } else {
		      await sendApiRequest(urlAcceptLink, id, 'no');
		    }
		  } else {
		  	const result = await Swal.fire({
		      title: 'Are you sure?',
		      text: 'Are you sure you want reject order ?',
		      icon: 'warning',
		      showCancelButton: true,
		      confirmButtonText: 'Yes',
		      cancelButtonText: 'No',
		    });

		    if (result.isConfirmed) {
		      await sendApiRequest(urlRejectLink, id);
		    } 
		    
		  }
		};

		const sendApiRequest = async (url, id, allocation = null) => {
		  const formData = new FormData();
		  
		  formData.append('id', id);
		  if (allocation) {
		    formData.append('allocation', allocation);
		  }

		  try {
		    const response = await fetch(url, {
		      method: 'POST',
		      headers: {
		        authorization: `Bearer ${accessToken}`, 
		      },
		      body: formData,
		    });

		    const data = await response.json();
		    const msg = data.message;
			    if (data){
	          Swal.fire({
	            title: "Success!",
	            text: "Order rejected successfully.",
	            icon: "success",
	            timer: 1500,
	            showConfirmButton: false,
	          }).then(() => 
	          {
	            
	             window.location.href = `${config.BASE_URL}/open_trips/trip_summary/${tripId}`;
	            
	          });

	      }else{
	        Swal.fire({
	          title: "Failed!",
	          text: msg,
	          icon: "error",
	          timer: 1500,
	          showConfirmButton: false,
	        });
	      }
		    

		  } catch (error) {
		    console.error('API request failed:', error);
		    return null; // Return null or handle error gracefully
		  }
		};



	return (
		<>
	  		<Header />
	  		<div className="breadcrumbs">
			  	<div className="container-fluid">
			    	<div className="row">
			      		<div className="col-12 col-lg-12 col-sm-12"> 
			        		<Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
			        		<Link to="/OpenTrips">Open Trips</Link> 
			        		<span className="last-crumb">Trip Summary {tripNumber}</span> 
			      		</div>
			   	 	</div>
			  	</div>
			</div>
			<div className="main-container">
			    <div className="container-fluid">
			        <div className="row">
			            <div className="col-12 col-lg-12 col-sm-12">
			                <div className="show-addprod-area dashboard">
			                    <div className="complete-summery-area">
			                        <div className="title">
			                            <span>Trip Summary - {tripNumber} </span>
			                            <ul>
			                                <li>Date - {date}</li>
			                                <li>Driver - {driverName}</li>
			                                <li>Vehicle - {vehicle}</li>
			                                
			                            </ul>
			                        </div>
			                    </div>
			                    <div className="float:left; width:100%;">
			                        <div className="row">
			                            <div className="col-12 col-lg-3 col-sm-6">
			                                <div className="sumery-delivery-box">
			                                    <table width="100%" border="0" className="table table-bordered resizable">
			                                        <tbody>
			                                            <tr>
			                                                <td>Orders Delivered</td>
			                                                <td> <a href="#" data-bs-toggle="modal" data-bs-target="#ordersDeliveredMdl"> {deliveredOrders}</a></td>
			                                            </tr>
			                                            <tr>
			                                                <td>Orders Undelivered</td>
			                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#ordersUndeliveredMdl">{undeliveredOrders}</a></td>
			                                            </tr>
			                                            <tr>
			                                                <td>Cash Collected</td>
			                                                <td>£ {totalCashCollection}</td>
			                                            </tr>
			                                            <tr>
			                                                <td>Cheque (0)</td>
			                                                <td>£ {totalChequeCollection}</td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                            <div className="col-12 col-lg-3 col-sm-6">
			                                <div className="sumery-delivery-box">
			                                    <table width="100%" border="0" className="table table-bordered resizable">
			                                        <thead>
			                                            <tr>
			                                                <th>Return Goods</th>
			                                                <th>Box</th>
			                                                <th>Pcs</th>
			                                            </tr>
			                                        </thead>
			                                        <tbody>
			                                            <tr>
			                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#partialUndeliveredMdl"> Partial Undeliv. </a></td>
			                                                <td>{partialNotDeliveredBox}</td>
			                                                <td>{partialNotDeliveredPiece}</td>
			                                            </tr>
			                                            <tr>
			                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#undeliveredProductsMdl">Undelivered</a></td>
			                                                <td>{totalUndeliveredBox}</td>
			                                                <td>{totalUndeliveredPcs}</td>
			                                            </tr>

			                                            <tr>
			                                                <td><a href="#" data-bs-toggle="modal" data-bs-target="#returnDetailsMdl">Return</a></td>
			                                                <td>{totalReturnBox}</td>
			                                                <td>{totalReturnPcs}</td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                            <div className="col-12 col-lg-3 col-sm-6">
			                                <div className="sumery-delivery-box">
			                                    <table width="100%" border="0" className="table table-bordered resizable">
			                                        <thead>
			                                            <tr>
			                                                <th>&nbsp;</th>
			                                                <th>Box</th>
			                                                <th>Pcs</th>
			                                            </tr>
			                                        </thead>
			                                        <tbody>
			                                            <tr>
			                                                <td>Total Products</td>
			                                                <td>{totalProductsBox}</td>
			                                                <td>{totalProductsPiece}</td>
			                                            </tr>
			                                            <tr>
			                                                <td>Delivered</td>
			                                                <td>{deliveredProductsBox}</td>
			                                                <td>{deliveredProductsPices}</td>
			                                            </tr>

			                                            <tr>
			                                                <td>Undelivered</td>
			                                                <td>{undeliveredProductsBox}</td>
			                                                <td>{undeliveredProductsPices}</td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                            <div className="col-12 col-lg-3 col-sm-6">
			                                <div className="sumery-delivery-box">
			                                    <table width="100%" border="0" className="table table-bordered resizable">
			                                        <tbody>
			                                            <tr>
			                                                <td>Delivered AMT</td>
			                                                <td>£ {deliveredAmt}</td>
			                                            </tr>
			                                            <tr>
			                                                <td>Undeliverd AMT</td>
			                                                <td>£ {undeliveredAmt}</td>
			                                            </tr>
			                                            <tr>
			                                                <td>&nbsp;</td>
			                                                <td>&nbsp;</td>
			                                            </tr>
			                                            <tr>
			                                                <td>Total</td>
			                                                <td>£ {totalAmt}</td>
			                                            </tr>
			                                        </tbody>
			                                    </table>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                </div>

			                <div className="total-manifests-area dashboard">
			                    <p><b>Total Manifests :</b>{totalManifest}</p>
			                    <div className="verical-scrl-wrapper common-table autoheight-table trip_summary" id="style-3">
			                        <table className="table table-bordered resizable" id="tripOrderDetails">
			                            <thead>
			                                <tr>
			                                    <th>#</th>
			                                    <th>Customers (A/C No)</th>
			                                    <th>Manifest No</th>
			                                    <th>Order ID</th>
			                                    <th>Post code</th>
			                                    <th>Route</th>
			                                    <th>Status</th>
			                                    <th>Action</th>
			                                </tr>
			                            </thead>
			                            <tbody>
			                            </tbody>
			                        </table>
			                    </div>
			                
				                <div>
				                	<button type="button" onClick={handleCompleteTrip} disabled={isButtonDisabled} class="float-left-btn common-popup-btn">Complete Trip</button>
				                </div>
			                </div>
			            </div>
			        </div>
			    </div>
			</div>


			<div ref={modalReassignRef} className="modal fade" id="rejectOrderMdl" aria-hidden="true" tabIndex="-1" role="dialog">
		        <div class="modal-dialog modal-dialog-centered modal-lg">
		          <div class="modal-content">
		            <div class="modal-header">
		              <h5 class="modal-title">Order Reassign</h5>
		              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		            </div>
		            <div class="modal-body">
		              <div class="rejectorder-popup">
		              <form>
		                
		                <table class="table table-bordered resizable">
		                  <tr>
		                    <td>Customer (A/C No)</td>
		                    <td>{reassignTripData.customerName} ({reassignTripData.customerAccountNo})</td>
		                  </tr>
		                  <tr>
		                    <td>Manifest No</td>
		                    <td>{reassignTripData.manifestId}</td>
		                  </tr>
		                  <tr>
		                    <td> Order ID</td>
		                    <td>{reassignTripData.referenceNo}</td>
		                  </tr>
		                  <tr>
		                    <td>Post code</td>
		                    <td>{reassignTripData.postCode}</td>
		                  </tr>
		                  <tr>
		                    <td>Route</td>
		                    <td>{reassignTripData.route}</td>
		                  </tr>
		                  <tr>
		                    <td>Status</td>
		                    <td>{reassignTripData.sale_status}</td>
		                  </tr>
		                </table>
		                <label class="col-form-label text-start" style={{float:'left'}}>Reason Note</label>
		                <div class="form-group">
		                
		                
		                <textarea
						          
						          className="form-control"
						          
						          value={reassignComment}
						          onChange={handleReassignCommentChange}
						          placeholder="Reason For Reassigning"
						        />
		                </div>
		                <button type="button" onClick={ReassignFormData}  class="updatebtn left"> Update</button>

		              </form> 
		              </div>
		            </div>
		            
		          </div>
		        </div>
		    </div>

		    <div ref={modalUndeliveredRef} className="modal fade" id="UndeliveredMdl" aria-hidden="true" tabIndex="-1" role="dialog">
		        <div class="modal-dialog modal-dialog-centered modal-lg">
		          <div class="modal-content">
		            <div class="modal-header">
		              <h5 class="modal-title">Order Undelivered</h5>
		              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		            </div>
		            <div class="modal-body">
		              <div class="rejectorder-popup">
		              <form>
		                
		                <table class="table table-bordered resizable">
		                  <tr>
		                    <td>Customer (A/C No)</td>
		                    <td>{undeliveredTripData.customerName} ({undeliveredTripData.customerAccountNo})</td>
		                  </tr>
		                  <tr>
		                    <td>Manifest No</td>
		                    <td>{undeliveredTripData.manifestId}</td>
		                  </tr>
		                  <tr>
		                    <td> Order ID</td>
		                    <td>{undeliveredTripData.referenceNo}</td>
		                  </tr>
		                  <tr>
		                    <td>Post code</td>
		                    <td>{undeliveredTripData.postCode}</td>
		                  </tr>
		                  <tr>
		                    <td>Route</td>
		                    <td>{undeliveredTripData.route}</td>
		                  </tr>
		                  <tr>
		                    <td>Status</td>
		                    <td>{undeliveredTripData.sale_status}</td>
		                  </tr>
		                </table>

		                
		                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
		                        <table className="table table-bordered resizable" >
		                            <thead>
		                                <tr>
		                                    <th>#</th>
		                                    <th>Product Name (code)</th>
		                                    <th>Order Qty</th>
		                                    <th>Delivery Qty</th>
		                                   
		                                </tr>
		                            </thead>
		                            <tbody>
		                                {filteredProducts.map((product, index) => (
					                        <tr key={product.productId}>
					                            <td style={{width: '5%'}}>{index + 1}</td>
					                            <td>{`${product.productName} (${product.productCode})`}</td>
					                            <td style={{width: '15%'}}>{`${product.qty} (${product.qtyType})`}</td>
					                            <td style={{width: '15%'}}>{`${product.order_qty} (${product.qtyType})`}</td>
					                        </tr>
					                    ))}
		                            </tbody>
		                        </table>
		                    </div>
		                
			              
		                

		                <label class="col-form-label text-start" style={{float:'left'}}>Reason Note</label>
		                <div class="form-group">
		                
		                
		                	<textarea
						          
						          className="form-control"
						          
						          value={reassignComment}
						          onChange={handleReassignCommentChange}
						          placeholder="Reason For Reassigning"
						        />
		                </div>
		                <button type="button" onClick={UndeliveredFormData}  class="updatebtn left"> Update</button>

		              </form> 
		              </div>
		            </div>
		            
		          </div>
		        </div>
		    </div>


			<div id="ordersDeliveredMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Orders Delivered</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table id="orders_delivered" className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              <th>#</th>
					              <th>Invoice No</th>
					              <th>Invoice Date</th>
					              <th>Customers (A/C No)</th>               
					            </tr>
					          </thead>
					          <tbody id="trip_delivered">

					          	{deliveredOrderDetails.map((item, index) => (
						            <tr>
						              <td>{index + 1}</td>
						              <td>
							            <a href={`${BASE_URL}/trip_summary/print/${item.orderId}`}>
							                {item.referenceNo}
							            </a>
							        </td>
													              <td>{item.invoice_date}</td>
						              <td>{item.customerName} ( {item.customerAccountNo} )</td>
						            </tr>
						        ))}
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>

	      	<div id="ordersUndeliveredMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Orders Undelivered</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table id="orders_delivered" className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              <th>#</th>
					              <th>Invoice No</th>
					              <th>Invoice Date</th>
					              <th>Customers (A/C No)</th>               
					            </tr>
					          </thead>
					          <tbody id="trip_delivered">
					          	{undeliveredOrderDetails.map((item, index) => (
						            <tr>
						              <td>{index + 1}</td>
						              <td>{item.referenceNo}</td>
						              <td>{item.invoice_date}</td>
						              <td>{item.customerName} ( {item.customerAccountNo} )</td>
						            </tr>
						        ))}
					          	
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>

	      	<div id="partialUndeliveredMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog modal-xl" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Partial Undelivered List</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body verical-scrl-wrapper">
		            		<table id="orders_delivered" className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              	<th>#</th>
					              	<th>Order Id</th>
					              	<th>Description</th>
					              	<th>Order Qty</th>
					              	<th>Order Type</th>   
					              	<th>Unit Price</th>
					              	<th>VAT</th>
					              	<th>Total</th>  
					              	<th>Undeli.Qty</th> 
					              	<th>Reason</th> 
					              	<th>Action</th>                  
					            </tr>
					          </thead>
					          <tbody >

					          	{partialUndelivered.map((item, index) => {
					          	
					          		const subtotal = parseFloat(item.subtotal);
					          		const tax = parseFloat(item.tax);
					          		const total = (subtotal + tax).toFixed(2);

					          		return (
							            <tr>
							              <td>{index + 1}</td>
							              <td>{item.referenceNo}</td>
							              <td>{item.productName} ( {item.productCode} )</td>
							              <td>{item.quantity}</td>
							              <td>{item.order_type}</td>
							              <td>£ {item.unit_price}</td>
							              <td>£ {item.tax}</td>
							              <td>£ {total}</td>
							              <td>{item.not_delivered_qty}</td>
							              <td>{item.reason}</td>
							              <td>
								              {item.reason === 'no longer needed' || item.reason === 'wrong product' ? (
								                item.IsStock_update === 'N' ? (
								                  <button
								                    className="btn btn-primary btn-sm add-stock"
								                    product-id={item.productId}
								                    order-id={item.orderId}
								                    order-type={item.order_type}
								                    onClick={handleAddStockClick}
								                  >
								                    Add Stock
								                  </button>
								                ) : null
								              ) : null}
								            </td>
															              
							            </tr>
						             );
						         })}
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>

	      	<div id="undeliveredProductsMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog modal-lg" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Undelivered Products</h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table id="orders_delivered" className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              	<th>#</th>
					              	<th>Description</th>
					              	<th className="text-center" >Order Qty</th>
					              	<th className="text-center" >Order Type</th>   
					              	<th className="text-end" >Unit Price</th>
					              	<th className="text-end" >VAT</th>
					              	<th className="text-end" >Total</th>        
					            </tr>
					          </thead>
					          <tbody id="trip_delivered">
					          	{undeliveredProductsList.map((item, index) => {
					          	
					          		const unSubtotal = parseFloat(item.subtotal);
					          		const unTax = parseFloat(item.item_tax);
					          		const unTotal = (unSubtotal + unTax).toFixed(2);

					          		return (
					          			<tr>
							              	<td>{index + 1}</td>
							              	<td>{item.productName} ( {item.productCode} )</td>
							              	<td className="text-center" >{item.qty}</td>
							              	<td className="text-center" >{item.qtyType}</td>
							              	<td className="text-end" >£ {item.unit_price}</td>
							              	<td className="text-end" >£ {item.item_tax}</td>
							              	<td className="text-end" >£ {unTotal}</td>
							            </tr>
					          		);
					          	})}
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>

	      	<div id="returnDetailsMdl" className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog">
	        	<div className="modal-dialog modal-lg" role="document">
		          	<div className="modal-content">
		            	<div className="modal-header">
		              	<h5 className="modal-title">Undelivered Products </h5>
		              	<button type="button" className="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
		            	</div>
		            	<div className="modal-body">
		            		<table className="table table-bordered table-hover table-striped dataTable resizable" >
					          <thead>  
					            <tr className="active">
					              	<th>#</th>
					              	<th>Date</th>
						            <th>Return No</th>
						            <th>Customer (Ac/No)</th>   
						            <th>Grand Total</th>
						            <th>Total Items</th>
						            <th>Return By</th>  
						            <th>Status</th> 
						            <th> Actions</th>          
					            </tr>
					          </thead>
					          <tbody >
					          	{returnDetails.map((item, index) => {
					          		let status = '';
					          		if (item.is_accept === 'Y') {
					                    status = 'Accept';
					                } else if (item.is_accept === 'R') {
					                    status = 'Reject';
					                } else if (item.is_accept === 'N') {
					                    status = 'Pending';
					                }
					          		const rdTotal = parseFloat(item.grand_total);

					          		return (
					          			<tr>
							              	<td>{index + 1}</td>
							              	<td className="text-center" >{item.date}</td>
							              	<td className="text-center" >{item.reference_no}</td>
							              	<td>{item.company} ( {item.accound_no} )</td>
							              	<td className="text-end" >£ {rdTotal}</td>
							              	<td className="text-center" >{item.total_items}</td>
							              	<td className="text-center">{item.first_name} {item.last_name} </td>
							              	<td className="text-center" >{status}</td>
							              	<td>

							              	{
							              		status == 'Pending' 
							              		? 
							              			<div className="filter verti-popdot">
													  <a className="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
													    Action <i className="bi bi-three-dots-vertical"></i>
													  </a>
													  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
													    <li><a className="deductionbtn" onClick={handelAcceptReturnList} data-id={item.id} >Accept Return</a></li>
													    <li><a className="applybtn" onClick={handelAcceptReturnList} data-id={item.id} >Reject Return</a></li>
													  </ul>
													</div>
							              		: ''

							              	}

							              	</td>
							            </tr>
					          		);
					          	})}
					          </tbody>
					        </table>
		              

		            	</div>
		            	
		          	</div>
	        	</div>
	      	</div>
	      	<FlashMessage
	          message={flashMessage?.message}
	          type={flashMessage?.type}
	          onClose={handleCloseMessage}
	        />

			<Footer />
		</>
	);
};

export default OpenTripSummary;