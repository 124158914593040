import React, { useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';
import config from "../common/Config";
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const Finish_Pay = ({ finishToPay, tableRows, totalVal, customeId, discountQty, salesId, salesStatus, salesRefNo ,customerDetails }) => {
    // Set default payment method to 'cash'

    const navigateToEpos = useNavigate();
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');

    const [activePaymentMethod, setActivePaymentMethod] = useState('cash');
    const [payments, setPayments] = useState([]);
    const [amount, setAmount] = useState('');
    const [chequeNo, setChequeNo] = useState('');
    const [chequeDate, setChequeDate] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [accountNumber, setAccountNumber] = useState('');

    const [paidAmt, setPaidAmt] = useState(0.00);

    let parsedCreditType = [];

    // Check if creditType exists and is a valid JSON string
    if (customerDetails.credit_type) {
      try {
        parsedCreditType = JSON.parse(customerDetails.credit_type);
    } catch (error) {
        console.error("Failed to parse JSON:", error);
    }
}
const totalAmount = parsedCreditType.length > 2 ? parsedCreditType[2].total_amount : '';


const handleButtonClick = (method) => {
    setActivePaymentMethod(method);
    resetInputs();
};

const resetInputs = () => {
    setAmount('');
    setChequeNo('');
    setChequeDate('');
    setCardNumber('');
    setExpiryDate('');
    setAccountNumber('');
};

const handleSave = () => {
    if (amount < 0.01 || amount == null) {
        Swal.fire({
            icon: 'warning',
            title: 'Invalid Amount',
            text: 'Please enter a valid amount greater than zero!',
            showConfirmButton: false,
            timer: 2000, 
            timerProgressBar: true,
        });
        return; // Exit the function early if the amount is invalid
    }

    const newPaidAmt = parseFloat(paidAmt) + parseFloat(amount || 0);

//     alert('newPaiAMT   '+newPaidAmt);
//     alert('paidAMT   '+paidAmt);
//     alert('Amount   '+amount);

// return;

    setPaidAmt(newPaidAmt); 

    const newPayment = {
        uid: uuidv4(),
        amount,
        method: activePaymentMethod,
        chequeNo,
        chequeDate,
        cardNumber,
        expiryDate,
        accountNumber
    };

    setPayments([...payments, newPayment]);
    resetInputs();
};

const generateUniqueId = () => {
    return `id_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
};

  const handleAmountCancel = (id) => {
      
    /*const paymentToDelete = payments.find(payment => payment.uid === id);
    if (paymentToDelete) {
        const newPaidAmt = parseFloat(paidAmt) - parseFloat(paymentToDelete.amount || 0);
        setPaidAmt(newPaidAmt);
    }
    setPayments(payments.filter(payment => payment.uid !== id));*/

    Swal.fire({
        title: 'Are you sure?',
        text: "This action will remove the payment and update the total amount.",
        icon: 'warning',
        showCancelButton: true,  
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,  
    }).then((result) => {
        
        if (result.isConfirmed) {
           
            const paymentToDelete = payments.find(payment => payment.uid === id);
            if (paymentToDelete) {
                const newPaidAmt = parseFloat(paidAmt) - parseFloat(paymentToDelete.amount || 0);
                setPaidAmt(newPaidAmt);
            }
            setPayments(payments.filter(payment => payment.uid !== id));
        } 
    });
  };

const handleCompleteOrder = async () => 
{   


        //window.location.href = config.BASE_URL+'/sales/Epos/';

        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to place this order?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, place it!',
          cancelButtonText: 'Cancel',
      });
        if (result.isConfirmed) {
          try {



            const body = {
              platform: "pos",
              authorization: `Bearer ${accessToken}`,
              user_id: userId,
              product_count: totalVal.boxTotal + totalVal.pieceTotal,
              customer_id: customeId,
              customer_address: "abc", 
              totalAmount: totalVal.grandTotal,
              totalVAT: totalVal.totalTax,
              paymentMode: "", 
              staff_note: "",
              note_for_driver: "",
              invoiceWiseDiscountPercentage: discountQty,
              invoiceWiseDiscountAmount: "",
              discountAmount: totalVal.orderDiscount,
              promotionId: "",
              promotionSubType: "",
              collect_amount: totalVal.grandTotal,
              uniqueOrderId: generateUniqueId(),
              chequeNo: "",
              chequeDate: "",
              latitude: "",
              longitude: "",
              address: "",
              product_discount : "0.0", 
              status : 'Delivered',
              sales_id: salesId,
              salesRefNo : salesRefNo,
              sale_details: tableRows.map(row => ({
                product_id: row.id,
                product_code: row.code,
                product_name: row.name,
                unit_price: row.pdtcost,
                quantity: row.order_qty,
                quantityType: row.type,
                productWiseDiscountPercentage: "0.0",
                productWiseDiscountAmount: "",
                subTotal: row.subtotal,
                vat: row.vat,
                isPromoted: "",
                promo_id: "",
                total: row.total,
            })),
              payment_details: payments.map(row => ({
                amount: row.amount,
                method: row.method,
                chequeNo: row.chequeNo ? row.chequeNo : '',
                cardNumber: row.cardNumber ? row.cardNumber : '',
                chequeDate : row.chequeDate ? row.chequeDate : '',
                expiryDate: row.expiryDate ? row.expiryDate : '',
                accountNumber: row.accountNumber ? row.accountNumber : '',

            })),
          };

                //console.log(body);

                const response = await fetch(`${config.API_URL}/ws-place-order-v2_pos`, {
                  method: 'POST',
                  headers: {

                  },
                  body: JSON.stringify(body) 
              });

                if (!response.ok){
                  throw new Error('Network response was not ok');
              }
              const data = await response.json();
              
              if (data.success) {
                Swal.fire({
                    title: "Success!",
                    text: "Order Complete successfully.",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                }).then(() => {
                    window.location.href = config.BASE_URL + '/order_summary/print/'+data.data.orderId;
                });
            } else {
                Swal.fire({
                    title: "Failed!",
                    text: "Order Complete Failed. Try Again",
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false,
                });
            }
            
      } catch (error) {
        console.error('Error:', error);

    }
}
};

return (
    <div className="container">
  <div className="modal fade" id="verticalycentered" tabIndex="-1">
    <div className="modal-dialog modal-dialog-centered modal-md">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title"></h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <div className="finish-pay-area">
            <div className="title-area">
              <ul>
                <li><p>Invoice Total: {totalVal.grandTotal}</p></li>
                <li><p className="paid">Paid : {paidAmt.toFixed(2)}</p></li>
                <li><p className="balance">Balance: {(paidAmt.toFixed(2) - totalVal.grandTotal).toFixed(2)}</p></li>
              </ul>
            </div>
            <div className="table-area">
              <table className="table table-bordered resizable">
                <thead>
                  <tr>
                    <th style={{width: '5%'}} >No</th>
                    <th style={{textAlign : 'right', width: '15%'}}>Paid</th>
                    <th style={{textAlign : 'right', width: '16%'}}>Mode</th>
                    <th>Details</th>
                    <th style={{width: '5%'}}>#</th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment, index) => (
                    <tr key={index}>
                      <td style={{width: '5%'}}>{index + 1}</td>
                      <td style={{textAlign : 'right', width: '15%'}}>{parseFloat(payment.amount).toFixed(2)}</td>
                      <td style={{textAlign : 'right', width: '16%'}}>{payment.method.charAt(0).toUpperCase() + payment.method.slice(1)}</td>
                      <td>{payment.chequeNo || payment.cardNumber || payment.accountNumber || '--'}</td>
                      <td style={{width: '5%'}}>
                        <button
                          className="btn btn-danger cancel-btn"
                          onClick={() => handleAmountCancel(payment.uid)}
                          style={{ padding: '0px', fontSize: '19px', color: '#dc3545', background: 'none', border: 'none' }}
                          >
                          <i class="fa fa-trash-o" ></i>
                          </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5">
                      <div className="content">
                        {activePaymentMethod === 'cash' && (
                          <div className="recievedbx-area">
                            <ul>
                              <li><span>Received</span></li>
                              <li>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Amount"
                                  value={amount}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                    const finalValue = value.indexOf('.') !== -1 ? value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') : value;
                                    setAmount(finalValue);
                                  }}
                                />
                              </li>
                              <li>
                                <button type="button" className="recievedbtn" onClick={handleSave}>Save</button>
                              </li>
                            </ul>
                          </div>
                        )}
                        {activePaymentMethod === 'cheque' && (
                          <div className="recievedbx-area">
                            <ul>
                              <li><span>Received</span></li>
                              <li>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Amount"
                                  value={amount}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                    const finalValue = value.indexOf('.') !== -1 ? value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') : value;
                                    setAmount(finalValue);
                                  }}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Cheque No"
                                  value={chequeNo}
                                  onChange={(e) => setChequeNo(e.target.value)}
                                />
                              </li>
                              <li>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Cheque Date"
                                  value={chequeDate}
                                  onChange={(e) => setChequeDate(e.target.value)}
                                />
                              </li>
                              <li>
                                <button type="button" className="recievedbtn" onClick={handleSave}>Save</button>
                              </li>
                            </ul>
                          </div>
                        )}
                        {activePaymentMethod === 'card' && (
                          <div className="recievedbx-area">
                            <ul>
                              <li><span>Received</span></li>
                              <li>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Amount"
                                  value={amount}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                    const finalValue = value.indexOf('.') !== -1 ? value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') : value;
                                    setAmount(finalValue);
                                  }}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Transaction ID"
                                  value={cardNumber}
                                  onChange={(e) => setCardNumber(e.target.value)}
                                  style={{ width: '200px' }} // Adjust the width as needed
                                />
                              </li>

                              {/* <li>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Expiry Date"
                                  value={expiryDate}
                                  onChange={(e) => setExpiryDate(e.target.value)}
                                />
                              </li> */}
                              <li>
                                <button type="button" className="recievedbtn" onClick={handleSave}>Save</button>
                              </li>
                            </ul>
                          </div>
                        )}
                        {activePaymentMethod === 'onAccount' && (
                          <div className="recievedbx-area">
                            <ul>
                              <li><span>Received</span></li>
                              <li>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Account Number"
                                  value={totalAmount}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Amount"
                                  value={amount}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                    const finalValue = value.indexOf('.') !== -1 ? value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') : value;
                                    setAmount(finalValue);
                                  }}
                                />
                              </li>
                              <li>
                                <button type="button" className="recievedbtn" onClick={handleSave}>Save</button>
                              </li>
                            </ul>
                          </div>
                        )}
                        <div className="buttonarea button-wrap">
                          <a href="#" className="button" onClick={() => handleButtonClick('cash')}>Cash</a>
                          <a href="#" className="button" onClick={() => handleButtonClick('cheque')}>Cheque</a>
                          <a href="#" className="button" onClick={() => handleButtonClick('card')}>Card</a>
                          {/* <a href="#" className="button" onClick={() => handleButtonClick('onAccount')}>On Account</a> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="closebtn" data-bs-dismiss="modal">Cancel</button>
          <button
            data-bs-dismiss="modal"
            aria-label="Close"
            type="button"
            onClick={handleCompleteOrder}
            disabled={paidAmt < totalVal.grandTotal}
            className="float-right-btn common-popup-btn"
          >
            Save & Print
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

);
    };

    export default Finish_Pay;
