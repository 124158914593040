import React, { useState, useEffect,useRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditPicking = () => {
  const { picklistNo } = useParams();
  const [options, setOptions] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [picker, setSelectedPicker] = useState('');
  const [newOrders, setNewOrders] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const AddNewOrderTable = useRef(null);
  const neworderTableAddNewOrder = useRef(null);
  const [checkedValues, setCheckedValues] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [flashMessage, setFlashMessage] = useState(null);
  const [searchValue, setSearchValue] = useState([]);
  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    async function fetchData() {
      const formData = new FormData();
      formData.append('searchByFromdate', fromDate);
      formData.append('searchByTodate', toDate);
      formData.append('draw', draw);
      formData.append('start', start);
      formData.append('length', length);
      formData.append('pickerId', picker);
      formData.append('picklist_number', picklistNo);

      const apiUrl = `${API_URL}/edit_picking_order_list`;
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        window.location.href = `${config.BASE_URL}/login`;
        return;
      }

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }

        const data = await response.json();
        //console.log(data);
        setNewOrders(data.data || []);
        // alert(data.data[0].picker_id);
        setSelectedPicker(data.data[0].picker_id);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    }

    fetchData();
  }, [fromDate, toDate, picklistNo, draw, start, length]);

  useEffect(() => {
    async function fetchPickerOptions() {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        window.location.href = `${config.BASE_URL}/login`;
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/getPicker`, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.data || !response.data.picker || !Array.isArray(response.data.picker)) {
          throw new Error('Invalid response format or empty picker array');
        }

        const formattedOptions = response.data.picker.map(item => ({
          key: `${item.first_name} ${item.last_name}`,
          value: item.id,
        }));

        setOptions(formattedOptions);
      } catch (error) {
        console.error('Error fetching picker options:', error);
      }
    }

    fetchPickerOptions();
  }, []);

  useEffect(() => {
    if (newOrders.length) {
      const table = $('#neworderTable').DataTable({
        paging: true,
        lengthMenu: [10, 20, 50],
        searching: true,
        ordering: false,
        info: true,
        responsive: true,
        autoWidth: false,
        language: {
          paginate: {
            first: 'First',
            last: 'Last',
            next: 'Next',
            previous: 'Previous',
          },
          search: 'Search:',
        },
        data: newOrders,
        columns: [
          { data: null, defaultContent: '' },
          { data: 'date' },
          { data: 'customer', render: data => `<p class="mightOverflow col-xs-1">${data}</p>` },
         
          { 
            data: 'reference_no', 
            width: '10%', 
            className: 'text-center',
            render: function (data, type, row) {
                return '<a href="'+BASE_URL+'/trip_summary/print/' + row.action + '">' + row.reference_no + '</a>';
            }
          },
          { data: 'grand_total' },
          { data: 'sales_rep_collected' },
          { data: 'route_number' },
          { data: null, defaultContent: '<a href="#" class="viewbtn" title="Delete"><i class="fa fa-trash"></i></a>' },
        ],
        drawCallback: function () {
         const table = $('#neworderTable').DataTable();
         
        //  const firstRow = table.row(0).data(); 
        //  if (firstRow && firstRow.picker_id) {
        //   setSelectedPicker(firstRow.picker_id); 
        // } 
        $('#neworderTable .viewbtn').off('click').on('click', function (e) {
          e.preventDefault();
          const row = table.row($(this).closest('tr')).data();
          
          handleButtonClick(row.action, picklistNo);
          
        });
      },
    });

      return () => {
        table.destroy();
      };
    }
  }, [newOrders]);

  const handleButtonClick = async (orderId, picklistNumber) => {
  const formData = new FormData();
  formData.append('orderId', orderId);
  formData.append('picklistNumber', picklistNumber);

  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    window.location.href = `${config.BASE_URL}/login`;
    return;
  }

  try {
    const response = await fetch(`${API_URL}/remove_order_picklist`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (data.status) {
      setNewOrders((prevOrders) => {
        const updatedOrders = prevOrders.filter(order => order.action !== orderId);
        $('#neworderTable').DataTable().clear().rows.add(updatedOrders).draw();
        return updatedOrders;
      });

      Swal.fire('Success', 'Order removed successfully!', 'success');
    } else {
      Swal.fire('Error', 'Failed to remove the order.', 'error');
    }
  } catch (error) {
    console.error('API call error:', error);
    Swal.fire('Error', 'An error occurred while removing the order.', 'error');
  }
};


  const handleSelectionSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    const formData = new URLSearchParams();
    formData.append('picker_id', picker);
    formData.append('selectedOrderIds', JSON.stringify(selectedOrderIds));
    formData.append('picklist_number', picklistNo);

    try {
      const response = await fetch(`${API_URL}/update_picker`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      if (!response.ok) {
        throw new Error('Failed to submit selection');
      }

      await response.json();
       Swal.fire('Success', 'Picker Update successfully', 'success');
      // alert("Picking created successfully");
    } catch (error) {
      console.error('Error submitting selection:', error);
    }
  };

const AddNewOrder = () => {
    const $table = $(AddNewOrderTable.current); 
    const searchValue = '';  // Define search value as needed, could be dynamically updated

    // Destroy the DataTable if it already exists
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }

    // Initialize DataTable
    $table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            url: `${API_URL}/picking/add_order_list`,
            type: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`,  
            },
            data: function (d) {
                return $.extend({}, d, {
                    draw: d.draw,
                    start: d.start,
                    length: d.length,
                    searchValue: searchValue  
                });
            },
            dataSrc: function (json) {
                console.log('API Response:', json);  
                return Array.isArray(json.data) ? json.data : []; 
            }
        },
        language: {
            paginate: {
                first: 'First',
                last: 'Last',
                next: 'Next',
                previous: 'Previous',
            },
            search: 'Search:',
        },
        columns: [
            {
                data: null,
              render: function (data, type, row) { 
                const isChecked = !!checkedItems[row.id];
                  return `
                    <label class="checkbox">
                      <input 
                        type="checkbox" 
                        name="val[]" 
                        value="${row.id}" 
                        class="dataTableCheckbox"
                      />
                      <span class="default"></span>
                    </label>
                  `;
                }
            },
            { data: 'date' },
            { data: 'customer' },
            { data: 'reference_no' },
           
            { data: 'route_number' },
            { data: 'first_name' },
            { data: 'deliverydate' }
        ],
        columnDefs: [
            {
                orderable: false, 
                targets: '_all'  
            }
        ],
        pageLength: 10, 
    });
};
  $(document).off('change', '#addnewOrderTable .dataTableCheckbox').on('change', '#addnewOrderTable .dataTableCheckbox', function() {
     
      const value = $(this).val();
      const checked = $(this).is(':checked');
      setCheckedValues(prevState => {
        if (checked) {
          return [...prevState, value];
        } else {
          return prevState.filter(val => val !== value);
        }
      });
    });
  const handleSubmit = async () => {
    
    if (checkedValues.length > 0) {
      const formData = new FormData();    
      formData.append('picklist_number', picklistNo);
      formData.append('pickerid', picker);

      checkedValues.forEach((value, index) => {
        formData.append('val[]', value);
      });

      const apiAddOrdersUrl = `${config.API_URL}/add_order_picking_list`;
      try {
        const response = await fetch(apiAddOrdersUrl, {
          method: 'POST',
          headers: {
            
            authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
        }

        const data = await response.json();
        if (data.status) {
          Swal.fire('Success', 'Picking Update Successfully', 'success').then(() => {
            
           window.location.reload();
         });

        } else {
          Swal.fire('Error', 'Failed to add the Picking.', 'error');
        }
        AddNewOrder();

      } catch (error) {
        console.error('Error Accept Order:', error);        
        setFlashMessage({ message: 'Error Picking Order . Please check the console for details', type: 'error' });
        
      }
    }else{
      

      Swal.fire({
        title: "Falied!",
        text: "please select order to picking.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }

  };

useEffect(() => { 
    if (accessToken === null) {
        window.location.href = `${config.BASE_URL}/login`; 
        return;
    }
    AddNewOrder(); 
    return () => {
        if (neworderTableAddNewOrder.current && $.fn.DataTable.isDataTable(neworderTableAddNewOrder.current)) {
            $(neworderTableAddNewOrder.current).DataTable().destroy();
        }
    };
}, [accessToken, searchValue]);


  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#"><i className="fa fa-home"></i></a>
              <span className="last-crumb">Edit Picking</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div>
               
                  <div className="bank-pagearea">
                    <div>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#edtpicking" type="button" role="tab" aria-controls="edtpicking" aria-selected="true"> Edit Picking </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#addneworder" type="button" role="tab" aria-controls="addneworder" aria-selected="false"> Add New Order </button>
                        </li>
                      </ul>

                      <div className="tab-content fulltable-area" id="myTabContent">
                        <div className="tab-pane fade show active" id="edtpicking" role="tabpanel" aria-labelledby="edtpicking">
                          <div className="backlist-content-area">
                            <h3>Picklist No : {picklistNo}</h3>
                        <form id="picking_create" onSubmit={handleSelectionSubmit}>  
                            <div className="dashform right">
                              <div style={{ float: 'right' }}>
                                <div style={{ width: '300px', float: 'left', marginRight: '15px' }}>
                                  <select
                                    value={picker}
                                    onChange={(e) => setSelectedPicker(e.target.value)}
                                    className="form-control datepicker-input"
                                  >
                                    <option value="">Re-assigned picker</option>
                                    {options.map(option => (
                                      <option key={option.value} value={option.value}>
                                        {option.key}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <button type="submit" className="multisubmit">
                                  Submit
                                </button>
                              </div>
                            </div>
                             </form>
                            <hr className='devider'/>

                            <div className="verical-scrl-wrapper Edit-picking-list" id="style-3">
                              <table className="table table-bordered dataTable resizable" id="neworderTable"  ref={neworderTableAddNewOrder}>
                                <thead>
                                  <tr>
                                    <th>&nbsp;</th>
                                    <th>Order Date</th>
                                    <th>Customers (Ac/No)</th>
                                    <th>Order Id</th>
                                    <th>Invoice Amount</th>
                                    <th>Sales Rep Collected</th>
                                    <th>Route No</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="addneworder" role="tabpanel" aria-labelledby="addneworder">
                          <div className="backlist-content-area">
                            <h3>Picklist No : {picklistNo}</h3>
                            <hr className='devider'/>
                            <div className="verical-scrl-wrapper  Edit-picking-list" id="style-3">
                              <table className="table table-bordered dataTable resizable" id="addnewOrderTable" ref={AddNewOrderTable}>
                                <thead>
                                  <tr>
                                    <th>&nbsp;</th>
                                    <th>Order Date</th>
                                    <th>Customers (Ac/No)</th>
                                    <th>Order Id</th>
                                    
                                    <th>Route No</th>
                                    <th>Sales Person</th>
                                    <th>Delivery Date</th>                               
                                  </tr>
                                </thead>
                                <tbody>
                                </tbody>
                              </table>
                            </div>
                            <button onClick={handleSubmit} type="botton" className="acceptbtn" style={{float: 'left', margin: '26px 0 0 0'}} >Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditPicking;
