import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../../Config';
import AddUsers from './AddUsers';
import config from "../../common/Config";
import Swal from 'sweetalert2';
import ExportUserList from './ExportUserList';

const Users = () => {
  
  const userTableRef = useRef(null);
  const [searchValue, setSearchValue] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const [totalUsers, setTotalUsers] = useState('');
  
  const initializeCustomerTable = () => {
    const $table = $(userTableRef.current);
    const searchValue = $('#searchValue').val();
    // Destroy existing DataTable instance if it exists
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
  
    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
          url: `${config.API_URL}/get_users_list`,
          type: 'POST',
          headers: {
             authorization : `Bearer ${accessToken}`,
          },
          data: function (d) {
              return $.extend({}, d, {
                  draw: d.draw,
                  start: d.start,
                  length: d.length,
                  searchValue : searchValue
              });
          },
          dataSrc: function (json) {

            if (json && json.data && Array.isArray(json.data)) {
              setTotalUsers(json.recordsFiltered); 
              
            }
            return Array.isArray(json.data) ? json.data : [];
             // return json.data;
             // console.log(json);
          }
      },
      language: {
          paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
          },
          search: 'Search:',
      },
      columns: [
         
          { data: 'username', width :  '5%'   },
          { 
            data: null,
            render : function(data, type, row){
              return `<span>${row.first_name}</span>`;
            }
          },
          { 
            data: null,
            render : function(data, type, row){
              return `<span>${row.last_name}</span>`;
            }
          },
         { 
                data: null,
                render : function(data, type, row){
                   return `<span class="text-lowercase">${row.email.toLowerCase()}</span>`;
                }   
              },
          { 
            data: null,
            render : function(data, type, row){
              return `<span>${row.name}</span>`;
            }   
          },
          { data: 'roles',  width: '20%'  },
          { 
            data: null,
            render : function(data, type, row){
              const checked = row.active == '1' ? "checked" : "";
                  return `<label class="switch" style="margin: 0px auto;">
                      <input data-id="${row.id}" class="switch-input" type="checkbox" name="changeStatus" id="changeStatus_${row.id}" ${checked} />
                      <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                      <span class="switch-handle"></span>
                    </label>`;
            }

          },
          {
            data: null,
            title: 'Action',
            width: "7%",
            render: (data, type, row) => `
            <a href="${config.BASE_URL}/user/edit_profile/${row.id}" class="icon editbtn" title="Edit" >
                  <i class="fa fa-pencil"></i>
                </a>
            `
          }
          
      ],
      columnDefs: [
        {
          orderable: false, 
          targets: '_all'
        },
        {
          targets: 2, 
          render: function(data) {
            return data === '1' ? 'Active' : 'Inactive'; 
          }
        }
      ],
    
      pageLength: 10,
    });

    
    $table.on('change', '.switch-input', function() {
      const id = $(this).data('id');
      const status = this.checked ? 1 : 0;

      handleChangeStatus(id, status);
    });
  };

  const handleChangeStatus = async(id, status)=>{
    const formData = new FormData();
    formData.append('status', status);
    formData.append('id', id);
    const apiUrl = `${config.API_URL}/update_users_status`; 
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
           authorization : `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to change status');
      }

      const data = await response.json();
      console.log(data);
      
      Swal.fire({
        title: 'Success',
        text: 'Status changed successfully.',
        icon: 'success', 
        showConfirmButton: false, 
        timer: 1500,  
      });
      
    } catch (error) {
      console.error('Error status change:', error);
    }
  }


  useEffect(() => {
      if (accessToken === null) {
        window.location.href = `${config.BASE_URL}/login`;
        return;
      }
      initializeCustomerTable();  
      return () => {
          if (userTableRef.current && $.fn.DataTable.isDataTable(userTableRef.current)) {
              $(userTableRef.current).DataTable().destroy();
          }
      };
  }, [searchValue]);

  
  const handleUserAdded = () => {
        initializeCustomerTable(); // Call this after a user is added
    };

  return (

    <div>

      

      <AddUsers onUserAdded={handleUserAdded} />

      <Header />
      
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12">
              <a href="#"><i class="fa fa-home"></i></a> &gt; 
              <a href="Dashboard">Master</a> &gt; 
              <span class="last-crumb">Users</span>
            </div>
          </div>
        </div>
      </div>

      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: 'relative' }}>
                <div class="row">
                  <div class="col-12 col-lg-4 col-sm-4"></div>
                  <div class="col-12 col-lg-8 col-sm-8">
                    <div style={{ float: "right", width: "200px" }}>
                      <a href="#" className="create-catalogue-btn" data-bs-toggle="modal" data-bs-target="#add-user" style={{marginLeft: "10px"}}>Add User</a>
                      <ExportUserList totalUsers={totalUsers} urlLink="get_users_list" style={{ float: "left"  }} />
                    </div>
                  </div>
                </div>
                <div className="show-order-area dashboard">
                 
                  <div className="verical-scrl-wrapper common-table users-table" id="style-3">
                    <table className="table table-bordered dataTable resizable" id="userListTable" ref={userTableRef} >
                      <thead>
                        <tr>
                          <th>User Name</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th style={{width: '150px'}}>Default Role</th>
                          <th>Multiple Role</th>
                          <th style={{width: '100px'}}>Status</th>
                          <th style={{width: '120px'}}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

    </div> 
  );
};

export default Users; 