import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';

const Proforma = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [newSales, setNewSales] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const orderIdRef = useRef(null);
  const reasonRef = useRef(null);

  const fetchData = async () => {
    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('role', selectedRole);

    const apiUrl = `${config.API_URL}/proforma/invoice`;
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch new sales data');
      }

      const data = await response.json();
      setNewSales(Array.isArray(data.data) ? data.data : []);
      
    } catch (error) {
      console.error('Error fetching new sales data:', error);
    }
  };

// Function to print multiple invoices
const printMultipleInvoices = async () => {
  const formData = new FormData();
  const apiUrl = `${config.API_URL}/printMultipleInvoices`;
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken === null) {
    window.location.href = `${config.BASE_URL}/login`;
    return;
  }

  // Collect all selected checkbox IDs
  const selectedIds = [];
  $('input[name="selectedRows[]"]:checked').each(function () {
    const rowId = $(this).closest('tr').data('id');  
    if (rowId) {
      selectedIds.push(rowId);
    }
  });

  if (selectedIds.length === 0) {
    Swal.fire('Error!', 'Please select at least one invoice.', 'error');
    return;
  }

  // Append selected IDs to the FormData object
  formData.append('invoiceIds', JSON.stringify(selectedIds));
  let invoiceIds = JSON.stringify(selectedIds);

  sessionStorage.setItem('invoiceIds', JSON.stringify(invoiceIds));

  // Redirect to the next page
  window.location.href = '/trip_summary/print/MultipleSummaryPrint';

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to print invoice');
    }

    const data = await response.json();
    // Handle the response (e.g., display confirmation or handle data)
    console.log('Invoices printed successfully:', data);

  } catch (error) {
    console.error('Error to print invoice:', error);
  }
};

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, selectedRole]);

  useEffect(() => {
    const table = $('#neworderTable').DataTable({
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: 'First',
          last: 'Last',
          next: 'Next',
          previous: 'Previous',
        },
        search: 'Search:',
      },
      data: newSales,
      columns: [
        {
          targets: 0,
          data: null,
          width: '8%',
          className: 'text-center',
          render: function (data, type, row) {
            return `
				<label class="checkbox">
				  <input 
					type="checkbox" 
					name="val[]" 
					value="${row.ids}" 
					class="dataTableCheckbox"
				  />
				  <span class="default"></span>
				</label>
			  `;
          }
        },
        { data: 'invoice_date', width: '8%', className: 'text-center' },
        {
          data: 'reference_no',
          width: '10%',
          className: 'text-center',
          render: function (data, type, row) {
            return '<a href="' + config.BASE_URL + '/edit/proforma/' + row.id + '">' + row.reference_no + '</a>';
          }
        },
        { data: 'customer' },
        { data: 'route_number', width: '5%', className: 'text-center' },
        { data: 'deliverydate', width: '10%', className: 'text-center' },
        {
          data: null, width: '8%',
          render: function (data, type, row) {
            const invoiceTotal = row.invoice_total ? row.invoice_total : '';
            return `<span>${invoiceTotal}</span>`;
          }
        },
        { data: 'sale_status', width: '10%', className: 'text-center' },
        {
          data: null,
          width: '5%',
          render: function (data, type, row) {
            return `<div>
              <a class="deductionbtn" data-action="reject" data-order='${encodeURIComponent(JSON.stringify(data))}' title="Reject Order"><i class="bi bi-ban"></i></a>
              <a class="applybtn" href="../trip_summary/print/${row.id}" title="Print Invoice"><i class="bi bi-printer"></i></a>
            </div>`;
          },
        },
      ],
      createdRow: function (row, data, dataIndex) {
        // Add a data-id attribute to each row based on the row's id
        $(row).attr('data-id', data.id);
      }
    });
  
    // Handle clicks for actions
  $('#neworderTable').on('click', 'a[data-action="reject"]', function (e) {
      e.preventDefault();
      const orderData = $(this).data('order');
      try {
        const order = JSON.parse(decodeURIComponent(orderData));     
        setSelectedOrder(order);
        setShowRejectModal(true);
      } catch (error) {
        console.error('Error parsing order data:', error);
      }
    });

  
    return () => {
      table.destroy();
    };
  }, [newSales]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const updateReject = async (event) => {
    event.preventDefault();

    if (!orderIdRef.current || !reasonRef.current) {
      console.error('Required form elements are missing');
      alert('Required form elements are missing. Please try again.');
      return;
    }

    const orderId = orderIdRef.current.value;
    const reason = reasonRef.current.value;

    const formData = new FormData();
    formData.append('status', 'Reject');
    formData.append('reason_note', reason);
    formData.append('id', orderId);

    const accessToken = localStorage.getItem('accessToken');
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    try {
      const response = await fetch(`${config.API_URL}/ws-update-status`, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}. Error: ${errorText}`);
      }

      const data = await response.json();
      console.log('API Response:', data);
      

       Swal.fire({
        title: "Success!",
        text: "Order rejected successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => 
      {
        window.location.href = config.BASE_URL + '/picking/proforma/';

        
    
      });

    } catch (error) {
      console.error('Error rejecting order:', error);
      alert('Error rejecting order. Please check the console for details.');
    }

    setShowRejectModal(false);
  };

  return (
    <div>
  <Header />
  <nav className="breadcrumbs">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <a href="#"><i className="fa fa-home"></i></a>
          <span className="last-crumb">Proforma Invoice</span>
        </div>
      </div>
    </div>
  </nav>

  {showRejectModal && (
    <div className="modal fade show d-block" id="reject-order" tabIndex="-1" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Reject Order</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowRejectModal(false)}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {selectedOrder && (
              <div className="rejectorder-popup">
                <form id="rejectForm">
                  <h4>Proforma Invoice Details</h4>
                  <table className="table table-bordered resizable">
                    <tbody>
                      <tr>
                        <td>Order Id</td>
                        <td>{selectedOrder.reference_no}</td>
                      </tr>
                      <tr>
                        <td>Customer (A/C)</td>
                        <td>{selectedOrder.customer}</td>
                      </tr>
                      <tr>
                        <td>Order Amount</td>
                        <td>{selectedOrder.invoice_total}</td>
                      </tr>
                      <tr>
                        <td>Previous Dues</td>
                        <td>{selectedOrder.previous_dues || 'N/A'}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="form-group">
                    <label className="col-form-label">Reason Note</label>
                    <textarea id="reason" name="reason" ref={reasonRef}></textarea>
                  </div>
                  <input
                    type="hidden"
                    name="order_id"
                    id="order_id"
                    ref={orderIdRef}
                    value={selectedOrder.id}
                  />
                  <button
                    type="button"
                    onClick={updateReject}
                    className="updatebtn left updateReject"
                  >
                    Update
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )}

  <div className="main-container">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div>
            <div className="row">
              <div
                className="date-range-picker col-md-4 col-lg-4 col-sm-12"
                style={{ float: 'left' }}
              >
                <div className="row mb-3 padd-0">
                  <label className="col-sm-3" style={{ marginBottom: '5px' }}>
                    <b>From</b>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      name="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-lg-8 col-sm-8" style={{ float: 'right' }} >
							{/*	<button onClick={printMultipleInvoices} className='acceptbtn' style={{float: 'right'}}>Print Multiple Invoices</button>*/}
							</div>
              <div className="total-manifests-area dashboard">
                <div className="verical-scrl-wrapper proforma" id="style-3">
                  <table className="table table-bordered dataTable" id="neworderTable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Invoice Date</th>
                        <th>Order Id</th>
                        <th>Customers (Account No)</th>
                        <th>Route</th>
                        <th>Delivery Date</th>
                        <th>Invoice Amt</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</div>

  );
};

export default Proforma;
