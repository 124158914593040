import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../../Header';
import Footer from '../../Footer';
import FlashMessage from '../../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { API_URL, BASE_URL } from '../../Config';
import Select from 'react-select';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Swal from 'sweetalert2';
import config from "../../common/Config";

const Order_Receipt_sticker = () =>{

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [rejectedHistory, setRejectedHistory] = useState([]);
    const [draw, setDraw] = useState(1);
    const [start, setStart] = useState(1);
    const [length, setLength] = useState(50);

    const accessToken = localStorage.getItem('accessToken');


    const handleSubmit = (e) => {
        e.preventDefault();

    };



    return(
        <>
            
            

            <div className='price-sticker'>
                <h4>Product Name</h4>
                    <p>£ 99.99</p>
                
            </div>

            


        </>

    );
}
export default Order_Receipt_sticker;