import React, { useState, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL, BASE_URL } from "../Config";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import config from "../common/Config";
import { Link } from "react-router-dom";
import axios from "axios";

const Promos = () => {
  const [activeTab, setActiveTab] = useState("active"); // Track active tab
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [promotionData, setPromotionData] = useState([]);
  const [inactivePromotionData, setInactivePromotionData] = useState([]);
  const accessToken = localStorage.getItem("accessToken");

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("draw", draw);
    formData.append("start", start);
    formData.append("length", length);
    formData.append("status", activeTab);

    const apiUrl = API_URL + `/getpromotion`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch new sales data");
      }

      const data = await response.json();
      if(activeTab === 'active'){
        setPromotionData(data.data);
      }
      else if(activeTab === 'inactive'){
        setInactivePromotionData(data.data);
      }


    } catch (error) {
      console.error("Error fetching new sales data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }
  }, []);

  // Handle tab change
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const deletePromotion = async (id) => {
    const formData = new FormData();
    formData.set("promoId", id);
    const apiUrl = `${API_URL}/remove_promation`;
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.data.success) {
        fetchData();
      }
    } catch (error) {
      console.error("Error deleting promotion:", error);
    }
  };

  useEffect(() => {
    const table = $("#promotion_list").DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: promotionData,
      columns: [
        {
          data: null,
          render: (data, type, row, meta) => {
            return meta.row + 1;
          },
          className: "text-center",
          orderable: false,
        },
        { data: "name" },
        { data: "type" },
        { data: "product2buy" },
        { data: "product2get" },
        { data: "start_date" },
        { data: "end_date" },
        {
          data: "action",
          render: (data, type, row, meta) => {
            return `
                      <div class="text-center">
                          <a class="tip" title="View Promo" href="${BASE_URL}/Promotions/Edit_Promos/${row.id}">
                              <i class="fa fa-eye"></i>
                          </a>
                       <!--     <a href="#" class="delete-promo" data-id="${row.id}">
                              <i class="fa fa-trash"></i>
                          </a>-->
                      </div>
                  `;
          },
          className: "text-center",
        },
      ],
    });

    const handleDeleteClick = (e) => {
      e.preventDefault();
      const promoId = $(e.currentTarget).data("id");
      deletePromotion(promoId);
    };

    $("#promotion_list tbody").on("click", ".delete-promo", handleDeleteClick);

    return () => {
      $("#promotion_list tbody").off(
        "click",
        ".delete-promo",
        handleDeleteClick
      );
      table.destroy();
    };
  }, [promotionData]);

  
  useEffect(() => {
    const table = $("#InactivePromotionList").DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: true,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: inactivePromotionData,
      columns: [
        {
          data: null,
          render: (data, type, row, meta) => {
            return meta.row + 1;
          },
          className: "text-center",
          orderable: false,
        },
        { data: "name" },
        { data: "type" },
        { data: "product2buy" },
        { data: "product2get" },
        { data: "start_date" },
        { data: "end_date" },
        {
          data: "action",
          render: (data, type, row, meta) => {
            return `
                      <div class="text-center">
                          <a class="tip" title="View Promo" href="${BASE_URL}/Promotions/Edit_Promos/${row.id}">
                              <i class="fa fa-eye"></i>
                          </a>
                       <!--     <a href="#" class="delete-promo" data-id="${row.id}">
                              <i class="fa fa-trash"></i>
                          </a>-->

                            <a class="tip" title="Create Similar Promo" href="${BASE_URL}/Promotions/Create_similar_promo/${row.id}">
                              <i class="fa fa-plus"></i>
                          </a>
                      </div>
                  `;
          },
          className: "text-center",
        },
      ],
    });

    const handleDeleteClick = (e) => {
      e.preventDefault();
      const promoId = $(e.currentTarget).data("id");
      deletePromotion(promoId);
    };

    $("#promotion_list tbody").on("click", ".delete-promo", handleDeleteClick);

    return () => {
      $("#promotion_list tbody").off(
        "click",
        ".delete-promo",
        handleDeleteClick
      );
      table.destroy();
    };
  }, [inactivePromotionData]);

  return (
    <div>
      <Header />
      <nav className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <a href="#">
                <i className="fa fa-home"></i>{" "}
              </a>
              <span className="last-crumb">Promotions</span>
            </div>
          </div>
        </div>
      </nav>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4 col-sm-4"></div>
            <div className="col-12 col-lg-8 col-sm-8">
              <div style={{ float: "right" }}>
                <Link
                  to={`${config.BASE_URL}/Promotions/Add_Promos`}
                  className="create-catalogue-btn"
                >
                  Add Promotion{" "}
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "active" ? "active" : ""
                    }`}
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#active"
                    type="button"
                    role="tab"
                    aria-controls="active"
                    aria-selected={activeTab === "active"}
                    onClick={() => handleTabChange("active")}
                  >
                    Active Promotion
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "inactive" ? "active" : ""
                    }`}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#inactive"
                    type="button"
                    role="tab"
                    aria-controls="inactive"
                    aria-selected={activeTab === "inactive"}
                    onClick={() => handleTabChange("inactive")}
                  >
                    Inactive Promotion
                  </button>
                </li>
              </ul>

              <div className="tab-content fulltable-area" id="myTabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "active" ? "show active" : ""
                  }`}
                  id="active"
                  role="tabpanel"
                  aria-labelledby="eur-tab"
                >
                  <div className="backlist-content-area">
                    <div
                      className="verical-scrl-wrapper common-table active-product"
                      id="style-3"
                    >
                      <div className="show-order-area dashboard">
                        <div
                          className="verical-scrl-wrapper promo-table"
                          id="style-3"
                        >
                          <table
                            className="table table-bordered dataTable resizable"
                            id="promotion_list"
                          >
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Product to buy</th>
                                <th>Product to get</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "inactive" ? "show active" : ""
                  }`}
                  id="inactive"
                  role="tabpanel"
                  aria-labelledby="gbp-tab"
                >
                  <div className="backlist-content-area">
                    <div
                      className="verical-scrl-wrapper common-table active-product"
                      id="style-3"
                    >
                      <div className="show-order-area dashboard">
                        <div
                          className="verical-scrl-wrapper promo-table"
                          id="style-3"
                        >
                          <table
                            className="table table-bordered dataTable resizable"
                            id="InactivePromotionList"
                          >
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Product to buy</th>
                                <th>Product to get</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Promos;
