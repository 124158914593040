import React, { useState, useEffect } from 'react';
import axios from "axios";
import Select from 'react-select';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { API_URL } from '../Config';
import config from "../common/Config";
import Swal from 'sweetalert2';

const AddReturn = () => {

  const [selectedDate, setSelectedDate] = useState('');
  const [noteInfo, setNoteInfo] = useState('');
  const [customeId, setCustomeId] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const [returnTableRows, setReturnTableRows] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const user_id = localStorage.getItem('userId');

  const [productOptions, setProductOptions] = useState([]);
  const [productFilteredOptions, setProductFilteredOptions] = useState([]);
  const [productValue, setProductValue] = useState(null);  
  const [productInputValue, setProductInputValue] = useState('');

  const [checkedValues, setCheckedValues] = useState([]);
 
  
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const handleNoteChange = (event) => {
    setNoteInfo(event.target.value);
  };

  const handleCustomerInputChange = (inputValue) => {

    fetchCustomerNameOptions(inputValue);
  };

  const fetchCustomerNameOptions = async (inputValue) => {
      if (inputValue.length < 3) {
        return;
      }

      const formData = new FormData();
      formData.append('pattern', inputValue);
      formData.append('platform', 'web');
      formData.append('authorization', accessToken);

      const apiSalesRapUrl = `${config.API_URL}/ws-get-customer-list`;
      try {
        const response = await fetch(apiSalesRapUrl, {
          method: 'POST',
          headers: {
            
          },
          body: formData,
        });

        const data = await response.json();
        
          

          const formattedOptions = Array.isArray(data.customer_details) 
            ? data.customer_details.map(item => ({
                label: item.name,
                value: item.id,
            }))
            : [];
          
          setCustomerData(formattedOptions);
        

      } catch (error) {
        console.error('Error fetching customer options:', error);
      }
    };

  const handleCustomerChange = (selectedOption,) => {

    console.log(selectedOption);

    if (selectedOption) {
      setSelectedCustomer(selectedOption);
      setCustomeId(selectedOption.value);
      fetchProductList(selectedOption);

       fetchReturnProductInfo(selectedOption);
      setTableRows([]);
      setReturnTableRows([]);
      
    } else {
      setSelectedCustomer(null);
      setCustomeId('');

    }
  };

  const fetchProductList = async (selectedOption) => {


    const formData = new FormData();
      formData.append("platform", 'web');
      formData.append("authorization", `Bearer ${accessToken}`);
      formData.append("customer_id", selectedOption.value);


    const apiUrl = `${config.API_URL}/ws-get-products`;
    try {
      const response = await axios.post(apiUrl, formData, {
              
      });
      
    const data = await response.data; 
    console.log(data);
    const formattedOptions = data.product_details.map(item => ({
       label: `${item.product_name || 'N/A'} - ${item.product_code || 'N/A'}`,  
        value: item.id,    
      }));
   
      setProductOptions(formattedOptions);
        
    } catch (error) {
      console.error("Error fetching products data:", error);
    }
  };

  const productHandleInputChange = (inputValue) => {
      setProductInputValue(inputValue);
      const newFilteredOptions = productOptions.filter(option =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setProductFilteredOptions(newFilteredOptions);
  };

  const productHandleChange = (selectedOption) => {
      
      if (selectedOption ) {
          setProductValue(selectedOption);
          fetchAddProductInfo(selectedOption.value);
         
      } else {
          setProductValue(null); 
      }
  };

  const fetchAddProductInfo = async (selectedOption) => {
    const formData = new FormData();
    setTableRows([]);
        

    formData.append('customer_id', selectedCustomer.value);
    formData.append('product_id', selectedOption);
    const apiUrl = `${config.API_URL}/get_return_datails`; 
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch new product');
        }

        const data = await response.json();
        


        const newRows = data.map(productData => {
           
            return {
                id: productData.id,
                reference_no: productData.reference_no,
                pack: productData.pack,
                invoice_date: productData.invoice_date,
                product_id: productData.product_id,
                quantity: productData.quantity,
                unit_price: productData.unit_price,
                order_type: productData.order_type,
                item_tax: productData.item_tax,
                subtotal: productData.subtotal,
            };
        });

        // Update tableRows state with all the new rows
        setTableRows(prevRows => [...prevRows, ...newRows]);

    } catch (error) {
        console.error('API fetch error:', error);
    }
  };



    const deleteReturnRecord = async (rowId) => {
        // Show confirmation alert
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this return record?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });

        if (result.isConfirmed) {
            const formData = new FormData();
            formData.append('sale_item_id', rowId); 
            
            const apiUrl = `${config.API_URL}/remove_return_item`; 

            try {
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${accessToken}`,
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Failed to delete return record');
                }

                Swal.fire({
                    title: 'Deleted!',
                    text: 'The return record has been deleted.',
                    icon: 'success',
                });

                fetchReturnProductInfo(selectedCustomer);

            } catch (error) {
                console.error('API fetch error:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'There was an issue deleting the return record.',
                    icon: 'error',
                });
            }
        }
    };


  const fetchReturnProductInfo = async (selectedOption) => {
    const formData = new FormData();
    setReturnTableRows([]);
        

    formData.append('customer_id', selectedOption.value);
    const apiUrl = `${config.API_URL}/add_temp_return`; 
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch new product');
        }

        const data = await response.json();
        console.log(data);


        const newRows = data.map(productData => {
           
            return {
                id: productData.id,
                reference_no: productData.reference_no,
                invoice_date: productData.invoice_date,
                quantity: productData.quantity,
                product_code: productData.product_code,
                product_id: productData.product_id,
                product_name: productData.product_name,
                unit_price: productData.unit_price,
                order_type: productData.order_type,
                item_tax: productData.item_tax,
                return_qty: productData.return_qty,
                subtotal: productData.subtotal,
                return_type: productData.return_type,
                return_unitprice: productData.return_unitprice,
                return_itemtax: productData.return_itemtax,
                return_total: productData.return_total,
                returnReason: productData.returnReason,
            };
        });

        // Update tableRows state with all the new rows
        setReturnTableRows(prevRows => [...prevRows, ...newRows]);

    } catch (error) {
        console.error('API fetch error:', error);
    }
  };




  const handleCheckboxChange = (e) => {
    const value = e.target.value; 
    const isChecked = e.target.checked; 

    setCheckedValues((prevCheckedValues) => {
      if (isChecked) {
        return [...prevCheckedValues, value]; 
      } else {
        return prevCheckedValues.filter((val) => val !== value); 
      }
    });
  };

  
 /* const handleReturnSubmit = async (e) => {
    e.preventDefault();  // Prevent default form submission
    
    const formData = new FormData();
    const invalidRows = [];

    // Loop through all the selected checkboxes
    for (let row of tableRows) {
      if (checkedValues.includes(row.id.toString())) {
        const returnQty = parseFloat(document.querySelector(`input[name="return_qty[]"][data-id="${row.id}"]`).value);
        const returnType = document.querySelector(`select[name="return_type[]"][data-id="${row.id}"]`).value;
        
        let maxReturnQty = 0;
        if (returnType === 'box') {
          maxReturnQty = row.quantity;  // Max return quantity is the order quantity if it's a box
        } else if (returnType === 'piece') {
          maxReturnQty = row.quantity * row.pack;  // Max return quantity is quantity * pack if it's a piece
        }

        if (returnQty > maxReturnQty) {
          invalidRows.push(row);
        } else {
          // If valid, append data for submission
          formData.append('order_ids[]', row.id);
          formData.append('return_qty[]', returnQty);
          formData.append('return_type[]', returnType);
          formData.append('returnReason[]', document.querySelector(`select[name="returnReason[]"][data-id="${row.id}"]`).value);
        }
      }
    }

    if (invalidRows.length > 0) {
      // If there are invalid rows, show a Swal alert
      Swal.fire({
        title: 'Error',
        text: `Return quantity exceeds allowed limit for product(s) with invoice(s): ${invalidRows.map(row => row.reference_no).join(', ')}`,
        icon: 'error',
      });
      return;
    }

    // If there are no invalid rows, submit the form data
    try {
      const apiUrl = `${config.API_URL}/add_return`;  // Replace with your actual API endpoint
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to add return');
      }

      const data = await response.json();
      Swal.fire({
        title: 'Success',
        text: 'Return added successfully!',
        icon: 'success',
      });

      // You can also reset or handle post-submission logic here if needed
    } catch (error) {
      console.error('API fetch error:', error);
      
    }
  };*/

  const handleReturnSubmit = async (e) => {
    e.preventDefault();     
    const formData = new FormData();
    const invalidRows = [];
    const submittedRows = []; 
    console.log(checkedValues);
    console.log(tableRows);
    for (let row of tableRows) {
      if (checkedValues.includes(row.id)) {
        const returnQty = parseFloat(document.querySelector(`input[name="return_qty[]"][data-id="${row.id}"]`).value);
        const returnType = document.querySelector(`select[name="return_type[]"][data-id="${row.id}"]`).value;
        console.log(returnQty);
        console.log(returnType);

        let maxReturnQty = 0;
        if (returnType === 'box') {
          maxReturnQty = row.quantity;  // Max return quantity is the order quantity if it's a box
        } else if (returnType === 'piece') {
          maxReturnQty = row.quantity * row.pack;  // Max return quantity is quantity * pack if it's a piece
        }

        if (returnQty > maxReturnQty) {
          invalidRows.push(row); 
        } else {
          formData.append('order_ids[]', row.id);
          formData.append('return_qty[]', returnQty);
          formData.append('return_type[]', returnType);
          formData.append('returnReason[]', document.querySelector(`select[name="returnReason[]"][data-id="${row.id}"]`).value);
          submittedRows.push(row);           
        }
      }
      //  else {
        //  Swal.fire({
        //   title: 'Error',
        //   text: `Select project to return`,
        //   icon: 'error',
        // });
        // return;
      // }
    }

    console.log(invalidRows);
    console.log(submittedRows);



    if (invalidRows.length > 0) 
    {
      // If there are invalid rows, show a Swal alert
      Swal.fire({
        title: 'Error',
        text: `Return quantity exceeds allowed limit for product(s) with invoice(s): ${invalidRows.map(row => row.reference_no).join(', ')}`,
        icon: 'error',
      });
      return;
    }

    try {
      const apiUrl = `${config.API_URL}/add_temp_return`; 
      formData.append('user_id', user_id);
      formData.append('customer_id', selectedCustomer.value); 
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to add return');
      }

      const data = await response.json();

      // Show success alert
      Swal.fire({
        title: 'Success',
        text: 'Return added successfully!',
        icon: 'success',
      });

      // Remove the successfully submitted rows from tableRows
      const remainingRows = tableRows.filter((row) => !submittedRows.some((submittedRow) => submittedRow.id === row.id));
      setTableRows(remainingRows);  // Update the state to reflect the changes
      fetchReturnProductInfo(selectedCustomer);
      setReturnTableRows(remainingRows);


    } catch (error) {
      console.error('API fetch error:', error);
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong while submitting the return.',
        icon: 'error',
      });
    }
  };
 

   const SubmitRejectFormData = async () => {

    if (!selectedDate || returnTableRows.length === 0) {
      Swal.fire({
        title: 'Error',
        text: 'Please select a date and add products to return.',
        icon: 'error',
      });
      return;
    }

    // Construct the payload
    const formData = {
      return_date: selectedDate, 
      customerId: selectedCustomer.value,
      note : noteInfo, 
      user_id : user_id,
      returnProductDetails: returnTableRows.map((row) => ({
        itemId: row.id.toString(), 
        returnPieceType: row.return_type, 
        returnQty: row.return_qty.toString(),  
        returnReason: row.returnReason || '', 
      })),
    };

    // Display confirmation dialog before submitting
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to submit this return?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });

    if (result.isConfirmed) {
      try {
        const apiUrl = `${config.API_URL}/return_request_web`; 
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(formData),  
        });

        if (!response.ok) {
          throw new Error('Failed to submit return');
        }

        const data = await response.json();

        Swal.fire({
          title: 'Success',
          text: 'Return data submitted successfully!',
          icon: 'success',
        });

        setSelectedDate('');
        setReturnTableRows([]);
      } catch (error) {
        console.error('API error:', error);
        Swal.fire({
          title: 'Error',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
        });
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard"><i className="fa fa-home"></i></Link>
              <Link to="#">Return</Link>
              <Link to="#">Add Return</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div className="show-order-area dashboard" style={{ position: 'relative' }}>
                <div className=''>
                  <div>
                    <form id='addcustomerform'>
                                            <div className="row">

						<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
							<div class="row mb-3 padd-0">
								<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b> Date</b></label>
								<div class="col-sm-8">
                <input
                                type="date"
                                id="dateInput"
                                name="dateInput"
                                value={selectedDate} // This binds the input value to the state
                                onChange={handleDateChange} // This updates the state when the user selects a date
                                className="form-control"
                              />
								</div>
							</div>
						</div>

						<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
							<div class="row mb-3 padd-0">
								<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Customer</b></label>
								<div class="col-sm-8">
                <Select className='abovedd selct-dd'
                                isClearable
                                onInputChange={handleCustomerInputChange}
                                onChange={handleCustomerChange}
                                options={customerData}
                                value={selectedCustomer}
                                placeholder="Enter Customer Name"
                              />
								</div>
							</div>
						</div>

						<div className="date-range-picker col-md-3 col-lg-3 col-sm-12" style={{ float: 'left' }}>
							<div class="row mb-3 padd-0">
								<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select Product</b></label>
								<div class="col-sm-8">
                <Select className='abovedd'
                                  isClearable
                                  onInputChange={productHandleInputChange}
                                  onChange={productHandleChange}
                                  options={productFilteredOptions}  // Pass the filtered options here
                                  placeholder="Select Product"
                                  value={productValue}
                              />
								</div>
							</div>
						</div>

				




					</div>




                      <div class="row">
                        <label>Order Items</label>
                        <div className='col-12 col-lg-12 col-sm-12'>
                          <div className="verical-scrl-wrapper add-return-top" id="style-3">
                          <form id='addproductform'>
                            <table className="table table-bordered dataTable resizable">
                              <thead>
                                <tr>
                                  <th>&nbsp;</th>
                                  <th>Invoice Date	</th>
                                  <th>Invoice No	</th>
                                  <th>Order Qty	</th>
                                  <th>Total Price	</th>
                                  <th>Vat</th>
                                  <th>Sub Total	</th>
                                  <th>Return Qty	</th>
                                  <th>Return Type	</th>
                                  <th>Reason</th>
                                </tr>
                              </thead>
                              <tbody>                              
                                {tableRows.length === 0 ? (
                                    <tr style={{ textAlign: 'center' }}>
                                      <td colSpan={10}>No product</td>
                                    </tr>
                                  ) : (
                                    tableRows.map((row) => (
                                      <tr key={row.id}>
                                        <input type="hidden" value={row.id} name="order_ids[]" />
                                        <input type="hidden" value={row.pack} name="pack[]" />
                                        <td>
                                          <label className="checkbox" style={{ margin: '0px auto', float: 'none' }}>
                                            <input
                                              type="checkbox"
                                              name="val[]"
                                              value={row.id}
                                              className="dataTableCheckbox"
                                              onChange={handleCheckboxChange}
                                            />
                                            <span className="default"></span>
                                          </label>
                                        </td>
                                        <td>{row.invoice_date}</td>
                                        <td>{row.reference_no}</td>
                                        <td>{parseInt(row.quantity)}({row.order_type})</td>
                                        <td>{row.unit_price}</td>
                                        <td>{row.item_tax}</td>
                                        <td>{row.subtotal}</td>
                                        <td>
                                          <input
                                            type="text"
                                            name="return_qty[]"
                                            min="0"
                                            className="form-control order-qty text-center"
                                            style={{ width: '70px', padding: '5px' }}
                                            data-id={row.id}
                                          />
                                        </td>
                                        <td className="text-center">
                                          <select className="form-select" name="return_type[]" data-id={row.id}>
                                            <option value="box">Box</option>
                                            <option value="piece">Piece</option>
                                          </select>
                                        </td>
                                        <td>
                                          <select className="form-select" name="returnReason[]" data-id={row.id}>
                                            <option value="">Select</option>
                                            <option value="damaged">Damaged</option>
                                            <option value="expired">Expired</option>
                                            <option value="unwanted">Unwanted</option>
                                          </select>
                                        </td>
                                      </tr>
                                    ))
                                  )}                                
                              </tbody>
                            </table>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-lg-12 col-sm-12'>
                        <hr />
                      </div>
                      <div class="row padd-l-13px">
                        <div class="col-12 col-lg-12 col-sm-12">
                          <button type="button" onClick={handleReturnSubmit} class="float-right-btn common-popup-btn">Add Return</button>
                        </div>
                      </div>
                      <div class="row">
                        <label>Return Items </label>
                        <div className='col-12 col-lg-12 col-sm-12'>
                          <div className="verical-scrl-wrapper add-return" id="style-3">
                            <table className="table table-bordered dataTable resizable">
                              <thead>
                                <tr>
                                  <th>Invoice Date	</th>
                                  <th>Invoice No	</th>
                                  <th>Product Name	</th>
                                  <th>Qty</th>
                                  <th>Price</th>
                                  <th>Vat</th>
                                  <th>Total</th>
                                  <th>Return Qty	</th>
                                  <th>Amt</th>
                                  <th>Vat</th>
                                  <th>Subtotal</th>
                                  <th>Reason</th>
                                  <th>Action</th>

                                </tr>
                              </thead>
                              <tbody>
                                  {returnTableRows.length === 0 ? (
                                    <tr style={{ textAlign: 'center' }}>
                                      <td colSpan={13}>No product</td>
                                    </tr>
                                  ) : (
                                    returnTableRows.map((row) => (
                                      <tr key={row.id}>
                                        <input type="hidden" value={row.id} name="pack[]" />
                                        <td>{row.invoice_date}</td>
                                        <td>{row.reference_no}</td>
                                        <td>{row.product_code} - {row.product_name}</td>
                                        <td>{parseInt(row.quantity)}({row.order_type})</td>
                                        <td>{parseFloat(row.unit_price).toFixed(2)}</td>
                                        <td>{parseFloat(row.item_tax).toFixed(2)}</td>
                                        <td>{parseFloat(row.subtotal).toFixed(2)}</td>
                                        <td>{parseInt(row.return_qty)}({row.return_type})</td>
                                        <td>{parseFloat(row.return_unitprice).toFixed(2)}</td>
                                        <td>{parseFloat(row.return_itemtax).toFixed(2)}</td>
                                        <td>{parseFloat(row.return_total).toFixed(2)}</td>
                                        <td>{row.returnReason}</td>
                                        <td>
                                           <a className="deductionbtn" href="#" onClick={() => deleteReturnRecord(row.id)}>
                                              <i className="fa fa-trash"></i>
                                            </a>
                                        </td>
                                        
                                      </tr>
                                    ))
                                  )}
                                

                                
                              </tbody>
                            </table>
                          </div>
                        </div>

                      </div>


                      <div class="row mb-3">
                        <div className='col-12 col-lg-12 col-sm-12'>
                          <label className='col-form-label left-label'>Note </label>
                          <textarea class="form-control" style={{ height: '100px' }} onChange={handleNoteChange} ></textarea>
                        </div>
                      </div>

                      <div className='col-12 col-lg-12 col-sm-12'>
                        <hr />
                      </div>

                      <div className='row mb-3 padd-l-13px'>
                        <div className='col-12 col-lg-12 col-sm-12'>
                          <button type="button" onClick={SubmitRejectFormData} class="float-right-btn common-popup-btn">Submit</button>
                        </div>
                      </div>



                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AddReturn;
