import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import printNewQuoteInvoice from './printNewQuoteInvoice';
import config from "../common/Config";
import Swal from 'sweetalert2';

import Dropdown from 'react-bootstrap/Dropdown';



const NewRSales = () => {
	const [draw, setDraw] = useState(1);
	const [start, setStart] = useState(0);
	const [length, setLength] = useState(50);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [newSales, setNewSales] = useState([]);
	const [showRejectModal, setShowRejectModal] = useState(false);
	const changeCustmodalRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [rejectData, setRejectData] = useState([]);
	const [rejectComment, setRejectComment] = useState('');
	const [flashMessage, setFlashMessage] = useState(null);
	const [changeCustData, setchangeCustData] = useState([]);
	const [customerOptions, setCustomerOptions] = useState([]);
	const [customerInputValue, setCustomerInputValue] = useState('');
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [showHoldModal, setShowHoldModal] = useState(false);
	const orderIdRef = useRef(null);
	const reasonRef = useRef(null);
	const accessToken = localStorage.getItem('accessToken');

	const fetchCustomerNameOptions = async (inputValue) => {
		if (inputValue.length < 3) {
			return;
		}

		const formData = new FormData();
		formData.append('term', inputValue);
		formData.append('limit', '10');

		const apiSalesRapUrl = `${config.API_URL}/suggestions`;
		try {
			const response = await fetch(apiSalesRapUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			const data = await response.json();
			if (Array.isArray(data.results)) {
				const formattedOptions = data.results.map(item => ({
					label: item.text,
					value: item.id,
				}));
				setCustomerOptions(formattedOptions);
			}

		} catch (error) {
			console.error('Error fetching customer options:', error);
		}
	};

	const handleInputChange = (inputValue) => {
		fetchCustomerNameOptions(inputValue);
	};

	const handleChange = (selectedOption) => {
		setCustomerInputValue(selectedOption);
	};
	


	const handleGenerateQuotePDF = (orderId) => {
		// alert('dd');
    printNewQuoteInvoice(orderId); 
	}


	const handleRejCommentChange = (event) => {
		setRejectComment(event.target.value);
	};


	const rejectFormData = async () => {
		const reason = rejectComment;

		if (!reason) {
			Swal.fire({
				title: "Falied!",
				text: "Please enter resion for reject.",
				icon: "error",
				timer: 1500,
				showConfirmButton: false,
			});
		} else {

			const formData = new FormData();
			formData.append('status', 'Reject');
			formData.append('reason_note', reason);
			formData.append('id', rejectData.ids);

			const apistatusUrl = `${config.API_URL}/ws-update-status`;
			try {
				const response = await fetch(apistatusUrl, {
					method: 'POST',
					headers: {
						// 'Content-Type': 'application/json',
						authorization: `Bearer ${accessToken}`,
					},
					body: formData,
				});

				if (!response.ok) {

					setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
				}

				const data = await response.json();
				console.log('API Response:', data);
				setFlashMessage({ message: 'Order rejected successfully', type: 'success' });
				setShowRejectModal(false);
				setRejectComment('');
				fetchPageData();
			} catch (error) {
				console.error('Error rejecting order:', error);
				setFlashMessage({ message: 'Error rejecting order. Please check the console for details', type: 'error' });
			}
		}
	};

	const changeCustFormData = async () => {

		let customerId = customerInputValue ? customerInputValue : '';
		if (customerId.value != null) {

			//console.log(changeCustData.ids + '---' +customerId.value);

			const formData = new FormData();
			//formData.append('status', 'Rejected');
			formData.append('id', changeCustData.ids);
			formData.append('customer_id', customerId.value);

			const apistatusUrl = `${config.API_URL}/ws-update_customer_to_order`;
			try {
				const response = await fetch(apistatusUrl, {
					method: 'POST',
					headers: {
						// 'Content-Type': 'application/json',
						authorization: `Bearer ${accessToken}`,
					},
					body: formData,
				});

				if (!response.ok) {

					setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
				}
				const data = await response.json();
				console.log('API Response:', data);
				setFlashMessage({ message: 'Customer Change successfully', type: 'success' });
				fetchPageData();
			} catch (error) {
				console.error('Error rejecting order:', error);
				setFlashMessage({ message: 'Error Change Customer. Please check the console for details', type: 'error' });
			}

		} else {


			Swal.fire({
				title: "Falied!",
				text: "Please select customer name.",
				icon: "error",
				timer: 1500,
				showConfirmButton: false,
			});
		}
	};

	$(document).off('click', '#neworderTable .rejectmdlBtn').on('click', '#neworderTable .rejectmdlBtn', function () {
		const salesData = $(this).attr('data-order');
		const salesOrder = JSON.parse(decodeURIComponent(salesData));
		setRejectData([]);
		setTimeout(() => {
			setRejectData(salesOrder);
		}, 0);

		setShowRejectModal(true);

	});



	const handleStartDateChange = (e) => {
		setStartDate(e.target.value);
	};

	const handleCloseMessage = () => {
		setFlashMessage(null);
	};

	const handleEndDateChange = (e) => {
		setEndDate(e.target.value);
	};

	const fetchPageData = async () => {
		const formData = new FormData();
		formData.append('draw', draw);
		formData.append('start', start);
		formData.append('length', length);
		formData.append('searchByFromdate', startDate ? startDate : '');
		formData.append('searchByTodate', endDate ? endDate : '');

		formData.append('authorization', `Bearer ${accessToken}`);

		const apiUrl = `${config.API_URL}/sales/saleslist_test`;
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,

				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to fetch Not Order Report');
			}
			const data = await response.json();
			//	console.log(data.data);
			setNewSales(data.data);

		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}

	};

	const searchFormData = () => {
		fetchPageData();
	};




	useEffect(() => {
		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}
		fetchPageData();
	}, []);




	const [checkedValues, setCheckedValues] = useState([]);
	const [checkedItems, setCheckedItems] = useState({});

	//const [formData, setFormData] = useState({});

	//const handleSubmit = (event) => {

	const handleSubmit = async () => {
		//event.preventDefault();
		// Log or use checkedValues as needed
		//console.log('Checked values:', checkedValues);
		if (checkedValues.length > 0) {
			const formData = new FormData();
			formData.append('myTable_length', '50');
			checkedValues.forEach((value, index) => {
				formData.append('val[]', value);
			});
			formData.append('form_action', '');

			const apiAddOrdersUrl = `${config.API_URL}/sales/add_orders_front_sheet`;
			try {
				const response = await fetch(apiAddOrdersUrl, {
					method: 'POST',
					headers: {
						// 'Content-Type': 'application/json',
						authorization: `Bearer ${accessToken}`,
					},
					body: formData,
				});

				if (!response.ok) {

					setFlashMessage({ message: 'something went wrong, try again', type: 'error' });
				}

				const data = await response.json();
				console.log('API Response:', data);
				setFlashMessage({ message: 'Accept Order successfully', type: 'success' });

				fetchPageData();
			} catch (error) {
				console.error('Error Accept Order:', error);
				setFlashMessage({ message: 'Error Accept Order order. Please check the console for details', type: 'error' });

			}
		} else {


			Swal.fire({
				title: "Falied!",
				text: "please select order to accept.",
				icon: "error",
				timer: 1500,
				showConfirmButton: false,
			});
		}

	};

	useEffect(() => {
		const table = $('#neworderTable').DataTable({
			pageLength: 50,
			paging: true,
			lengthMenu: [10, 20, 50],
			searching: true,
			ordering: false,
			info: true,
			responsive: true,
			autoWidth: false,
			language: {
				paginate: {
					first: 'First',
					last: 'Last',
					next: 'Next',
					previous: 'Previous',
				},
				search: 'Search:',
			},
			data: newSales,  // Ensure `newSales` contains the necessary data
			columns: [
				{
					data: null,
					render: function (data, type, row) {
						const isChecked = !!checkedItems[row.ids];
						if (row.sale_status === 'New') {
							return `
				<label class="checkbox">
				  <input 
					type="checkbox" 
					name="val[]" 
					value="${row.ids}" 
					class="dataTableCheckbox"
				  />
				  <span class="default"></span>
				</label>
			  `;
						} else {
							return ``;
						}
					}
				},
				{
					data: 'date',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return `<span style="color: #f14240;">${row.date}</span>`;
						} else {
							return `<span>${row.date}</span>`;
						}
					}
				},
				{
					data: 'reference_no',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return '<label style="color: #f14240; display: block;">' + row.reference_no + '</label>';
						} else {
							return '<a href="' + config.BASE_URL + '/sales/edit_invoice/' + row.ids + '" >' + row.reference_no + '</a>';
						}
					}
				},
				{
					data: 'customer',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return `<span style="color: #f14240;">${row.customer}</span>`;
						} else {
							return `<span title="${data}" class="mightOverflow col-xs-1">${row.customer}</span>`;
						}
					}
				},
				{
					data: 'postal_code',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return `<span style="color: #f14240;">${row.postal_code}</span>`;
						} else {
							return `<span>${row.postal_code}</span>`;
						}
					}
				},
				{
					data: 'route_number',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return `<span style="color: #f14240;">${row.route_number}</span>`;
						} else {
							return `<span>${row.route_number}</span>`;
						}
					}
				},
				{
					data: 'grand_total',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return `<span style="color: #f14240;">${row.grand_total}</span>`;
						} else {
							return `<span>${row.grand_total}</span>`;
						}
					}
				},
				{
					data: 'prev_due',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return `<span style="color: #f14240;">${row.prev_due}</span>`;
						} else {
							return `<span>${row.prev_due}</span>`;
						}
					}
				},
				{
					data: 'name',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return `<span style="color: #f14240;">${row.name}</span>`;
						} else {
							return `<span title="${data}" class="mightOverflow col-xs-1">${row.name}</span>`;
						}
					}
				},
				{
					data: 'sale_status',
					render: function (data, type, row) {
						if (row.sale_status === 'Hold') {
							return `<span style="color: #f14240;">${row.sale_status}</span>`;
						} else {
							return `<span>${row.sale_status}</span>`;
						}
					}
				},
				{
			    data: null,
			    render: function (data, type, row) {
			        let html = `<div><a class="deductionbtn rejectmdlBtn" title="Reject Order" data-action="reject" data-order='${encodeURIComponent(JSON.stringify(data))}'><i class="bi bi-ban"></i></a>`;
			        
			        if (row.sale_status === 'New') {
			            html += `<a class="deductionbtn" data-action="hold" data-order='${row.ids}' title="Hold Order">
			                      <i class="bi bi-pause-fill"></i></a>`;
								  // if(accessToken === 'knm')
								  // {
								// 	html +=  `<a href="${config.BASE_URL}/KM_Foods_Invoice/${row.ids}" title="Print Invoice">
								// 	  <i class="bi bi-printer"></i></a>`;
								  // }
								  // else
								  // {
									html +=  `<a href="${config.BASE_URL}/new_sales/invoice/${row.ids}" title="Print Invoice">
									  <i class="bi bi-printer"></i></a>`
								//  }
			                      html += `<a href="#" class="printQuatation" data-id='${row.ids}' title="Print Quote">
			                      <i class="bi bi-chat-left-quote"></i></a>`;
			        } else if (row.sale_status === 'Hold') {
			            html += `<a class="ConvertToNewOrder" data-action="new" title="New order" data-order='${row.ids}'>
			                      <i class="bi bi-cart-plus"></i></a>
			                      <a href="#" onClick="handleGenerateQuotePDF(${row.ids})" title="Print Quote">
			                      <i class="bi bi-chat-left-quote"></i></a>`;
			        }

			        html += `</div>`;

			        return html;
			    }
			}


			],

			columnDefs: [
				{
					targets: [0], // Make sure the checkbox column is non-orderable
					orderable: false
				},
				{
					targets: [1, 2, 3, 4, 5, 6, 7, 8, 9], // Allow sorting on other columns
					orderable: true
				}
			],
			createdRow: function (row, data, dataIndex) {
				if (data.sale_status === 'Hold') {
					$(row).css('background-color', '#f7f7f7'); // Set row background color for "Hold"
				}
			}
		});



		// Event listener for checkbox changes
		$(document).off('change', '#neworderTable .dataTableCheckbox').on('change', '#neworderTable .dataTableCheckbox', function () {
			const value = $(this).val();
			const checked = $(this).is(':checked');
			setCheckedValues(prevState => {
				if (checked) {
					return [...prevState, value];
				} else {
					return prevState.filter(val => val !== value);
				}
			});
		});

		// Handle clicks for actions (e.g., hold order)
		$('#neworderTable').on('click', 'a[data-action="hold"]', function (e) {
			e.preventDefault();
			const orderData = $(this).data('order');
			setSelectedOrder(orderData);
			setShowHoldModal(true);
		});


		// Handle clicks for actions
		$('#neworderTable').on('click', '.ConvertToNewOrder', function (e) {
			e.preventDefault();
			const orderData = $(this).data('order');
			handleNewStatus(orderData);
		});

		$('#neworderTable').on('click', '.printQuatation', function (e) {
			e.preventDefault();
			const id = $(this).data('id');
			handleGenerateQuotePDF(id);
		});


		// Cleanup the DataTable on component unmount
		return () => {
			table.destroy();
		};
	}, [newSales]);


	const handleNewStatus = (id, accessToken) => {
		// Confirming the action with the user
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you want to transfer this order to new sales?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, proceed',
			cancelButtonText: 'No, cancel',
		}).then((result) => {
			// If confirmed, proceed with the API call
			if (result.isConfirmed) {
				let userId = localStorage.getItem('userId');
				const accessToken = localStorage.getItem('accessToken');
				const formData = new FormData();
				formData.append('status', 'New');
				formData.append('id', id);
				formData.append('reason_note', '');
				formData.append('user_id', userId);
				axios.post(
					`${config.API_URL}/ws-update-status`,
					formData,
					{
						headers: {
							'authorization': `Bearer ${accessToken}`,
						},
					}
				)
					.then((response) => {
						fetchPageData();
						Swal.fire('Success', 'Order has been transferred!', 'success');
					})
					.catch((error) => {
						Swal.fire('Error', 'Something went wrong!', 'error');
						console.error('Error:', error);
					});
			}
		});
	};

	const updateReject = async (event) => {
		event.preventDefault();

		if (!orderIdRef.current || !reasonRef.current) {
			alert('Required form elements are missing. Please try again.');
			return;
		}

		const orderId = orderIdRef.current.value;
		const reason = reasonRef.current.value;
		const formData = new FormData();
		formData.append('status', 'Hold');
		formData.append('reason_note', reason);
		formData.append('id', orderId);

		try {
			const response = await fetch(`${config.API_URL}/ws-update-status`, {
				method: 'POST',
				headers: {
					'authorization': `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(`HTTP error! Status: ${response.status}. Error: ${errorText}`);
			}

			const data = await response.json();
			console.log(data);
			Swal.fire({
				title: "Success!",
				text: "Order Hold successfully.",
				icon: "success",
				timer: 1500,
				showConfirmButton: false,
			})
			fetchPageData();
		} catch (error) {
			console.error('Error holding order:', error);
			alert('Error holding an order. Please check the console for details.');
		}
		setShowHoldModal(false);
	};


	return (
		<div>
			<Header />
			{showRejectModal && (
				<div className="modal fade show d-block" id="rejectOrderMdl" tabIndex="-1" style={{ display: 'block' }}>
					<div class="modal-dialog modal-dialog-centered modal-lg">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title">Reject Order</h5>
								<button type="button" class="btn-close" onClick={() => setShowRejectModal(false)} data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div class="modal-body">
								<div class="rejectorder-popup">
									<form>
										<h4>Sale Details</h4>
										<table class="table table-bordered">
											<tr>
												<td>Order Id</td>
												<td>{rejectData.reference_no}</td>
											</tr>
											<tr>
												<td>customer (A/C)</td>
												<td>{rejectData.customer}</td>
											</tr>
											<tr>
												<td>Order Amount</td>
												<td>{rejectData.grand_total}</td>
											</tr>
											<tr>
												<td>Previous Dues</td>
												<td>{rejectData.prev_due}</td>
											</tr>
										</table>
										<div class="form-group">
											<label class="col-form-label">Reason Note</label>
											<textarea
												className="form-control"
												value={rejectComment}
												onChange={handleRejCommentChange}
												placeholder="Leave Comment"
											/>
										</div>
										<button type="button" onClick={rejectFormData} data-bs-dismiss="modal" class="updatebtn left"> Update</button>

									</form>
								</div>
							</div>

						</div>
					</div>
				</div>
			)}

			{showHoldModal && (
				<div className="modal fade show d-block" id="reject-order" tabIndex="-1" style={{ display: 'block' }}>
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Hold Order</h5>
								<button type="button" className="btn-close" onClick={() => setShowHoldModal(false)} aria-label="Close"></button>
							</div>
							<div className="modal-body">
								{selectedOrder && (
									<div className="rejectorder-popup">
										<form id="rejectForm">
											<div className="form-group">
												<label>Reason Note</label>
												<textarea id="reason" name="reason" ref={reasonRef}></textarea>
											</div>
											<input type="hidden" name="order_id" id="order_id" ref={orderIdRef} value={selectedOrder} />
											<button type="button" onClick={updateReject} className="updatebtn left updateReject">Update</button>
										</form>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}



			<div className="breadcrumbs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<Link to="/Dashboard"><i className="fa fa-home"></i></Link>
							<Link to="/reports/sales_report">Sales</Link>
							<Link to="/reports/sales_report">New sales</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="main-container">
				<div className="container-fluid">
					<div className="row">
						
							<div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left' }}>
								<div class="row mb-3 padd-0">
									<label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>From</b></label>
									<div class="col-sm-9">
										<input
											id="startDate"
											type="date"
											value={startDate}
											onChange={handleStartDateChange}
											className="form-control"
										/>
									</div>
								</div>
							</div>


							<div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left'}}>

								<div class="row mb-3 padd-0">
									<label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>To</b></label>
									<div class="col-sm-9">
										<input id="endDate"
											type="date"
											value={endDate}
											onChange={handleEndDateChange}
											className="form-control"
										/>
									</div>
								</div>
							</div>

							
							<div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
								<button type="button" onClick={searchFormData} className="acceptbtn" style={{ float: 'left' }} >Submit</button>
							</div>

							<div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'rigth' }} >
								<button onClick={handleSubmit} type="botton" className="acceptbtn" style={{ float: 'right', margin: '0 0 0 0' }} >Accept Order</button>
							</div>

      <div ref={changeCustmodalRef} className="modal fade" id="changeCustMdl" aria-hidden="true" tabIndex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">update customer</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="rejectorder-popup">
              <form>
                <h4>Sale Details</h4>
                <table class="table table-bordered resizable">
                  <tr>
                    <td>Order Id</td>
                    <td>{changeCustData.reference_no}</td>
                  </tr>
                  <tr>
                    <td>customer (A/C)</td>
                    <td>{changeCustData.customer}</td>
                  </tr>
                  
                </table>
                <div className="col-md-5 cp-lg-5 col-sm-5" > 
                <div class="form-group">
                	<label style={{marginBottom: '5px'}} ><b>Select Customer</b></label>
							    <Select
							      isClearable
							      onInputChange={handleInputChange}
							      onChange={handleChange}
							      options={customerOptions}
							      placeholder="Search..."
							      value={customerInputValue}
							    />                
                </div>
                </div>
                <button type="button" onClick={changeCustFormData} data-bs-dismiss="modal" class="updatebtn left"> Update</button>
              </form> 
              </div>
            </div>
          </div>
        </div>
      </div>
    
	    {/* <Footer /> */}

					</div>
					<div className="total-manifests-area dashboard" style={{ position: 'relative' }} >

						<div className="verical-scrl-wrapper common-table autoheight-table new-sales" id="style-3">
							<table className="table table-bordered resizable" id="neworderTable">
								<thead>
									<tr>

										<th>#</th>
										<th>Order Date</th>
										<th>Order Id</th>
										<th>Customers (A/C No)</th>
										<th>Post Code</th>
										<th>Route</th>
										<th>Order Amt</th>
										<th>Previous Dues</th>
										<th>Order By</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>



			<div ref={changeCustmodalRef} className="modal fade" id="changeCustMdl" aria-hidden="true" tabIndex="-1" role="dialog">
				<div class="modal-dialog modal-dialog-centered modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">update customer</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="rejectorder-popup">
								<form>
									<h4>Sale Details</h4>
									<table class="table table-bordered resizable">
										<tr>
											<td>Order Id</td>
											<td>{changeCustData.reference_no}</td>
										</tr>
										<tr>
											<td>customer (A/C)</td>
											<td>{changeCustData.customer}</td>
										</tr>

									</table>
									<div className="col-md-5 cp-lg-5 col-sm-5" >
										<div class="form-group">
											<label style={{ marginBottom: '5px' }} ><b>Select Customer</b></label>
											<Select
												isClearable
												onInputChange={handleInputChange}
												onChange={handleChange}
												options={customerOptions}
												placeholder="Search..."
												value={customerInputValue}
											/>


										</div>
									</div>
									<button type="button" onClick={changeCustFormData} data-bs-dismiss="modal" class="updatebtn left"> Update</button>

								</form>
							</div>
						</div>

					</div>
				</div>
			</div>


			<Footer />

			<FlashMessage
				message={flashMessage?.message}
				type={flashMessage?.type}
				onClose={handleCloseMessage}
			/>
		</div>
	);
}


export default NewRSales;
