
let API_URL, BASE_URL,REACT_APP_HOMEPAGE_URL,PREFIX;

const getTenantFromURL = () => 
{
  const url = window.location.hostname;
  const tenantId = url.split('.')[0];  
  return url;  
};


const currentURL = getTenantFromURL();

// console.log(currentURL);

if (currentURL === 'localhost') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'http://localhost:3000';
  REACT_APP_HOMEPAGE_URL='http://localhost:3000';
  PREFIX ='ayt';
} 
if (currentURL === 'ddsnacks.co.uk') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://ddsnacks.co.uk';
  REACT_APP_HOMEPAGE_URL='https://ddsnacks.co.uk';
  PREFIX ='dds';
}

if (currentURL === 'sales.ddsnacks.co.uk') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://sales.ddsnacks.co.uk';
  REACT_APP_HOMEPAGE_URL='https://sales.ddsnacks.co.uk';
  PREFIX ='dds';
}




if (currentURL === 'dan.rameegroup.co.in') 
{
  API_URL = 'https://testapi.rameegroup.co.in';
  BASE_URL = 'https://dan.rameegroup.co.in';
  REACT_APP_HOMEPAGE_URL='https://dan.rameegroup.co.in';
  PREFIX ='dan';
}


if (currentURL === 'karoon.rameegroup.co.in') 
{
  API_URL = 'https://testapi.rameegroup.co.in';
  BASE_URL = 'https://karoon.rameegroup.co.in';
  REACT_APP_HOMEPAGE_URL='https://karoon.rameegroup.co.in';
  PREFIX ='knm';
}

if (currentURL === 'tsdev.rameegroup.co.in') 
{
  API_URL = 'https://testapi.rameegroup.co.in';
  BASE_URL = 'https://tsdev.rameegroup.co.in';
  REACT_APP_HOMEPAGE_URL='https://tsdev.rameegroup.co.in';
  PREFIX ='tsc';
}

//production

if (currentURL === 'aytac.rameesystems.com')
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://aytac.rameesystems.com';
  REACT_APP_HOMEPAGE_URL='https://aytac.rameesystems.com';
  PREFIX ='ayt';
}

if (currentURL ==='kmfood.co.uk') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://kmfood.co.uk';
  REACT_APP_HOMEPAGE_URL='https://kmfood.co.uk';
  PREFIX ='knm';
}

if (currentURL === 'tsc.ebusso.com')
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://tsc.ebusso.com';
  REACT_APP_HOMEPAGE_URL='https://tsc.ebusso.com';
  PREFIX ='tss';
}

if (currentURL === 'dds.ebusso.com')
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://dds.ebusso.com';
  REACT_APP_HOMEPAGE_URL='https://dds.ebusso.com';
  PREFIX ='ddd';
}

if (currentURL === 'daninternational.rameesystems.com') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://daninternational.rameesystems.com';
  REACT_APP_HOMEPAGE_URL='https://daninternational.rameesystems.com';
  PREFIX ='dan';
}
if (currentURL === 'everest.rameesystems.com') 
{
  API_URL = 'https://api.rameesystems.com';
  BASE_URL = 'https://everest.rameesystems.com';
  REACT_APP_HOMEPAGE_URL='https://everest.rameesystems.com';
  PREFIX ='eve';
}

export { API_URL, BASE_URL,REACT_APP_HOMEPAGE_URL,PREFIX };


const Config = {

 API_URL : API_URL, 
 BASE_URL : BASE_URL,
 LOG_OUT : BASE_URL,
 PREFIX  : PREFIX,
};

  
export default Config;
  
 
