import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import { useParams } from 'react-router-dom';
import config from "../common/Config";
import Swal from 'sweetalert2';
import { id } from 'date-fns/locale';

const SuggestedPO = (props) => {
	const { orderId } = useParams();
	const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierFilteredOptions, setSupplierFilteredOptions] = useState([]);
    const [supplierValue, setSupplierValue] = useState('');
    const [supplierInputValue, setSupplierInputValue] = useState('');
    const[selectedSupplierValue, setSelectedSupplierValue] = useState('');
  	const [productOptions, setProductOptions] = useState([]);
    const [productFilteredOptions, setProductFilteredOptions] = useState([]);
    const [productValue, setProductValue] = useState(null);  
    const [productInputValue, setProductInputValue] = useState('');
    const accessToken = localStorage.getItem('accessToken'); 
    const [newProductList, setNewProductList] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [deletedProducts, setDeletedProducts] = useState([]);
    const [note, setNote] = useState(''); 
    const [netStdGoods, setNetStdGoods] = useState(0);
    const [netVatAmt, setNetVatAmt] = useState(0);
    const [netZeroGoods, setNetZeroGoods] = useState(0);
    const [netGrandTotal, setNetGrandTotal] = useState(0);
    const [netWeight, setNetWeight] = useState(0);
    const [grossWeight, setGrossWeight] = useState(0);

    useEffect(() => {
        let stdGoods = 0;
        let totalVat = 0;
        let zeroGoods = 0;
        let netWeightTotal = 0;
        let netGrossTotal = 0;
      
        const calculateTotals = () => {
          const newRows = tableRows.map((productData) => {
    
            if (parseFloat(productData.rate) > 0) {
              stdGoods +=   parseFloat(productData.subtotal);
            }
      
            if (parseFloat(productData.rate) === 0.00) {
              zeroGoods += parseFloat(productData.subtotal);
            }
      
            totalVat += parseFloat(productData.vat);
    
            netWeightTotal += (parseFloat(productData.net_weight || "0") * parseInt(productData.order_qty || "0"));
            netGrossTotal += (parseFloat(productData.gross_weight || "0") * parseInt(productData.order_qty || "0"));
    
          });
      
          setNetStdGoods(stdGoods);
          setNetVatAmt(totalVat);
          setNetZeroGoods(zeroGoods);
          
          const GrandTotal = stdGoods + totalVat + zeroGoods;
          setNetGrandTotal(GrandTotal);
          
          setNetWeight(netWeightTotal);
          setGrossWeight(netGrossTotal);
        };
      
          calculateTotals();
      }, [tableRows]); 


    const supplierHandleInputChange = (inputValue) => {
        setSupplierInputValue(inputValue);
        const newFilteredOptions = supplierOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSupplierFilteredOptions(newFilteredOptions);
    };

    const supplierHandleChange = (selectedOption) => {
        setSupplierValue(selectedOption);
    };

    const productHandleInputChange = (inputValue) => {
        setProductInputValue(inputValue);
   
        const newFilteredOptions = productOptions.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()) 
        );
   
        setProductFilteredOptions(newFilteredOptions);
    };
   

    const productHandleChange = (selectedOption) => {
        
        if (selectedOption ) {
            setProductValue(selectedOption);
            fetchAddProductInfo(selectedOption);

            //console.log(selectedOption);
        } else {
            setProductValue(null); 
        }
    };
    const formatNumber = (num) => {
	    return parseFloat(num).toFixed(2);
	};

	const fetchAddProductInfo = async (selectedOption) => {
        const formData = new FormData();
        formData.append('product_id', selectedOption.value);
        formData.append('supplier', supplierValue.value);
        const existingProduct = tableRows.find(item => item.code === selectedOption.code);
        if (existingProduct) {            
             Swal.fire({
              title: 'Error',
              text: 'Product already exists in the list.',
              icon: 'error',
              showConfirmButton: false, 
              timer: 2000,               
              timerProgressBar: true,     
            });
            return;
        }

        const apiUrl = `${config.API_URL}/purchases/get_order_datails`; 
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch new product');
            }

            const data = await response.json();
            const productData = data.data[0];
            const unitCost = productData.split === '0' || productData.split === '2'
                ? parseFloat(productData.price)
                : parseFloat(productData.piece_cost);

            const onOrder = data.on_order_data[0]?.on_order_data || 0;
            const sales_avg_quantity = data.averageData[0].sales_avg_quantity;
            const number = parseFloat(sales_avg_quantity);
            const adjustedValue = (number % 1 >= 0.5) ? Math.ceil(number) : Math.floor(number);
            const stockCount = productData.split == '0' || productData.split == '2' ? parseInt(productData.quantity) : productData.split_quantity == null ? 0 : productData.split_quantity
            const deficit  = stockCount - parseInt(adjustedValue) - parseInt(onOrder);
            const net_weight = productData.net_weight === null ? 0 : productData.net_weight;
            const gross_weight = productData.gross_weight === null ? 0 : productData.gross_weight;

            const newRow = {
                id: productData.id,
                product_id: productData.id,
                code: productData.code,
                pack: productData.pack,
                price: productData.price,
                piece_cost: productData.piece_cost,
                split: productData.split,
                type :  productData.split === '0' || productData.split === '2' ? 'box' : 'piece',
                split_quantity: productData.split_quantity,
                split_price: productData.split_price,
                name: productData.name,
                size: productData.size,
                cost: productData.cost,
                rate: productData.rate,
                net_weight: net_weight,
                gross_weight: gross_weight,
                stockCount: stockCount,
                pdtcost : unitCost.toFixed(2),
                order_qty: 1,
                subtotal: (unitCost * 1).toFixed(2),
                vat: ((unitCost * 1) * formatNumber(productData.rate) / 100).toFixed(2),
                total: ((unitCost * 1) + ((unitCost * 1) * formatNumber(productData.rate) / 100)).toFixed(2),
                current_stock : productData.quantity,
                estimate : parseInt(productData.sales_quantity),
                product_id : productData.id,
                onOrder : onOrder,
                deficit : deficit
            };

            setTableRows((prevRows) => [...prevRows, newRow]);

        } catch (error) {
            console.error('API fetch error:', error);
        }
    };

	const fetchSupplierList = async () => {
  		const apiSalesRouteUrl = `${config.API_URL}/purchases/getSupplier`;
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization : `Bearer ${accessToken}`,
  				}
  			});
  			const data = await response.json();
  			const formattedOptions = data.supplier.map(item => ({
                label: item.company,  
          		value: item.id,    
            }));
            setSupplierOptions(formattedOptions);
            setSupplierFilteredOptions(formattedOptions);

  		}catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};

  	const fetchProductList = async () => {
        const apiSalesRouteUrl = `${config.API_URL}/purchases/getProducts`;
        try {
            const response = await fetch(apiSalesRouteUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            });

            const data = await response.json();
           // console.log(data);
                const formattedOptions = data.products.map(item => ({
                    label: item.name + '(' + item.code + ')',  // Concatenated name and code for the label
                    value: item.id,
                    code: item.code
            }));
            setProductOptions(formattedOptions);
       
            setProductFilteredOptions(formattedOptions);

        } catch (error) {
            console.error('api fail error fetching products');
        }
    };

    const fetchPurchesItemsList = async () => {
        
  		const formData = new FormData();
  		// formData.append('id', orderId);
        const apiSalesRouteUrl = `${config.API_URL}/purchases/get_suggested_po_item`; 
  		try {
  			const response = await fetch(apiSalesRouteUrl, {
  				method : 'POST',
  				headers : {
  					authorization : `Bearer ${accessToken}`,
  				},
  				body: formData,
  			});
  			const data = await response.json();
  			const productData = data.purchase;
            const onOrderData = data.onOrderData;

  			const pdtRows = productData.map((item) => {
	            // Determine unit cost
	            const unitCost = item.split === '0' || item.split === '2'
	                ? parseFloat(item.cost)
	                : parseFloat(item.piece_cost);

                const onOrder = onOrderData.find((i)=> i.id  === item.id);
                const stockCount =  item.split == '0' || item.split == '2' ? parseInt(item.current_stock) : parseInt(item.split_quantity);
                const net_weight = item.net_weight === null ? 0 : item.net_weight;
                const gross_weight = item.gross_weight === null ? 0 : item.gross_weight;
                const qty = 1;
                return {
                    id:item.id,
	                code: item.code,
                    product_id:item.id,
	                price: item.price,
                    pack: item.pack,
	                piece_cost: item.piece_cost,
	                split: item.split,
	                type: item.split === '0' || item.split === '2' ? 'box' : 'piece',
                    item_type: item.type === '1' ? 'Box' : 'Piece',
                    split_quantity: item.split_quantity,
	                split_price: item.split_price,
	                name: item.name,
	                size: item.size,
	                cost: item.cost,
	                rate: item.rate,
                    net_weight: net_weight,
                    gross_weight: gross_weight,
	                stockCount: stockCount,
	                pdtcost: unitCost.toFixed(2),
	                order_qty:  qty,
	                subtotal: (unitCost * parseInt(qty)),
	                vat: ((unitCost * parseInt(qty)) * (parseFloat(item.rate) / 100)).toFixed(2),
	                total: ((unitCost * parseInt(qty)) + ((unitCost * parseInt(qty)) * (parseFloat(item.rate) / 100))).toFixed(2),
                    estimate : parseInt(item.sales_quantity),
                    onOrder : parseInt(onOrder.on_order_data),
                    deficit : stockCount - parseInt(item.sales_quantity) - parseInt(onOrder.on_order_data)
	            };
	        });
        	setTableRows(pdtRows);
  		} catch(error){
  			console.error('api fail error fetching sales routes');
  		}
  	};

  	useEffect(() => {

  		if (accessToken === null){
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }

        fetchSupplierList();
        fetchProductList();
        fetchPurchesItemsList();
  	},[]);

      useEffect(() => {
      console.log(tableRows);
    },[tableRows]);

    useEffect(() => {
        if (selectedSupplierValue) {
            const selectedCategory = supplierOptions.find(option => option.value == selectedSupplierValue);
            setSupplierValue(selectedCategory || null);
        }
    }, [selectedSupplierValue, supplierOptions]);

  	const handleQuantityChange = (id, newQty) => {
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const order_qty = parseFloat(newQty) || 0;
                    const unitCost = parseFloat(row.pdtcost) || 0;
                    const rate = parseFloat(row.rate) || 0;
                    const subtotal = (order_qty * unitCost).toFixed(2);
                    const vat = ((order_qty * unitCost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
                    return {
                        ...row,
                        order_qty,
                        subtotal,
                        vat,
                        total
                    };
                }
                return row;
            });
        });
    };

    const handleCostChange = (id, newCost) => {
        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                    const pdtcost = parseFloat(newCost) || 0;
                    const order_qty = parseFloat(row.order_qty) || 0;
                    const rate = parseFloat(row.rate) || 0;

                    const subtotal = (order_qty * pdtcost).toFixed(2);
                    const vat = ((order_qty * pdtcost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

                    return {
                        ...row,
                        pdtcost,
                        subtotal,
                        vat,
                        total
                    };
                }
                return row;
            });
        });
    };

    const handleOptionChange = (id, pdtType) => {

        setTableRows(prevRows => {
            return prevRows.map(row => {
                if (row.id === id) {
                  
                    const pdtcost = pdtType === 'Box' ? row.price : row.piece_cost;
                    const order_qty = parseFloat(row.order_qty) || 0;
                    const rate = parseFloat(row.rate) || 0;
                    const subtotal = (order_qty * pdtcost).toFixed(2);
                    const vat = ((order_qty * pdtcost) * rate / 100).toFixed(2);
                    const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
                    const type = pdtType === 'Box' ? 'box' : 'piece';
                    const item_type = pdtType === 'Box' ? 'Box' : 'Piece';
                    const stockCount = pdtType == 'Box' ? parseInt(row.current_stock || 0) : parseInt(row.split_quantity !== null ? row.split_quantity : 0);
                    const deficit  = parseInt(stockCount) - parseInt(row.estimate) - parseInt(row.onOrder);
                    // console.log(stockCount);
                    // console.log(row.sales_quantity);
                    // console.log(row.onOrder);

                    // alert(deficit);
                    // console.log(typeof(deficit));
                    return {
                        ...row,
                        type,
                        pdtcost,
                        subtotal,
                        vat,
                        item_type,
                        total,
                        stockCount,
                        deficit
                    };
                }
                return row;
            });
        });
    };

    const handleCancel = (product_id) => {
        setTableRows(prevRows => prevRows.filter(row => row.product_id !== product_id));
        setDeletedProducts(prevProducts => [...prevProducts, product_id]);
    };

    const handleChange = (event) => {
        setNote(event.target.value);
    };

    const submitFormData = async () => {
        if (!supplierValue.value) {
          Swal.fire({
            title: "Error!",
            text: "Please select a supplier.",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }
    
        if (tableRows.length === 0) {
          Swal.fire({
            title: "Error!",
            text: "Please add at least one product.",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
          return;
        }
    
        const filteredTableRows = tableRows.map((row) => ({
          code: row.code,
          name: row.name,
          order_qty: row.order_qty,
          subtotal: row.subtotal,
          vat: row.vat,
          total: row.total,
          type: row.type,
        }));
    
        const form = document.getElementById("suggetsedProductForm");
        const formData = new FormData(form);
        formData.append("supplier", supplierValue.value);
        formData.append("note", note);
        formData.append("warehouse", "1");
    
        const apiSalesRapUrl = `${config.API_URL}/purchases/purchasesAdd`;
        try {
          const response = await fetch(apiSalesRapUrl, {
            method: "POST",
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          });
    
          const data = await response.json();
          console.log(data);
          if (data.success == true) {
            Swal.fire({
              title: "Success!",
              text: "Purchase Added successfully.",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            }).then(() => {
              window.location.href = `${config.BASE_URL}/purchases/purchases_order/`;
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: "Purchase  Failed",
              icon: "error",
              timer: 1500,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          console.error("Error fetching api:", error);
        }
    
        setTableRows([]);
        setSupplierValue("");
        setNote("");
      };





	return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/mis_report">Purchases</Link> 
		                  <Link to="/reports/mis_report">Suggested Purchase Order </Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			    	<div className="row">
			    		<div className="col-md-9 col-lg-9 col-sm-12">
			    			<div className="col-md-4 col-lg-4 col-sm-4" style={{float: 'left',  padding: '17px 0px 0px 0px'}}>
				                <Select
						            isClearable
						            onInputChange={supplierHandleInputChange}
						            onChange={supplierHandleChange}
						            options={supplierFilteredOptions}
						            placeholder="Select Supplier"
						            value={supplierValue}
						        />
							</div>
							<div className="col-md-4 col-lg-4 col-sm-4" style={{float: 'left',  padding: '17px 0px 0px 15px'}}>
				                <Select
						            isClearable
						            onInputChange={productHandleInputChange}
						            onChange={productHandleChange}
						            options={productFilteredOptions}  // Pass the filtered options here
						            placeholder="Select Product"
						            value={productValue}
						        />
							</div>
			    		</div>
			    	</div>

			    	<div className="total-manifests-area dashboard" style={{position : 'relative'}} >
	                    <p><b>Order Items </b></p>
                        <form id="suggetsedProductForm">
	                    <div className="verical-scrl-wrapper common-table autoheight-table edit-purchase-order" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="newProductTable">
                                <thead>
                                    <tr>
                                        <th>Code-Name-Size</th>
                                        <th>PK</th>
                                        <th>Current Stock</th>
                                        <th>On Order <a href="#" class="info" title="PO Qty with Draft or Partial status" data-toggle="popover" data-placement="top" data-content="Content"><i class="fa fa-info-circle"></i></a></th>
                                        <th>Estimated <a href="#" class="info" title="Avg 2 month sell" data-toggle="popover" data-placement="top" data-content="Content"><i class="fa fa-info-circle"></i></a></th>
                                        <th>Deficit <a href="#" class="info" title="Current stock - On order Stock - Estimate" data-toggle="popover" data-placement="top" data-content="Content"><i class="fa fa-info-circle"></i></a></th>
                                        <th>Order QTY</th>
                                        <th>Type</th>
                                        <th>Cost Price</th>
                                        <th>VAT</th>
                                        <th>Sub Total</th>
                                        <th>Net P</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows.length === 0 ? (
                                        <tr style={{ textAlign: "center" }}>
                                        </tr>
                                    ) : (
                                        tableRows.map(row => (
                                            <tr key={row.id}>
                                                <td>{row.code} - {row.name} - {row.size}</td>
                                                <td style={{width: '80px'}} className="text-center">{row.pack}</td>
                                                <input type="hidden" value={row.product_id} name="product_id[]" />
                                                <input type="hidden" value={row.code} name="code[]" />
                                                <input type="hidden" value={row.name} name="name[]" />
                                                <td style={{width: '80px'}} className="text-center">{row.stockCount}</td>
                                                <td style={{width: '80px'}} className="text-center">{row.onOrder}</td>
                                                <td style={{width: '80px'}} className="text-center">{row.estimate}</td>
                                                <td style={{width: '80px'}} className="text-center">{row.deficit}</td>
                                                <td style={{width: '80px'}}>
                                                    <input
                                                        type="number"
                                                        value={row.order_qty}
                                                        name="order_qty[]"
                                                        autoComplete='off'
                                                        min="0"
                                                        onChange={(e) => handleQuantityChange(row.id, e.target.value)}
                                                        className="form-control order-qty no-arrows "
                                                        style={{width: '75px', textAlign : 'center'}}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {row.split == 2 ? ( 
                                                        <select
                                                           value={row.item_type}
                                                           onChange={(e) => handleOptionChange(row.id, e.target.value)}
                                                           className="form-control small-select"
                                                           name="type[]"
                                                        > 
                                                            <option value="Box">Box</option>
                                                            <option value="Piece">Piece</option>
                                                        </select>
                                                    ) : (
                                                        <>
                                                          <input
                                                            type="hidden"
                                                            value={row.split == 0 ? 'Box' : 'Piece'}
                                                            name="type[]"
                                                          />
                                                          {row.split == 0 ? 'Box' : 'Piece'}
                                                        </>
                                                    )}
                                                </td>
                                                <td style={{width: '80px'}}>
                                                    <input
                                                        type="number"
                                                        value={row.pdtcost}
                                                        min="0"
                                                        autoComplete='off'
                                                        name="cost[]"
                                                        onChange={(e) => handleCostChange(row.id, e.target.value)}
                                                        className="form-control order-cost no-arrows"
                                                        style={{width: '75px', textAlign : 'right'}}
                                                    />
                                                </td>
                                                 <td className="text-center">
                                                    {row.vat}
                                                </td>
                                                <td style={{width: '60px'}} className="text-end">
                                                    {row.subtotal}
                                                </td>
                                                 <td style={{width: '60px'}} className="text-end">
                                                    {row.total}
                                                </td>
                                                <input type="hidden" name="subtotal[]" value={row.subtotal} />
                                                     <input type="hidden" name="vat[]" value={row.vat} />
                                                      <input type="hidden" name="total[]" value={row.total} />
                                                <td style={{width : '3%'}} className="text-center">
                                                    <a onClick={() => handleCancel(row.product_id)} className="icon editbtn">
                                                        <i className="fa fa-trash-o"></i>
                                                    </a>                                                    
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
	                    </div>
                        </form>

                        <div className="purchaseftr">
                            <div className="row">
                            <div className="col-6 col-lg-3 col-sm-3">
                                <p className="frtbx">
                                    {" "}
                                    Net Weight :{" "}
                                    <span id="netWeight">{netWeight.toFixed(2)}</span>
                                </p>
                                </div>
                                <div className="col-6 col-lg-3 col-sm-3">
                                <p className="frtbx">
                                    {" "}
                                    Gross Weight :{" "}
                                    <span id="grossWeight">{grossWeight.toFixed(2)}</span>
                                </p>
                                </div>
                                <div className="col-6 col-lg-3 col-sm-3">
                                <p className="frtbx">
                                    {" "}
                                    Std Goods :{" "}
                                    <span id="std_goods">{netStdGoods.toFixed(2)}</span>
                                </p>
                                </div>
                                <div className="col-6 col-lg-3 col-sm-3">
                                <p className="frtbx">
                                    {" "}
                                    VAT Amount :{" "}
                                    <span id="vat_amt">{netVatAmt.toFixed(2)}</span>
                                </p>
                                </div>
                                <div className="col-6 col-lg-3 col-sm-3">
                                <p className="frtbx">
                                    {" "}
                                    Zero Goods :{" "}
                                    <span id="zero_goods">{netZeroGoods.toFixed(2)}</span>
                                </p>
                                </div>
                                <div className="col-6 col-lg-3 col-sm-3">
                                <p className="frtbx last">
                                    {" "}
                                    Gross Total :{" "}
                                    <span id="grand_total">{netGrandTotal.toFixed(2)}</span>
                                </p>
                                </div>
                            </div>
                            </div>

                        <div className="row">
                            <div className="col-12">
                                <label><b>Note</b></label>
                                <textarea
                                    name="note"
                                    cols="40"
                                    rows="10"
                                    className="form-control"
                                    id="ponote"
                                    style={{ marginTop: '10px', height: '100px' }}
                                    value={note}        
                                    onChange={handleChange} 
                                />
                            </div>

                            <div className="col-12" style={{paddingTop: '20px'}}>
                                <button onClick={submitFormData} type="button" className="acceptbtn">Place Order</button>                             
                            </div>
                        </div>
	                </div>
			    </div>
			</div>
	      	<Footer />

		</>
	);
}
export default SuggestedPO;
