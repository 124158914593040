import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";

const PicklistGenerated = () => {
  const accessToken = localStorage.getItem("accessToken");
  const TableRef = useRef(null);

  var s = localStorage.getItem('startDate');
  var e = localStorage.getItem('endDate');

  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/react/login";
      return;
    }

    initializeTable();
  }, []);


    const formatDate = (date) => {
    if (!date) return "0";
    
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const getCurrentMonthDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = now.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const startDateFormatted = getCurrentMonthDate();
  // alert(startDateFormatted);

  const initializeTable = () => {
    const $table = $(TableRef.current);
    if ($.fn.DataTable.isDataTable($table)) {
        $table.DataTable().destroy();
    }
  
    let i = 0;
    $table.DataTable({
      processing: true,
      serverSide: true,
      ajax: {
          url: `${config.API_URL}/picking/list`,
          type: 'POST',
          headers: {
             authorization : `Bearer ${accessToken}`,
          },
          data: function (d) {
              return $.extend({}, d, {
                  draw : d.draw,
                  start : d.start,
                  length : d.length,
                  today : '1',
              });
          },
          dataSrc: function (json) {
            return Array.isArray(json.data) ? json.data : [];
          }
      },
      language: {
          paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
          },
          search: 'Search:',
      },
      columns: [      
        {
            data: null, 
            render: function(data, type, row, meta) {
              return meta.row + 1;
            },
            createdCell: function(td) {
              $(td).css("text-align", "center"); 
            }
          },
          {
            data: "picklist_number", 
            render: function(data, type, row) {
              return `<a href='${config.BASE_URL}/sales/view_picking_list/${row.picklist_number}' > ${row.picklist_number} </a>`;
            },
            createdCell: function (td) {
              $(td).css("text-align", "center");
            }
          },
        { data: "i",
            createdCell: function (td) {
              $(td).css("text-align", "center");
            } },
        { data: "route_name",
            createdCell: function (td) {
              $(td).css("text-align", "center");
            } },
        { data: "name"
         },
         {
            data: "print_on",
            createdCell: function (td) {
              $(td).css("text-align", "center");
            }
        },          
        { data: "picking_status",
          createdCell: function (td) {
            $(td).css("text-align", "center");
          }
         },
      ],
      columnDefs: [
        {
          orderable: false, 
          targets: '_all'
        },
      ],
    
      pageLength: 10,
    });
  };




  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">Picking List</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
      <div className="dashdate-area">
            From: {s ? formatDate(s) : startDateFormatted} &nbsp;
            To: {e ? formatDate(e) : startDateFormatted}
          </div>
        <div className="container-fluid">
          <div  className="total-manifests-area dashboard"  style={{ position: "relative" }} >
            <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3" >
              <table className="table table-bordered resizable" id="neworderTable" ref={TableRef}>
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>#</th>
                    <th style={{textAlign:'center'}}>Picklist No</th>
                    <th style={{textAlign:'center'}}>Invoices</th>
                    <th style={{textAlign:'center'}}>Route Name</th>
                    <th style={{textAlign:'center'}}>Picker</th>
                    <th style={{textAlign:'center'}}>Picklist Date</th>
                    <th style={{textAlign:'center'}}>Picking Status</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PicklistGenerated;
