import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { API_URL } from '../Config';
import config from "../common/Config";
import Swal from 'sweetalert2';

const ReturnList = () => {
  

  const returnListRef = useRef(null);
  const accessToken = localStorage.getItem('accessToken');
  const [searchValue, setSearchValue] = useState([]);



  const getReturnList = () => {
  const $table = $(returnListRef.current);
  const searchValue = $('#searchValue').val();

  // Destroy any existing DataTable instance to reinitialize with new data
  if ($.fn.DataTable.isDataTable($table)) {
    $table.DataTable().destroy();
  }

  // Initialize the DataTable with the new date range
  $table.DataTable({
    processing: true,
    serverSide: true,
    ajax: {
      url: `${config.API_URL}/return/returnList`,
      type: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      data: function (d) {
        return $.extend({}, d, {
          draw: d.draw,
          start: d.start,
          length: d.length,
          searchValue: searchValue,
        });
      },
      dataSrc: function (json) {
        if (json && json.data && Array.isArray(json.data)) {
          return json.data;
        }
        return [];
      },
    },
    language: {
      paginate: {
        first: 'First',
        last: 'Last',
        next: 'Next',
        previous: 'Previous',
      },
      search: 'Search:',
    },
    columns: [
      {
        data: null,
        render: function (data, type, row) {
          return `<span>${row.date}</span>`;
        },
      },
      {
        data: null,
        render: (data, type, row) => {
          return `<a href="${config.BASE_URL}/returns/view/${row.id}">${row.reference_no}</a>`;
        },
      },
      {
        data: 'company',
        render: function (data, type, row) {
          return `<span>${row.company}</span>`;
        },
      },
      { data: 'grand_total' },
      { data: 'total_items' },
      { data: 'return_by' },
      { data: 'status' },
      {
        data: null,
        render: function (data, type, row) {
          const rstatus = row.status;
          if (rstatus === 'Pending') {
           {/*	 return `
              <div class="btn-group">
                <button type="button" class="action-btn-dd dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                <ul class="dropdown-menu dropdown-menu-arrow">
                  <li><a href="javascript:void(0);" class="deductionbtn" data-id="${row.id}" data-action="accept">Accept Return</a></li>
                  <li><a href="javascript:void(0);" class="applybtn" data-id="${row.id}" data-action="reject">Reject Return</a></li>
                </ul>
              </div>`;*/}

              return `<div>
           <a href="javascript:void(0);" class="deductionbtn" data-id="${row.id}" data-action="accept" title="Accept Return"><i class="bi bi-check-square-fill"></i></a>
<a href="javascript:void(0);" class="applybtn" data-id="${row.id}" data-action="reject" title="Reject Return"><i class="bi bi-sign-turn-right-fill"></i></a>

              </div>`;

          }
          return '';
        },
      },
    ],
    columnDefs: [
      {
        orderable: false,
        targets: '_all',
      },
    ],
    pageLength: 10,
    // After the table is created, bind event listeners for the action buttons
    drawCallback: function () {
     $(document).off('click', '.deductionbtn').on('click', '.deductionbtn', (e) => {
 
  const target = $(e.currentTarget); 
  const id = target.data('id');
  const action = target.data('action');
  handelAcceptReturnList(e, id, action);
});

$(document).off('click', '.applybtn').on('click', '.applybtn', (e) => {
  const target = $(e.currentTarget);
  const id = target.data('id');
  const action = target.data('action');
  handelAcceptReturnList(e, id, action);
});
    }
  });
};

// Updated method to handle accept/reject logic
const handelAcceptReturnList = async (event, id, action) => {
  const urlRejectLink = `${config.API_URL}/add_reject_return`;
  const urlAcceptLink = `${config.API_URL}/add_accept_return`;
  // alert(id);
  const confirmMessage = action === 'accept' 
    ? 'Are you sure you want to create the Credit Note?' 
    : 'Are you sure you want to reject this order?';

  const result = await Swal.fire({
    title: 'Are you sure?',
    text: confirmMessage,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  });

  if (result.isConfirmed) {
    await sendApiRequest(action === 'accept' ? urlAcceptLink : urlRejectLink, id, action === 'accept' ? 'yes' : 'no');
  }
};

const sendApiRequest = async (url, id, allocation = null) => {
  const formData = new FormData();
  formData.append('id', id);
  if (allocation) {
    formData.append('allocation', allocation);
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    const data = await response.json();
    if (data) {
      Swal.fire({
        title: "Success!",
        text: "Action completed successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      }).then(() => {
        window.location.href = `${config.BASE_URL}/return_list`;
      });
    } else {
      Swal.fire({
        title: "Failed!",
        text: data.message || "An error occurred.",
        icon: "error",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  } catch (error) {
    console.error('API request failed:', error);
  }
};


  useEffect(() => {
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    getReturnList();

    return () => {
      if (returnListRef.current && $.fn.DataTable.isDataTable(returnListRef.current)) {
        $(returnListRef.current).DataTable().destroy();
      }
    };
  }, [searchValue]);


  return (
    <div>
      <Header />
      <div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="#">Return</Link> 
		                  <Link to="#">Return List</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12"> 
            
                <div>
                  <div style={{ float: 'right' }}> 
                   <a href={`${config.BASE_URL}/return/add_return`} className="create-catalogue-btn">Add Return</a>
                  </div>
                  <div className="show-order-area dashboard">
                  <div className="verical-scrl-wrapper returnlist-table" id="style-3">
                    <table className="table table-bordered dataTable resizable" id="returnListTable" ref={returnListRef}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Return No</th>
                          <th>Customer (Ac/No)</th>
                          <th>Grand Total</th>
                          <th>Total Items</th>
                          <th>Return By</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReturnList;
