import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import Footer from "../Footer";
import FlashMessage from "../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../common/Config";
import { DateRangePicker } from "rsuite";
import { format } from "date-fns";

const NewSales = () => {
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [newSales, setNewSales] = useState([]);
  const [dates, setDates] = useState([null, null]);
  const accessToken = localStorage.getItem("accessToken");
  const TableRef = useRef(null);

  const getCurrentMonthDates = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // 0-indexed month (January is 0)
    
    // First day of the current month
    const startOfMonth = new Date(year, month, 1);
    const startDay = String(startOfMonth.getDate()).padStart(2, "0");
    const startMonth = String(startOfMonth.getMonth() + 1).padStart(2, "0");
    
    // Last day of the current month
    const endOfMonth = new Date(year, month + 1, 0); // 0 will give the last day of the previous month
    const endDay = String(endOfMonth.getDate()).padStart(2, "0");
    const endMonth = String(endOfMonth.getMonth() + 1).padStart(2, "0");
    
    // Format both start and end dates as dd-mm-yyyy
    const startDateFormatted = `${year}-${startMonth}-${startDay}`;
    const endDateFormatted = `${year}-${endMonth}-${endDay}`;
    
    return { startDateFormatted, endDateFormatted };
  };

  
  
  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/react/login";
      return;
    }

    
    

    //alert(startDateFormatted);
    
   

    

    
      initializeTable();
    

    
  }, [ ]);

  var s = localStorage.getItem('startDate');
  var e = localStorage.getItem('endDate');
 

  const handleSubmit = () => {
    // alert(s);
    // alert(e);

    initializeTable(); 
  };

  const handleDateChange = (value) => {
    setDates(value);
    if (value && value[0] && value[1]) {
      // const formattedStartDate = value[0] ? format(value[0], "MM/dd/yyyy") : "";
      // const formattedEndDate = value[1] ? format(value[1], "MM/dd/yyyy") : "";
    // const reportrange = (formattedStartDate && formattedEndDate) ? `${formattedStartDate} - ${formattedEndDate}` : "";
      s = value[0] ? format(value[0], 'yyyy-MM-dd') : '';
      e = value[1] ? format(value[1], 'yyyy-MM-dd') : '';  
      // s = formattedStartDate;
      // e = formattedEndDate;
      //alert(s);
      //alert(e);

      setStartDate(s);
      setEndDate(e);
      }
    };

    const initializeTable = () => {
      const $table = $(TableRef.current);
      if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
      }
    
      let i = 0;
      $table.DataTable({
        processing: true,
        serverSide: true,
        ajax: {
            url: `${config.API_URL}/get_new_sales`,
            type: 'POST',
            headers: {
               authorization : `Bearer ${accessToken}`,
            },
            data: function (d) {
                return $.extend({}, d, {
                    draw : d.draw,
                    start : d.start,
                    length : d.length,
                    start_date : startDate ? startDate : startDateFormatted,
                    end_date : endDate ? endDate : endDateFormatted,
                });
            },
            dataSrc: function (json) {
              return Array.isArray(json.data) ? json.data : [];
            }
        },
        language: {
            paginate: {
                first: 'First',
                last: 'Last',
                next: 'Next',
                previous: 'Previous',
            },
            search: 'Search:',
        },
        columns: [
          { data: "date",
            render: function(data, type, row){
              const [year, month, day] = row.date.split("-");
              const formattedDate = `${day}-${month}-${year}`;  
              return formattedDate;
            },
            createdCell: function (td) {
              $(td).css("text-align", "center");
            }
           },
           {
            data: "reference_no",
            render: function (data, type, row) 
            {
              return (
                `
                  ${row.reference_no}
                `
              );
            },
            createdCell: function (td) 
            {
              $(td).css("text-align", "center");
            }
          },        
          { data: "customer" },
          { data: "postal_code",
            createdCell: function (td) {
              $(td).css("text-align", "center");
            } },
          { data: "grand_total" },
          { data: "spc" },
          { data: "prev_due" },
          { data: "route_number",
            createdCell: function (td) {
              $(td).css("text-align", "center");
            }
          },
          { data: "name" },
        ],
        columnDefs: [
          {
            orderable: false, 
            targets: '_all'
          },
        ],
      
        pageLength: 10,
      });
    };

  const formatDate = (date) => {
    if (!date) return "0";
    
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  // Function to get the first and last dates of the current month
  

  const { startDateFormatted, endDateFormatted } = getCurrentMonthDates();

  return (
    <div>
      <Header />
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <Link to="/Dashboard">
                <i className="fa fa-home"></i>
              </Link>
              <Link to="#">Sales</Link>
              <Link to="#">New Sales</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container-fluid">
                           <DateRangePicker
                                value={dates}
                                
                                onChange={handleDateChange}
                                format="dd/MM/yyyy" // Ensure the calendar uses dd/MM/yyyy format
                                character=" - "
                                className="datepkr"
                              />
                            <button
                              type="button"
                              id="submit"
                              className="multisubmit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          
                            <div className="dashdate-area" style={{float:'right', marginTop: '10px'}}>
                              From: {s ? formatDate(s) : startDateFormatted} &nbsp;
                              To: {e ? formatDate(e) : endDateFormatted}
                            </div>
          <div
            className="total-manifests-area dashboard"
            style={{ position: "relative" }}
          >
            <div
              className="verical-scrl-wrapper common-table autoheight-table"
              id="style-3"
            >
              <table className="table table-bordered resizable" id="neworderTable" ref={TableRef}>
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Order Date</th>
                    <th style={{textAlign:'center'}}>Order Id</th>
                    <th style={{textAlign:'center'}}>Customers (A/C No)</th>
                    <th style={{textAlign:'center'}}>Post Code</th>
                    <th style={{textAlign:'center'}}>Order Amt</th>
                    <th style={{textAlign:'center'}}>Rep Cash</th>
                    <th style={{textAlign:'center'}}>Previous Dues</th>
                    <th style={{textAlign:'center'}}>Route</th>
                    <th style={{textAlign:'center'}}>Order By</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewSales;
