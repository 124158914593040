// src/components/CustomDateRangePicker.js
import React from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { format } from 'date-fns';

const CustomDateRangePicker = ({ dates, onChange, onSubmit }) => {
  const handleDateChange = (value) => {
    onChange(value);
  };

  return (
    <div className="row" style={{ marginBottom: '15px' }}>
      <div className="col-md-9 col-lg-9 col-sm-12">
        <div className="col-md-6 col-lg-6 col-sm-6" style={{ float: 'left', marginRight: '15px' }}>
          <DateRangePicker
            value={dates}
            onChange={handleDateChange}
            format="dd/MM/yyyy"
            character=" - "
            style={{ width: '100%' }}
          />
        </div>
        <div className="col-md-3 col-lg-3 col-sm-3" style={{ float: 'left' }}>
          <button type="button" onClick={onSubmit} className="multisubmit">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default CustomDateRangePicker;
