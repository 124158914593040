import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../Header";
import About from "../About";
import Footer from "../Footer";
import FinishPay from "../sales/Finish-Pay";
import Calculator from "./Calculator";
import IncompleteOrder from "../sales/Incomplete_Order";
import BarcodeScanner from "../sales/BarcodeScannerComponent";
import SaveExit from "../sales/Save_Exit";
import OrderReceipt from "../sales/Order_Receipt_sticker";
import ProformaInvoice from "../sales/Proforma_Invoice";
import OpeningBalance from "../sales/Opening_Balance";
import CustomerLedger from "../sales/Customer_Ledger";
import DayClose from "../sales/Day_Close";
import { API_URL } from "../Config";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import Swal from "sweetalert2";
import config from "../common/Config";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from 'lodash';

import {
  fetchProductList,
  handleEposProductAdd,
  fetchEposSearchCustomer,
} from "../../services/EposServices";

const Epos = () => {
  const navigateToEpos = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [customeId, setCustomeId] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [customerDetails, setCustomerDetails] = useState([]);
  const [companyAddress, setCompanyAddress] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  let parsedCreditType = [];
  const barcode=0;

  const [productData, setProductData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [incompleteOrder, setIncompleteOrder] = useState([]);
  const [finishToPay, setFinishToPay] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("userId");
  const tillUserId = localStorage.getItem("tillUserId");
  const tillUserName = localStorage.getItem("tillUserName");
  const [debtProfile, setdebtProfile] = useState([]);
  const [customerinfo, setcustomerInfo] = useState([]);
  const [customerLedgerData, setcustomerLedgerData] = useState([]);
  const [custData, setCustData] = useState([]);
  const [totalVal, setTotalVal] = useState({
    totalGoods: 0,
    totalGross: 0,
    totalzeroGoods: 0,
    totalTax: 0,
    orderDiscount: 0,
    grandTotal: 0,
    boxTotal: 0,
    pieceTotal: 0,
  });
  const [discountQty, setDiscountQty] = useState(0.0);
  const [salesId, setSalesId] = useState("");
  const [salesStatus, setSalesStatus] = useState("New");
  const [salesRefNo, setSalesRefNo] = useState(0);
  const [referenceNo, setreferenceNo] = useState(0);

  useEffect(() => {
    // alert(tillUserId);
    // alert(tillUserName);
    console.log(customerLedgerData);
  }, [customerLedgerData]);

  /*const handleCancel = (id) => {
    setTableRows(prevRows => prevRows.filter(row => row.id !== id));
  };*/


  const handleCancel = async (id, promosId, promosType) => {
  // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to Delete this item?',  
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    reverseButtons: true, // Reverses the order of buttons
  });

  // If user clicks "Yes", proceed with canceling the item
    if (result.isConfirmed) {
      setTableRows((prevRows) => prevRows.filter((row) => row.id !== id));

      try {
        const updatedRows = [...tableRows];
        const currQty = 0;

        let totalOrderQty;
        if (promosType === "combo") {
          totalOrderQty = tableRows.reduce((sum, row) => {
            if (row.promosId === promosId && row.promosType === "combo") {
              if (row.id === id) {
                return sum + currQty;
              } else {
                return sum + row.order_qty;
              }
            } else {
              return sum;
            }
          }, 0);
        } else {
          totalOrderQty = 0;
        }

        for (let row of updatedRows) {
          if (row.id === id) {
            const order_type = row.split === "0" || row.split === "2" ? "box" : "piece";
            const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;
            const secondConfig = {
              headers: { authorization: `Bearer ${accessToken}` },
            };

            const secondFormData = new FormData();
            secondFormData.append("orderId", 0);
            secondFormData.append("productId", id);
            secondFormData.append("order_type", order_type);
            secondFormData.append("qty", totalOrderQty);

            const secondResponse = await axios.post(
              secondApiUrl,
              secondFormData,
              secondConfig
              );
            const secondData = secondResponse.data.item;

            console.log("Second API response:", secondData);

            if (secondData && secondData.is_promoted === "1") {
              const promotionUnitPrice = parseFloat(secondData.unit_price) || 0;
              const promotionSubtotal = parseFloat(secondData.subtotal) || 0;
              const promotionQuantity = parseFloat(secondData.quantity) || 0;

              const existingProductIndex = tableRows.findIndex(
                (row) =>
                row.id === secondData.product_id &&
                row.is_promoted === secondData.is_promoted
                );

              if (secondData) {
                if (promotionQuantity === 0) {
                  setTableRows((prevRows) =>
                    prevRows.filter((row) => secondData.product_id !== row.id)
                    );
                  return;
                }

                if (existingProductIndex !== -1) {
                  setTableRows((prevRows) =>
                    prevRows.map((row, index) => {
                      if (index === existingProductIndex) {
                        const itemTax =
                        isNaN(secondData.item_tax) ||
                        secondData.item_tax === null ||
                        secondData.item_tax === ""
                        ? 0
                        : secondData.item_tax;

                        const updatedRow = {
                          ...row,
                          order_qty: promotionQuantity,
                          subtotal: promotionSubtotal.toFixed(2),
                          vat: (
                            (promotionSubtotal * formatNumber(itemTax)) / 100
                            ).toFixed(2),
                          total: (
                            promotionSubtotal +
                            (promotionSubtotal * formatNumber(itemTax)) / 100
                            ).toFixed(2),
                        };
                        return updatedRow;
                      }
                      return row;
                    })
                    );
                } else {
                  const split = secondData.order_type === "box" ? 0 : 1;

                  const newRow1 = {
                    code: secondData.product_code,
                    id: secondData.product_id,
                    price: promotionUnitPrice.toFixed(2),
                    piece_cost: promotionUnitPrice.toFixed(2),
                    split: split,
                    type: secondData.order_type === "box" ? "box" : "piece",
                    split_quantity: secondData.unit_quantity,
                    split_price: promotionUnitPrice.toFixed(2),
                    name: secondData.product_name,
                    size: secondData.size,
                    cost: 0,
                    rate: secondData.item_tax
                    ? parseFloat(secondData.item_tax)
                    : 0,
                    quantity: promotionQuantity,
                    pdtcost: promotionUnitPrice.toFixed(2),
                    order_qty: promotionQuantity,
                    vat: 0.0,
                    subtotal: 0.0,
                    total: 0.0,
                    is_promoted: secondData.is_promoted,
                  };

                  setTableRows((prevRows) => [...prevRows, newRow1]);
                }
              }
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };



  // const handleCustomerLedgerClick_old = () => {
  //   const customerId = customeId || 0;  
  //   const formData = new FormData();
  //   formData.append('customer_id', customeId);
  //   formData.append('start_date', "");
  //   formData.append('end_date', "");
  //   formData.append('driver', 0);
  //   formData.append('sale_rep', 0);

  //   fetch(`${config.API_URL}/get_customer_ladger`, {
  //     method: 'POST',
  //     headers: {
  //       'Authorization': `Bearer ${accessToken}`,
  //     },
  //     body: formData, 
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       console.log(data.customer);
  //       setcustomerLedger(data.customer_ledger);
  //       setdebtProfile(data.debtProfile);
  //       setcustomerInfo(data.customer);
  //     })
  //     .catch(error => {
  //       console.error('Error calling API:', error);
  //     });
  // };


  const handleCustomerLedgerClick = async () => {
    const customerId = customeId || 0;
    const formData = new FormData();
    formData.append('customer_id', customeId);
    formData.append('start_date', "");
    formData.append('end_date', "");
    formData.append('driver', 0);
    formData.append('sale_rep', 0);
    const apiRouteUrl = `${config.API_URL}/get_customer_ladger`;
    try {
      const response = await fetch(apiRouteUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      console.log(data.data.customer_ledger);

      setCustData(data);
      setcustomerLedgerData(data.data.customer_ledger);
      setdebtProfile(data.data.debtProfile);
      setcustomerInfo(data.data.customer);

    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  useEffect(() => {

    console.log(custData);

  }, [customerLedgerData]);



  // const handleQuantityChange1111 = (id, newQty) =>
  // {

  //   setTableRows(prevRows => {
  //     return prevRows.map(row =>
  //     {
  //       if (row.id === id) {
  //         const order_qty = parseFloat(newQty) || 1;
  //         const unitCost = parseFloat(row.pdtcost) || 0;
  //         const rate = parseFloat(row.rate) || 0;

  //         const subtotal = (order_qty * unitCost).toFixed(2);
  //         const vat = ((order_qty * unitCost) * rate / 100).toFixed(2);
  //         const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

  //         return {
  //           ...row,
  //           order_qty,
  //           subtotal,
  //           vat,
  //           total
  //         };
  //       }
  //       return row;

  //         // Check for product promotions
  //       const productId = row.id;
  //       const order_type= row.split === '0' || row.split === '2' ? 'box' : 'piece';
  //       const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;

  //       const secondConfig = {
  //           headers: { 'authorization': 'Bearer ayt' },
  //       };

  //       const secondFormData = new FormData();
  //       secondFormData.append("orderId", 0);
  //       secondFormData.append("productId", productId);
  //       secondFormData.append("order_type", order_type);
  //       secondFormData.append("qty", 1);

  //       const secondResponse = await axios.post(secondApiUrl, secondFormData, secondConfig);
  //       const secondData = secondResponse.data.item;

  //       console.log("Second API response:", secondData);

  //       // Handle the promotion data
  //    //   if (secondData && secondData.success && secondData.is_promoted === "1")
  //    //   {

  //      //   alert("ggggg")
  //           const promotionUnitPrice = parseFloat(secondData.unit_price) || 0; // Use the promoted price (0 if promotion sets it to 0)
  //           const promotionSubtotal = parseFloat(secondData.subtotal) || 0; // Use the promoted subtotal (0 if it's discounted)
  //           const promotionQuantity = parseFloat(secondData.order_qty) || 0; // Quantity after promotion (should be the same)

  //           const newRow1 = {
  //               code: secondData.product_code,
  //               id: secondData.product_id,
  //               price: promotionUnitPrice.toFixed(2),
  //               piece_cost: promotionUnitPrice.toFixed(2),
  //               split: secondData.order_type,
  //               type: secondData.order_type === "box" ? "box" : "piece",
  //               split_quantity: secondData.unit_quantity,
  //               split_price: promotionUnitPrice.toFixed(2),
  //               name: secondData.product_name,
  //               size: secondData.size,
  //               cost: 0, // Assuming promotion means no cost
  //               rate: secondData.item_tax ? parseFloat(secondData.item_tax) : 0,
  //               quantity: promotionQuantity,
  //               pdtcost: promotionUnitPrice.toFixed(2),
  //               order_qty: promotionQuantity,
  //               subtotal: promotionSubtotal.toFixed(2),
  //               vat: (promotionSubtotal * formatNumber(secondData.item_tax) / 100).toFixed(2),
  //               total: (promotionSubtotal + (promotionSubtotal * formatNumber(secondData.item_tax) / 100)).toFixed(2),
  //           };

  //           // Add the promotion row to the table
  //           setTableRows(prevRows => [...prevRows, newRow1]);
  //       //  }

  //     });
  //   });

  //   calculateTotals(tableRows);
  // };

  const handleQuantityChange = async (id, newQty, promosId, promosType) => 
  {
    // First, update the table rows synchronously with the new quantity

    if (newQty == 0) {
      newQty = 1;
    }

    setTableRows((prevRows) =>
      prevRows.map((row) => {

       
        if (row.id === id && row.promosType == promosType) 
        {
          const order_qty = parseFloat(newQty) || 0;
          const unitCost = parseFloat(row.pdtcost) || 0;
          const rate = parseFloat(row.rate) || 0;
          const discountPercentage = parseFloat(row.discount) || 0; 

          const rawSubtotal = order_qty * unitCost;
          const discountValue = (rawSubtotal * discountPercentage) / 100;
          //const subtotal = (order_qty * unitCost).toFixed(2);
          const subtotal = (rawSubtotal - discountValue).toFixed(2); 

          const vat = ((order_qty * unitCost * rate) / 100).toFixed(2);
          
          const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

          return {
            ...row,
            order_qty,
            subtotal,
            vat,
            total,
          };
        }
        return row;
      })
      );

    try {
      const updatedRows = [...tableRows];
      const currQty = parseInt(newQty);

      let totalOrderQty;
      if (promosType == "combo") {
        totalOrderQty = tableRows.reduce((sum, row) => {
          // Check if row matches the condition
          //  alert(row.promosType);
          if (row.promosId == promosId && row.promosType == "combo") {
            if (row.id == id) {
              return sum + currQty; // Add newQty for the row with the matching id
            } else {
              return (sum += row.order_qty); // Add the order_qty for other matching rows
            }
          } else {
            return sum;
          }
        }, 0);
      } else {
        totalOrderQty = parseInt(newQty);
      }

      //alert(totalOrderQty);

      for (let row of updatedRows) {
        if (row.id === id) {
          const order_type = row.split === "0" || row.split === "2" ? "0" : "1";
          const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;
          const secondConfig = {
            headers: { authorization: `Bearer ${accessToken}` },
          };

          const secondFormData = new FormData();
          secondFormData.append("orderId", 0);
          secondFormData.append("productId", id);
          secondFormData.append("order_type", order_type);
          secondFormData.append("qty", totalOrderQty);

          // Await API call to check for promotions
          const secondResponse = await axios.post(
            secondApiUrl,
            secondFormData,
            secondConfig
            );
          const secondData = secondResponse.data.item;

          console.log("Second API response:", secondData);

          // Only apply promotion if it's valid
          if (secondData && secondData.is_promoted === "1") {
            const promotionUnitPrice = parseFloat(secondData.unit_price) || 0;
            const promotionSubtotal = parseFloat(secondData.subtotal) || 0;
            const promotionQuantity = parseFloat(secondData.quantity) || 0;

            // Check if product with the same ID already exists
            const existingProductIndex = tableRows.findIndex(
              (row) =>
              row.id === secondData.product_id &&
              row.is_promoted == secondData.is_promoted
              );

            if (promotionQuantity === 0) {
              setTableRows((prevRows) =>
                prevRows.filter((row) => secondData.product_id !== row.id)
              ); // Remove item if quantity is 0
              return;
            }

            // If product exists, update the existing row
            if (existingProductIndex !== -1) {
              setTableRows((prevRows) =>
                prevRows.map((row, index) => {
                  if (index === existingProductIndex) {
                    const itemTax =
                    isNaN(secondData.item_tax) ||
                    secondData.item_tax === null ||
                    secondData.item_tax === ""
                    ? 0
                    : secondData.item_tax;

                    const updatedRow = {
                      ...row,
                      order_qty: promotionQuantity, // Update quantity based on promotion
                      subtotal: promotionSubtotal.toFixed(2),
                      //vat: (promotionSubtotal * formatNumber(secondData.item_tax) / 100).toFixed(2),
                      //total: (promotionSubtotal + (promotionSubtotal * formatNumber(secondData.item_tax) / 100)).toFixed(2),
                      vat: (
                        (promotionSubtotal * formatNumber(itemTax)) /
                        100
                        ).toFixed(2),
                      total: (
                        promotionSubtotal +
                        (promotionSubtotal * formatNumber(itemTax)) / 100
                        ).toFixed(2),
                    };
                    return updatedRow;
                  }
                  return row; // Keep other rows unchanged
                })
                );
            } else {
              // Add a new row if product doesn't exist
              const split = secondData.order_type === "box" ? 0 : 1;

              const newRow1 = {
                code: secondData.product_code,
                id: secondData.product_id,
                price: promotionUnitPrice.toFixed(2),
                piece_cost: promotionUnitPrice.toFixed(2),
                split: split,
                type: secondData.order_type === "box" ? "box" : "piece",
                split_quantity: secondData.unit_quantity,
                split_price: promotionUnitPrice.toFixed(2),
                name: secondData.product_name,
                size: secondData.size,
                cost: 0,
                rate: secondData.item_tax ? parseFloat(secondData.item_tax) : 0,
                quantity: promotionQuantity,
                pdtcost: promotionUnitPrice.toFixed(2),
                order_qty: promotionQuantity,
                vat: 0.0,
                subtotal: 0.0,
                total: 0.0,
                is_promoted: secondData.is_promoted,
              };

              // Append the new row
              setTableRows((prevRows) => [...prevRows, newRow1]);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error checking promotion:", error);
    }
  };

  // UseEffect to call calculateTotals after tableRows changes
  useEffect(() => {
    calculateTotals(tableRows);
  }, [tableRows]); // Will run calculateTotals every time tableRows changes

  const formatNumber = (num) => {
    return parseFloat(num).toFixed(2);
  };
  const handleDiscountChange = (discQty) => {
    const value = Number(discQty);

    // console.log(value);
    // if (isNaN(value) || value < 0 || value.length < 2) {
    //   setDiscountQty("");
    //   return;
    // }

    // Proceed if the value is a valid non-negative number
    setDiscountQty(value);
  };

  const handleOptionChange = async (id, pdtType, promosId, promosType) => {
    var newQty = 0;
    setTableRows((prevRows) => {
      return prevRows.map((row) => {
        if (row.id === id && row.is_promoted === 0) {
          const pdtcost = pdtType === "box" ? row.price : row.piece_cost;
          const order_qty = parseFloat(row.order_qty) || 0;
          // newQty = row.order_qty;
          newQty = parseInt(row.order_qty);

          const rate = parseFloat(row.rate) || 0;
          const subtotal = (order_qty * pdtcost).toFixed(2);
          const vat = ((order_qty * pdtcost * rate) / 100).toFixed(2);
          const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
          const type = pdtType === "box" ? "box" : "piece";
          calculateTotals(tableRows);
          return {
            ...row,
            type,
            pdtcost,
            subtotal,
            vat,
            total,
          };
        }
        return row;
      });
    });

    const found = Array.isArray(tableRows)
    ? tableRows.find(
      (element) => element.id === id && element.is_promoted === 0
      )
    : false;

    if (found) {
      //console.log();

      try {
        const updatedRows = [...tableRows];
        const currQty = parseInt(found.order_qty);
        const orderType = pdtType === "box" || pdtType === "2" ? "0" : "1";

        let totalOrderQty;
        if (promosType == "combo") {
          totalOrderQty = tableRows.reduce((sum, row) => {
            if (row.promosId == promosId && row.promosType == "combo") {
              if (row.id == id) {
                return sum + currQty; // Add newQty for the row with the matching id
              } else {
                return (sum += row.order_qty); // Add the order_qty for other matching rows
              }
            } else {
              return sum;
            }
          }, 0);
        } else {
          totalOrderQty = parseInt(currQty);
        }

        for (let row of updatedRows) {
          if (row.id === id && row.is_promoted == "0") {
            const order_type = orderType;
            const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;
            const secondConfig = {
              headers: { authorization: `Bearer ${accessToken}` },
            };

            const secondFormData = new FormData();
            secondFormData.append("orderId", 0);
            secondFormData.append("productId", id);
            secondFormData.append("order_type", order_type);
            secondFormData.append("qty", totalOrderQty);

            // Await API call to check for promotions
            const secondResponse = await axios.post(
              secondApiUrl,
              secondFormData,
              secondConfig
              );
            const secondData = secondResponse.data.item;

            console.log("Second API response:", secondData);

            // Only apply promotion if it's valid
            if (secondData && secondData.is_promoted === "1") {
              const promotionUnitPrice = parseFloat(secondData.unit_price) || 0;
              const promotionSubtotal = parseFloat(secondData.subtotal) || 0;
              const promotionQuantity = parseFloat(secondData.quantity) || 0;

              // Check if product with the same ID already exists
              const existingProductIndex = tableRows.findIndex(
                (row) =>
                row.id === secondData.product_id &&
                row.is_promoted == secondData.is_promoted
                );

              if (promotionQuantity === 0) {
                setTableRows((prevRows) =>
                  prevRows.filter((row) => secondData.product_id !== row.id)
                ); // Remove item if quantity is 0
                return;
              }

              // If product exists, update the existing row
              if (existingProductIndex !== -1) {
                setTableRows((prevRows) =>
                  prevRows.map((row, index) => {
                    if (index === existingProductIndex) {
                      const itemTax =
                      isNaN(secondData.item_tax) ||
                      secondData.item_tax === null ||
                      secondData.item_tax === ""
                      ? 0
                      : secondData.item_tax;

                      const updatedRow = {
                        ...row,
                        order_qty: promotionQuantity, // Update quantity based on promotion
                        subtotal: promotionSubtotal.toFixed(2),
                        //vat: (promotionSubtotal * formatNumber(secondData.item_tax) / 100).toFixed(2),
                        //total: (promotionSubtotal + (promotionSubtotal * formatNumber(secondData.item_tax) / 100)).toFixed(2),
                        vat: (
                          (promotionSubtotal * formatNumber(itemTax)) /
                          100
                          ).toFixed(2),
                        total: (
                          promotionSubtotal +
                          (promotionSubtotal * formatNumber(itemTax)) / 100
                          ).toFixed(2),
                      };
                      return updatedRow;
                    }
                    return row; // Keep other rows unchanged
                  })
                  );
              } else {
                // Add a new row if product doesn't exist
                const split = secondData.order_type === "box" ? 0 : 1;

                const newRow1 = {
                  code: secondData.product_code,
                  id: secondData.product_id,
                  price: promotionUnitPrice.toFixed(2),
                  piece_cost: promotionUnitPrice.toFixed(2),
                  split: split,
                  type: secondData.order_type === "box" ? "box" : "piece",
                  split_quantity: secondData.unit_quantity,
                  split_price: promotionUnitPrice.toFixed(2),
                  name: secondData.product_name,
                  size: secondData.size,
                  cost: 0,
                  rate: secondData.item_tax
                  ? parseFloat(secondData.item_tax)
                  : 0,
                  quantity: promotionQuantity,
                  pdtcost: promotionUnitPrice.toFixed(2),
                  order_qty: promotionQuantity,
                  vat: 0.0,
                  subtotal: 0.0,
                  total: 0.0,
                  is_promoted: secondData.is_promoted,
                };

                // Append the new row
                setTableRows((prevRows) => [...prevRows, newRow1]);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error checking promotion:", error);
      }
    }

    //calculateTotals(tableRows);
  };

  /* const calculateTotals = (rows) => {
 
 
     const totalGoods = rows.reduce((sum, item) => sum + (parseFloat(item.subtotal) || 0), 0);
     const totalTax = rows.reduce((sum, item) => sum + (parseFloat(item.vat) || 0), 0);
     const totalGross = (totalGoods + totalTax);
     const orderDiscount = (totalGross * (discountQty / 100)) || 0;
     const grandTotal = (totalGross - orderDiscount);
     
     const boxTotal = rows
         .filter(item => item.type === 'box') // Filter for box type
         .reduce((sum, item) => sum + (item.order_qty || 0), 0); // Sum order_qty
 
     const pieceTotal = rows
         .filter(item => item.type === 'piece') // Filter for piece type
         .reduce((sum, item) => sum + (item.order_qty || 0), 0);
 
     
     setTotalVal({
       totalGoods : totalGoods.toFixed(2),
       orderDiscount: orderDiscount,
       totalGross: totalGross.toFixed(2),
       totalTax: totalTax.toFixed(2),
       grandTotal: grandTotal.toFixed(2),
       boxTotal: boxTotal, 
       pieceTotal: pieceTotal, 
     });
   };*/

  const calculateTotals = (rows) => 
  {


  //  alert("jjjjjjjjjjjjjj");
 /*    const suTotalGoods = rows.reduce(    
      (sum, item) => sum + (parseFloat(item.subtotal) || 0),
      0
    );
*/
    const suTotalGoods = rows
    .filter(item => item.vat !== '0.00' && item.vat != null)
    .reduce((sum, item) => sum + parseFloat(item.subtotal), 0);

    const totalzeroGoods = rows
    .filter(item => item.vat === '0.00' || item.vat === null)
    .reduce((sum, item) => sum + parseFloat(item.subtotal), 0);



    const sumTotalTax = rows.reduce(
      (sum, item) => sum + (parseFloat(item.vat) || 0),
      0
      );



    const goodsDiscount = suTotalGoods + totalzeroGoods  * (discountQty / 100) || 0;


    const taxDiscount = sumTotalTax * (discountQty / 100) || 0;

    const totalGoods = suTotalGoods + totalzeroGoods - goodsDiscount;
    const totalTax = sumTotalTax - taxDiscount;

    const totalGross = suTotalGoods + totalzeroGoods + sumTotalTax;
    const grandTotal = totalGoods +  totalTax;
    const orderDiscount = discountQty;

   

    const boxTotal = rows
    .filter((item) => item.type == "box") 
    .reduce((sum, item) => sum + (item.order_qty || 0), 0); 

    const pieceTotal = rows
    .filter((item) => item.type == "piece")
    .reduce((sum, item) => sum + (item.order_qty || 0), 0);

    setTotalVal({
      totalGoods: suTotalGoods.toFixed(2),
      totalzeroGoods:totalzeroGoods.toFixed(2),
      orderDiscount: orderDiscount,
      totalGross: totalGross.toFixed(2),
      totalTax: sumTotalTax.toFixed(2),
      grandTotal: grandTotal.toFixed(2),
      boxTotal: boxTotal,
      pieceTotal: pieceTotal,
    });
  };

  const openingBalance = async () => {
    const formData = new FormData();
    formData.append("user_id", userId);
    try {
      const response = await fetch(`${config.API_URL}/ws_get_opening_bal`, {
        method: "post",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      //console.log('Opening balance:', data);

      if (data && data.success) {
      } else {
        openModal();
      }
      return data;
    } catch (error) {
      console.error("Error fetching opening balance:", error);
    }
  };

  // Call the function

  const openModal = () => {
    const myModal = new window.bootstrap.Modal(
      document.getElementById("opening_balance")
      );
    myModal.show(); // Show the modal
  };

  const handleCustomerInputChange = (inputValue) => {
    fetchEposSearchCustomer(
      inputValue,
      customerInfo,
      setCustomerInfo,
      setCustomerData,
      accessToken
      );
  };

  const handleCustomerChange = (selectedOption) => {
    // console.log(selectedOption);



    if (selectedOption) {
      setSelectedCustomer(selectedOption);
      setCustomeId(selectedOption.value);
      fetchProductList(
        setProductData,
        selectedOption,
        setCustomerDetails,
        setCompanyAddress,
        selectedOption.value
        );
      setTableRows([]);
      setSalesStatus("New");
      setSalesRefNo(0);
    } else {
      setSelectedCustomer(null);
      setCustomeId("");
    }
  };

  const getProductListChange = (selectedOption, customeId) => {
    if (selectedOption) {
      fetchProductList(
        setProductData,
        selectedOption,
        setCustomerDetails,
        setCompanyAddress,
        customeId
        );
    }
  };

  const generateUniqueId = () => {
    return `id_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  };

  const handleCancelOrder = () => {
    window.location.href = config.BASE_URL + "/sales/Epos";
  };
  const handlePlaceOrder = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to Save this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Save it!",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        const body = {
          platform: "pos",
          authorization: `Bearer ${accessToken}`,
          user_id: userId,
          product_count: totalVal.boxTotal + totalVal.pieceTotal,
          customer_id: customeId,
          customer_address: "abc",
          totalAmount: totalVal.grandTotal,
          totalVAT: totalVal.totalTax,
          paymentMode: "",
          staff_note: "",
          note_for_driver: "",
          invoiceWiseDiscountPercentage: discountQty,
          invoiceWiseDiscountAmount: "",
          discountAmount: totalVal.orderDiscount,
          promotionId: "",
          promotionSubType: "",
          collect_amount: totalVal.grandTotal,
          uniqueOrderId: generateUniqueId(),
          chequeNo: "",
          chequeDate: "",
          latitude: "",
          longitude: "",
          address: "",
          product_discount: "0.0",
          status: "Incomplete",
          sales_id: salesId,
          reference_no: referenceNo,
          sale_details: tableRows.map((row) => ({
            product_id: row.id,
            product_code: row.code,
            product_name: row.name,
            unit_price: row.pdtcost,
            quantity: row.order_qty,
            quantityType: row.type,
            productWiseDiscountPercentage: row.discount,
            productWiseDiscountAmount:row.discountValue,
            subTotal: row.subtotal,
            vat: row.vat,
            isPromoted: row.is_promoted,
            promo_id: row.promosId,
            promo_type: row.promosType,
            total: row.total,
          })),
        };

        const response = await fetch(
          `${config.API_URL}/ws-place-order-v2_pos`,
          {
            method: "POST",
            headers: {},
            body: JSON.stringify(body),
          }
          );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }


        const data = await response.json();

        setSalesRefNo(data.data.reference);
        setreferenceNo(0);
        if (data.success) 
        {
          Swal.fire({
            title: "Success!",
            text: "Order Saved successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            // window.location.href = config.BASE_URL + "/sales/Epos";

            const modal = new window.bootstrap.Modal(document.getElementById('Order_Receipt'));
            modal.show();

            // setSelectedCustomer(null);
            // setCustomerDetails([]);
            setCompanyAddress([]);
            // setCustomeId('');
            setProductData([]);
            setTableRows([]);
            setIncompleteOrder([]);



            const initialState = {
              totalGoods: 0,
              totalGross: 0,
              totalzeroGoods: 0,
              totalTax: 0,
              orderDiscount: 0,
              grandTotal: 0,
              boxTotal: 0,
              pieceTotal: 0,
            };

            setTotalVal(initialState);
            setDiscountQty(0.0);
            setSalesId('');
            setSalesStatus("New");
         //   setSalesRefNo(0);
            setSelectedProduct(null);

          });
        } else {
          Swal.fire({
            title: "Failed!",
            text: "Order Saved Failed. Try Again",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        }

        // console.log(data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };


  const handleClose = () => 
  {
   // console.log('Modal closed');
    // const modalElement = document.getElementById('myModal');
    // const modal = new window.bootstrap.Modal(modalElement);
    // modal.hide();

    setSelectedCustomer(null);
    setCustomerDetails([]);
    setCompanyAddress([]);
    setCustomeId('');
    setProductData([]);
    setTableRows([]);
    setIncompleteOrder([]);
  };

  const handleIncompleteOrder = async () => {
    try {
      const response = await fetch(`${config.API_URL}/ws-incomplete-order`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setIncompleteOrder(data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFinishToPay = async () => {
    try {
      const response = await fetch(`${config.API_URL}/ws-finish-to-pay`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setIncompleteOrder(data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    openingBalance();
    calculateTotals(tableRows);
    // handleCustomerInputChange('ramee');
  }, [tableRows, discountQty]);

  useEffect(() => {
    handleCustomerInputChange('ramee');
  }, []);


const handleInputChangedesc = (event) => {
  const value = parseFloat(event.target.value) || 0; // Discount percentage value
  const id = event.target.dataset.id; // Get row ID from input
  const promosType = event.target.dataset.promosType; // Get promo type from input

  setTableRows((prevRows) =>
    prevRows.map((row) => {
      if (row.id === id) {
        const order_qty = parseFloat(row.order_qty) || 0;
        const unitCost = parseFloat(row.pdtcost) || 0;
        const rate = parseFloat(row.rate) || 0;



        const rawSubtotal = order_qty * unitCost; // Subtotal before discount
        const discountValue = (rawSubtotal * value) / 100; // Discount value
        const subtotal = (rawSubtotal - discountValue).toFixed(2); // Subtotal after discount
        const vat = ((parseFloat(subtotal) * rate) / 100).toFixed(2); // VAT on discounted subtotal
        const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2); // Total amount

        return {
          ...row,
          order_qty,
          discount: value, // Store the discount percentage
          discountValue: discountValue.toFixed(2), // Store the calculated discount value
          subtotal,
          vat,
          total,
        };
      }
      return row;
    })
  );
};



  const handleInputChange = debounce(async (inputValue) => {

  setSelectedProduct(inputValue); // Update the selected product

  if (inputValue) {
    try {
      // Prepare FormData for the POST request
      const formData = new FormData();
      formData.append('pattern', inputValue);
      formData.append("platform", 'web');
      formData.append("authorization", accessToken);

      

       
      const apiUrl = `${config.API_URL}/ws_get_products_pos1`;

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
      });

      // Check if the response was successful
      // if (!response.ok) {
      //   setProductData([]); // Reset product data if response is not ok
      //   alert('Failed to fetch products. Please try again.');
      //   return;
      // }

      // Parse the JSON response
      const data = await response.json();

      // Check if product_details exists in the response
      if (data && data.product_details && Array.isArray(data.product_details)) {
        // Map the product details to the format expected by the select dropdown
        const formattedOptions = data.product_details.map(item => ({
          label: `${item.product_name || 'N/A'} - ${item.inner_ean_number || 'N/A'}`,
          value: item.id,
        }));

        // Debugging: Check the formatted options
        console.log('Formatted Options:', formattedOptions);

        // Update product data with the formatted options
        setProductData(formattedOptions);
      } else {
        setProductData([]); // Reset product data if product_details is not valid
       // alert('No product details found.');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      //alert('An error occurred while fetching the products.');
    }
  } else {
    setProductData([]); // Clear product data if input is empty
  }
}, 500); // Debounce delay of 500ms after typing stops



  useEffect(() => {


    const handleKeyPress = (event) => {
      // Check if Ctrl and D are pressed together
      if (event.ctrlKey && event.key === 'd') {
        event.preventDefault(); // Prevent the default browser behavior (for Ctrl + D)
        
        // Open the modal programmatically
        const modal = new window.bootstrap.Modal(document.getElementById('Day-Close'));
        modal.show();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);


  useEffect(() => {
    if (customerData && customerData.length > 0) 
    {
      const defaultCustomer = customerData.find(customer => customer.value === '38');

      
      setCustomeId(customerData[0].value);

      setSelectedCustomer(defaultCustomer); 
    }
  }, [customerData]);

  return (
    <div>
      {/* Incomplete Order */}
    <Header />
    <About />
    <FinishPay
    finishToPay={finishToPay}
    tableRows={tableRows}
    totalVal={totalVal}
    customeId={customeId}
    discountQty={discountQty}
    salesId={salesId}
    salesStatus={salesStatus}
    salesRefNo={salesRefNo}
    customerDetails={customerDetails}
    />

    <ProformaInvoice
    tableRows={tableRows}
    totalVal={totalVal}
    customerDetails={customerDetails}
    companyAddress={companyAddress}
    />
    <OrderReceipt
    tableRows={tableRows}
    totalVal={totalVal}
    customerDetails={customerDetails}
    companyAddress={companyAddress}
    salesRefNo={salesRefNo}
    customeId={customeId}
    selectedCustomer={selectedCustomer}
    handleClose={handleClose}
    tillUserName={tillUserName}

    />

    <BarcodeScanner  
    tableRows={tableRows}
    setTableRow={setTableRows}
    customeId={customeId}
    handleQuantityChange={handleQuantityChange}
    accessToken={accessToken}/>


    <IncompleteOrder
    incompleteOrder={incompleteOrder}
    setTableRow={setTableRows}
    setCustomeId={setCustomeId}
    setDiscountQty={setDiscountQty}
    setSelectedCustomer={setSelectedCustomer}
    getProductListChange={getProductListChange}
    setSalesId={setSalesId}
    setSalesStatus={setSalesStatus}
    setSalesRefNo={setSalesRefNo}    
    setIncompleteOrder={setIncompleteOrder}
    tillUserName={tillUserName}
    setreferenceNo={setreferenceNo}
    />

    <SaveExit />

      {/* <CustomerLedger customerInfo={customerinfo} debtProfile={debtProfile} customerLedgerInfo={customerLedgerData} /> */}

    <DayClose />
    <OpeningBalance />

      {/* <nav className="breadcrumbs">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12">
  <a href="#"><i className="fa fa-home"></i> </a>
  <span className="last-crumb">Epos</span>
  </div>
  </div>
  </div>
</nav> */}

    <div class="modal fade" id="Customer-ledger" tabIndex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
    <div class="modal-header">
    <h5 class="modal-title">&nbsp;</h5>
    <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    ></button>
    </div>
    <div class="modal-body">
    <div class="customer-ledger-table">
    <h5>Period From : {custData.start_date} To : {custData.end_date}</h5>
    <div class="customer-ledger-detail">
    <table class="table table-bordered dataTable resizable">
    <thead>
    <tr>
    <th>{customerinfo.name}({customerinfo.accound_no})</th>
    <th>&nbsp;</th>
    <th>0 to 30 Days</th>
    <th>0.00</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <td>Current O/S </td>
    <td>0.00</td>
    <td>30 + Days </td>
    <td>{debtProfile.moreThan30DaysDue}</td>
    </tr>
    <tr>
    <td>Total Amount </td>
    <td>0.00</td>
    <td>60 + Days </td>
    <td>{debtProfile.moreThan60DaysDue}</td>
    </tr>
    <tr>
    <td>Unallocated Amount </td>
    <td>
    <a href="#">
    {custData?.data?.allocation_amount?.[0]?.total ?? ''}
    </a>

    </td>
    <td>90 + Days </td>
    <td>{debtProfile.moreThan90DaysDue}</td>
    </tr>
    </tbody>
    </table>
    </div>

    <div class="customer-ledger-list">
    <table class="table table-bordered dataTable resizable">
    <thead>
    <tr>
    <th>Date</th>
    <th>Inv No</th>
    <th>Status</th>
    <th>Type</th>
    <th>Amount </th>
    <th>Balance</th>
    </tr>
    </thead>
    <tbody>
    {customerLedgerData?.map((item) => {
      const {
        id,
        date,
        payment_type,
        amount,
        balance,
        sales_person_id,
        driver_id,
        payment_status,
        particulars,
        paid_by,
        orderId
      } = item;

      let amountFormatted = parseFloat(amount).toFixed(2);
      let balanceFormatted = parseFloat(balance).toFixed(2);
      let particular1 = '';
      let status = '';
      let name1 = '';
      let bgColor = '';

      if (payment_type === 'c') {
                          // Credit logich
        if (particulars == "driver collected" || particulars == "sales rep collected") {

          particular1 = 'Pay';
        } else {
          particular1 = (particulars === 'Credit Note') ? 'CrN' : particulars;
        }
      } else {
                          // Debit logic
        if (sales_person_id === '0' && driver_id === '0') {
          particular1 = 'Inv';

          status = payment_status === 'Paid' ? 'Paid' : payment_status === 'Due' ? 'UnPaid' : 'Partial';
          if (orderId != null) {
            name1 = `<a href="${config.BASE_URL}/trip_summary/print/${orderId}" target="_black" >${particulars}</a>`;
          } else {
            name1 = `<span >${particulars}</span>`;
          }

        } else {
          particular1 = 'Pay';
        }
      }

                        // Handle undefined amounts
      if (isNaN(amountFormatted) || amountFormatted === '') {
        amountFormatted = '0.00';
      }
      if (isNaN(balanceFormatted) || balanceFormatted === '') {
        balanceFormatted = '0.00';
      }

                        // Adjust amount1 if necessary
      let amount1Formatted = amountFormatted; // Initialize it directly

      if (particular1 === 'sales rep collected') {
        amount1Formatted = '0.00'; // Set to '0.00' if necessary
      }

                        // Set the background color based on conditions
      if (particular1 === 'Pay') {
        bgColor = '#b1edb1';
      } else if (particular1 === 'Inv') {
        bgColor = '';
      } else {
        bgColor = '';
      }

      return (
      <tr key={id} >
      <td style={{ backgroundColor: bgColor }}>{date}</td>
      <td style={{ backgroundColor: bgColor }} dangerouslySetInnerHTML={{ __html: name1 || '' }}></td>
      <td style={{ backgroundColor: bgColor }} >{particular1 === 'Inv' ? status : ''}</td>
      <td style={{ backgroundColor: bgColor }} >{particular1}</td>
      <td style={{ backgroundColor: bgColor }} >{amount1Formatted}</td>
      <td style={{ backgroundColor: bgColor }} >{balanceFormatted}</td>
      </tr>
      );
    })}
    </tbody>
    </table>
    </div>
    </div>
    </div>
            {/* <div class="modal-footer">
        <button type="button" class="closebtn" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="printbtn">Save</button>
      </div> */}
    </div>
    </div>
    </div>





    <div className="epos-container">
    <section id="topbar" class="d-flex align-items-center">
    <div class="container-fluid">
    <div class="product-code-area epos_procd-area">
    {/* <div class="row">
    <div class="col-12 col-lg-12 col-sm-12">
    <ul>

    <li>
    {" "}
    <span>Status : {salesStatus}-{salesRefNo} </span>
    </li>

    </ul>
    </div>
              </div> */}


    <div className="row">

    <div className="date-range-picker col-md-6 col-lg-4 col-sm-12" style={{ float: 'left' }}>
    <div class="row mb-3 padd-0">
    <label class="col-sm-4" style={{ marginTop: '5px', fontWeight: '600' }} >Customer </label>
    <div class="col-sm-8">
    <Select
    className="abovedd selct-dd"
    isClearable
    onInputChange={handleCustomerInputChange}
    onChange={handleCustomerChange}
    options={customerData}
    value={selectedCustomer}
    placeholder="Enter Customer Name"
    />


    </div>
    </div>
    </div>


    <div className="date-range-picker col-md-6 col-lg-4 col-sm-12" style={{ float: 'left'}}>

    <div class="row mb-3 padd-0">
    <label class="col-sm-4" style={{ marginTop: '5px', fontWeight: '600' }} >Product</label>
    <div class="col-sm-8">
    <Select
    className="abovedd"
    isClearable
    value={selectedProduct}
    onInputChange={handleInputChange}
    onChange={(e) => {
      setSelectedProduct(e); 
      handleEposProductAdd(
        e,
        barcode,
        tableRows,
        setTableRows,
        customeId,
        handleQuantityChange,
        accessToken
        );
    }}

  options={Array.isArray(productData) ? productData : []} // Ensure it's an array
  placeholder=""
  />



  </div>
  </div>
  </div>

  <div className="col-md-6 col-lg-4 col-sm-6" style={{ float: 'left', textAlign: 'right' }} >
  <button
  type="submit"
  class="btn btn-primary topbtn"
  data-bs-toggle="modal"
  data-bs-target="#Day-Close"
  >
  Day Close
  </button>

  <button
  type="submit"
  class="btn btn-primary topbtn"
  data-bs-toggle="modal"
  data-bs-target="#incomplete-order"
  onClick={handleIncompleteOrder}
  >
  Incomplete Orders
  </button>


  </div>


  


  </div>
  {/* <div className="row">
  <div class="col-12 col-lg-6 col-sm-12">
  <div class="right-button-area">
  <div class="top-custm-dtl">

  <h3>
  {customerDetails.company !== undefined ? customerDetails.company : 'Customer Name'}
  </h3>

  <ul>
  <li>
  <p>
  <span>Credit Type</span>
  </p>
  </li>

  {parsedCreditType.length === 0 && (
    <>
    <li><span>No Credit</span></li>
    </>
    )}
    </ul>

    </div>
    </div>
    </div>
</div> */}

    </div>
    <div class="products-area">
    <div>
    <div class="col-12 col-lg-12 col-sm-12">
    <div class="productlist-area">
    <div>
    <div class="col-12 col-sm-12 col-lg-12">
    <div class="tbl-header">
    <table
    cellPadding="0"
    cellSpacing="0"
    border="0"
    class=""
    >
    <thead>
    <tr>
    <th style={{ width: '5%' }}>No</th>
    <th style={{ width: '10%' }}>Code</th>
    <th>Product Description</th>
    <th style={{ width: '5%' }}>Type</th>
    <th style={{ width: '5%' }}>Qty</th>
    <th style={{ width: '5%'}}>Rate</th>
    <th style={{ width: '5%'}}>Disc %</th>
    <th style={{ width: '10%' }}>Amount</th>
    <th style={{ width: '10%' }}>VAT</th>
    <th  className="text-end" style={{ width: '10%' }}>Total</th>
    <th style={{ width: '5%' }}>#</th>
    </tr>
    </thead>
    </table>
    </div>
    <div class="tbl-content">
    <table
    cellPadding="0"
    cellSpacing="0"
    border="0"
    className="table table-striped"
    >
    <tbody>
    {tableRows.length === 0 ? (
      <tr style={{ textAlign: "center" }}>
      <td colSpan={9}>No product added</td>
      </tr>
      ) : (
      tableRows.map((row, index) => (
        <tr key={row.id}>
        <td style={{ width: '5%' }}>{index + 1}</td>
        <td style={{ width: '10%' }}>{row.code}</td>
        <td>
                                      {row.name} {/*row.is_promoted*/}{" "}
        </td>
        <td style={{ width: '5%' }}>
        {row.split == 2 ? (
        <select
        onChange={(e) =>
        handleOptionChange(
        row.id,
        e.target.value,
        row.promosId,
        row.promosType
        )
      }
      defaultValue={row.orderType || "box"}
      className="form-select "
      >
      <option value="box">Box</option>
      <option value="piece">Piece</option>
      </select>
      ) : row.split == 0 ? (
      "Box"
      ) : (
      "Piece"
      )}
      </td>
      <td style={{ width: '5%' }}>
      {row.is_promoted == 1 ? (
        <>
        <input
        readOnly
        type="text"
        value={row.order_qty}
        min="1"
        onChange={(e) =>
        handleQuantityChange(
          row.id,
          e.target.value,
          row.promosId,
          row.promosType
          )
      }
      className="form-control order-qty text-center"
      />
                                          {/*(P) */}
      </>
      ) : (
      <input
      type="text"
      value={row.order_qty}
      min="1"
      onChange={(e) =>
      handleQuantityChange(
      row.id,
      e.target.value,
      row.promosId,
      row.promosType
      )
    }
    onFocus={(e) => e.target.select()}
    className="form-control order-qty text-center"
    />
    )}
    </td>

    <td className="text-end" style={{ width: '5%' }}>
    <input
    type="text"
    value={row.pdtcost}
    min="0"
    className="form-control order-cost text-center price-txt"
    />
    </td>
      <td>
   <input
  type="text"
  defaultValue={row.discount}
  min="0"
  className="form-control text-center"
  data-id={row.id} // Pass the correct row ID
  data-promosType={row.promosType} // Pass the correct promo type
  onChange={handleInputChangedesc}
/>

    </td>

    <td className="text-end" style={{ width: '10%' }}>{row.subtotal}</td>
    <td className="text-end" style={{ width: '10%' }}>{row.vat}</td>
    <td className="text-end" style={{ width: '10%' }}>{row.total}</td>
    <td style={{ width: '5%' }}>
    {row.is_promoted == 0 ? (
      <button
      className="btn btn-danger cancel-btn"
      onClick={() =>
      handleCancel(
        row.id,
        row.promosId,
        row.promosType
        )
    }
    style={{
      padding: "0px",
      fontSize: "19px",
      color: "#dc3545",
      background: "none",
      border: "none",
    }}
    >
    <i class="fa fa-trash-o"></i>
    </button>
    ) : (
    <>
    <div
    style={{
      position: "relative",
      minHeight: "40px",
      display: "block",
    }}
    >
    <img
    src={`${config.BASE_URL}/assets/img/orange_ribbon.png`}
    style={{
      width: "40px",
      transform: "rotate(90deg)",
      height: "15px",
      position: "absolute",
      right: "-4px",
      top: "12px",
    }}
    alt="PRMO"
    />
    <span
    style={{
      transform: "rotate(90deg)",
      display: "block",
      color: "#fff",
      fontSize: "9px",
      letterSpacing: "0.9px",
      padding: "12px",
      marginRight: "2px",
    }}
    >
    P
    </span>
    </div>
    </>
    )}
    </td>
    </tr>
    ))
    )}
    </tbody>
    </table>
    </div>

    </div>
    </div>
    <div class="pos-footer">
    <div class="row">
    <div class="col-12 col-lg-12 col-sm-12">
    <ul>
    <li>
    <label class="col-form-label">Box :</label>
    <span>{totalVal.boxTotal}</span>{" "}
    </li>
    <li>
    <label class="col-form-label">Piece :</label>
    <span>{totalVal.pieceTotal}</span>{" "}
    </li>
    <li>
    <label class="col-form-label">
    Zero Goods :
    </label>
    <span>{totalVal.totalzeroGoods}</span>{" "}
    </li>
    <li>
    <label class="col-form-label">
    Std Goods :
    </label>
    <span>{totalVal.totalGoods}</span>{" "}
    </li>
    <li>
    <label class="col-form-label">VAT :</label>
    <span>{totalVal.totalTax}</span>{" "}
    </li>
    <li>
    <label class="col-form-label">Gross :</label>
    <span>{totalVal.totalGross}</span>{" "}
    </li>
    <li>
    <label class="col-form-label">Disc % :</label>
    <input
    type="text"
    minLength="0"
    maxLength="2"
    class="form-control no-arrows"
    onChange={(e) =>
    handleDiscountChange(e.target.value)
  }
  value={discountQty}
  />
  </li>
  <li>
  <label class="col-form-label">
  Grand Total :
  </label>
  <span>{totalVal.grandTotal}</span>{" "}
  </li>
  </ul>
  </div>
  </div>
  </div>
  </div>
  </div>
  <div class="col-12 col-lg-12 col-sm-12">
  <div class="right-button-area">
  <div className="bottom-btn-area">
                            {/*   <button
                              type="submit"
                              disabled={tableRows.length === 0}
                              className="btn btn-primary eposbtn topbtn"
                              data-bs-toggle="modal"
                              data-bs-target="#Order_Receipt"
                            >
                              Order Receipt
                            </button> */}


  <button
  type="submit"
  class="btn btn-primary eposbtn topbtn"
  onClick={handlePlaceOrder}
  disabled={tableRows.length === 0}
  >
  Save & Exit
  </button>



  <button
  type="submit"
  class="btn btn-primary eposbtn topbtn"
  onClick={handleCancelOrder}
  >
  Cancel & Exit
  </button>
  </div>
  <div>
                      {/*  <form>*/}
                      {/* Add Keyboard / Calculator  */}
                      {/* <Calculator /> */}

  {/* <table class="table-borderless">
  <tbody>
  <tr>
  <td>Goods Total</td>
  <td><input type="text" class="form-control" /></td>
  </tr>
  <tr>
  <td>Total Discount</td>
  <td><input type="text" class="form-control" /></td>
  </tr>
  <tr>
  <td>Total Net Price</td>
  <td><input type="text" class="form-control" /></td>
  </tr>
  <tr>
  <td>Total VAT</td>
  <td><input type="text" class="form-control" /></td>
  </tr>
  <tr>
  <td>Total to pay</td>
  <td><input type="text" class="form-control" /></td>
  </tr>
  </tbody>
                      </table> */}
                      {/*  </form>*/}
  </div>
  {/* <div class="row">
  <div class="col-12 col-sm-12 col-lg-12">
  <ul>
  <li>
  <button
  type="submit"
  class="btn btn-primary eposbtn"
  data-bs-toggle="modal"
  data-bs-target="#Day-Close"
  >
  Day Close
  </button>
  </li>
  <li>
  <button
  type="submit"
  className="btn btn-primary eposbtn"
  data-bs-toggle="modal"
  data-bs-target="#Customer-ledger"
  onClick={handleCustomerLedgerClick}
  >
  Customer Ledger
  </button>

  </li>
  <li>

  <button
  type="submit"
  disabled={tableRows.length === 0}
  className="btn btn-primary eposbtn"
  data-bs-toggle="modal"
  data-bs-target="#proforma_invoice"
  >
  Proforma
  </button>
  </li>
  <li>
  <button
  type="submit"
  disabled={tableRows.length === 0}
  class="btn btn-primary eposbtn"
  data-bs-toggle="modal"
  data-bs-target="#verticalycentered"
  >
  Finish & Pay
  </button>

  </li>
  <li>
  <button
  type="submit"
  class="btn btn-primary eposbtn"
  data-bs-toggle="modal"
  data-bs-target="#incomplete-order"
  onClick={handleIncompleteOrder}
  >
  Incomplete Orders
  </button>

  </li>
  <li>
  <button
  type="submit"
  class="btn btn-primary eposbtn"
  onClick={handlePlaceOrder}
  disabled={tableRows.length === 0}
  >
  Save & Exit
  </button>

  </li>
  <li>
  <button
  type="submit"
  class="btn btn-primary eposbtn"
  onClick={handleCancelOrder}
  >
  Cancel & Exit
  </button>

  </li>

  <li>

  <button
  type="submit"
  disabled={tableRows.length === 0}
  className="btn btn-primary eposbtn"
  data-bs-toggle="modal"
  data-bs-target="#Order_Receipt"
  >
  Order Receipt
  </button>

  </li> 

  <li>
  <button type="submit" class="btn btn-primary eposbtn">Proforma</button>
  <button type="submit" className="btn btn-primary eposbtn" data-bs-toggle="modal" data-bs-target="#opening_balance">Opening Balance</button>
  </li>

  </ul>
  </div>
                    </div> */}
  </div>
  </div>
  </div>
  </div>
  </div>
  </section>
  </div>
  <Footer />
  </div>
  );
};

export default Epos;
// JavaScript Document
