import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Header from '../../Header';
import Footer from '../../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../../common/Config";
import Swal from 'sweetalert2';

const Add_Supplier = () => {
  const accessToken = localStorage.getItem('accessToken');
  const navigateToSupplierList = useNavigate();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    company: '',
    name: '',
    email: '',
    phone: '',
    contact_person: '',
    contact_person_mob: '',
    contact_person_email: '',
    finance_person: '',
    finance_person_mob: '',
  });
  
  const validateForm = () => {
    const newErrors = {};

    // Company validation
    if (!formData.company) {
      newErrors.company = "Mention company Name.";
    } else if (formData.company.length < 2) {
      newErrors.company = "Company name must consist of at least 2 characters.";
    } else if (formData.company.length > 255) {
      newErrors.company = "Company name must not exceed 255 characters.";
    }

    // Name validation
    if (!formData.name) {
      newErrors.name = "Mention Name.";
    } else if (formData.name.length < 2) {
      newErrors.name = "Name must consist of at least 2 characters.";
    } else if (formData.name.length > 255) {
      newErrors.name = "Name must not exceed 255 characters.";
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone is Required.";
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be between 10 and 12 digits.";
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Mention Email Id.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email.";
    }

    // Contact person validation
    if (!formData.contact_person) {
      newErrors.contact_person = "Mention person Name.";
    } else if (formData.contact_person.length < 2) {
      newErrors.contact_person = "Contact person name must consist of at least 2 characters.";
    } else if (formData.contact_person.length > 255) {
      newErrors.contact_person = "Contact person name must not exceed 255 characters.";
    }

    // Contact person mobile validation
    if (!formData.contact_person_mob) {
      newErrors.contact_person_mob = "Contact person mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.contact_person_mob)) {
      newErrors.contact_person_mob = "Contact person mobile must be between 10 and 12 digits.";
    }

    // Contact person email validation
    if (!formData.contact_person_email) {
      newErrors.contact_person_email = "Mention Contact Person Email.";
    } else if (!/\S+@\S+\.\S+/.test(formData.contact_person_email)) {
      newErrors.contact_person_email = "Please enter a valid contact person email.";
    }

    // Finance person validation
    if (!formData.finance_person) {
      newErrors.finance_person = "Mention Finance Person Name.";
    } else if (formData.finance_person.length < 2) {
      newErrors.finance_person = "Finance person name must consist of at least 2 characters.";
    } else if (formData.finance_person.length > 255) {
      newErrors.finance_person = "Finance person name must not exceed 255 characters.";
    }


    if (!formData.finance_person_mob) {
      newErrors.finance_person_mob = "finance mobile is Required.";
    } else if (!/^\d{10,12}$/.test(formData.finance_person_mob)) {
      newErrors.finance_person_mob = "finance person mobile must be between 10 and 12 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let fieldErrors = {};

    // Validate the input field immediately as the user types
    switch (name) {
      case 'company':
        if (!value) {
          fieldErrors.company = "Mention company Name.";
        } else if (value.length < 2) {

          fieldErrors.company = "Company name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.company = "Company name must not exceed 255 characters.";
        }

        // Clear error if all conditions are met
        if (!fieldErrors.company) {
          //delete fieldErrors.company;
          fieldErrors.company = '';
        }
        break;

      case 'name':
        if (!value) {
          fieldErrors.name = "Mention Name.";
        } else if (value.length < 2) {
          fieldErrors.name = "Name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.name = "Name must not exceed 25 characters.";
        }
        if (!fieldErrors.name) {
          fieldErrors.name = '';
        }
        break;

      case 'phone':
        if (!value) {
          fieldErrors.phone = "Phone is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.phone = "Phone number must be between 10 and 12 digits.";
        }
        if (!fieldErrors.phone) {

          fieldErrors.phone = '';
        }
        break;

      case 'email':
        if (!value) {
          fieldErrors.email = "Mention Email Id.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.email = "Please enter a valid email.";
        }
        if (!fieldErrors.email) {
          fieldErrors.email = '';
        }
        break;

      case 'contact_person':
        if (!value) {
          fieldErrors.contact_person = "Mention person Name.";
        } else if (value.length < 2) {
          fieldErrors.contact_person = "Contact person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.contact_person = "Contact person name must not exceed 255 characters.";
        }
        if (!fieldErrors.contact_person) {
          fieldErrors.contact_person = '';
        }
        break;

      case 'contact_person_mob':
        if (!value) {
          fieldErrors.contact_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.contact_person_mob = "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.contact_person_mob) {
          fieldErrors.contact_person_mob = '';
        }
        break;

      case 'contact_person_email':
        if (!value) {
          fieldErrors.contact_person_email = "Mention Contact Person Email.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.contact_person_email = "Please enter a valid contact person email.";
        }
        if (!fieldErrors.contact_person_email) {
          fieldErrors.contact_person_email = '';
        }
        break;

      case 'finance_person':
        if (!value) {
          fieldErrors.finance_person = "Mention Finance Person Name.";
        } else if (value.length < 2) {
          fieldErrors.finance_person = "Finance person name must consist of at least 2 characters.";
        } else if (value.length > 255) {
          fieldErrors.finance_person = "Finance person name must not exceed 255 characters.";
        }
        if (!fieldErrors.finance_person) {
          fieldErrors.finance_person = '';
        }
        break;

      case 'finance_person_mob':
        if (!value) {
          fieldErrors.finance_person_mob = "Contact person mobile is Required.";
        } else if (!/^\d{10,12}$/.test(value)) {
          fieldErrors.finance_person_mob = "Contact person mobile must be between 10 and 12 digits.";
        }
        if (!fieldErrors.finance_person_mob) {
          fieldErrors.finance_person_mob = '';
        }
        break;

      default:
        break;
    }

    // Update errors state for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...fieldErrors
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked ? '1' : '0', // Set '1' if checked, null if unchecked
    }));
  };


  const submitSalesOrderForm = async () => {

    if (!validateForm()) return; // Validate before submission

    const form = document.getElementById('addsupplierform');


    const formData = new FormData(form);
    try {
      const response = await axios.post(`${config.API_URL}/add_supplier`, formData, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        }
      });

      const result = response.data;

      if (result.success == true) {
        Swal.fire({
          title: "Success!",
          text: "Supplier added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => {
          navigateToSupplierList(`/supplier/`);
        });

      } else {
        Swal.fire({
          title: "Failed!",
          text: "Supplier add Failed. Try Again",
          icon: "error",
          timer: 1500,
          showConfirmButton: false,
        });
      }


    } catch (error) {
      console.error("Error accepting order:", error);
    }
  };



  useEffect(() => {

    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    //navigateToSupplierList(`/supplier/`);


  }, []);

  return (
    <div>
      <Header />
      <div class="breadcrumbs">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="Dashboard">Master</a> &gt; <span class="last-crumb">Add Supplier</span> </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-sm-12">
              <div style={{ position: 'relative' }}>
                <div className='add-supplier-popup'>
                  <form id='addsupplierform'>

                    <h5>Company Detail</h5>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="company" className="col-form-label">Company <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" name="company" id="company" value={formData.company} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.company && <span className="text-danger">{errors.company}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="name" className="col-form-label">Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" name="name" id="name" value={formData.name} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.name && <span className="text-danger">{errors.name}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="email" className="col-form-label">Email Address <span style={{ color: 'red' }}>*</span></label>
                            <input type="email" className="form-control" name="email" id="email" value={formData.email} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.email && <span className="text-danger">{errors.email}</span>}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="phone" className="col-form-label">Phone <span style={{ color: 'red' }}>*</span></label>
                            <input type="number" className="form-control no-arrows" name="phone" id="phone" value={formData.phone} onChange={handleChange} style={{ appearance: 'none', MozAppearance: 'textfield' }} />
                          </div>
                          <div className="col-sm-12">
                            {errors.phone && <span className="text-danger">{errors.phone}</span>}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div className='col-4'>
                        <div className="row">
                          <div className="col-sm-12">
                            <label htmlFor="address" className="col-form-label">Address</label>
                            <textarea className="form-control" id="address" style={{ height: "110px" }} />
                          </div>
                        </div>
                      </div>

                      <div className='col-8'>
                        <div className='row'>
                          <div className="col-sm-4">
                            <label htmlFor="city" className="col-form-label">City</label>
                            <input type="text" className="form-control" name="city" id="city" />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="state" className="col-form-label">State</label>
                            <input type="text" className="form-control" name="state" id="state" />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="country" className="col-form-label">Country</label>
                            <input type="text" className="form-control" name="country" id="country" />
                          </div>

                          <div className="col-sm-4">
                            <label htmlFor="postal_code" className="col-form-label">Postal Code</label>
                            <input type="text" className="form-control" name="postal_code" id="postal_code" />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="email" className="col-form-label">Email Address <span style={{ color: 'red' }}>*</span></label>
                            <input type="email" className="form-control" name="email" id="email" value={formData.email} onChange={handleChange} />
                            <div className="col-sm-12">
                              {errors.email && <span className="text-danger">{errors.email}</span>}
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="phone" className="col-form-label">Phone <span style={{ color: 'red' }}>*</span></label>
                            <input type="number" className="form-control no-arrows" name="phone" id="phone" value={formData.phone} onChange={handleChange} style={{ appearance: 'none', MozAppearance: 'textfield' }} />
                            <div className="col-sm-12">
                              {errors.phone && <span className="text-danger">{errors.phone}</span>}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <h5>Contact Person</h5>

                    <div className="row mb-3 padd-l-13px">

                      <div className="col-sm-3">
                        <label htmlFor="city" className="col-form-label">Name</label>
                        <input type="text" className="form-control" name="city" id="city" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="phone" className="col-form-label">Phone <span style={{ color: 'red' }}>*</span></label>
                        <input type="number" className="form-control no-arrows" name="phone" id="phone" value={formData.phone} onChange={handleChange} style={{ appearance: 'none', MozAppearance: 'textfield' }} />
                        <div className="col-sm-12">
                          {errors.phone && <span className="text-danger">{errors.phone}</span>}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="contact_person_email" className="col-form-label">Email <span style={{ color: 'red' }}>*</span></label>
                            <input type="email" className="form-control" name="contact_person_email" id="contact_person_email" value={formData.contact_person_email} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.contact_person_email && <span className="text-danger">{errors.contact_person_email}</span>}
                          </div>
                        </div>
                      </div>

                    </div>

                    <h5>Banking & Finance Details</h5>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="finance_person" className="col-form-label">Financial Person Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" className="form-control" name="finance_person" id="finance_person" value={formData.finance_person} onChange={handleChange} />
                          </div>
                          <div className="col-sm-12">
                            {errors.finance_person && <div className="text-danger">{errors.finance_person}</div>}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="col-sm-12">
                            <label htmlFor="finance_person_mob" className="col-form-label">Financial Person Mobile <span style={{ color: 'red' }}>*</span></label>
                            <input type="number" className="form-control no-arrows" name="finance_person_mob" id="finance_person_mob" value={formData.finance_person_mob} onChange={handleChange} style={{ appearance: 'none', MozAppearance: 'textfield' }} />
                          </div>
                          <div className="col-sm-12">
                            {errors.finance_person_mob && <div className="text-danger">{errors.finance_person_mob}</div>}
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <label htmlFor="finance_person_email" className="col-form-label">Financial Person Email</label>
                        <input type="email" className="form-control" name="finance_person_email" id="finance_person_email" />
                      </div>


                      {/* <div className="col-sm-3">
                        <label htmlFor="po_delivery_days" className="col-form-label">Standard PO Delivery (in days)</label>
                        <input type="text" className="form-control" name="po_delivery_days" id="po_delivery_days" />
                      </div> */}
                      {/* <div className="col-sm-3">
                        <label htmlFor="payment_clearance_days" className="col-form-label">Invoice Payment Clearance after (in days)</label>
                        <input type="text" className="form-control" name="payment_clearance_days" id="payment_clearance_days" />
                      </div> */}
                      {/* <div className="col-sm-3">
                        <label htmlFor="tobacco" className="col-form-label">Allowed for Tobacco Product Supply</label>
                        <select name="tobacco" className="form-select orgselect">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div> */}
                    </div>

                    <div className="row mb-3 padd-l-13px">
                      <div className="col-sm-3">
                        <label htmlFor="supplier_bank_name" className="col-form-label">Bank Name</label>
                        <input type="text" className="form-control" name="supplier_bank_name" id="supplier_bank_name" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="supplier_branch_name" className="col-form-label">Branch Name</label>
                        <input type="text" className="form-control" name="supplier_branch_name" id="supplier_branch_name" />
                      </div>

                      <div className="col-sm-3">
                        <label htmlFor="supplier_account_number" className="col-form-label">Bank Account Number</label>
                        <input type="text" className="form-control" name="supplier_account_number" id="supplier_account_number" />
                      </div>

                      <div className="col-sm-3">
                        <label htmlFor="supplier_branch_code" className="col-form-label">Branch Code</label>
                        <input type="text" className="form-control" name="supplier_branch_code" id="supplier_branch_code"  />
                      </div>
                    </div>

                    <h5>Licence Details</h5>

                    <div class="row mb-3 mt-3">
                      <div class="col-6">
                        <div class="col-12">
                          <div class="form-check">
                            <label class="checkbox">
                              <input
                                type="checkbox"
                                name="new_arrival"
                                checked={formData.new_arrival === '1'}
                                onChange={handleCheckboxChange}
                                value="1"
                              />
                              <span class="default"></span>
                            </label>
                            <label class="form-check-label" for="gridCheck1"> Tobacco Product </label>
                          </div>

                          {formData.new_arrival === '1' && (
                            <div className='arrivebox'>
 <div class="row">
                              <div class="col-6">
                               
                                  <div class="col-12">
                                  <label htmlFor="tpd_license_no" className="col-form-label">TPD License Number</label>
                                  </div>
                                  <div class="col-12">
                                  <input type="text" className="form-control" name="tpd_license_no" id="tpd_license_no" />
                                  </div>

                                
                              </div>

                              <div class="col-6">
                                <div class="col-12">
                                <label htmlFor="tpd_license_validity" className="col-form-label">TPD License Validity Till Date</label>
                                </div>
                                <div class="col-12">
                                <input type="date" className="form-control" name="tpd_license_validity" id="tpd_license_validity" />
                                </div>
                              </div>
</div>


                            </div>
                          )}

                        </div>
                      </div>

                      <div class="col-6">
                        <div class="col-12">
                          <div class="form-check">
                            <label class="checkbox">

                              <input
                                type="checkbox"
                                name="is_discount_allow"
                                checked={formData.is_discount_allow === '1'}
                                onChange={handleCheckboxChange}
                                value="1"
                              />
                              <span class="default"></span>
                            </label>
                            <label class="form-check-label" for="gridCheck2"> Alcohol Licence </label>
                          </div>
                          {formData.is_discount_allow === '1' && (
                             <div className='arrivebox'>
                             <div class="row">
                             <div class="col-6">
                              
                                 <div class="col-12">
                                 <label htmlFor="awrs_license_no" className="col-form-label">AWRS License Number</label>
                                 </div>
                                 <div class="col-12">
                                 <input type="text" className="form-control" name="awrs_license_no" id="awrs_license_no" />
                                 </div>

                               
                             </div>

                             <div class="col-6">
                               <div class="col-12">
                               <label htmlFor="awrs_license_validity" className="col-form-label">AWRS License Validity Till Date</label>
                               </div>
                               <div class="col-12">
                               <input type="date" className="form-control" name="awrs_license_validity" id="awrs_license_validity" />
                               </div>
                             </div>
                            </div>
                            </div>

                          )}

                        </div>
                      </div>
                    </div>


                  


                    <div className='col-12 col-lg-12 col-sm-12'>
                      <hr />
                    </div>

                    <div className='row mb-3 padd-l-13px'>
                      <div className='col-12 col-lg-12 col-sm-12' style={{ textAlign: "center" }}>
                        <button onClick={submitSalesOrderForm} type="button" className="common-popup-btn">Add Supplier</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Add_Supplier; 