import React, { useState } from "react";
import Select, { components } from "react-select";

const Multiselect = ({ productOptions }) => {
  // State to manage selected options
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Handle selection changes
  const handleChange = (selected) => {
    setSelectedOptions(selected || []);
  };

  // Custom Option Component with Checkbox
  const CustomOption = (props) => {
    const { data, isSelected } = props;
    return (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null} 
          style={{ marginRight: 8 }}
        />
        {data.label}
      </components.Option>
    );
  };

  // Render the Multiselect Dropdown
  return (
    <div style={{ width: "300px", margin: "0 auto" }}>
      <Select
        isMulti
        options={productOptions} // Use productOptions passed as a prop
        value={selectedOptions}
        onChange={handleChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option: CustomOption }}
        placeholder="Select products..."
        styles={{
          control: (provided) => ({
            ...provided,
            minHeight: "50px",
          }),
        }}
      />
    </div>
  );
};

export default Multiselect;
