import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import jsPDF from 'jspdf';  
import 'jspdf-autotable';  
import generateSummaryPDF from './generateSummaryPDF';
import generateMultipleSummaryPDF_dds from './generateMultipleSummaryPDF_dds';


const MultipleSummaryPrint = (props) => {
	const { orderId } = useParams();
	const [shopDetails, setShopDetails] = useState([]);
	const [orderDetails, setOrderDetails] = useState([]);
	const [productDetails, setProductDetails] = useState([]);
	const [totalVal, setTotalVal] = useState({
		totalstd: 0,
		totalzeroGoods: 0,
		totalTax: 0,
		orderDiscount: 0,
		grandTotal: 0,
	}
	);
	const accessToken = localStorage.getItem('accessToken');
	const invoiceIds = JSON.parse(sessionStorage.getItem('invoiceIds'));
	console.log(invoiceIds); 
	

	const fetchInvoice = async () => {
		const formData = new FormData();
		formData.set('invoiceIds', invoiceIds);
		const apiUrl = `${config.API_URL}/ws-multiple-proforma-invoice`;
		try {
			const response = await fetch(apiUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to fetch Not Order Report');
			}
			const data = await response.json();
	   
			setShopDetails(data.details.shop_details);
			setOrderDetails(data.details.orderDetails[0]);
			setProductDetails(data.details.product_details);

			const totalstd = data.details.product_details
			.filter(item => item.item_tax !== '0.00' && item.item_tax != null)
			.reduce((sum, item) => sum + (parseFloat(item.subtotal) || 0), 0); 

			const totalzeroGoods = data.details.product_details
			.filter(item => item.item_tax === '0.00' || item.item_tax === null)
			.reduce((sum, item) => sum + (parseFloat(item.subtotal) || 0), 0); 


	   
			setTotalVal({
				totalstd,
				totalzeroGoods,
				totalTax: parseFloat(data.details.orderDetails[0].total_tax),
				orderDiscount: parseFloat(data.details.orderDetails[0].order_discount),
				grandTotal: parseFloat(data.details.orderDetails[0].grand_total),
			});


		} catch (error) {
			console.error('api fail Error fetching Not Order Report:', error);
		}
	};


	useEffect(() => {
		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}
		fetchInvoice();
	},[]);

	useEffect(() => {
		const table = $('#invoicePrint').DataTable({
			pageLength: 50,
			paging: false,
			lengthMenu: [10, 20, 50],
			searching: false,
			ordering: false,
			info: false,
			responsive: true,
			autoWidth: false,
			data: productDetails,
			columns: [
			{ 
				data: null,
				render: function (data, type, row) { 
					return `<span>${row.product_code} - ${row.product_name}</span> `;
				}
			},
			{ data: null, className : 'text-center',
			render : function(data, type, row){
				return `<span>${parseInt(row.quantity)}</span>`;
			}
		},
		{ 
			data: 'unit_price',
			className : 'right',
			render: function (data, type, row) { 
				return row.unit_price ? `<span>£ ${row.unit_price}</span>` : `<span>£ 0.00</span>`;
			}
		},
		{ 
			data: 'item_discount',
			className : 'right',
			render: function (data, type, row) { 
				return row.item_discount ? `<span>£ ${row.item_discount}</span>` : `<span>£ 0.00</span>`;
			}
		},
		{ 
			data: 'item_tax',
			className : 'right',
			render: function (data, type, row) { 
				return row.item_tax ? `<span>${row.item_tax}</span>` : `<span>0.00</span>`;
			}
		},
		{ 
			data: 'subtotal',
			className : 'right',
			render: function (data, type, row) { 
				return row.subtotal ? `<span>£ ${row.subtotal}</span>` : `<span>£ 0.00</span>`;
			}
		},

		],
			columnDefs: [
			{
				targets: [0], 
				orderable: false
			}
			],
			drawCallback: function (settings) {
				var api = this.api();

				$('tfoot').empty();

		        // Create footer rows with totals
				const footerRows = [
				{
					colSpan: 5,
					label: 'Total Std Goods',
					value: `£${totalVal.totalstd.toFixed(2)}`
				},
				{
					colSpan: 5,
					label: 'VAT Amount',
					value: `£${totalVal.totalTax.toFixed(2)}`
				},
				{
					colSpan: 5,
					label: 'Total Zero Goods',
					value: `£${totalVal.totalzeroGoods.toFixed(2)}`
				},
				{
					colSpan: 5,
					label: 'Promotion Discount',
					value: `£${totalVal.orderDiscount.toFixed(2)}`
				},
				{
					colSpan: 5,		         
					label: 'Invoice Total',
					value: `£${totalVal.grandTotal.toFixed(2)}`
				}
				];


				footerRows.forEach(row => {
					const footerRow = $('<tr>')
					.append(`<td colSpan="${row.colSpan}"><span class="right">${row.label}</span></td>`)
					.append(`<td><span class="right">${row.value}</span></td>`);
					$('tfoot').append(footerRow);
				});
			}
		});
		return () => {
			table.destroy();
		};
	}, [productDetails, totalVal]);

	// Function to generate pages of data
	const generatePages = (data, pageSize) => {
		const pages = [];
		for (let i = 0; i < data.length; i += pageSize) {
			pages.push(data.slice(i, i + pageSize));
		}
		return pages;
	};

  // Function to create PDF document
	const generatePDF = () => {
		const doc = new jsPDF();

		const pages = generatePages(productDetails, 22);
		const totalPages = pages.length;

		pages.forEach((pageData, index) => {
			if (index > 0) {
				doc.addPage();
			}
      // Add header
			doc.setFontSize(12);
			doc.text(`Invoice for Order ${orderDetails.reference_no}`, 10, 10);
			doc.text(`Company: ${shopDetails.shop_name}`, 10, 20);
			doc.text(`Date: ${orderDetails.invoice_date}`, 10, 30);

      // Add table
			const startY = 40;
			doc.autoTable({
				startY,
				head: [['Description', 'Quantity', 'Unit Price', 'VAT Amount', 'Net Amount']],
				body: pageData.map(item => [
					`${item.product_code} - ${item.product_name}`,
					item.quantity,
					`£${item.unit_price}`,
					item.item_tax ? item.item_tax : '0.00',
					`£${item.subtotal}`
					]),
				theme: 'grid',
				margin: { top: 10 },
				didDrawPage: () => {
					const table = doc.autoTable.previous;
					const endY = table.finalY;

          // Ensure valid footer placement
          if (index === totalPages - 1) {  // Last page
            const footerY = endY + 10; // Adjust this value as needed for spacing

            doc.setFontSize(10);

            // Safeguard against invalid coordinates
            const addFooterText = (text, y) => {
            	if (typeof y === 'number' && !isNaN(y) && y > 0) {
            		doc.text(text, 10, y);
            	}
            };

            addFooterText(`Total Std Goods: £${totalVal.totalstd.toFixed(2)}`, footerY);
            addFooterText(`VAT Amount: £${totalVal.totalTax.toFixed(2)}`, footerY + 10);
            addFooterText(`Total Zero Goods: £${totalVal.totalzeroGoods.toFixed(2)}`, footerY + 20);
            addFooterText(`Order Discount: £${totalVal.orderDiscount.toFixed(2)}`, footerY + 30);
            addFooterText(`Grand Total: £${totalVal.grandTotal.toFixed(2)}`, footerY + 40);
          }
        }
      });
		});

    // If there are fewer items than a full page, ensure footer is on the same page
		if (productDetails.length <= 20) {
			const table = doc.autoTable.previous;
			if (table) {
				const endY = table.finalY;
        const footerY = endY + 10; // Adjust this value as needed for spacing

        doc.setFontSize(10);

        const addRightAlignedText = (text, y) => {
        	const pageWidth = doc.internal.pageSize.width;
        	const textWidth = doc.getTextWidth(text);
	        const x = pageWidth - textWidth - 10; // 10 margin from the right edge
	        doc.text(text, x, y);
	      };


	      addRightAlignedText(`Total Std Goods: £${totalVal.totalstd.toFixed(2)}`, footerY);
	      addRightAlignedText(`VAT Amount: £${totalVal.totalTax.toFixed(2)}`, footerY + 10);
	      addRightAlignedText(`Total Zero Goods: £${totalVal.totalzeroGoods.toFixed(2)}`, footerY + 20);
	      addRightAlignedText(`Order Discount: £${totalVal.orderDiscount.toFixed(2)}`, footerY + 30);
	      addRightAlignedText(`Grand Total: £${totalVal.grandTotal.toFixed(2)}`, footerY + 40);
	    }
	  }

	  doc.save(`invoice_${orderId}.pdf`);
	};

	const newGeneratePDF = () => {
		const newDoc = new jsPDF();

    // Header
		const header = [
			[`Customer Name : ${orderDetails.company}`, `Order Date: ${orderDetails.date}`],
			[`Invoice No : INV${orderDetails.reference_no}`, `Status: ${orderDetails.sale_status}`],
			[`Cust Account No : ${orderDetails.account_no}`, `Trip No: ${orderDetails.trip_no}`],
			];

    // Footer
		const footer = [
			[`Total Std Goods: £${totalVal.totalstd.toFixed(2)}`],
			[`VAT Amount: £${totalVal.totalTax.toFixed(2)}`],
			[`Total Zero Goods: £${totalVal.totalzeroGoods.toFixed(2)}`],
			[`Order Discount: £${totalVal.orderDiscount.toFixed(2)}`],
			[`Invoice Total: £${totalVal.grandTotal.toFixed(2)}`],
			];

    // Table Columns
		const columns = [
			{ title: 'Description', dataKey: 'description' },
			{ title: 'Quantity', dataKey: 'quantity' },
			{ title: 'Unit Price', dataKey: 'unit_price' },
			{ title: 'VAT Amount', dataKey: 'item_tax' },
			{ title: 'Net Amount', dataKey: 'subtotal' },
			];

    // Add header

    // Add table
		const itemsPerPage = 20;
		const totalPages = Math.ceil(productDetails.length / itemsPerPage);

		for (let i = 0; i < totalPages; i++) {
			const startIndex = i * itemsPerPage;
			const endIndex = startIndex + itemsPerPage;
			const pageData = productDetails.slice(startIndex, endIndex);

			newDoc.autoTable({
				head: header,
				startY: 10,
				headStyles: { fontSize: 10, textColor: '#666', fillColor: '#eee' }, 
			});

			newDoc.autoTable({
				head: [columns.map(col => col.title)],
				body: pageData.map(item => [
					`${item.product_code} - ${item.product_name}`,
					item.quantity,
					`£ ${item.unit_price}`,
					item.item_tax || '0.00',
					`£ ${item.subtotal}`,
					]),
				startY: 35,
				headStyles: { fontSize: 10, textColor: '#fff', fillColor: '#e28483' },
				theme: 'grid',
			});

      // Add footer only on the last page
			if (i === totalPages - 1) {
				const table = newDoc.autoTable.previous;
				const endY = table.finalY;

				newDoc.autoTable({
					startY: endY + 10,
					body: footer.map(row => [row[0]]),
					styles: { fontSize: 10 },
				});
			}
      // Add page break if not last page
			if (i < totalPages - 1) {
				newDoc.addPage();
			}
		}

    //newDoc.save('OrderSummary.pdf');
		newDoc.save(`invoice_${orderDetails.reference_no}.pdf`);
	};


	const handleGenerateDeliveryNotePDF = (e, invoiceIds) => {
		if(accessToken=='dds' || accessToken=='ddd' ||  accessToken=='dan' ||  accessToken=='ayt'){
			alert(accessToken);  		
			generateMultipleSummaryPDF_dds(invoiceIds);
    	}
	}

	return (
		<>
		<Header />
		<div className="breadcrumbs">
		<div className="container-fluid">
		<div className="row">
		<div className="col-12 col-lg-12 col-sm-12"> 
		<Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		<Link to="/picking/proforma">Proforma Invoice</Link> 
		<span className="last-crumb">Invoice Details</span> 
		</div>
		</div>
		</div>
		</div>
		<div className="main-container">
		<div className="container-fluid">
		<div className="row">

		<div className="col-6">
		<button onClick={(e) => handleGenerateDeliveryNotePDF(e, orderId)}>
		Print Invoice 
		</button>
		</div>
		</div>
		<div className="row">
		<div className="col-12">
		<div className="show-order-area dashboard">
		<div className="sales-view-area">

		<div className="row" style={{paddingBottom: '10px', borderBottom: '1px solid #ccc'}} >
		<div className="col-7">
		<p>
		<span>{shopDetails.shop_name}</span><br /> TEL {shopDetails.phone}<br />
		VAT NO,<br />
		<span>COMPANY REG NO : {shopDetails.company_reg_no}</span>
		</p>
		</div>
		<div className="col-5">
		<ul>
		<li>PLEASE PHONE THE OFFICE TO <br /> PAY BY CREDIT/DEBIT CARD</li>
		<li><span>Bank A/C :</span> {shopDetails.bank_ac}</li>
		<li><span>Sort Code :</span> {shopDetails.sort_code}</li>
		</ul>
		</div>
		</div>			                  	
		<div className="row" style={{paddingTop: '10px'}}>
		<div className="col-8">
		<p>
		<span>{orderDetails.company}</span><br />{orderDetails.address}<br />
		{orderDetails.city}, {orderDetails.postal_code}, {orderDetails.country}<br />

		</p>
		</div>
		<div className="col-4">
		<ul>
		<li><span>Invoice No :</span>  INV{orderDetails.reference_no}</li>
		<li><span>Invoice Date :</span> {orderDetails.invoice_date}</li>
		<li><span>Cust Order Id :</span> {orderDetails.reference_no}</li>
		<li><span>Cust Account No :</span> {orderDetails.account_no}</li>
		<li><span>Trip No :</span> {orderDetails.trip_no}</li>
		</ul>
		</div>
		</div>

		<div className="sales-view-table">
		<table className="table table-bordered" id="invoicePrint">
		<thead>
		<tr>
		<th style={{backgroundColor: '#e28483'}} >Description</th>
		<th style={{backgroundColor: '#e28483'}} >Quantity</th>
		<th style={{backgroundColor: '#e28483'}} >Unit Price</th>
		<th style={{backgroundColor: '#e28483'}} >Discount</th>
		<th style={{backgroundColor: '#e28483'}} >VAT Amount</th>
		<th style={{backgroundColor: '#e28483'}} >Net Amount</th>
		</tr>
		</thead>
		<tbody>
		</tbody>
		<tfoot>
		<tr>
		<td colSpan="4">
		<span className="right">Total Std Goods</span>
		</td>
		<td><span>£43.84</span></td>
		</tr>
		<tr>
		<td colSpan="4">
		<span className="right">VAT Amount</span>
		</td>
		<td><span>£8.77</span></td>
		</tr>
		<tr>
		<td colSpan="4">
		<span className="right">Total Zero Goods</span>
		</td>
		<td><span>0.00</span></td>
		</tr>
		<tr>
		<td colSpan="4">
		<span className="right">Order Discount</span>
		</td>
		<td><span>0.00</span></td>
		</tr>
		<tr>
		<td colSpan="4">
		<span className="right">Total Amount</span>
		</td>
		<td><span>£52.61</span></td>
		</tr>
		</tfoot>
		</table>
		<p style ={{textAlign: 'center'}}>No claims for damages or short delivery will be considered unless made at the time of delivery of goods.These goods are not sold on a sale or return basis.</p>
		</div>
		</div>
		</div>
		</div>
		</div>






		</div>
		</div>

		<Footer />
		</>

		);
	}

	export default MultipleSummaryPrint;
