import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";

const ExpiryReport = () => {
    const getCurrentMonthRange = () => {
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };
      
        return `${formatDate(startOfMonth)} - ${formatDate(endOfMonth)}`;
      };
      
    const [reportrange, setReportrange] = useState(getCurrentMonthRange());
	const [fromDate, setFromDate] = useState('');
  	const [toDate, setToDate] = useState('');
	const [draw, setDraw] = useState(1);
  	const [start, setStart] = useState(0);
  	const [length, setLength] = useState(50);
  	const [dates, setDates] = useState([null, null]);
  	const [reportDate, setReportDate] = useState('');
  	const [notOrderReport, setNotOrderReport] = useState([]);
	const salesOrderRef = useRef(null);
  	const accessToken = localStorage.getItem('accessToken');      
  	const handleDateChange = (value) => {
    	setDates(value);
  	};

  	const formatDateRange = (dates) => {
    	if (dates[0] && dates[1]) {
      		const startDate = dates[0].toDateString();
      		const endDate = dates[1].toDateString();
      		return `${startDate} - ${endDate}`;
    	}
    	return 'Not selected';
  	};

      const fetchReport = (startDate, endDate) => {
        const $table = $(salesOrderRef.current);
        const searchValue = $('#searchValue').val();
      
        // Destroy any existing DataTable instance to reinitialize with new data
        if ($.fn.DataTable.isDataTable($table)) {
          $table.DataTable().destroy();
        }
      
        // Initialize the DataTable with the new date range
        $table.DataTable({
          processing: true,
          serverSide: true,
          ajax: {
            url: `${config.API_URL}/getExpiryReport`,
            type: 'POST',
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
            data: function (d) {
              return $.extend({}, d, {
                draw: d.draw,
                start: d.start,
                length: d.length,
                searchValue: searchValue,
                reportrange: reportrange,
                authorization: `Bearer ${accessToken}`,
              });
            },
            dataSrc: function (json) {
              if (json && json.data && Array.isArray(json.data)) {
                setNotOrderReport(json.data);
                setReportDate(`${json.startdate} To Date : ${json.enddate}`);
              }
      
              return Array.isArray(json.data) ? json.data : [];
            },
          },
          language: {
            paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous',
            },
            search: 'Search:',
          },
          columns: [
            { 
              data: null,
              render: function (data, type, row, meta) {
                // Calculate the serial number based on current page and row index
                const page = meta.settings._iDisplayStart / meta.settings._iDisplayLength;
                return (page * meta.settings._iDisplayLength) + meta.row + 1;
              }
            },
            { data: 'product_name' },
            {
              data: 'expiry',
              render: function (data) {
                if (data) {
                  const [year, month, day] = data.split('-'); // Split yyyy-mm-dd
                  return `${day}-${month}-${year}`; // Convert to dd-mm-yyyy
                }
                return ''; // Return empty string if no data
              }
            },              
            { 
              data: 'qty',
              render: function(data, type, row){
                return parseInt(row.qty);
              }
            },
            { data: 'status' }
          ],
          columnDefs: [
            { 
              targets: [0, 1, 2, 3], 
              orderable: false 
            }
          ],
          pageLength: 10,
        });
      };
      
	  useEffect(() => {
    	fetchReport();
  	},[reportrange]);

	  const searchFormData = () => {
		let startDate = dates[0] ? format(dates[0], 'dd/MM/yyyy') : '';
		let endDate = dates[1] ? format(dates[1], 'dd/MM/yyyy') : '';
	  
		// Convert them to 'MM/dd/yyyy' format for further use
		let formattedStartDate = dates[0] ? format(dates[0], 'MM/dd/yyyy') : '';
		let formattedEndDate = dates[1] ? format(dates[1], 'MM/dd/yyyy') : '';
	  
		setReportrange(formattedStartDate ? formattedStartDate + ' - ' + formattedEndDate : '');
		setReportDate(formattedStartDate + ' To Date : ' + formattedEndDate);
		// fetchReport();
	  };
	  
	return (
		<div>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/reports/customer_not_order_report">Reports</Link> 
		                  <Link to="/reports/customer_not_order_report">Expiry report</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">
			        <div className="row">
							<div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left' }}>
								<div class="row mb-3 padd-0">
									<label class="col-sm-4" style={{ marginBottom: '5px' }} ><b>Select Date</b></label>
									<div class="col-sm-7">
									<DateRangePicker
                                value={dates}
                                placeholder="DD/MM/YYYY - DD/MM/YYYY"
                                onChange={handleDateChange}
                                format="dd/MM/yyyy"
                                character=" - "
                                className="datepkr"
								style={{ width: '100%' }}
                              />
                            </div>
                        </div>
                    </div>	
                    <div className="col-md-2 col-lg-2 col-sm-6" style={{ float: 'left' }} >
                        <button type="button" onClick={searchFormData} className="acceptbtn" style={{ float: 'left' }} >Submit</button>
                    </div>
	                </div>
	                <div className="total-manifests-area dashboard">
	                    <p><b> From Date : {reportDate}</b></p>
	                    <div className="verical-scrl-wrapper common-table autoheight-table customer-report-area" id="style-3">
	                        <table ref={salesOrderRef} className="table table-bordered resizable" id="notOrderReport">
	                            <thead>
	                                <tr>
	                                    <th>Sr.No</th>
	                                    <th>Product Name</th>
	                                    <th>Expiry</th>
	                                    <th>Quantity</th>
                                      <th>Status</th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                            </tbody>
	                        </table>
	                    </div>
	                </div>
			    </div>
			</div>
			<Footer />
		</div>
	);
}


export default ExpiryReport;
