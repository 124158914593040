import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';

const Registration = () => {


    return (
        <div>
            <Header />
            <div class="breadcrumbs">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-sm-12"> <a href="#"><i class="fa fa-home"></i></a> &gt; <a href="/Dashboard">Dashboard</a> &gt; <a href="javascript:void(0)">Setting</a> &gt; <span class="last-crumb">Registration</span></div>
                    </div>
                </div>
            </div>

            <div className="main-container">
                <div className="container-md">
                    <div className="row">
                        <div className="col-sm-9 mx-auto">
                            <div>
                                <div className='registration'>
                                    <h3>Registration</h3>

                                    <form >
                                        
                                            <div class="row mb-3">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-4">
                                                            <label className='form-check-label'>First Name <b className='red'>*</b></label>
                                                        </div>
                                                        <div class="col-md-8 col-lg-8">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="first_name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-4">
                                                            <label className='form-check-label'>Last Name <b className='red'>*</b></label>
                                                        </div>
                                                        <div class="col-md-8 col-lg-8">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="Last Name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row mb-3">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-4">
                                                            <label className='form-check-label'>Email Address <b className='red'>*</b></label>
                                                        </div>
                                                        <div class="col-md-8 col-lg-8">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="first_name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-4">
                                                            <label className='form-check-label'>Contact No. <b className='red'>*</b></label>
                                                        </div>
                                                        <div class="col-md-8 col-lg-8">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="Last Name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-2">
                                                            <label className='form-check-label'>Address Line 1<b className='red'> *</b></label>
                                                        </div>
                                                        <div class="col-md-10 col-lg-10">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="first_name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-2">
                                                            <label className='form-check-label'>Address Line 2 </label>
                                                        </div>
                                                        <div class="col-md-10 col-lg-10">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="first_name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div class="row mb-3">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-4">
                                                            <label className='form-check-label'>City</label>
                                                        </div>
                                                        <div class="col-md-8 col-lg-8">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="first_name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-4">
                                                            <label className='form-check-label'>State</label>
                                                        </div>
                                                        <div class="col-md-8 col-lg-8">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="Last Name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="row">
                                                        <div class="col-md-4 col-lg-4">
                                                            <label className='form-check-label'>Zip code</label>
                                                        </div>
                                                        <div class="col-md-8 col-lg-8">
                                                            <div className="input-group">
                                                                <div className="col-sm-12">
                                                                    <input type="text" class="userinput" id="Last Name" />
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        


                                        <hr />

                                        <div class="mb-3">
                                            {/* <label class="col-md-4 col-lg-3 col-form-label">&nbsp;</label> */}
                                            <div class="col-md-8 col-lg-9">
                                                <button type="button" class="common-popup-btn">Register</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Registration;
