import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import axios from "axios";
import Header from "../../Header";
import Footer from "../../Footer";
import FlashMessage from "../../FlashMessage";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import $ from "jquery";
import "datatables.net-bs5";
import { Link, useParams, useNavigate} from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import config from "../../common/Config";
import Swal from 'sweetalert2';

const Contra_Price_Edit = () => {
  const [newSales, setNewSales] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [data, setData] = useState([]);
  const accessToken = localStorage.getItem("accessToken");
  const id  = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  let dateRange = '';
  const navigateToContraList = useNavigate();


  useEffect(() => {
    if (accessToken === null) {
      window.location.href = "/react/login";
      return;
    }
    fetchProductData();
    fetchPageData();
  }, []);


  const fetchPageData = async () => {
    const formData = new FormData();
    formData.append("id", id.customerId);
    const apiUrl = `${config.API_URL}/ws_get_customer_details_id`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      setData(data.data);
    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };

  const fetchProductData = async () => {
    const formData = new FormData();
    formData.append("id", id.customerId);
    const apiUrl = `${config.API_URL}/contra_price_edit`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch Not Order Report");
      }
      const data = await response.json();
      setTableRows(data.data);
    } catch (error) {
      console.error("api fail Error fetching Not Order Report:", error);
    }
  };

  const handleRemoveRow = async (rowId) => {
    const formData = new FormData();
    formData.append("id", rowId); 
    const apiUrl = `${config.API_URL}/contra_price_delete`;
  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });
  
      // Check for successful response
      if (!response.ok) {
        throw new Error("Failed to remove row from the server.");
      }
  
      // Parse response data
      const data = await response.json();
      if (data.success) {
        
        Swal.fire({
          title: "Success!",
          text: "Product removed successfully.",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        })

      } else {
        Swal.fire({
          title: 'Error',
          text: 'Failed to remove product.',
          icon: 'error',
          showConfirmButton: false, 
          timer: 1000,               
          timerProgressBar: true,     
        });
      }
  
      // Remove row from table if successful
      const updatedRows = tableRows.filter((row) => row.id !== rowId);
      setTableRows(updatedRows); 
  
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred while trying to remove the row.");
    }
  };
  

  useEffect(() => {
    const table = $("#neworderTable").DataTable({
      pageLength: 50,
      paging: true,
      lengthMenu: [10, 20, 50],
      searching: true,
      ordering: false,
      info: true,
      responsive: true,
      autoWidth: false,
      language: {
        paginate: {
          first: "First",
          last: "Last",
          next: "Next",
          previous: "Previous",
        },
        search: "Search:",
      },
      data: newSales,
      columns: [
        {
          data: null,
          render: function (data, type, row, meta) {
            return meta.row + 1;
          },
          createdCell: function (td) {
            $(td).css("text-align", "center");
          }
        },        
        {
            data: "company",
            render: function(data, type, row) {
              return `<a href="${config.API_URL}/customers/contra_price_edit/${row.id}" class="btn" target="_blank">${data}</a>`;
            }
        },          
        { data: "email" },
        { data: "phone" },
        { data: "accound_no" },
        { data: "postal_code" },
      ],
      columnDefs: [
        {
          targets: [1, 2, 3, 4, 5], // Allow sorting on other columns
          orderable: true,
        },
      ],
    });
    return () => {
      table.destroy();
    };
  }, [newSales]);


  const submitFormData = async () => {
    if (tableRows.length < 1) {
        Swal.fire({
           title: 'Error',
           text: 'Please add a product.',
           icon: 'error',
           showConfirmButton: false, 
           timer: 1000,               
           timerProgressBar: true,     
         });
         return;
     }

    setIsSubmitting(true);
    const form = document.getElementById('editcontraform');
    const formData = new FormData(form);
    let a = formData.getAll('product_price[]'); 
    let nonEmptyPrices = a.filter(price => price.trim() !== ''); 

    if(nonEmptyPrices.length !== tableRows.length )
    {
        Swal.fire({
            title: 'Error',
            text: 'Please enter price for each product.',
            icon: 'error',
            showConfirmButton: false, 
            timer: 1500,               
            timerProgressBar: true,     
          });
          setIsSubmitting(false);
          return;
    }
    formData.append('customer_id', id.customerId);
    const apiSalesRouteUrl = `${config.API_URL}/contra_price_update`;
    try {
        const response = await fetch(apiSalesRouteUrl, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });

        const data = await response.json();
        if(data.success)
        {
            Swal.fire({
                title: "Success!",
                text: "Contra price updated successfully.",
                icon: "success",
                timer: 1500,
                showConfirmButton: false,
              })

              navigateToContraList(`/ContraList`);
        }
        else
        {
            setIsSubmitting(false);
        }

    } catch (error) {
        console.error('api fail error fetching products');
    }
};

return (
  <div>
      <Header />
      <div className="breadcrumbs">
          <div className="container-fluid">
              <div className="row">
                  <div className="col-12 col-lg-12 col-sm-12">
                      <Link to="/Dashboard">
                          <i className="fa fa-home"></i>
                      </Link>
                      <Link to="#">Customer</Link>
                      <Link to="#">Contra Price Edit</Link>
                  </div>
              </div>
          </div>
      </div>
      <div className="main-container">
          <div className="container-fluid">
              <div className="row">
		               
                   <div className="date-range-picker col-md-4 col-lg-4 col-sm-12" style={{ float: 'left' }}>
                     <div class="row mb-3 padd-0">
                       <label class="col-sm-3" style={{ marginBottom: '5px' }} ><b>Customer Name</b></label>
                       <div class="col-sm-9">
                       <input type="text" name="customer" value={data.company} disabled />
                       </div>
                     </div>
                   </div>
     
                   
                        
                  
                       </div>



              <div className="total-manifests-area dashboard" style={{ position: "relative" }}>
                 
                      <form id="editcontraform">
                          <div className="verical-scrl-wrapper common-table autoheight-table edit-contra-price" id="style-3">
                              <table className="table table-bordered dataTable resizable" id="newProductTable">
                                  <thead>
                                      <tr>
                                          <th style={{width: '80px'}}>Sr. No</th>
                                          <th>Product Name</th>
                                          <th>Price</th>
                                          <th style={{width: '120px'}}>Action</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {tableRows.length === 0 ? (
                                          <tr style={{ textAlign: "center" }}>
                                              <td colSpan={4}>No product added</td>
                                          </tr>
                                      ) : (
                                          tableRows.map((row, i) => (
                                              <tr key={row.id}>
                                                  <td>{i + 1}
                                                      <input type="hidden" name="product_id[]" value={row.product_id} />
                                                      <input type="hidden" name="id[]" value={row.id} />
                                                  </td>
                                                  <td>{row.name} ({row.code})</td>
                                                  <td>
                                                      <input 
                                                          type="text" 
                                                          name="product_price[]" 
                                                          defaultValue={row.price} 
                                                          className="form-control" 
                                                      />
                                                  </td>
                                                  {/* <td>
                                                      <input
                                                          type="button"
                                                          className="icon editbtn"
                                                          value="X"
                                                          onClick={() => handleRemoveRow(row.id)} 
                                                      />
                                                  </td> */}


                                                  <td>
                <a class="icon editbtn" onClick={() => handleRemoveRow(row.id)} title="Edit">
                  <i class="fa fa-trash"></i>
                </a></td>

                                              </tr>
                                          ))
                                      )}
                                  </tbody>
                              </table>
                          </div>
                      </form>
                      <div className="row">
                          <div className="col-12" style={{ paddingTop: '20px' }}>
                              <button
                                  onClick={submitFormData}
                                  type="button"
                                  className="acceptbtn"
                                  disabled={isSubmitting}
                              >
                                  {isSubmitting ? 'Submitting...' : 'Submit'}
                              </button>
                          </div>
                      </div>
                  
              </div>
          </div>
      </div>
      <Footer />
  </div>
);
};

export default Contra_Price_Edit;