import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import config from "../common/Config";
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Finish_Pay_Incomplete_Order from "./Finish_Pay_Incomplete_Order";

const IncompleteOrderList = () => {
	const eposSaleTableRef = useRef(null);
	const [searchValue, setSearchValue] = useState([]);
	const modalRef = useRef(null);
	const accessToken = localStorage.getItem('accessToken');
	const user_id = localStorage.getItem('userId');
	const [customerInputValue, setCustomerInputValue] = useState('');
	const [payDate, setPayDate] = useState(new Date().toISOString().split('T')[0]);
	const [paymentType, setPaymentType] = useState('');
	const [chequeNo, setChequeNo] = useState('');
	const [payAmount, setPayAmount] = useState('');
	const [reference, setReference] = useState('');
	const [isChequeVisible, setChequeVisible] = useState(false);
	const [grandTotal, setGrandTotal] = useState(0);
	const [orderId, setOrderId] = useState(0);
	const [tableRow, setTableRow] = useState([]);

	const handlePaymentTypeChange = (e) => {
		const selectedType = e.target.value;
		setPaymentType(selectedType);
		setChequeVisible(selectedType === 'Cheque');
	};

	const closeModal = () => {
		document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove());
	}

	const handleCustomerPaymentSubmit = async (event) => {
		event.preventDefault();

		const saleId = document.getElementById('saleIdInput').value;
		const formData = new FormData();
		formData.append('user_id', user_id);
		formData.append('customer_id', customerInputValue.value);
		formData.append('date', payDate);
		formData.append('amount', payAmount);
		formData.append('paid_by', paymentType);
		formData.append('reference_no', reference);
		formData.append('cheque_no', chequeNo);
		formData.append('sale_id', saleId);

		const apiBrandUrl = `${config.API_URL}/pos_payment`;
		try {
			const response = await fetch(apiBrandUrl, {
				method: 'POST',
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error('Failed to add payment');
			}

			const data = await response.json();
			// console.log(data);
			const msg = data.message;
			if (data.success) {
				Swal.fire({
					title: "Success!",
					text: "Payment added successfully.",
					icon: "success",
					timer: 1500,
					showConfirmButton: false,
				}).then(() => {
				});

				fetchEposSalesTable();
				document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove());

				// .modal-backdrop.show 



			} else {
				Swal.fire({
					title: "Failed!",
					text: msg,
					icon: "error",
					timer: 1500,
					showConfirmButton: false,
				});
			}

		} catch (error) {
			console.error('Error adding payment:', error);
		}
	};

	const fetchEposSalesTable = () => {
		const $table = $(eposSaleTableRef.current);
		const searchValue = $('#searchValue').val();
	  
		if ($.fn.DataTable.isDataTable($table)) {
		  $table.DataTable().destroy();
		}
	  
		let i = 0;
		$table.DataTable({
		  processing: true,
		  serverSide: true,
		  ajax: {
			url: `${config.API_URL}/sales/pos_list`,
			type: 'POST',
			headers: {
			  authorization: `Bearer ${accessToken}`,
			},
			data: function (d) {
			  return $.extend({}, d, {
				draw: d.draw,
				start: d.start,
				length: d.length,
				searchValue: searchValue,
				sale_status: 'Incomplete',
			  });
			},
			dataSrc: function (json) {
			  setTableRow(json.data); 
			  return Array.isArray(json.data) ? json.data : [];
			}
		  },
		  language: {
			paginate: {
			  first: 'First',
			  last: 'Last',
			  next: 'Next',
			  previous: 'Previous',
			},
			search: 'Search:',
		  },
		  columns: [
			{
			  data: null,
			  width: '8%',
			  render: function (data, type, row) {
				return `<span>${row.date}</span>`;
			  }
			},
			{
			  data: null,
			  width: '10%',
			  render: function (data, type, row) {
				return '<a href="' + config.BASE_URL + '/order_summary/print/' + row.ids + '">' + row.reference_no + '</a>';
			  }
			},
			{
			  data: null,
			  width: '12%',
			  render: function (data, type, row) {
				return `<span>${row.customer}</span>`;
			  }
			},
			{
			  data: null,
			  width: '12%',
			  render: function (data, type, row) {
				return `<span class="text-right">${row.grand_total}</span>`;
			  },
			  createdCell: function (td) {
				$(td).css("text-align", "right");
			  }
			},
			{
			  data: null,
			  width: '12%',
			  render: function (data, type, row) {
				return `<span>${row.grand_total}</span>`;
			  },
			  createdCell: function (td) {
				$(td).css("text-align", "right");
			  }
			},
			{
			  data: null,
			  width: '12%',
			  render: function (data, type, row) {
				return `<span>00.0</span>`;
			  },
			  createdCell: function (td) {
				$(td).css("text-align", "right");
			  }
			},
			{
			  data: null,
			  width: '12%',
			  render: function (data, type, row) {
				return `<span>${row.sale_status}</span>`;
			  }
			},
			{
			  data: null,
			  width: '12%',
			  render: function (data, type, row) {
				return `<span>Unpaid</span>`;
			  }
			},
			{
			  data: null,
			  width: '12%',
			  render: function (data, type, row) {
				return `
				  <button class="change-vehicl-btn mdlBtn action-btn" data-grand_total="${row.grand_total}" data-id="${row.ids}">
					Pay
				  </button>`;
			  }
			}
		  ],
		  columnDefs: [
			{
			  orderable: false,
			  targets: '_all'
			},
		  ],
		  pageLength: 10,
		});
	  
		$(document).on('click', '.action-btn', function () {
		  const saleId = $(this).data('id');
		  const grand_total = $(this).data('grand_total');
		  // Clear all the fields correctly
		  $('#date').val('');
		  $('#paid_by_1').val('');
		  $('#amount').val('');
		  $('#reference').val('');
		  $('#cheque_1').val('');
		  
		  openModalWithSaleId(saleId, grand_total);
		});
	  };
	  

	const openModalWithSaleId = (saleId, grand_total) => {
		setOrderId(saleId);
		setGrandTotal(grand_total);
		// alert(grand_total);
		const modalElement = document.getElementById('modalId');
		const modal = new window.bootstrap.Modal(modalElement, {
			backdrop: true,
			keyboard: true,
			focus: true
		});
		modal.show();
	};

	useEffect(() => {
		if (accessToken === null) {
			window.location.href = `${config.BASE_URL}/login`;
			return;
		}
		fetchEposSalesTable();
		return () => {
			if (eposSaleTableRef.current && $.fn.DataTable.isDataTable(eposSaleTableRef.current)) {
				$(eposSaleTableRef.current).DataTable().destroy();
			}
		};
	}, [searchValue]);

	
	useEffect(() => {
		console.log(tableRow);
	}, [tableRow]);

	return (
		<div>
			<Header />
			<Finish_Pay_Incomplete_Order
				orderId={orderId}
				grandTotal={grandTotal}
				fetchEposSalesTable={fetchEposSalesTable}
			/>
			<div className="breadcrumbs">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<a href="#"><i className="fa fa-home"></i></a> &gt; <a href="Dashboard">Epos</a> &gt; <span className="last-crumb">POS Sales</span>
						</div>
					</div>
				</div>
			</div>
			<div className="main-container">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 col-lg-12 col-sm-12">
							<div className="show-order-area dashboard" style={{ position: 'relative' }}>
								<div className="row">
									<div className="col-12 col-lg-4 col-sm-4">

									</div>

								</div>
								<div>
									<div className="verical-scrl-wrapper common-table incomplete-order-list" id="style-3">
										<table className="table table-bordered dataTable resizable" id="neworderTable" ref={eposSaleTableRef}>
											<thead>
												<tr>
													<th>Date</th>
													<th>Reference No</th>
													<th>Customer</th>
													<th>Grand Total</th>
													<th>Paid</th>
													<th>Balance</th>
													<th>Sale Status</th>
													<th>Payment Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>

											</tbody>
										</table>

										<div ref={modalRef} className="modal fade" aria-hidden="true" tabIndex="-1" role="dialog" id='modalId'>
											<div className="modal-dialog" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title">Add Payment</h5>
														<button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" onClick={closeModal}></button>
													</div>
													<div className='add-payment-popup'>
														<form id='addCustomerForm' onSubmit={handleCustomerPaymentSubmit}>
															<div className="modal-body">

																<div className="row">
																	<div className="col-md-6">
																		<div className="form-group">
																			<label style={{ float: 'left' }} htmlFor="date" className="col-form-label">Date: *</label>
																			<input
																				type="date"
																				className="form-control"
																				id="date"
																				value={payDate}
																				name="date"
																				required
																				onChange={(e) => setPayDate(e.target.value)}
																			/>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className="form-group">
																			<label style={{ float: 'left' }} className="col-form-label" htmlFor="paid_by_1">Paying by *</label>
																			<select
																				name="paid_by"
																				id="paid_by_1"
																				className="form-select"
																				required
																				value={paymentType}
																				onChange={handlePaymentTypeChange}
																			>
																				<option value="">Select Payment Type</option>
																				<option value="cash">Cash</option>
																				<option value="CC">Credit Card</option>
																				<option value="Cheque">Cheque</option>
																			</select>
																		</div>
																	</div>
																	{isChequeVisible && (
																		<div className="col-md-6">
																			<div className="form-group">
																				<label style={{ float: 'left' }} htmlFor="cheque_1" className="col-form-label">Cheque No: *</label>
																				<input
																					type="text"
																					name="cheque"
																					className="form-control"
																					id="cheque_1"
																					required
																					value={chequeNo}
																					onChange={(e) => setChequeNo(e.target.value)}
																				/>
																			</div>
																		</div>
																	)}
																	<div className="col-md-6">
																		<div className="form-group">
																			<label htmlFor="amount" style={{ float: 'left' }} className="col-form-label">Amount: *</label>
																			<input
																				type="number"
																				className="form-control no-arrows"
																				id="amount"
																				required
																				value={payAmount}
																				onChange={(e) => setPayAmount(e.target.value)}
																			/>
																		</div>
																	</div>
																	<div className="col-md-6">
																		<div className="form-group">
																			<label htmlFor="reference" style={{ float: 'left' }} className="col-form-label">Reference:</label>
																			<input
																				type="text"
																				className="form-control"
																				id="reference"
																				name="reference"
																				value={reference}
																				onChange={(e) => setReference(e.target.value)}
																			/>
																		</div>
																	</div>
																</div>


															</div>
															<div className="modal-footer">
																<input
																	type="hidden"
																	id="saleIdInput"
																	className="form-control"
																	readOnly
																/>
																<button type="button" className="common-closebtn" data-bs-dismiss="modal" onClick={closeModal} >Close</button>
																<button type="submit" className="float-right-btn common-popup-btn" data-bs-dismiss="modal" >Submit</button>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />

		</div>
	);

}
export default IncompleteOrderList;